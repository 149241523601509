import apiRequest from '@helpers/apiRequest';

//MAILING

const getMailingDetail = idMailing => {
    return apiRequest('GET', `Mailings/Details/${idMailing}`);
};

const updateMailing = data => {
    const idMailing = data.idMailing;
    return apiRequest('PUT', `Mailings/Update/${idMailing}`, data);
};

const newMailing = data => {
    return apiRequest('POST', 'Mailings/New', data);
};

const deleteMailing = data => {
    const idMailing = data.idMailing;
    return apiRequest('DELETE', `Mailings/Delete/${idMailing}`);
};

const getMailingsList = () => {
    return apiRequest('GET', 'Mailings/ListMailing');
};

const getMailingsCategories = () => {
    return apiRequest('GET', 'MailingsType/ListMailingCategory');
};
//TARGETS

const getTargetDetail = idTarget => {
    return apiRequest('GET', `Targets/Details/${idTarget}`);
};

const updateTarget = data => {
    const idTarget = data.idTarget;
    return apiRequest('PUT', `Targets/Update/${idTarget}`, data);
};

const newTarget = data => {
    return apiRequest('POST', 'Targets/New', data);
};

const deleteTarget = data => {
    const idTarget = data.idTarget;
    return apiRequest('DELETE', `Targets/Delete/${idTarget}`);
};

// WORKSHEETS
const getWorksheetDetail = idWorksheet => {
    return apiRequest('GET', `Worksheets/Details/${idWorksheet}`);
};

const updateWorksheet = data => {
    const idWorksheet = data.idWorksheet;
    return apiRequest('PUT', `Worksheets/Update/${idWorksheet}`, data);
};

const newWorksheet = data => {
    return apiRequest('POST', 'Worksheets/New', data);
};

const deleteWorksheet = data => {
    const idWorksheet = data.idWorksheet;
    return apiRequest('DELETE', `Worksheets/Delete/${idWorksheet}`);
};

// TAGS
const getTagsDetail = () => {
    return apiRequest('GET', `Mailings/ListTags`);
};

const serviceRecords = {
    getMailingDetail,
    updateMailing,
    newMailing,
    deleteMailing,
    getMailingsList,
    getMailingsCategories,
    getTargetDetail,
    updateTarget,
    newTarget,
    deleteTarget,
    getWorksheetDetail,
    updateWorksheet,
    newWorksheet,
    deleteWorksheet,
    getTagsDetail,
};

export default serviceRecords;