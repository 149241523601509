import { Fragment, useState } from 'react';
import DeletePopover from '@components/DeletePopover';
import DialogEmail from '@components/DialogEmail';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {Paragliding} from "@mui/icons-material";
import {
    Box,
    Button,
    ButtonGroup,
    Chip,
    Grid,
    MenuItem,
    Select,
    Typography,
    Checkbox,
    Tooltip
} from '@mui/material';
import { useCommonStyles } from '@styles/common.style';
import { useTranslation } from 'react-i18next';

const EmailConfiguration = ({
    getNewJourneys,
    setFormData,
    formData,
    journey,
    categories,
    emailList,
    showEmailItems,
    allEmails,
}) => {
    const classes = useCommonStyles();
    const { t } = useTranslation();
    const [showEmailModal, setShowEmailModal] = useState({
        open: false,
        loading: true,
        content: {},
    });
    const daysToText = days => {
        if (days === 0) {
            return t('CONFIG_JOURNEYS.EMAIL.SAME_DAY');
        }
        if (days < 0) {
            return `${Math.abs(days)} ${Math.abs(days) === 1
                    ? t('CONFIG_JOURNEYS.EMAIL.DAY')
                    : t('CONFIG_JOURNEYS.EMAIL.DAYS')
                } ${t('CONFIG_JOURNEYS.EMAIL.BEFORE')}`;
        }
        if (days > 0) {
            return `${days} ${days === 1
                    ? t('CONFIG_JOURNEYS.EMAIL.DAY')
                    : t('CONFIG_JOURNEYS.EMAIL.DAYS')
                }  ${t('CONFIG_JOURNEYS.EMAIL.AFTER')}`;
        }
    };
    //TODO REVISAR.
    const categoriesFiltered = categories.filter(c => c.visibleGenericType);

    const addEmail = item => {
        const newMailValues = [
            ...item.cycleTypeJourneyMailings,
            {
                idCycleTypesJourneysMailing: 0,
                idCycleTypeJourney: 0,
                idMailing: emailList[categoriesFiltered[0].idMailingType][0].idMailing,
                mailingDays: 0,
            },
        ];

        const newJourneys = getNewJourneys(
            newMailValues,
            'cycleTypeJourneyMailings',
        );

        setFormData({
            ...formData,
            cycleTypeJourneys: newJourneys,
        });
    };
    const removeEmail = (journey, emailIndex) => {
        const newEmailValues = journey.cycleTypeJourneyMailings.filter(
            (_, index) => index !== emailIndex,
        );
        const newJourneys = getNewJourneys(
            newEmailValues,
            'cycleTypeJourneyMailings',
        );
        setFormData({
            ...formData,
            cycleTypeJourneys: newJourneys,
        });
    };

    const handleChangeEmail = (e, journey, index, isCategory) => {
        const newEmailValues = journey.cycleTypeJourneyMailings.map((email, i) => {
            if (i === index) {
                return {
                    ...email,
                    idMailing: isCategory
                        ? emailList[e.target.value][0].idMailing
                        : e.target.value,
                };
            }
            return email;
        });

        const newJourneys = getNewJourneys(
            newEmailValues,
            'cycleTypeJourneyMailings',
        );

        setFormData({
            ...formData,
            cycleTypeJourneys: newJourneys,
        });
    };

    const handleChangeDays = (value, journey, index) => {
        const newEmailValues = journey.cycleTypeJourneyMailings.map((email, i) => {
            if (i === index) {
                return {
                    ...email,
                    mailingDays: value,
                };
            }
            return email;
        });

        const newJourneys = getNewJourneys(
            newEmailValues,
            'cycleTypeJourneyMailings',
        );

        setFormData({
            ...formData,
            cycleTypeJourneys: newJourneys,
        });
    };
    const getIdMailingType = idMailing => {
        const { idMailingType } = allEmails?.find(
            email => email.idMailing === idMailing,
        );

        return idMailingType;
    };


    const handleCheckbox = (e, journey, index) => {
        const newEmailValues = journey.cycleTypeJourneyMailings.map((email, i) => {
            if (i === index) {
                return {
                    ...email,
                    sendToParatrooper: e.target.checked,
                };
            }
            return email;
        });

        const newJourneys = getNewJourneys(
            newEmailValues,
            'cycleTypeJourneyMailings',
        );

        setFormData({
            ...formData,
            cycleTypeJourneys: newJourneys,
        });
    };

    return (
        <Fragment>
            {showEmailModal.open && (
                <DialogEmail
                    showEmailModal={showEmailModal}
                    setShowEmailModal={setShowEmailModal}
                />
            )}

            <Typography
                textAlign='center'
                color='white'
                variant='subtitle1'
                mb={2}
                className={classes.cardTitle}>
                {t('CONFIG_JOURNEYS.EMAIL.CONFIG_EMAIL').toUpperCase()}
            </Typography>
            {/* MOSTRAR SI EL ARRAY TIENE CONTENIDO */}
            {journey?.cycleTypeJourneyMailings.length !== 0 ? (
                <Grid container spacing={2}>
                    <Grid item xs={1}>
                    <Tooltip style={{ marginLeft: '2px' }} placement="top" title={t('CONFIG_JOURNEYS.EMAIL.PARATROOPER')}>
                        <Paragliding fontSize="small" />
                    </Tooltip>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant='caption' component='div'>
                            {t('CONFIG_JOURNEYS.EMAIL.CATEGORY')}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='caption' component='div'>
                            {t('CONFIG_JOURNEYS.EMAIL.EMAIL')}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='caption' component='div'>
                            {t('CONFIG_JOURNEYS.EMAIL.TIME_SEND')}
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <Typography variant='caption' component='div'>
                    {t('CONFIG_JOURNEYS.EMAIL.NO_ASSIGNED_EMAILS')}
                </Typography>
            )}

            {journey.cycleTypeJourneyMailings.map((mail, index) => {
                return (
                    <Grid container spacing={1} mb={1} key={index}>
                        <Grid item xs={1}>
                            <Checkbox
                                value={mail.sendToParatrooper || ''}
                                sx={{ p: 0 }}
                                onChange={e =>
                                    handleCheckbox(
                                    e, journey, index
                                )
                            }
                            checked={mail.sendToParatrooper}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Select
                                className={classes.select}
                                fullWidth
                                size='small'
                                value={getIdMailingType(mail.idMailing)}
                                onChange={e => handleChangeEmail(e, journey, index, true)}>
                                {categoriesFiltered.map(category => (
                                    <MenuItem
                                        className={classes.menuItem}
                                        value={category.idMailingType}
                                        key={category.idMailingType}>
                                        {category.mailingTypeName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>

                        <Grid item xs={4}>
                            <Select
                                className={classes.select}
                                fullWidth
                                size='small'
                                name='idMailing'
                                value={mail.idMailing}
                                renderValue={selected => {
                                    const name = allEmails.find(e => e.idMailing === selected);
                                    return name.mailingName;
                                }}
                                onChange={e => handleChangeEmail(e, journey, index)}>
                                {showEmailItems({
                                    key: getIdMailingType(mail.idMailing),
                                    setShowEmailModal,
                                })}
                            </Select>
                        </Grid>
                        <Grid item xs={4}>
                            <Box display='flex' alignItems='center'>
                                <ButtonGroup
                                    disableElevation
                                    variant='contained'
                                    fullWidth
                                    style={{ height: 39 }}>
                                    <Button
                                        sx={{
                                            width: 25,
                                        }}
                                        onClick={() => {
                                            handleChangeDays(mail.mailingDays - 1, journey, index);
                                        }}>
                                        -
                                    </Button>
                                    <Button
                                        disabled
                                        sx={{
                                            border: 'none',
                                            textTransform: 'none',
                                            '&:disabled': {
                                                color: 'black',
                                                padding: 1,
                                                fontSize: 11.5,
                                                lineHeight: 1.3,
                                            },
                                        }}>
                                        {daysToText(mail.mailingDays)}
                                    </Button>
                                    <Button
                                        sx={{
                                            width: 50,
                                        }}
                                        onClick={() => {
                                            handleChangeDays(mail.mailingDays + 1, journey, index);
                                        }}>
                                        +
                                    </Button>
                                </ButtonGroup>
                            </Box>
                        </Grid>
                        <Grid item xs={1}>
                            <Box
                                style={{
                                    height: '100%',
                                    alignItems: 'center',
                                    display: 'flex',
                                }}>
                                <DeletePopover
                                    remove={() => {
                                        removeEmail(journey, index);
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                );
            })}

            <Box display='flex' justifyContent='start' mt={2}>
                <Chip
                    size='small'
                    onClick={() => {
                        addEmail(journey);
                    }}
                    label={t('CONFIG_JOURNEYS.EMAIL.ADD')}
                    color='primary'
                    sx={{ padding: 0 }}
                    icon={<AddCircleIcon />}
                />
            </Box>
        </Fragment>
    );
};

export default EmailConfiguration;