import React from 'react'
import DynamicTable from '@components/DynamicTable'
import { Checkbox, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
const TableCompanies = ({ companiesSelected, setCompaniesSelected }) => {
  const { t } = useTranslation()

  const handleCheckbox = (isChecked, value) => {
    if (!isChecked) {
      setCompaniesSelected(prevState => {
        return [...prevState, value]
      })
    } else {
      const newCompanies = companiesSelected.filter(
        company => company.idCompany !== value.idCompany
      )
      setCompaniesSelected(newCompanies)
    }
  }
  const ORDER = [
    {
      orderField: 'companyParentsNames',
      orderAsc: true
    }
  ]
  const COLUMNS = [
    {
      filterable: false,
      hideHeader: true,
      accessor: '',
      Header: 'Info',
      width: '5%',
      Cell: ({ row: { values } }) => {
        delete values['Info']
        const isChecked = companiesSelected.some(
          company => company.idCompany === values.idCompany
        )

        return (
          <Checkbox
            disableRipple
            sx={{ p: 0 }}
            checked={isChecked}
            onClick={e => handleCheckbox(isChecked, values)}
          />
        )
      }
    },
    {
      hiddenColumn: true,
      accessor: 'idCompany',
      Header: t('LABEL.ID'),
      filterable: false,
      width: '5%'
    },
    {
      width: '95%',
      accessor: 'companyParentsNames',
      Header: t('LABEL.COMPANY'),
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    }
  ]

  return (
    <Box flex={1}>
      <DynamicTable
        size='small'
        columns={COLUMNS}
        endpoint='Companies/List'
        isColumnsSearch={true}
        isOrdered={true}
        orderColumn={ORDER}
      />
    </Box>
  )
}

export default TableCompanies
