import apiRequest from '@helpers/apiRequest';

const postSendMail = data => {
    return apiRequest('POST', 'TutorShip/Send', data);
};


const serviceTutorShip = {
    postSendMail
};

export default serviceTutorShip;