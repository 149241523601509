import { Fragment, useState, useEffect } from 'react'
import DeletePopover from '@components/DeletePopover'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import {Paragliding} from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Grid,
  MenuItem,
  Select,
  Typography,
  Checkbox,
  Tooltip
} from '@mui/material'
import { useCommonStyles } from '@styles/common.style'
import { useTranslation } from 'react-i18next'
import DialogEmail from '@components/DialogEmail'

const EmailCyclesConfiguration = ({
  getNewJourneys,
  categories,
  emailList,
  showEmailItems,
  allEmails,
  journeyIndex,
  data,
  setData
}) => {
  const classes = useCommonStyles()
  const [journey, setJourney] = useState(data[journeyIndex])
  const { t } = useTranslation()
  const [showEmailModal, setShowEmailModal] = useState({
    open: false,
    loading: true,
    content: {}
  })
  const daysToText = days => {
    if (days === 0) {
      return t('CONFIG_JOURNEYS.EMAIL.SAME_DAY')
    }
    if (days < 0) {
      return `${Math.abs(days)} ${
        Math.abs(days) === 1
          ? t('CONFIG_JOURNEYS.EMAIL.DAY')
          : t('CONFIG_JOURNEYS.EMAIL.DAYS')
      } ${t('CONFIG_JOURNEYS.EMAIL.BEFORE')}`
    }
    if (days > 0) {
      return `${days} ${
        days === 1
          ? t('CONFIG_JOURNEYS.EMAIL.DAY')
          : t('CONFIG_JOURNEYS.EMAIL.DAYS')
      }  ${t('CONFIG_JOURNEYS.EMAIL.AFTER')}`
    }
  }

  const categoriesFiltered = categories.filter(c => c.visibleGenericType)

  useEffect(() => {
    setJourney(data[journeyIndex])
  }, [data])

  const addEmail = item => {
    const newMailValues = [
      ...item.cycleJourneyMailings,
      {
        //  idCycleJourneysMailing: 0,
        // idCycleJourney: 0,
        idMailing: emailList[categoriesFiltered[0].idMailingType][0].idMailing,
        mailingDays: 0
      }
    ]

    const newJourneys = getNewJourneys(
      newMailValues,
      'cycleJourneyMailings',
      journeyIndex
    )

    setData(newJourneys)
  }
  const removeEmail = (journey, emailIndex) => {
    const newEmailValues = journey.cycleJourneyMailings.filter(
      (_, index) => index !== emailIndex
    )
    const newJourneys = getNewJourneys(
      newEmailValues,
      'cycleJourneyMailings',
      journeyIndex
    )
    setData(newJourneys)
  }

  const handleChangeEmail = (e, journey, emailIndexSelected, isCategory) => {
    const newEmailValues = journey.cycleJourneyMailings.map((email, index) => {
      if (index === emailIndexSelected) {
        return {
          ...email,
          idMailing: isCategory
            ? emailList[e.target.value][0].idMailing
            : e.target.value
        }
      }
      return email
    })

    const newJourneys = getNewJourneys(
      newEmailValues,
      'cycleJourneyMailings',
      journeyIndex
    )

    setData(newJourneys)
  }

  const handleCheckbox = (e, journey, index) => {
    const newEmailValues = journey.cycleJourneyMailings.map((email, i) => {
        if (i === index) {
            return {
                ...email,
                sendToParatrooper: e.target.checked,
            };
        }
        return email;
    });

    const newJourneys = getNewJourneys(
        newEmailValues,
        'cycleJourneyMailings',
        journeyIndex
    );

    setData(newJourneys)
};

  const handleChangeDays = (value, journey, emailIndexSelected) => {
    const newEmailValues = journey.cycleJourneyMailings.map((email, index) => {
      if (index === emailIndexSelected) {
        return {
          ...email,
          mailingDays: value
        }
      }
      return email
    })

    const newJourneys = getNewJourneys(
      newEmailValues,
      'cycleJourneyMailings',
      journeyIndex
    )

    setData(newJourneys)
  }
  const getIdMailingType = idMailing => {
    const { idMailingType } = allEmails?.find(
      email => email.idMailing === idMailing
    )

    return idMailingType
  }
  return (
    <Fragment>
      {showEmailModal.open && (
        <DialogEmail
          showEmailModal={showEmailModal}
          setShowEmailModal={setShowEmailModal}
        />
      )}
      <Typography
        textAlign='center'
        color='white'
        variant='subtitle1'
        mb={2}
        className={classes.cardTitle}
      >
        {t('CONFIG_JOURNEYS.EMAIL.CONFIG_EMAIL').toUpperCase()}
      </Typography>
      {/* MOSTRAR SI EL ARRAY TIENE CONTENIDO */}
      {journey?.cycleJourneyMailings?.length !== 0 ? (
        <Grid container spacing={2}>
           <Grid item xs={1}>
              <Tooltip style={{ marginLeft: '40%' ,marginTop: '5%'}} placement="top" title={t('CONFIG_JOURNEYS.EMAIL.PARATROOPER')}>
                  <Paragliding fontSize="small" />
              </Tooltip>
            </Grid>
            <Grid item xs={2}>
            <Typography variant='caption' component='div'>
              {t('CONFIG_JOURNEYS.EMAIL.CATEGORY')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='caption' component='div'>
              Email
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='caption' component='div'>
              {t('CONFIG_JOURNEYS.EMAIL.TIME_SEND')}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Typography variant='caption' component='div'>
          {t('CONFIG_JOURNEYS.EMAIL.NO_ASSIGNED_EMAILS')}
        </Typography>
      )}

      {journey?.cycleJourneyMailings?.map((mail, emailIndex) => {
        return (
          <Grid
            container
            spacing={1}
            mb={1}
            key={`cycleJourneyEmail${emailIndex}`}
          >
            <Grid item xs={1}>
                <Checkbox
                  style={{ marginLeft: '30%',marginTop: '5%' }}
                  value={mail.sendToParatrooper || ''}
                  sx={{ p: 0 }}
                  onChange={e =>
                      handleCheckbox(
                      e, journey, emailIndex
                    )
                  }
                  checked={mail.sendToParatrooper}
                />
              </Grid>
            <Grid item xs={2}>
              <Select
                className={classes.select}
                fullWidth
                size='small'
                value={getIdMailingType(mail.idMailing)}
                onChange={e => handleChangeEmail(e, journey, emailIndex, true)}
              >
                {categoriesFiltered.map(category => (
                  <MenuItem
                    className={classes.menuItem}
                    value={category.idMailingType}
                    key={category.idMailingType}
                  >
                    {category.mailingTypeName}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={4}>
              <Select
                className={classes.select}
                fullWidth
                size='small'
                name='idMailing'
                value={mail.idMailing}
                onChange={e => handleChangeEmail(e, journey, emailIndex)}
                renderValue={selected => {
                  const name = allEmails.find(e => e.idMailing === selected)
                  return name.mailingName
                }}
              >
                {showEmailItems({
                  key: getIdMailingType(mail.idMailing),
                  setShowEmailModal
                })}
              </Select>
            </Grid>
            <Grid item xs={4}>
              <Box display='flex' alignItems='center'>
                <ButtonGroup
                  disableElevation
                  variant='contained'
                  fullWidth
                  style={{ height: 39 }}
                >
                  <Button
                    sx={{
                      width: 25
                    }}
                    onClick={() => {
                      handleChangeDays(
                        mail.mailingDays - 1,
                        journey,
                        emailIndex
                      )
                    }}
                  >
                    -
                  </Button>
                  <Button
                    disabled
                    sx={{
                      border: 'none',
                      textTransform: 'none',
                      '&:disabled': {
                        color: 'black',
                        padding: 0,
                        fontSize: 12
                      }
                    }}
                  >
                    {daysToText(mail.mailingDays)}
                  </Button>
                  <Button
                    sx={{
                      width: 50
                    }}
                    onClick={() => {
                      handleChangeDays(
                        mail.mailingDays + 1,
                        journey,
                        emailIndex
                      )
                    }}
                  >
                    +
                  </Button>
                </ButtonGroup>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box
                style={{
                  height: '100%',
                  alignItems: 'center',
                  display: 'flex'
                }}
              >
                <DeletePopover
                  remove={() => {
                    removeEmail(journey, emailIndex)
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        )
      })}

      <Box display='flex' justifyContent='start' mt={2}>
        <Chip
          size='small'
          onClick={() => {
            addEmail(journey)
          }}
          label={t('CONFIG_JOURNEYS.EMAIL.ADD')}
          color='primary'
          sx={{ padding: 0 }}
          icon={<AddCircleIcon />}
        />
      </Box>
    </Fragment>
  )
}

export default EmailCyclesConfiguration
