import Dialog from '@components/Dialog';
import { Box, Button, Typography } from '@mui/material';
import Editor from '@components/CkEditor';
import { useTranslation } from 'react-i18next';

const DialogPreparations = ({ showPreparationModal, setShowPreparationModal }) => {
    const { t } = useTranslation();
    return (
        <Dialog
            width={'50%'}
            open={showPreparationModal.open}
            hasCloseBtn={false}
            height={'50%'}
            title={t('DIALOG_PREPARATION.PREPARATION_CONTENT') + `${showPreparationModal?.content?.preparationSubject}`}
            close={() => {
                showPreparationModal(false);
            }}
            content={
                !showPreparationModal.loading && (
                    <div>
                        <Typography mb={1}>
                            {showPreparationModal?.content?.preparationSubject}
                        </Typography>
                        <Typography
                            color={showPreparationModal?.content?.preparationBody ? '' : 'error'}>
                            {showPreparationModal?.content?.preparationBody ? (
                                <Editor disabled data={showPreparationModal?.content?.preparationBody} onChange={(e) => console.log(e  )}/>
                            ) : (
                                t('DIALOG_PREPARATION.NOT_CONTENT')
                            )}
                        </Typography>
                    </div>
                )
            }
            actions={
                <Box display='flex' alignItems='center'>
                    <Button
                        variant='contained'
                        onClick={() => {
                            setShowPreparationModal({
                                open: false,
                                loading: true,
                                content: { preparationSubject: null, preparationBody: null },
                            });
                        }}>
                        {t('DIALOG_PREPARATION.EXIT')}
                    </Button>
                </Box>
            }
        />
    );
};

export default DialogPreparations;