import React, {
    useState,
    useCallback,
    useEffect
} from 'react'
import Dialog from '@components/Dialog'
import FormActions from '@components/FormActions'
import HeaderTitle from '@components/HeaderTitle'
import Loading from '@components/Loading'
import { useForm } from '@hooks/useForm'
import {
    Box,
    Button,
    IconButton,
    Tooltip,
    Tab,
    Tabs,
    Typography,
    Grid
} from '@mui/material'
import SectionCompanies from './SectionCompanies'
import SectionForm from './SectionForm'
import SectionParticipants from './SectionParticipants'
import { useCommonStyles } from '@styles/common.style'
import { useLocation, useNavigate } from 'react-router-dom'
import serviceEvaluatorData from '@services/serviceEvaluatorData'
import serviceEvaluatorInfo from '@services/serviceEvaluatorInfo'
import { useTranslation } from 'react-i18next'
import { snackActions } from '@helpers/snackbarUtils'

const EvaluatorDetail = () => {
    const classes = useCommonStyles()
    const navigate = useNavigate()
    const [openDialog, setOpenDialog] = useState(false)
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)
    const [loadingDelayRequest, setLoadingDelayRequest] = useState(false)
    const [evaluatorTypeList, setEvaluatorTypesList] = useState([])

    const {
        state: { dataFull: EVALUATOR_DATA, type: TYPE }
    } = useLocation()


    const HEADER_TITLE =
        TYPE === 'NEW' ? t('EVALUATOR.NEW_TITLE') : t('EVALUATOR.UPDATE_TITLE')
    const HEADER_SUBTITLE =
        TYPE === 'NEW' ? t('CYCLES.NEW_SUBTITLE') : t('CYCLES.UPDATE_SUBTITLE')

    /*
    const handleResponse = useCallback(
         response => {
        //     if (!response.error) {
        //         navigate('/evaluator')
        //     } else {
                setLoading(false)
                setLoadingDelayRequest(false)
        //     }
        },
        [navigate]
    )
    */

    const handleResponse = (response, actionType) => {
      setLoading(false);
      if (!response.error) {
        if (actionType === 'DELETE') { //Al Eliminar vuelve a la página anterior
          navigate('/evaluator');
        } else if (actionType === 'SAVE') { //Al Crear nuevo o Actualizar se queda en la página
        }
      } else {
      }
    }

    const { formData, handleChange, setFormData } = useForm(
        EVALUATOR_DATA || {
            evaluatorCompanies: [],
            evaluatorParticipants: []
        }
    )

    const disabledSaveButton = EVALUATOR_DATA === formData

    const getListValues = useCallback(async () => {

        const evaluatorTypesList = await fetchEvaluatorTypes();
        setEvaluatorTypesList(evaluatorTypesList);
        setLoading(false);

    }, []);

    useEffect(() => {
        let isCancelled = false
        if (!isCancelled) {
            getListValues()
        }
        return () => {
            isCancelled = true
        }
    }, [])

    const fetchEvaluatorTypes = async () => {
        const { data } = await serviceEvaluatorInfo.getTypes({
            pageNumber: 0,
            globalSearch: '',
            search: [],
            order: [
                {
                    orderField: 'evaluatorTypeName',
                    orderAsc: true
                }
            ]
        })

        return data
    }

    const props = {
        formData,
        setFormData,
        handleChange,
        isUpdate: TYPE === 'UPDATE',
        evaluatorTypeList
    }

    const handleSubmit = useCallback(
        type => {
            //Se verifica si el usuario tiene permisos para realizar las acciones sobre un ciclo
            const userSession = JSON.parse(sessionStorage.getItem('userSession'))
            if (userSession.userIdsRoles.find(idRole => idRole == 1 || idRole == 4)) {
                setLoading(true)
                setLoadingDelayRequest(true)

                switch (type) {
                    case 'NEW':
                        return serviceEvaluatorData.newEvaluator(formData).then(response => handleResponse(response, 'SAVE'))
                    case 'UPDATE':
                        return serviceEvaluatorData.updateEvaluator(formData).then(response => handleResponse(response, 'SAVE'))
                    case 'DELETE':
                        return serviceEvaluatorData.deleteEvaluator(formData).then(response => handleResponse(response, 'DELETE'))
                    default:
                        setLoadingDelayRequest(false)
                }
            } else {
                snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'))
            }
        },
        [formData, handleResponse]
    )

    if (loading) {
        return <Loading />
    }
    return (
        <div className={classes.container}>
            {openDialog && (
                <Dialog
                    open={openDialog}
                    hasCloseBtn={false}
                    height={200}
                    title={t('EVALUATOR.EVALUATOR_TITLE_DELETE')}
                    subtitle={t('CONFIRM.UNRECOVERY_DELETE')}
                    close={() => setOpenDialog(false)}
                    actions={
                        <Box display='flex' alignItems='center'>
                            <Box mr={1}>
                                <Button
                                    variant='contained'
                                    onClick={() => setOpenDialog(false)}
                                    color='error'
                                >
                                    {t('BUTTON.CANCEL')}
                                </Button>
                            </Box>
                            <Button
                                variant='contained'
                                onClick={() => handleSubmit('DELETE')}
                            >
                                {t('BUTTON.DELETE')}
                            </Button>
                        </Box>
                    }
                />
            )}

            <HeaderTitle title={HEADER_TITLE} subtitle={HEADER_SUBTITLE}>
                <Box display='flex' flex={1}>
                    <Box
                        flex={1}
                        justifyContent='center'
                        display='flex'
                        alignItems='center'
                    >
                    </Box>
                    <Box>
                        <FormActions
                            show={TYPE === 'UPDATE'}
                            handleDelete={() => setOpenDialog(true)}
                            handleSave={() => handleSubmit(TYPE)}
                            disabledButton={disabledSaveButton}
                            urlBack='/evaluator'
                        />
                    </Box>
                </Box>
            </HeaderTitle>

            <Grid container spacing={3} mt={2}>
                <Grid item xs={12} lg={12}>
                    <Grid container spacing={1} pt={1}>
                        <Grid item lg={8} xs={12}>
                            <SectionForm {...props} />
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <SectionCompanies
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Grid>
                    </Grid>
                    <SectionParticipants {...props} />
                </Grid>
            </Grid>
        </div>
    )

}

export default EvaluatorDetail
