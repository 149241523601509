import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => {
    return {
        cardContainer: props => {
            return {
                height: props.height || 'auto',
                minWidth: props.width || 'auto',
                [theme.breakpoints.down('sm')]: {
                    minWidth: '95%',
                },
            };
        },
        contentCenter: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        contentTop: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
        },
    };
});