import React,{useEffect, useState, useContext} from 'react';
import { Grid, Typography, Button, TextField, Select, MenuItem, TextareaAutosize, Avatar } from '@mui/material';
import Avatar2 from '@components/Avatar'
import Loading from '@components/Loading';
import { ListContext } from '@context/ListContext'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import Fallback from '@assets/images/avatar.png'
import { useStyles } from '@styles/scheduler.style'
import { useLocation, useNavigate } from 'react-router-dom';
import { useHandleImages } from '@hooks/useHandleImages'
import { AuthContext } from '@context/auth/AuthContext'
import { useForm } from '@hooks/useForm'
import { useTranslation } from 'react-i18next'
import serviceScheduler from '@services/serviceScheduler'
import moment from 'moment'
import { useAppContext } from '../../context/SchedulerContext';

const Profile = () => {
    const { countriesList, languageList } = useContext(ListContext)
    const classes = useStyles()
    const [loading, setLoading] = useState(false); //Después poner de inicio "true"
    const { manageImages, infoData } = useHandleImages()
    const { user, logout } = useContext(AuthContext)
    const { formData, setFormData, handleChange } = useForm(user)
    const navigate = useNavigate()
    const {t} = useTranslation()
    const { someChange, setSomeChange } = useAppContext();

    useEffect(() => {
        if ( infoData?.userImage !== undefined && infoData?.userImage !== null && infoData?.userImage.length !== 0 ) {
            setFormData((prevState) => {
                return { ...prevState, 
                    userImage: infoData.userImage, 
                };
            });
        }
    }, [infoData])

    const handleSubmit = e => {
        e.preventDefault();
        setLoading(true);
        serviceScheduler.updateUser(formData).then(response => {
            if (!response.error) {
                setSomeChange(true)
            }
            setLoading(false);
        });
    };
    
   return (
  <>
    {!loading ? (
        <Grid container spacing={1} px={2} sx={{
            maxWidth: { xs: '95%', md: '60%' },
            mx: 'auto',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 3,
            ml: { xs: 'auto' }
          }}>
            <Grid item xs={4} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                <Typography>Email</Typography>
            </Grid>
            <Grid item xs={8}>
                <TextField 
                    name="userEmail"
                    variant="outlined"
                    size="small"
                    fullWidth 
                    value={formData?.userEmail}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={4} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                <Typography>{t('SCHEDULER.NAME')}</Typography>
            </Grid>
            <Grid item xs={8}>
                <TextField 
                    name="userName"
                    variant="outlined"
                    size="small"
                    fullWidth 
                    value={formData?.userName}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={4} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                <Typography>{t('SCHEDULER.SURNAME')}</Typography>
            </Grid>
            <Grid item xs={8}>
                <TextField 
                    name="userSurname"
                    variant="outlined"
                    size="small"
                    fullWidth 
                    value={formData?.userSurname}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={4} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                <Typography align="right" style={{ width: '100%' }}>{t('LABEL.BIRTHDATE')}</Typography>
            </Grid>

            <Grid item xs={8}>
            <DateTimePicker
                name="userBirthdate"
                sx={{width: '100%'}}
                slotProps={{
                    textField: {
                        size: 'small',
                        // color: session?.errTo ? 'error' : 'primary'
                    }
                }}
                format='DD/MM/YYYY'
                value={formData?.userBirthdate ? moment(formData?.userBirthdate) : null}
                onChange={(newValue) => {
                    setFormData({...formData, userBirthdate: moment(newValue).format('YYYY-MM-DD')});
                }}
            />
            </Grid>
            <Grid item xs={4} sx={{ height:{ xs: '100px' }}} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                <Typography align="right" style={{ width: '100%', marginTop:'-30px' }}>{t('FORM.PROFILE_PICTURE')}</Typography>
            </Grid>
            <Grid item xs={4} sx={{ height:{ xs: '100px' }}} mb={4}>
                <Avatar2
                    sx={{ maxWidth:{ xs: '100px !important' }}}
                    handleChange={e => manageImages(e, 'userImage')}
                    name="userImage"
                    image={formData?.userImage || Fallback}
                    idbutton={'upload-button-participantImage'}
                />
            </Grid>
            <Grid item xs={4} sx={{ height: '95px', mt: -4, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Button 
                    className={classes.btn} 
                    variant='contained' 
                    color='error' 
                    fullWidth 
                    onClick={() => navigate('/userarea')}
                >
                    {t('BUTTON.CANCEL')}
                </Button>
                <Button 
                    className={classes.btn} 
                    variant='contained' 
                    sx={{backgroundColor:'#e9a459'}} 
                    fullWidth 
                    onClick={handleSubmit}
                >
                    {t('BUTTON.UPDATE')}
                </Button>
            </Grid>
        </Grid>
        ) : (
        <Loading height='50vh' />
        )}
    </>
    );
};

export default Profile;