import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
    Grid, TextField, Card, Box, Button,
    CircularProgress, MenuItem, Select, InputLabel,
    IconButton, FormControl, Typography, FormControlLabel, Checkbox 
} from '@mui/material';
import { useStyles } from '@styles/participants.style';
import { useTranslation } from 'react-i18next';
import serviceParticipants from '@services/serviceParticipants';
import { Link } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import Fallback from '@assets/images/avatar.png'
import serviceLogin from '@services/serviceLogin';
import serviceCompanies from '@services/serviceCompanies';
import { useNavigate } from 'react-router-dom';
import { useForm } from '@hooks/useForm';
import { ListContext } from '@context/ListContext';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import CheckBox from '@components/CheckBox';
import AutocompleteSearch from '@components/AutocompleteSearch';
import serviceList from '@services/serviceList';
import Avatar from '@components/Avatar';
import Loading from '@components/Loading';
import Logo from '@assets/images/logoCampus.png';
import { useHandleImages } from "@hooks/useHandleImages";

const Myprofile = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const navigate = useNavigate();
    const { languageList } = useContext(ListContext);
    const [countries, setCountries] = useState([]);
    const { manageImages, infoData } = useHandleImages();
    const [idCompany, setIdCompany] = useState('')
    const [nameCompany, setNameCompany] = useState('')
    const [companySelected, setCompanySelected] = useState();

    const { formData, setFormData, handleChange } = useForm({
        idCompany: '',
        idCountry: '',
        userEmail: '',
        participantEmail: '',
        participantShareEmail: false,
        participantPhone: '',
        participantSharePhone: false,
        participantCountry: '',
        participantNickName: '',
        participantName: '',
        participantSurname: '',
        participantCity: '',
        participantZip: '',
        participantPosition: '',
        participantImage: '',
        participantOtherCompany: '',
        idLanguage: 'es'
    });

    const [selectedLanguage, setSelectedLanguage] = useState('es');

    const handleLanguageChange = (event) => {
      setSelectedLanguage(event.target.value);
    };

    useEffect(() => {
      setFormData(prevState => ({
          ...prevState,
          idLanguage: selectedLanguage
      }));
  }, [selectedLanguage, setFormData]);
   
    useEffect(() => {
        if (infoData?.participantImage !== undefined && infoData?.participantImage !== null && infoData?.participantImage.length !== 0) {
            setFormData((prevState) => {
                return {
                    ...prevState,
                    participantImage: infoData.participantImage,
                };
            });
        }
    }, [infoData])

    useEffect(() => {
        const fetchParticipantImage = async () => {
            try {
                const response = await serviceParticipants.getParticipantImage(formData.idParticipant);
                if (!response.error && response.data) {
                    setFormData(prevState => {
                        return { ...prevState, participantImage: response.data }
                    })
                }
            } catch (error) {
                console.error("Error al obtener la imagen del participante:", error);
            }
        };

        if (formData.idParticipant) {
            fetchParticipantImage();
        }
    }, [formData.idParticipant]);

    useEffect(() => {
        if ( companySelected !== undefined && companySelected !== null) {
            setIdCompany(companySelected.idCompany)
            setNameCompany(companySelected.companyName)
            setFormData(prevState => {
                return {
                    ...prevState,
                    idCompanyParent: companySelected.idCompany
                }
            })
            handleSelect(companySelected);
        }
      
    }, [companySelected])

    // Funciones para select con autob�squeda empresas


    const handleSelect = companySelected => {
        setFormData(prevState => {
            return {
                ...prevState,
                idCompany: companySelected.idCompany,
            };
        });
    };

    const cleanDebounce = (data) => {
        if (data.globalSearch)
            data.search = [];
        return data;
    }

    const handleCallBack = useCallback(
        async debounceValue =>
            await serviceCompanies.getCompanies(cleanDebounce(debounceValue)),
        [],
    );

    // Fin funciones para select con autob�squeda empresas


    useEffect(() => {
        let idParticipant = 0; //Se indica cero ya que en esta parte de la pantalla, se desconoce dicho id, por tanto la API sacar� el idUser del UserSession
        serviceParticipants.getParticipantsDetails(idParticipant).then(response => {
            if (!response.error) {
                setFormData(response.data);
                formData.idCompany = response.data.idCompany;
                setSelectedLanguage(response.data.idLanguage || 'es');
            }
            setLoadingData(false);
        });

        serviceList.getCountriesList().then((response) => {
            if (!response.error) {
                setCountries(response.data);
            }
        });
    }, [setFormData]);

    const handleSubmitLostPass = e => {
        e.preventDefault();
        setLoading(true);
        formData.userEmail = formData.participantEmail;
        serviceLogin.lostPassword(formData).then(response => {
            if (!response.error) {
                navigate('/newPassword', { replace: true });
            }
            setLoading(false);
        });
    };

    const handleSubmit = e => {
        e.preventDefault();
        setLoading(true);
        serviceParticipants.updateParticipant(formData).then(response => {
            if (!response.error) {

            }
            setLoading(false);
        });
    };

    if (loadingData) {
        return <Loading />;
    }

    return (
            <div style={{padding:'0em 1em 2em 1em'}}>
                <Grid container spacing={0} marginTop={1} sx={{display:'flex', justifyContent:'start', alignItems:'center'}}>
                    <Grid item xs={10} sx={{display:'flex', justifyContent:'start', alignItems:'center'}}>
                        <Typography className={classes.title} sx={{ margin: '10px 5px'}}>{t('MENU_CONSULTANTS.PROFILE')}</Typography>
                    </Grid>
                    <Grid item xs={2} paddingLeft={2} paddingTop={0} sx={{display:'flex', justifyContent:'start', alignItems:'center'}}>
                        <Box>
                            <img className={classes.image} src={Logo} alt='logoxs' />
                        </Box>
                    </Grid>
                </Grid>
                <br />

                
                <Grid container spacing={1} padding={1}>
                  <Grid container spacing={1} sx={{ alignItems: 'center' }}>
                    <Grid item xs={4} display="flex" justifyContent="center">
                        <Avatar
                            handleChange={e => manageImages(e, 'participantImage')}
                            image={formData?.participantImage}
                            width='100%'
                            height={100}
                            idbutton={'upload-button-userImage'}
                        />
                    </Grid>
                    <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.participantShareEmail}
                                    onChange={handleChange}
                                    name="participantShareEmail"
                                    color="primary"
                                />
                            }
                            label={t('LABEL.SHARE_EMAIL')}
                            labelPlacement="start" // Coloca la casilla a la derecha del texto
                            sx={{
                                '& .MuiFormControlLabel-label': {
                                    fontSize: '1.2rem', // Ajusta el tamaño del texto
                                    textAlign: 'right', // Alinea el texto a la derecha
                                },
                            }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.participantSharePhone}
                                    onChange={handleChange}
                                    name="participantSharePhone"
                                    color="primary"
                                />
                            }
                            label={t('LABEL.SHARE_PHONE')}
                            labelPlacement="start" // Coloca la casilla a la derecha del texto
                            sx={{
                                '& .MuiFormControlLabel-label': {
                                    fontSize: '1.2rem', // Ajusta el tamaño del texto
                                    textAlign: 'right', // Alinea el texto a la derecha
                                },
                            }}
                        />
                    </Grid>
                </Grid>



                    <Grid item container xs={12} >
                        {/* <Box style={{ backgroundColor: 'white', color: '#0085B4', borderRadius: '5px', marginTop: '8px' }} padding={0}>  */}
                            <Grid item xs={12} marginBottom={1}>
                                <TextField
                                    label={t('LABEL.NAME')}
                                    name='participantName'
                                    value={formData.participantName || ''}
                                    size='small'
                                    onChange={handleChange}
                                    margin='dense'
                                    fullWidth
                                    inputProps={{
                                        maxLength: 100,
                                        sx: { fontSize: '1.2rem' }
                                    }}
                                    InputLabelProps={{
                                        sx: { fontSize: '1.2rem' }
                                    }}
                                />
                                <TextField
                                    label={t('LABEL.SURNAME')}
                                    name='participantSurname'
                                    value={formData.participantSurname || ''}
                                    size='small'
                                    onChange={handleChange}
                                    margin='dense'
                                    fullWidth
                                    inputProps={{
                                        maxLength: 100,
                                        sx: { fontSize: '1.2rem' }
                                    }}
                                    InputLabelProps={{
                                        sx: { fontSize: '1.2rem' }
                                    }}
                                />

                                <TextField
                                    label={t('LABEL.NICKNAME_PARTICIPANT')}
                                    fullWidth
                                    name='participantNickName'
                                    value={formData.participantNickName || ''}
                                    onChange={handleChange}
                                    margin='dense'
                                    size='small'
                                    inputProps={{ maxLength: 100,
                                      sx: { fontSize: '1.2rem' }
                                  }}
                                  InputLabelProps={{
                                      sx: { fontSize: '1.2rem' }
                                  }}
                                    sx={{marginBottom:'1em'}}
                                />
                                <TextField
                                    fullWidth
                                    size='small'
                                    name='participantEmail'
                                    label={t('LABEL.EMAIL')}
                                    value={formData?.participantEmail || ''}
                                    onChange={handleChange}
                                    margin='dense'
                                    style={{ marginRight: 10 }}
                                    inputProps={{ maxLength: 100,
                                      sx: { fontSize: '1.2rem' }
                                  }}
                                  InputLabelProps={{
                                      sx: { fontSize: '1.2rem' } }}
                                />
                                <TextField
                                    type='tel'
                                    label={t('LABEL.PHONE')}
                                    name='participantPhone'
                                    value={formData.participantPhone || ''}
                                    size='small'
                                    onChange={handleChange}
                                    margin='dense'
                                    fullWidth
                                    style={{ marginRight: 10 }}
                                    inputProps={{ maxLength: 20,
                                      sx: { fontSize: '1.2rem' }
                                  }}
                                  InputLabelProps={{
                                      sx: { fontSize: '1.2rem' } }}
                                />
                            </Grid>
                            <Grid item xs={5.9} marginRight={0.6}>
                                <TextField
                                    label={t('LABEL.POSTCODE')}
                                    name='participantZip'
                                    value={formData.participantZip || ''}
                                    size='small'
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 10,
                                      sx: { fontSize: '1.2rem' }
                                  }}
                                  InputLabelProps={{
                                      sx: { fontSize: '1.2rem' } }}
                                />
                            </Grid>
                            <Grid item xs={5.9}>
                                <FormControl fullWidth>
                                    <InputLabel
                                        id="languageLabel"
                                        sx={{ fontSize: '1.2rem' }} // Cambia el tamaño de la fuente del label
                                    >
                                        {t('LABEL.LANGUAGE')}
                                    </InputLabel>
                                    <Select
                                        style={{ height: '45px' }}
                                        label={t('LABEL.LANGUAGE')}
                                        labelId='languageLabel'
                                        value={selectedLanguage}
                                        onChange={handleLanguageChange}
                                        size='small'>
                                        {languageList.map(({ idLanguage, languageCode, Icon }) => {
                                            return (
                                                <MenuItem key={idLanguage} value={idLanguage}>
                                                    <Icon style={{ display: 'flex' }} />
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} marginTop={3}>
                                <AutocompleteSearch
                                    pageSize={10}
                                    handleSelect={handleSelect}
                                    optionSelected={companySelected}
                                    initialSearch={
                                        formData.idCompany != '' && {
                                            globalSearch: '',
                                            order: [],
                                            pageNumber: 0,
                                            pageSize: 1,
                                            search: [
                                                {
                                                    searchField: 'idCompany',
                                                    searchMode: 'equalTo',
                                                    searchValue: formData.idCompany,
                                                },
                                            ],
                                        }
                                    }
                                    disabled={false}
                                    setOptionSelected={setCompanySelected}
                                    setDisabledAddButton={() => {
                                        return false;
                                    }}
                                    fetch={handleCallBack}
                                    optionsDisabled={option => {
                                        return [];
                                    }}
                                    optionsLabel={option =>
                                        `${option.companyName || ''}`
                                    }
                                /> 
                                {idCompany === 999999 && (
                                
                                    <TextField
                                    // error={!patternName.test(otherCompanyParticipant)}
                                    // id='otherCompanyParticipant'
                                    label={t('LOGIN.SIGN_UP.OTHER_COMPANY')}
                                    name='participantOtherCompany'
                                    fullWidth
                                    value={formData.participantOtherCompany || ''}
                                    onChange={handleChange}
                                    size='small'
                                    inputProps={{ maxLength: 100 }}
                                    margin='dense'
                                    />
                                 )} 
                                <TextField
                                    label={t('LABEL.POSITION')}
                                    name='participantPosition'
                                    value={formData.participantPosition || ''}
                                    size='small'
                                    onChange={handleChange}
                                    fullWidth
                                    margin='dense'
                                    inputProps={{ maxLength: 100,
                                      sx: { fontSize: '1.2rem' }
                                  }}
                                  InputLabelProps={{
                                      sx: { fontSize: '1.2rem' } }}
                                />
                            </Grid>
                        {/* </Box> */}
                    </Grid>
                </Grid>
                <br />
                <Grid item xs={12} lg={12} display="flex" alignItems='center' marginBottom={5}>
                    <Button
                        onClick={handleSubmit}
                        color='info'
                        style={{ marginLeft: 'auto', fontWeight: 'bold' }}
                        variant='contained'
                        size='large'
                        // endIcon={<SaveRoundedIcon />}
                        >
                        {t('BUTTON.SAVE')}
                    </Button>
                    {/* // Este es el btn de recuperar contraseña */}
                    {/* <Button variant='contained' onClick={handleSubmitLostPass} type='submit' size='large'>
                        <Box display='flex' alignItems='center'>
                            {loading && <CircularProgress size={25} color='grey' />}
                            {!loading && t('BUTTON.RECOVER_PASSWORD')}
                        </Box>
                    </Button> */}
                </Grid>
            </div>
    );
};

export default Myprofile;