import apiRequest from '@helpers/apiRequest';
const getConsultantsList = data => {
    return apiRequest('POST', 'Consultants/List', data);
};

const getConsultantsDetails = idConsultant => {
    return apiRequest('GET', `Consultants/Details/${idConsultant}`);
};

const updateConsultant = data => {
    const idConsultant = data.idConsultant;
    return apiRequest('PUT', `Consultants/Update/${idConsultant}`, data);
};

const newConsultant = data => {
    return apiRequest('POST', 'Consultants/New', data);
};

const deleteConsultant = data => {
    const idConsultant = data.idConsultant;
    return apiRequest('DELETE', `Consultants/Delete/${idConsultant}`);
};
const serviceConsultants = {
    getConsultantsList,
    getConsultantsDetails,
    updateConsultant,
    newConsultant,
    deleteConsultant,
};

export default serviceConsultants;