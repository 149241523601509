import React, { useState } from 'react'
import AddButton from '@components/AddButton'
import DynamicTable from '@components/DynamicTable'
import HeaderTitle from '@components/HeaderTitle'
import { EditRounded } from '@mui/icons-material'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import serviceCycleTypes from '@services/serviceCycleTypes'
import { useCommonStyles } from '@styles/common.style'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { snackActions } from '@helpers/snackbarUtils'
import Loading from '@components/Loading'

const CycleTypes = () => {
  const classes = useCommonStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const ORDER = [
    {
      orderField: 'cycleTypeName',
      orderAsc: true
    }
  ]
  const COLUMNS = [
    {
      accessor: '',
      Header: 'Info',
      filterable: false,
      hideHeader: true,
      width: '5%',
      Cell: ({ row: { values } }) => (
        <Box display='flex' flexDirection='row'>
          <Tooltip title={t('LABEL.EDIT')}>
            <IconButton
              aria-label='info'
              color='icon_blue'
              style={{
                padding: '0px',
                marginRight: 15
              }}
              onClick={() => {
                handleShowCycleTypeDetail({
                  idCycleType: values.idCycleType,
                  type: 'UPDATE'
                })
              }}
            >
              <EditRounded fontSize='inherit' />
            </IconButton>
          </Tooltip>
        </Box>
      )
    },
    {
      accessor: 'idCycleType',
      Header: t('LABEL.ID'),
      filterable: false,
      width: '5%'
    },
    {
        accessor: 'cycleTypeCode',
        Header: t('CYCLE_TYPES.CYCLE_TYPE'),
        Cell: props => <Typography variant='body2'>{props.value}</Typography>
    },
    {
      accessor: 'cycleTypeName',
      Header: t('CYCLE_TYPES.TITLE_LOCALE'),
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    }
  ]

  const handleShowCycleTypeDetail = async ({ idCycleType, type }) => {
    //Se verifica si el usuario tiene permisos para realizar las acciones sobre un tipo de ciclo
    const userSession = JSON.parse(sessionStorage.getItem('userSession'))
    if (userSession.userIdsRoles.find(idRole => idRole == 1 || idRole == 4)) {
      setLoading(true)
      const { data, error } = await serviceCycleTypes.getCycleTypeDetail(
        idCycleType
      )
      setLoading(false)
      if (!error) {
        navigate(`/cycleTypesDetail`, {
          state: { data, type }
        })
      }
    } else {
      snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'))
    }
  }
  const handleNewCycleType = async () => {
    //Se verifica si el usuario tiene permisos para realizar las acciones sobre un tipo de ciclo
    const userSession = JSON.parse(sessionStorage.getItem('userSession'))
    if (userSession.userIdsRoles.find(idRole => idRole == 1 || idRole == 4)) {
      navigate('/cycleTypesDetail', { state: { type: 'NEW' } })
    } else {
      snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'))
    }
  }

  if (loading) {
    return <Loading />
  }
  return (
    <div className={classes.container}>
      <HeaderTitle
        title={t('TEXT.TITLE_CYCLE_TYPES')}
        subtitle={t('TEXT.SUBTITLE_CYCLE_TYPES')}
      >
        <AddButton
          text={t('BUTTON.NEW_CYCLE_TYPE')}
          handleOnclick={handleNewCycleType}
        />
      </HeaderTitle>
      <DynamicTable
        columns={COLUMNS}
        endpoint='CycleTypes/List'
        isGlobalSearch={true}
        isColumnsSearch={true}
        isOrdered={true}
        orderColumn={ORDER}
      />
    </div>
  )
}

export default CycleTypes
