import React from 'react';
import DynamicTable from '@components/DynamicTable';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import Moment from 'react-moment';

const ParticipantCycles = (props) => {
    const { t } = useTranslation();

    const idConsultant = props.idConsultant;

    const modality = [
        { label: t('LABEL.ONSITE'), value: '1' },
        { label: t('LABEL.DIGITAL'), value: '2' },
        { label: t('LABEL.MIXED'), value: '1,2' }
    ]
    const getModality = value => {
        let res = modality.filter(x => x.value === value)
        return res.length ? res[0].label : t('LABEL.MIXED')
    }

    const filterMap = value => {
        if (value === '') return ''
        let res = modality.filter(x => x.label.toLowerCase().includes(value.toLowerCase()))
        return res.length ? res[0].value : t('LABEL.MIXED')
    }


    const ORDER = [
        {
            "orderField": "cycleDateFrom",
            "orderAsc": false
        }
    ];
    const columns = [
        {
            accessor: 'idCycle',
            Header: t('LABEL.ID'),
            filterable: false,
            width: '5%',
        },
        {
            accessor: 'cycleName',
            Header: t('CYCLES.CYCLE_NAME'),
            Cell: props => <Typography variant='body2'>{props.value}</Typography>,
        },
        {
            accessor: 'companyName',
            Header: t('LABEL.COMPANY'),
            Cell: props => <Typography variant='body2'>{props.value}</Typography>,
        },
        {
            accessor: 'cycleModalities',
            Header: t('LABEL.MODALITY'),
            Cell: props => (
                <Typography variant='body2'> {getModality(props.value)}</Typography>
            ),
            width: '15%',
            searchMode: 'EqualTo',
            filterMap: x => filterMap(x)
        },
        {
            accessor: 'cycleDateFrom',
            Header: t('LABEL.DATE'),
            Cell: props => <Typography variant='body2'><Moment format='DD[/]MM[/]yyyy'>{props.value}</Moment></Typography>,
        }
    ];

    const filter = [
        {
            searchField: 'idConsultant',
            searchMode: 'EqualTo',
            searchValue: idConsultant
        }
    ]

    return (
        <DynamicTable
            columns={columns}
            customFilter={filter}
            endpoint='Consultants/ConsultantCycles'
            isOrdered={true}
            isPaginated={true}
            viewExcel={true}
            nameExcel={t('TEXT.CONSULTANT_CYCLES')}
            titleBodyExcel={t('CONSULTANT.BODY_EXCEL')}
            orderColumn={ORDER}
        />
    );
};

export default ParticipantCycles;