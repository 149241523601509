import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

const ColorPicker = ({
    bgColor = '#000000',
    onChangeComplete,
    setDisableDrag,
}) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [color, setColor] = useState(bgColor);

    const handleClick = () => {
        setDisableDrag && setDisableDrag(prevState => !prevState);
        setDisplayColorPicker(!displayColorPicker);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
        setDisableDrag && setDisableDrag(false);
    };

    const handleChange = color => {
        setColor(color.hex);
    };
    const popover = {
        position: 'absolute',
        zIndex: '2',
    };

    const swatch = {
        padding: '5px',
        background: '#fff',
        borderRadius: '3px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.3)',
        cursor: 'pointer',
    };

    const colorBtn = {
        height: '29px',
        borderRadius: '3px',
        background: color,
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    };

    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    };
    return (
        <div style={{ position: 'relative' }}>
            <div style={swatch} onClick={handleClick}>
                <div style={colorBtn} />
            </div>
            {displayColorPicker ? (
                <div style={popover}>
                    <div onClick={handleClose} style={cover} />
                    <SketchPicker
                        disableAlpha
                        presetColors={[
                            '#104976',
                            '#E2EDF3',
                            '#ffffff',
                            '#0085B4',
                            '#e9a459',
                            '#da4f49',
                            '#f1f1f1',
                            '#cccccc',
                            '#2B8A9A',
                            '#282828',
                        ]}
                        color={color}
                        onChange={handleChange}
                        onChangeComplete={onChangeComplete}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default ColorPicker;