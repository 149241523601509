import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './layout/Header';
import { Grid } from '@mui/material';
import Menu from './layout/Menu'
import { useOWStyles } from '@styles/ottowalter.style';

const Home = () => {
  const owclass = useOWStyles();
 
    const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);

    return (
        <>
      <Grid container sx={{backgroundColor: '#f0f0f0', height: '100vh'}}>
        <Grid item xs={12}>
          <Header
            handleShowMenu={setShowHamburgerMenu}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <Menu
            handleShowMenu={setShowHamburgerMenu}
            showMenu={showHamburgerMenu}
          />
        </Grid>
        <Grid className={owclass.outletZoneKitOW} item xs={12}>
          <Outlet />
        </Grid>
      </Grid>
    </>
    );
};

export default Home;