
const getCurrentLastJourney = (cycle) => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    date.setHours(0, 0, 0, 0);
    var filtered = cycle.cycleJourneys.filter(cycleJourney => (Date.parse(cycleJourney.cycleJourneyDateFrom) < date && cycleJourney.cycleJourneyPrivate == false));
    if (filtered.length > 0) {
        return filtered[filtered.length-1];
    } else {
        return cycle.cycleJourneys[0];
    }
}

export default getCurrentLastJourney;