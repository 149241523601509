import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => {
    return {
        header: {
            display: "flex",
            justifyContent: "space-between",
            height: "100px",
            background: `linear-gradient(90deg, ${theme.palette.primary.main}, 60%, ${theme.palette.primary.light} 70%)`,
        },
        headerTitle: {
            color: "white",
            fontSize: 25,
            fontWeight: "bold",
        },
        btn: {
            cursor: "pointer",
            "&:hover": {
                opacity: 0.7,
            },
        },
    };
});