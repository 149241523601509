import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { AuthProvider } from '@context/auth/AuthContext'
import customTheme from '@assets/theme'
import { ThemeProvider } from '@mui/material'
import { ListProvider } from '@context/ListContext'
import {WorksheetsListProvider} from '@context/WorksheetsListContext'
import './fonts/Arvo/Arvo.ttf'
import './fonts/Arvo/Arvo-Bold.ttf'
import './fonts/Arvo/Arvo-Italic.ttf'
import './fonts/Arvo/Arvo-BoldItalic.ttf'
import './fonts/ArchivoNarrow/ArchivoNarrow-Regular.ttf'
import './fonts/ArchivoNarrow/ArchivoNarrow-Bold.ttf'
import './fonts/ArchivoNarrow/ArchivoNarrow-Italic.ttf'
import './fonts/ArchivoNarrow/ArchivoNarrow-BoldItalic.ttf'
import './fonts/PTSerif/PTSerif-Regular.ttf'
import './fonts/PTSerif/PTSerif-Bold.ttf'
import './fonts/PTSerif/PTSerif-Italic.ttf'
import './fonts/Roboto/Roboto-Regular.ttf'
import './fonts/Roboto/Roboto-Bold.ttf'
import './fonts/Roboto/Roboto-Italic.ttf'
import './fonts/Swiss921BT-RegularA.ttf'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

ReactDOM.render(
  <ThemeProvider theme={customTheme}>
    <AuthProvider>
      <ListProvider>
        <WorksheetsListProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </LocalizationProvider>
        </WorksheetsListProvider>
      </ListProvider>
    </AuthProvider>
  </ThemeProvider>,
  document.getElementById('root')
)
