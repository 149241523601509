import {
    AutoStories,
    ManageAccounts,
    Logout,
    FileCopy,
    PhotoCamera
} from '@mui/icons-material';

const menuConsultantsItems = t => [
    {
        title: t('MENU_CONSULTANTS.CYCLES'),
        url: '/panel',
        icon: <AutoStories />,
    },
    {
        title: t('MENU_CONSULTANTS.PROFILE'),
        url: '/profile',
        icon: <ManageAccounts />,
    },
    {
        title: t('MENU_CONSULTANTS.LOGOUT'),
        url: '/logout',
        icon: <Logout />,
    },

];

export default menuConsultantsItems;