import { AuthContext } from '@context/auth/AuthContext';
import Home from '@areas/Scheduler/Home'; 
import UserArea from '../sections/userarea/UserArea';
import Profile from '../sections/profile/Profile';
import LostPassword from '@login/LostPassword';
import NewPassword from '@login/NewPassword';
import LoginSchedulerPage from '@login/LoginSchedulerPage';
import { useContext } from 'react';
import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
} from 'react-router-dom';


const SchedulerRoutes = () => {
    
    const PrivateRoute = () => {
        const { status } = useContext(AuthContext)
        return status === 'authenticated' ? <Home /> : <Navigate to='/' />;
    };
    const { status } = useContext(AuthContext);

    return (
        <Router>
            <Routes>
                <Route
                    path='/'
                    element={
                        status === 'authenticated' ? (
                            <Navigate to='/userarea' />
                        ) : (
                            <LoginSchedulerPage /> 
                        )
                    }
                />
                <Route path='*' element={<Navigate to='/' />} />
                <Route path='/lostPassword' element={<LostPassword />} />
                <Route path='/newPassword' element={<NewPassword />} />
                <Route element={<PrivateRoute />}>
                    <Route path='userarea' element={<UserArea />} />
                    <Route path='profile' element={<Profile />} />
                </Route>
            </Routes>
        </Router>
    );
};

export default SchedulerRoutes;