import { makeStyles } from '@mui/styles';

export const useOWStyles = makeStyles(theme => {

    const orangeOW = "#eda655";
    const grayOW = "#f0f1f4";
    const blueOW = "#0085b4";

    return {
        //Fondos
        backGray: props => {
            return {
                backgroundColor: grayOW
            };
        },
        backBlue: props => {
            return {
                backgroundColor: blueOW
            };
        },
        backOrange: props => {
            return {
                backgroundColor: orangeOW
            };
        },

        //Zona central en area, participantes
        outletZone: props => {
            return {
                maxWidth: '700px',
                margin: '0 auto',
                // maxHeight: 'calc(100vh - 200px)',
                // height: 'calc(100vh - 200px)',
                overflowY: 'auto',
                padding: '5px 15px',
                [theme.breakpoints.up('sm')]: {
                    maxHeight: 'calc(100vh - 200px)',
                    height: 'calc(100vh - 200px)',
                }
            };
        },
        outletZoneConsultores: props => {
            return {
                maxWidth: '700px',
                margin: '0 auto',
                maxHeight: 'calc(100vh - 100px)',
                height: 'calc(100vh - 100px)',
                overflowY: 'auto',
                padding: '25px 15px'
            };
        },
        outletZoneKitOW: props => {
            return {
                backgroundColor: '#f0f0f0',
                maxWidth: '700px',
                margin: '0 auto',
                // maxHekight: 'calc(100vh - 100px)',
                height: 'calc(100vh - 50px)',
                overflowY: 'auto',
                padding: '0px'
            };
        },

        //Fondo gris con sombra blanca
        grayShadowWhite: props => {
            return {
                backgroundColor: grayOW,
                boxShadow: '0px 0px 10px 10px rgba(255,255,255,1)'
            };
        },


        //Botones del footer
        navigationFooterBtn: props => {
            return {
                maxWidth: '110px',
                backgroundColor: orangeOW,
                margin:'15px 5px',
                borderRadius: '35px',
                color:'white',
                boxShadow:'inset 2px 2px 4px 0px rgba(0,0,0,0.5)',
            };
        },

        //Botones del footer
        shadowRounded: props => {
            return {
                borderRadius: '15px',
                boxShadow:'inset 2px 2px 4px 4px rgba(0,0,0,0.5)',
            };
        },
        footerButtonFontSize: props => { //Estilo del texto de los botones del Footer de Campus Participantes
          return {
              '& .MuiBottomNavigationAction-label': {
                  fontSize: '0.9rem !important',
                  fontWeight: 'bold !important',
              },
          };
      },
    };
});