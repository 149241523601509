import React from 'react';
import Loading from '@components/Loading';
import { useFlags } from '@hooks/useFlags';
import { useStyles } from '@styles/loginKitOW.style';
import SignInKitOW from './SignInKitOW';

const LoginKitOWPage = () => {
    const classes = useStyles();
    const { flagOptions } = useFlags();

    if (!flagOptions) return <Loading />;

    return (
        <div className={classes.loginKitOW}>
            <SignInKitOW flagOptions={flagOptions} />
        </div>
    );
};

export default LoginKitOWPage;