import Card from '@components/Card'
import DeletePopover from '@components/DeletePopover'
import Dialog from '@components/Dialog'
import Icon from '@components/Icon'
import AddInviteds from './AddInviteds'
import { useCommonStyles } from '@styles/common.style'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
  ListItemIcon,
  Checkbox,
  Grid
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import { Assessment, MailRounded } from '@mui/icons-material'
import TableTemplateMailing from '@components/TableTemplateMailing'
import serviceRabbitMq from '@services/serviceRabbitMq'
import { snackActions } from '@helpers/snackbarUtils'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import serviceCycleInvitedGuests from '@services/serviceCycleInvitedGuests'
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import serviceFiles from '@services/serviceFiles'

const SectionInvited = ({
  formData,
  setFormData,
  isUpdate,
  disabledSaveButton
}) => {
  const { t } = useTranslation()
  const [participantsSelected, setParticipantsSelected] = useState([])
  const [showAddInvitedsDialog, setShowAddInvitedsDialog] = useState(false)
  const [showTemplatesDialog, setShowTemplatesDialog] = useState(false)
  const [updateGuest, setUpdateGuest] = useState(false)
  const [guest, setGuest] = useState()
  const [loadingParticipants, setLoadingParticipants] = useState(false)
  const [participantChecked, setParticipantChecked] = useState([])
  const [allCheked, setAllChecked] = useState(false)
  const [mailData, setMailData] = useState([])
  const [listReload, setListReload] = useState(0)
  const classesCommon = useCommonStyles()
  const patternEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

  console.log(formData);
  


  const handleCheckbox = e => {
    if (!e.target.checked) {
      const quitParticipantCheck = participantChecked.filter(
        participant => participant !== e.target.value
      )
      setParticipantChecked(quitParticipantCheck)
    } else {
      const newParticipantCheck = [...participantChecked, e.target.value]
      setParticipantChecked(newParticipantCheck)
    }
  }

  const checkList = participant => {
    const checkItems =
      participantChecked.indexOf(participant) !== -1 ? true : false
    return checkItems
  }

  const handleAllCheck = e => {
    if (!e.target.checked) {
      setParticipantChecked([])
    } else if (e.target.checked) {
      const tempArray = []
      participantsSelected.forEach(participant => {
        tempArray.push(participant.invitedEmail)
      })
      setParticipantChecked(tempArray)
    }
    setListReload(Math.random())
  }

  useEffect(() => {
    setParticipantsSelected(formData.cycleInvitedGuests)
  }, [formData.cycleInvitedGuests])

  useEffect(() => {
    if (participantsSelected.length > 0)
      setAllChecked(
        participantChecked.length === participantsSelected.length ? true : false
      )
  }, [participantChecked])

  const handleNewGuest = async (newGuest, close, update) => {
    if (!close) {
      if (!update) {
        await serviceCycleInvitedGuests.newInvitedGuest(newGuest)
      } else {
        await serviceCycleInvitedGuests.updateCycleInvitedGuestsDetails(
          newGuest
        )
      }
      const newInvitedList =
        await serviceCycleInvitedGuests.getCycleInvitedGuestsByIdCycle(
          formData.idCycle
        )

      if (!newInvitedList.error) {
        setFormData(prevState => {
          return {
            ...prevState,
            cycleInvitedGuests: newInvitedList.data
          }
        })
        setParticipantsSelected(newInvitedList.data)
      }

      setLoadingParticipants(false)
      setShowAddInvitedsDialog(false)
    } else {
      setShowAddInvitedsDialog(false)
    }
  }

  const removeParticipantFromList = id => {
    const newParticipants = formData.cycleInvitedGuests.filter(
      invited => invited.idInvited !== id
    )

    setFormData(prevState => {
      return { ...prevState, cycleInvitedGuests: newParticipants }
    })
  }
  const handleInvitedDialog = (update, guest) => {
    if (update) {
      setUpdateGuest(update)
      setGuest(guest)
      setShowAddInvitedsDialog(true)
    } else {
      setUpdateGuest(update)
      setGuest(guest)
      setShowAddInvitedsDialog(true)
    }
  }

  const sendReminder = () => {
    if (participantChecked.length > 0) {
      const data = {
        idCycle: formData.idCycle,
        idLanguage: formData.idLanguage,
        typeMail: 2,
        typeSendTo: 2
      }
      serviceRabbitMq.getCallOrReminder(data).then(response => {
        if (!response.error) {
          const data = {
            idCycle: formData.idCycle,
            idLanguage: formData.idLanguage,
            typeMail: 2,
            mails: participantChecked,
            locale: response.data.locales
          }
          setMailData(data)
          setShowTemplatesDialog(true)
        }
      })
    } else {
      snackActions.error(t('CYCLES.JOURNEYS.SELECT_PARTICIPANT'))
    }
  }

  const sendCall = () => {
    if (participantChecked.length > 0) {
      const data = {
        idCycle: formData.idCycle,
        idLanguage: formData.idLanguage,
        typeMail: 1,
        typeSendTo: 1
      }
      serviceRabbitMq.getCallOrReminder(data).then(response => {
        if (!response.error) {
          const data = {
            idCycle: formData.idCycle,
            idLanguage: formData.idLanguage,
            typeMail: 1,
            mails: participantChecked,
            locale: response.data.locales
          }
          setMailData(data)
          setShowTemplatesDialog(true)
        }
      })
    } else {
      snackActions.error(t('CYCLES.JOURNEYS.SELECT_PARTICIPANT'))
    }
  }

  const sendEmail = () => {
    if (participantChecked.length > 0) {
      const data = {
        idCycle: formData.idCycle,
        typeMail: 5,
        mails: participantChecked,
        idLanguage: 1,
        locale: [
          {
            idLanguage: 1,
            mailingBody: '',
            mailingSubject: ''
          }
        ]
      }
      setMailData(data)
      setShowTemplatesDialog(true)
    } else {
      snackActions.error(t('CYCLES.JOURNEYS.SELECT_PARTICIPANT'))
    }
  }


    const sendEmailRrhh = () => {
        const idCompany = formData.idCompany
        const companyContact = formData.cycleCompanies?.find(
            x => x.idCompany === idCompany
        )
        const emailCompanyContact = companyContact.companyEmail
        const idCycle = formData.idCycle

        if (participantChecked.length > 0) {
            const data = {
                idCycle: idCycle,
                idLanguage: formData.idLanguage,
                typeMail: 12,
                typeSendTo: 1,
                participantsChecked: participantChecked
            }
            serviceRabbitMq.getMailRRHH(data).then(response => {
                if (!response.error) {
                    const data = {
                        idCycle: formData.idCycle,
                        idLanguage: formData.idLanguage,
                        typeMail: 12,
                        mails: [emailCompanyContact],
                        locale: response.data.locales
                    }
                    setMailData(data)
                    setShowTemplatesDialog(true)
                }
            })
        } else {
            snackActions.error(t('CYCLES.JOURNEYS.SELECT_INVITED_GUEST'))
        }
    }

  /*const sendEmailRrhh = () => {
    const idCompany = formData.idCompany
    const companyContact = formData.cycleCompanies?.find(
      x => x.idCompany === idCompany
    )
    const emailCompanyContact = companyContact.companyEmail
    const idCycle = formData.idCycle

    serviceRabbitMq.getMailRRHH(idCycle).then(response => {
      if (!response.error) {
        const data = {
          idCycle: idCycle,
          typeMail: 12,
          mails: [emailCompanyContact],
          locale: response.data.locales
        }
        setMailData(data)
        setShowTemplatesDialog(true)
      }
    })
  }*/

  //TODO: Unificar criterios, es posible que se estén haciendo mal algunas cosa
  const sendEmailParticipants = () => {
    let invalidMailFound = false;
      mailData.mails.forEach((mail) => {
      if (mail === '' || !patternEmail.test(mail.toLowerCase())) {
        invalidMailFound = true;
      }
    });

    if (invalidMailFound) {
      snackActions.error(t('LOGIN.SIGN_UP.ERROR.TYPE_EMAIL'));
    } else {
      if (mailData.typeMail === 5) {
        setShowTemplatesDialog(false)
        const data = {
          ...mailData,
          MailTo: participantChecked,
          Locales: mailData.locale
        }
        serviceRabbitMq.sendDirectMail(data).then(response => {
          if (!response.error) {
              setShowTemplatesDialog(false)
          }
        })
      } else if (mailData.typeMail === 12) {
        const data = {
          MailTo: mailData.mails,
          Locales: mailData.locale
        }
          setShowTemplatesDialog(false)
          serviceRabbitMq.sendMailRRHH({
            idCycle: formData.idCycle,
            to: data
          })
        
      } else {
        setShowTemplatesDialog(false)
        const tabLang = parseInt(sessionStorage.getItem('tabLang') !== null ? sessionStorage.getItem('tabLang') : formData.idLanguage - 1);
               
        const data = {
          ...mailData,
          idLanguage: tabLang + 1,
          mails: mailData.mails,
          typeSendTo: 0,
          locales: mailData.locale
        }
        serviceRabbitMq.sendCallOrReminder(data)
        sessionStorage.removeItem('tabLang');
      }
    }
  }

  //OVERRIDES ACCORDION
  const Accordion = styled(props => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  }))

  const AccordionSummary = styled(props => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)'
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1)
    }
  }))

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)'
  }))

  const [expanded, setExpanded] = React.useState('panel0')

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <>
      <Card>
        {showAddInvitedsDialog && (
          <Dialog
            width='30%'
            height='fit-content'
            centerContent={true}
            open={showAddInvitedsDialog}
            hasCloseBtn={false}
            title={t('CYCLES.ADD_PARTICIPANTS')}
            close={() => setShowAddInvitedsDialog(false)}
            content={
              <AddInviteds
                guest={guest}
                handleNewGuest={handleNewGuest}
                update={updateGuest}
                cycleCompanies={formData.cycleCompanies}
              />
            }
          />
        )}

        {showTemplatesDialog && (
          <Dialog
            disabled={loadingParticipants}
            width='70%'
            height='80%'
            open={showTemplatesDialog}
            hasCloseBtn={false}
            title={t('CYCLES.SEND_MAIL')}
            close={() => {
              setShowTemplatesDialog(false)
              setMailData('')
            }}
            content={
              <TableTemplateMailing
                mailData={mailData}
                setMailData={setMailData}
                participantChecked={participantChecked}
                disabled={false}
              />
            }
            actions={
              <Box display='flex' width='100%' justifyContent='flex-end'>
                <Button
                  disabled={loadingParticipants}
                  sx={{ mr: 1 }}
                  variant='contained'
                  color='error'
                  onClick={() => {
                    setShowTemplatesDialog(false)
                  }}
                  aria-label='move selected left'
                >
                  {t('BUTTON.CLOSE')}
                </Button>
                <Button
                  variant='contained'
                  onClick={() => sendEmailParticipants()}
                  aria-label='move selected left'
                  disabled={mailData?.length == 0}
                >
                  {loadingParticipants ? (
                    <CircularProgress size={10} color='white' />
                  ) : (
                    t('CYCLES.SEND_MAIL')
                  )}
                </Button>
              </Box>
            }
          />
        )}
        <Accordion
          expanded={expanded === 'panel'}
          onChange={handleChange('panel')}
        >
          <AccordionSummary
            sx={{
              bgcolor: 'grey.main',
              paddingLeft: '20px'
            }}
          >
            <Typography variant='subtitle1'>
              {t('CYCLES.LIST_INVITEDS')}
            </Typography>
          </AccordionSummary>
          
          <AccordionDetails>
            <Typography variant='caption' color={!isUpdate && 'grey.dark'}>
              {!formData.cycleInvitedGuests.length
                ? t('CYCLES.ADD_PARTICIPANTS')
                : t('CYCLES.EDIT_PARTICIPANTS')}
              {!isUpdate && (
                <Typography variant='caption' color='grey.dark'>
                  {' '}
                  {t('CYCLES.ADD_PARTICIPANTS_TAG')}
                </Typography>
              )}
              {formData?.cycleInvitedGuests.length > 0 && (
                <Tooltip title={t('TEXT.SUBTITLE_PARTICIPANTS')}>
                  <IconButton
                    aria-label='info'
                    color='primary'
                    style={{
                      height: 10,
                      float: 'right'
                    }}
                    onClick={() => {
                      serviceFiles.getExcelParticipants(formData.idCycle)
                    }}
                  >
                    <Assessment fontSize='inherit' />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title={t('CYCLES.RRHH')}>
                <IconButton
                  aria-label='info'
                  color='primary'
                  style={{
                    height: 10,
                    float: 'right'
                  }}
                  onClick={() => {
                    sendEmailRrhh()
                  }}
                >
                  <MailRounded fontSize='inherit' />
                </IconButton>
              </Tooltip>
            </Typography>
            <List>
              {formData?.cycleInvitedGuests.length > 0 && (
                <Grid style={{marginLeft:'-15px'}}>
                <Typography color='primary'>
                  <Checkbox
                    sx={{ p: 1 }}
                    onChange={e => handleAllCheck(e)}
                    checked={allCheked}
                  />

                  {t('CYCLES.JOURNEYS.SELECT_ALL')}
                </Typography>
                </Grid>
              )}
              
              <Grid style={{marginLeft:'-20px'}}>
                <Box key={listReload}>
                  {formData?.cycleInvitedGuests.map((guest, index) => {
                    return (
                      
                      <Box key={guest.idInvited}>
                        <ListItem
                          sx={{
                            bgcolor: 'theme.palette.primary.main'
                          }}
                          secondaryAction={
                            <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
                              <Grid item>
                                <Icon
                                  variant='success'
                                  onClick={() => {
                                    handleInvitedDialog(true, guest)
                                  }}
                                >
                                  <EditOutlinedIcon />
                                </Icon>
                              </Grid>

                              <Grid item style={{ marginRight: '-25px' }}>
                                <DeletePopover
                                  remove={() => {
                                    removeParticipantFromList(guest.idInvited, index)
                                  }}
                                />
                              </Grid>
                            </Grid>


                          }
                        >
                          <ListItemIcon>
                            <Checkbox
                              value={guest.invitedEmail}
                              sx={{ p: 0 }}
                              onChange={e => handleCheckbox(e)}
                              checked={checkList(guest.invitedEmail)}
                            />
                          </ListItemIcon>

                          <ListItemText
                            sx={{ marginLeft: '-15px' }}
                            primaryTypographyProps={{ fontSize: 14 }}
                            primary={
                              guest?.invitedName && guest?.invitedSurname
                                ? `${guest.invitedName} ${guest.invitedSurname}`
                                : t('CYCLES.NO_USER_DATA')
                            }
                            secondary={
                              <React.Fragment>
                                {guest.invitedEmail ? (            
                                  <Box
                                      sx={{
                                        width: { xs: '150px', sm: '350px', md: '550px', lg: '550px', xl: '550px' }
                                      }}
                                    >
                                    <Typography
                                      sx={{
                                        textOverflow: 'clip',
                                        whiteSpace: 'normal',
                                        wordWrap: 'break-word'
                                      }}
                                      component='span'
                                      variant='body2'
                                      color='text.primary'
                                    >
                                      {guest.invitedEmail}
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Typography
                                    sx={{ display: 'inline' }}
                                    component='span'
                                    variant='body2'
                                    color='text.primary'
                                  >
                                    {t('CYCLES.NO_EMAIL_DATA')}
                                  </Typography>
                                )}
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                        <Divider />
                      </Box>
                    )
                  })}
                </Box>
              </Grid>
              <Button
                variant='contained'
                color='primary'
                sx={{ mb: 2, mt: 2 }}
                fullWidth
                disabled={!isUpdate}
                onClick={() => handleInvitedDialog(false, formData.idCycle)}
              >
                <AddIcon />
                {t('CYCLES.ADD_PARTICIPANTS')}
              </Button>
              {formData?.cycleInvitedGuests.length > 0 && (
                <ButtonGroup
                  variant='contained'
                  fullWidth
                  size='medium'
                  disabled={!isUpdate}
                >
                  <Grid container spacing={.2}>
                    <Grid item xs={12} md={4}>
                      <Button
                        style={{height: '100%'}}
                        variant='contained'
                        color='success'
                        fullWidth
                        startIcon={<CampaignOutlinedIcon />}
                        onClick={() => {
                          sendCall()
                        }}
                      >
                        {t('CYCLES.SEND_ANNOUNCEMENT')}
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Button
                        style={{height: '100%'}}
                        variant='contained'
                        color='primary'
                        fullWidth
                        startIcon={<NotificationsActiveOutlinedIcon />}
                        onClick={() => {
                          sendReminder()
                        }}
                      >
                        {t('CYCLES.SEND_REMINDER')}
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Button
                        style={{height: '100%'}}
                        variant='contained'
                        startIcon={<EmailOutlinedIcon />}
                        color='secondary'
                        fullWidth
                        onClick={() => {
                          sendEmail()
                        }}
                      >
                        {t('CYCLES.SEND_MAIL')}
                      </Button>
                    </Grid>
                  </Grid>


                </ButtonGroup>
              )}
            </List>
          </AccordionDetails>
        </Accordion>
      </Card>
      <>
        <>
          <table id='idTableToXls' className={classesCommon.hidden}>
            <tbody>
              <tr style={{ color: '#00007D', fontSize: 'x-large' }}>
                <th colSpan={9}>{t('PARTICIPANT.BODY_EXCEL')}</th>
              </tr>
              <tr style={{ color: '#00007D', fontWeight: 'bold' }}>
                <td
                  key={1}
                  style={{ borderBottom: '1px solid rgb(0, 0, 125)' }}
                >
                  N.
                </td>
                <td
                  key={2}
                  style={{ borderBottom: '1px solid rgb(0, 0, 125)' }}
                  colSpan={3}
                >
                  {t('MAILINGS_TAGS.TAG_NAME_PARTICIPANT')}
                </td>
                <td
                  key={3}
                  style={{ borderBottom: '1px solid rgb(0, 0, 125)' }}
                >
                  1
                </td>
                <td
                  key={4}
                  style={{ borderBottom: '1px solid rgb(0, 0, 125)' }}
                >
                  2
                </td>
                <td
                  key={5}
                  style={{ borderBottom: '1px solid rgb(0, 0, 125)' }}
                >
                  3
                </td>
                <td
                  key={6}
                  style={{ borderBottom: '1px solid rgb(0, 0, 125)' }}
                >
                  4
                </td>
                <td
                  key={7}
                  style={{ borderBottom: '1px solid rgb(0, 0, 125)' }}
                >
                  5
                </td>
              </tr>

              {formData?.cycleInvitedGuests.map((participant, index) => {
                let dataExcelTable = participant
                let table =
                  '<td>' +
                  (index + 1) +
                  '</td><td colspan="3"><b>' +
                  participant.invitedName +
                  ' ' +
                  participant.invitedSurname +
                  '</b> (' +
                  participant.invitedPosition +
                  ')</td><td></td>'
                return (
                  <tr
                    key={index}
                    dangerouslySetInnerHTML={{ __html: table }}
                  ></tr>
                )
              })}
            </tbody>
          </table>
        </>
        <ReactHTMLTableToExcel
          id='idDownloadButton'
          className={classesCommon.hidden}
          table='idTableToXls'
          filename={t('PARTICIPANT.BODY_EXCEL')}
          sheet={t('PARTICIPANT.BODY_EXCEL')}
          buttonText='Download as XLS'
        />
      </>
    </>
  )
}

export default SectionInvited
