import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Header from './layout/Header'
import Menu from './layout/Menu'
import { Grid } from '@mui/material'

const Home = () => {
  const [showHorizontalMenu, setShowHorizontalMenu] = useState(false)
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false)

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Header
            handleMenuPosition={() => {
              setShowHorizontalMenu(!showHorizontalMenu)
            }}
            handleShowMenu={setShowHamburgerMenu}
            showMenu={showHamburgerMenu}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={showHorizontalMenu ? 12 : 3}
          lg={showHorizontalMenu ? 12 : 2}
        >
          <Menu
            showHorizontalMenu={showHorizontalMenu}
            handleShowMenu={setShowHamburgerMenu}
            showMenu={showHamburgerMenu}
          />
        </Grid>
        <Grid
          style={{
            maxHeight: showHorizontalMenu
              ? 'calc(100vh - 137px)'
              : 'calc(100vh - 100px)',
            overflowY: 'auto'
          }}
          item
          xs={12}
          md={showHorizontalMenu ? 12 : 9}
          lg={showHorizontalMenu ? 12 : 10}
        >
          <Outlet />
        </Grid>
      </Grid>
    </>
  )
}

export default Home
