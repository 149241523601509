import React, { useEffect, useState, useCallback } from 'react';
import HeaderTitle from '@components/HeaderTitle';
import { useForm } from '@hooks/useForm';
import { Grid, MenuItem, Select, Typography } from '@mui/material';
import serviceCycleTypes from '@services/serviceCycleTypes';
import serviceParticipants from '@services/serviceParticipants';
import { useCommonStyles } from '@styles/common.style';
import { useTranslation } from 'react-i18next';
import AutocompleteSearch from '@components/AutocompleteSearch';

const Searches = () => {
    const { formData, setFormData, handleChange } = useForm({
        idCycleType: '',
        idParticipant: '',
    });
    const classes = useCommonStyles();
    const [cycleTypes, setCycleTypes] = useState([]);
    const { t } = useTranslation();
    const [participantSelected, setParticipantSelected] = useState();

    useEffect(() => {
        if (participantSelected) {
            setFormData(prevState => {
                return {
                    ...prevState,
                    idParticipant: participantSelected.idParticipant,
                };
            });
        }
    }, [participantSelected]);

    useEffect(() => {
        serviceCycleTypes
            .getCycleTypesList({
                pageSize: 100,
                pageNumber: 0,
                globalSearch: '',
                search: [],
                order: [],
            })
            .then(response => {
                if (!response.error) {
                    setCycleTypes(response.data);
                }
            });
    }, []);

    return (
        <div className={classes.container}>
            <HeaderTitle title={t('MENU_ADMIN.SEARCH')} subtitle={t('MENU_ADMIN.QUERIES_ADVANCED')} />
            <Grid container>
                <Grid item xs={4}>
                    <Typography variant='caption'>{t('TEXT.CYCLE_TYPE')}</Typography>
                    <Select
                        fullWidth
                        size='small'
                        value={formData.idCycleType}
                        name='idCycleType'
                        onChange={handleChange}>
                        {cycleTypes.map(cycleType => (
                            <MenuItem
                                value={cycleType.idCycleType}
                                key={cycleType.idCycleType}>
                                {cycleType.cycleTypeName}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid xs={8}></Grid>
                <Grid xs={4}>
                    <Typography variant='caption'>{t('LABEL.NAME')}</Typography>
                    <AutocompleteSearch
                        initialSearch={
                            formData.idParticipant && {
                                globalSearch: '',
                                order: [],
                                pageNumber: 0,
                                pageSize: 1,
                                search: [
                                    {
                                        searchField: 'idParticipant',
                                        searchMode: 'equalTo',
                                        searchValue: formData.idParticipant,
                                    },
                                ],
                            }
                        }
                        disabled={false}
                        setOptionSelected={setParticipantSelected}
                        setDisabledAddButton={() => {
                            return false;
                        }}
                        fetch={useCallback(
                            async debounceValue =>
                                await serviceParticipants.getParticipants(debounceValue),
                            [],
                        )}
                        optionsDisabled={option => {
                            return [];
                        }}
                        optionsLabel={option =>
                            `${option.participantName || ''} ${option.participantSurname || ''
                            }`
                        }
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default Searches;