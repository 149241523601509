import { useSnackbar } from "notistack";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info'; // Ícono para info
import WarningIcon from '@mui/icons-material/Warning'; // Ícono para warning
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Ícono para success
import ErrorIcon from '@mui/icons-material/Error'; // Ícono para error
import { IconButton, SnackbarContent, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Definimos los estilos utilizando makeStyles
const useStyles = makeStyles(() => ({
    snackbarMessage: {
        backgroundColor: '#2196f3', // Color del fondo
        color: '#fff', // Color del texto
    },
    messageWithIcon: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        marginRight: '8px', // Espacio entre el ícono y el mensaje
    },
}));

// Componente que configura el SnackbarUtils y usa el hook useStyles
const InnerSnackbarUtilsConfigurator = (props) => {
    const snackbarRef = useSnackbar();
    const classes = useStyles(); // Aquí aplicamos los estilos de MUI
    props.setUseSnackbarRef(snackbarRef, classes); // Pasamos el snackbarRef y las clases
    return null;
};

// Variables para almacenar la referencia de Snackbar y las clases
let useSnackbarRef;
let snackbarClasses;

// Función para configurar la referencia de Snackbar y las clases
const setUseSnackbarRef = (useSnackbarRefProp, classesProp) => {
    useSnackbarRef = useSnackbarRefProp;
    snackbarClasses = classesProp; // Almacenamos las clases para utilizarlas más tarde
};

// Componente principal que configura el SnackbarUtils
export const SnackbarUtilsConfigurator = () => {
    return (
        <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
    );
};

// Definición de las acciones de Snackbar
export const snackActions = {
    success(msg) {
        this.toast(msg, "success");
    },
    warning(msg) {
        this.toast(msg, "warning");
    },
    info(msg) {
        this.toast(msg, "info");
    },
    error(msg) {
        this.toast(msg, "error");
    },
    toast(msg, variant = "default") {
        let customOptions = {};

        // Estilo especial solo para las notificaciones de tipo 'info'
        if (variant === 'info') {
            customOptions = {
                anchorOrigin: {
                    vertical: 'top', // Posición arriba a la derecha para 'info'
                    horizontal: 'right',
                },
                autoHideDuration: null,
                action: (key) => (
                    <IconButton onClick={() => useSnackbarRef.closeSnackbar(key)} color="inherit">
                        <CloseIcon />
                    </IconButton>
                ),
                content: (key) => (
                    <SnackbarContent
                        message={
                            <Box className={snackbarClasses.messageWithIcon}>
                                <InfoIcon className={snackbarClasses.icon} /> {/* Ícono para 'info' */}
                                {msg}
                            </Box>
                        }
                        sx={{
                            fontSize: '1.5rem', // Cambia el tamaño de la letra solo para 'info'
                            fontFamily: 'Arial Nova, sans-serif', // Estilo de fuente
                            backgroundColor: '#2196f3', // Color de fondo
                            color: '#fff', // Color del texto
                        }}
                        action={
                            <IconButton onClick={() => useSnackbarRef.closeSnackbar(key)} color="inherit">
                                <CloseIcon />
                            </IconButton>
                        }
                    />
                )
            };
        }

        // Configuración para 'warning' con su ícono y posición abajo a la izquierda
        if (variant === 'warning') {
            customOptions = {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left', // Posición abajo a la izquierda
                },
                autoHideDuration: null,
                action: (key) => (
                    <IconButton onClick={() => useSnackbarRef.closeSnackbar(key)} color="inherit">
                        <CloseIcon />
                    </IconButton>
                ),
                content: (key) => (
                    <SnackbarContent
                        message={
                            <Box className={snackbarClasses.messageWithIcon}>
                                <WarningIcon className={snackbarClasses.icon} /> {/* Ícono para 'warning' */}
                                {msg}
                            </Box>
                        }
                        sx={{
                            fontSize: '1rem',
                            fontFamily: 'Arial, sans-serif',
                            backgroundColor: '#ff9800', // Color de fondo para 'warning'
                            color: '#fff',
                        }}
                        action={
                            <IconButton onClick={() => useSnackbarRef.closeSnackbar(key)} color="inherit">
                                <CloseIcon />
                            </IconButton>
                        }
                    />
                )
            };
        }

        // Configuración para 'success' con su ícono y posición abajo a la izquierda
        if (variant === 'success') {
            customOptions = {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left', // Posición abajo a la izquierda
                },
                autoHideDuration: 3000,
                action: (key) => (
                    <IconButton onClick={() => useSnackbarRef.closeSnackbar(key)} color="inherit">
                        <CloseIcon />
                    </IconButton>
                ),
                content: (key) => (
                    <SnackbarContent
                        message={
                            <Box className={snackbarClasses.messageWithIcon}>
                                <CheckCircleIcon className={snackbarClasses.icon} /> {/* Ícono para 'success' */}
                                {msg}
                            </Box>
                        }
                        sx={{
                            fontSize: '1rem',
                            fontFamily: 'Arial, sans-serif',
                            backgroundColor: '#4caf50', // Color de fondo para 'success'
                            color: '#fff',
                        }}
                        action={
                            <IconButton onClick={() => useSnackbarRef.closeSnackbar(key)} color="inherit">
                                <CloseIcon />
                            </IconButton>
                        }
                    />
                )
            };
        }

        // Configuración para 'error' con su ícono y posición abajo a la izquierda
        if (variant === 'error') {
            customOptions = {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left', // Posición abajo a la izquierda
                },
                autoHideDuration: 4000,
                action: (key) => (
                    <IconButton onClick={() => useSnackbarRef.closeSnackbar(key)} color="inherit">
                        <CloseIcon />
                    </IconButton>
                ),
                content: (key) => (
                    <SnackbarContent
                        message={
                            <Box className={snackbarClasses.messageWithIcon}>
                                <ErrorIcon className={snackbarClasses.icon} /> {/* Ícono para 'error' */}
                                {msg}
                            </Box>
                        }
                        sx={{
                            fontSize: '1rem',
                            fontFamily: 'Arial, sans-serif',
                            backgroundColor: '#f44336', // Color de fondo para 'error'
                            color: '#fff',
                        }}
                        action={
                            <IconButton onClick={() => useSnackbarRef.closeSnackbar(key)} color="inherit">
                                <CloseIcon />
                            </IconButton>
                        }
                    />
                )
            };
        }

        // Encolamos la notificación con las opciones y el estilo
        useSnackbarRef.enqueueSnackbar(msg, {
            variant,
            ...customOptions,
        });

        // Configuración especial para dispositivos móviles
        if (window.innerWidth <= 480 && variant !== 'warning' && variant !== 'info') {
            setTimeout(() => { useSnackbarRef.closeSnackbar() }, 1500);
        }
    },
};
