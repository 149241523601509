import React from 'react';
import AddButton from '@components/AddButton';
import DynamicTable from '@components/DynamicTable';
import HeaderTitle from '@components/HeaderTitle';
import { EditRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useCommonStyles } from '@styles/common.style';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import serviceMobileSheets from '@services/serviceMobileSheets';
import { snackActions } from '@helpers/snackbarUtils';

const OwToolbox = () => {
    const classes = useCommonStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const ORDER = [
        /*{
            "orderField": "mobileSheetTitle",
            "orderAsc": true
        }*/
    ];
    const COLUMNS = [
        {
            accessor: '',
            Header: 'Info',
            filterable: false,
            hideHeader: true,
            width: '5%',
            Cell: ({ row: { values } }) => (
                <IconButton
                    aria-label='info'
                    color='info'
                    style={{
                        padding: '0px',
                    }}
                    onClick={() => {
                        handleShowMobileSheetDetail(values.idMobileSheet);
                    }}>
                    <EditRounded fontSize='inherit' />
                </IconButton>
            ),
        },
        {
            accessor: 'idMobileSheet',
            Header: t('LABEL.ID'),
            filterable: false,
            width: '1%',
        },
        {
            accessor: 'mobileSheetName',
            Header: t('LABEL.NAME'),
            width: '40%'
        },
        {
          accessor: 'mobileSheetCode',
          Header: t('OWTOOLS.WORKSHEET_UNLOCK_CODE'),
          width: '59%'
      },
    ];

    const handleShowMobileSheetDetail = idMobileSheet => {
        const userSession = JSON.parse(sessionStorage.getItem('userSession'))
        if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 4)) {
            serviceMobileSheets.getMobileSheetsDetails(idMobileSheet).then(response => {
                if (!response.error) {
                    navigate('/owToolboxDetail', {
                        state: { data: response.data, type: 'UPDATE' },
                    });
                }
            });
        } else {
            snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'));
        }
    };

    const handleNewTarget = () => {
        const userSession = JSON.parse(sessionStorage.getItem('userSession'))
        if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 4)) {
            navigate('/owToolboxDetail', { state: { type: 'NEW' } });
        } else {
            snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'));
        }
    };
    return (
        <div className={classes.container}>
            <HeaderTitle
                title={t('TEXT.TITLE_TEMPLATE')}
                subtitle={t('TEXT.SUBTITLE_TEMPLATE')}>
                <AddButton text={t('BUTTON.NEW_TEMPLATE_OWTOOL')} handleOnclick={handleNewTarget} />
            </HeaderTitle>
            <DynamicTable
                columns={COLUMNS}
                endpoint='MobileSheets/List'
                isGlobalSearch={true}
                isColumnsSearch={true}
                isOrdered={true}
                orderColumn={ORDER}
            />
        </div>
    );
};

export default OwToolbox;