import { AuthContext } from '@context/auth/AuthContext'
import Home from '@areas/Consultants/Home'
import LostPassword from '@login/LostPassword'
import NewPassword from '@login/NewPassword'
import LoginConsultantsPage from '@login/LoginConsultantsPage'
import PanelConsultants from '@areas/Consultants/sections/panel/Panel'
import Profile from '@areas/Consultants/sections/profile/Profile'
import Journey from '@areas/Consultants/sections/journey/Journey'
import JourneySer from '@areas/Consultants/sections/journey/JourneySer'
// import CycleDetails from '@areas/Consultants/sections/cycleDetails/CycleDetails';
import CycleDetails from '@areas/Participants/sections/cycleDetails/CycleDetails'
import CycleIncidents from '@areas/Consultants/sections/cycleIncidents/CycleIncidents'
import CycleNewIncident from '@areas/Consultants/sections/cycleNewIncident/CycleNewIncident'
import PhotoGroup from '@areas/Consultants/sections/photoGroup/PhotoGroup'
import SimulatedLogin from '@areas/Consultants/sections/simulatedLogin/SimulatedLogin'
import { useContext } from 'react'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from 'react-router-dom'
import CycleImage from '@areas/Consultants/sections/cycleImage/CycleImage'

const ConsultantsRoutes = () => {
  const PrivateRoute = () => {
    const { status } = useContext(AuthContext)
    return status === 'authenticated' ? <Home /> : <Navigate to='/' />
  }
  const { status } = useContext(AuthContext)

  return (
    <Router>
      <Routes>
        <Route
          path='/'
          element={
            status === 'authenticated' ? (
              <Navigate to='/panel' />
            ) : (
              <LoginConsultantsPage />
            )
          }
        />
        <Route path='*' element={<Navigate to='/' />} />
        <Route path='/simulatedLogin' element={<SimulatedLogin />} />
        <Route
          path='/consultants/simulatedLogin'
          element={<SimulatedLogin />}
        />
        <Route path='/lostPassword' element={<LostPassword />} />
        <Route path='newPassword' element={<NewPassword />} />
        <Route path='/reset' element={<NewPassword />} />
        <Route element={<PrivateRoute />}>
          <Route path='panel' element={<PanelConsultants />} />
          <Route path='profile' element={<Profile />} />
          <Route path='cycledetails/:idCycle' element={<CycleDetails />} />
          <Route path='cycleimage/:idCycle' element={<CycleImage />} />
          <Route path='journey/:idCycle' element={<Journey />} />
          <Route path='journeySer/:idJourney' element={<JourneySer />} />
          <Route
            path='cycleincidents/:idCycle/:idCycleJourney'
            element={<CycleIncidents />}
          />
          <Route
            path='cyclenewincident/:idCycle'
            element={<CycleNewIncident />}
          />
          <Route path='photoGroup' element={<PhotoGroup />} />
        </Route>
      </Routes>
    </Router>
  )
}

export default ConsultantsRoutes
