import React, { useState } from 'react'
import DynamicTable from '@components/DynamicTable'
import HeaderTitle from '@components/HeaderTitle'
import { Box, Button, IconButton, Typography, Tooltip } from '@mui/material'
import serviceScheduler from '@services/serviceScheduler'
import { useCommonStyles } from '@styles/common.style'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { EditRounded } from '@mui/icons-material'
import { snackActions } from '@helpers/snackbarUtils'
import Loading from '@components/Loading'
import AddIcon from '@mui/icons-material/Add'

const SchedulerCategories = () => {
  const classes = useCommonStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const ORDER = [
    {
      orderField: 'categoryName',
      orderAsc: true
    }
  ]
  
  const COLUMNS = [
    {
      accessor: '',
      Header: 'Info',
      filterable: false,
      hideHeader: true,
      width: '1%',
      Cell: ({ row: { values } }) => (
        <Box display='flex' flexDirection='row'>
          <Tooltip title={t('LABEL.EDIT')}>			
						<IconButton
							aria-label='info'
							color='info'
							style={{
								padding: '0px'
							}}
							onClick={() => {
                handleShowSchedulerCategoryDetail(values.idCategory)
							}}	
						>
							<EditRounded fontSize='inherit' />
						</IconButton>
					</Tooltip>
        </Box>
      )
    },
    {
      accessor: 'idCategory',
      Header: t('LABEL.ID'),
      filterable: false,
      width: '1%'
    },
    {
      accessor: 'categoryName',
      Header: t('SCHEDULER.CATEGORY_NAME'),
      Cell: props => <p>{props.value}</p>,
      width: '50%'
    }
  ]

  const handleShowSchedulerCategoryDetail = idCategory => {
		//Se verifica si el usuario tiene permisos para realizar las acciones sobre una categoría
    const userSession = JSON.parse(sessionStorage.getItem('userSession'))
    if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 2)) {
			setLoading(true)
      serviceScheduler.getSchedulerCategoryDetails(idCategory).then(response => {
				setLoading(false)
        if (!response.error) {
          navigate(`/SchedulerCategoryDetail`, {
            state: {data: response.data, type: 'UPDATE'}
          })
        }
      })
    } else {
      snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'))
    }
  }


  const handleNewSchedulerCategories = () => {
    //Se verifica si el usuario tiene permisos para realizar las acciones sobre un gestor
    const userSession = JSON.parse(sessionStorage.getItem('userSession'))
    if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 2)) {
      navigate('/SchedulerCategoryDetail', { state: { data: {}, type: 'NEW' } })
    } else {
      snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'))
    }
  }

  if (loading) {
    return <Loading />
  }
  
  return (
    <div className={classes.container}>

      <HeaderTitle
        title={t('SCHEDULER.TITLE')}
        subtitle={t('SCHEDULER.SCHEDULER_CHANGES')}
      >
        <Button
          variant='contained'
          color='primary'
          sx={{ mt: 2 }}
          onClick={handleNewSchedulerCategories}
          startIcon={<AddIcon />}
        >
          {t('BUTTON.NEW_CATEGORY')}
        </Button>
      </HeaderTitle>

      <DynamicTable
        columns={COLUMNS}
        endpoint='Scheduler/SchedulerCategories/List'
        isGlobalSearch={true}
        isColumnsSearch={true}
        isOrdered={true}
        orderColumn={ORDER}
      />

    </div>
  )
}

export default SchedulerCategories
