
const isLastJourney = (cycle) => {
    const date = new Date();
    date.setDate(date.getDate());
    date.setHours(23, 59,59);
    var cycleNotPrivate = cycle.cycleJourneys.filter(cycleJourney => cycleJourney.cycleJourneyPrivate == false);
    var filtered = cycle.cycleJourneys.filter(cycleJourney => (date >= Date.parse(cycleJourney.cycleJourneyDateFrom) && cycleJourney.cycleJourneyPrivate == false));
    if (filtered.length === cycleNotPrivate.length) {
        return true;
    } else {
        return false;
    }
}

export default isLastJourney;