import React, { useState, useEffect } from 'react'
import Dialog from '@components/Dialog'

import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemIcon,
  Checkbox
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import TableCompanies from './TableCompanies'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeletePopover from '@components/DeletePopover'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'

const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  }))

  const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    minHeight: '40px', //Mismo alto que los botones de Foto y Evaluador de SectionForm
    '& .MuiAccordionSummary-content': {
      margin: '0px', //Quito márgen para que no quede tan alto
      '&.Mui-expanded': {
        margin: '0px',
      },
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(0deg)',
      marginRight: theme.spacing(1),

      '&.Mui-expanded': {
        transform: 'rotate(90deg)',
      },
    },
  }));
  
  

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}))

const SectionCompanies = ({ formData, setFormData }) => {
  const { t } = useTranslation()
  const [openTable, setOpenTable] = useState(false)
  const [companiesSelected, setCompaniesSelected] = useState()
  const [isExpanded, setIsExpanded] = useState(!formData.idCycle)

  useEffect(() => {
    setCompaniesSelected(formData.cycleCompanies)
  }, [])

  useEffect(() => {
    setIsExpanded(!formData.idCycle)
  }, [formData.idCycle])

  const removeCompanyFromList = id => {
    const newCompanies = formData.cycleCompanies.filter(
      company => company.idCompany !== id
    )

    setFormData(prevState => {
      return {
        ...prevState,
        cycleCompanies: newCompanies,
        idCompany: prevState.idCompany == id ? null : prevState.idCompany
      }
    })
    setCompaniesSelected(newCompanies)
  }

  return (
    <Box>
      {openTable && (
        <Dialog
          disabled
          open={openTable ? true : false}
          width='50%'
          height='75%'
          hasCloseBtn={false}
          title={t('CYCLES.COMPANY_SEARCH')}
          close={() => setOpenTable(false)}
          content={
            <TableCompanies
              setCompaniesSelected={setCompaniesSelected}
              companiesSelected={companiesSelected}
            />
          }
          actions={
            <Box display='flex' width='100%' justifyContent='flex-end'>
              <Button
                sx={{ mr: 1 }}
                variant='contained'
                color='error'
                onClick={() => {
                  setOpenTable(false)
                }}
                aria-label='move selected left'
              >
                {t('CYCLES.OUT')}
              </Button>
              <Button
                variant='contained'
                color='success'
                onClick={() => {
                  setFormData(prevState => {
                    return {
                      ...prevState,
                      cycleCompanies: companiesSelected,
                      idCompany: prevState.idCompany
                        ? prevState.idCompany
                        : companiesSelected[0].idCompany
                    }
                  })
                  setOpenTable(false)
                }}
                aria-label='move selected left'
              >
                {t('CYCLES.SAVE_FORM_CHANGES')}
              </Button>
            </Box>
          }
        />
      )}

      <Accordion expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)}>
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
          sx={{
            bgcolor: 'grey.main',
            paddingLeft: '20px'
          }}
        >
          <Typography variant='subtitle1'>
            {t('MENU_ADMIN.COMPANIES')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='caption'>
            {!formData.cycleCompanies.length
              ? t('CYCLES.ADD_COMPANIES')
              : t('CYCLES.EDIT_COMPANIES')}
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                startIcon={<VisibilityIcon />}
                onClick={() => setOpenTable(true)}
                variant='contained'
                color='primary'
                fullWidth
              >
                <Typography
                  variant='button'
                  sx={{
                    textTransform: 'none'
                  }}
                >
                  {t('CYCLES.COMPANY_LIST')}
                </Typography>
              </Button>
            </Grid>
          </Grid>

          <List>
            {formData.cycleCompanies.map((company, index) => (
              <Box key={company.idCompany}>
                <ListItem
                  sx={{
                    marginLeft: '-20px',
                    bgcolor: 'theme.palette.primary.main'
                  }}
                  secondaryAction={
                    <Grid item style={{ marginRight: '-30px' }}>
                    <DeletePopover
                      remove={() => {
                        removeCompanyFromList(company.idCompany)
                      }}
                    />
                    </Grid>
                  }
                >
                  <ListItemIcon>
                    <Checkbox
                      edge='start'
                      checked={formData.idCompany === company.idCompany}
                      onChange={() => {
                        setFormData(prevState => {
                          return { ...prevState, idCompany: company.idCompany }
                        })
                      }}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ marginLeft: '-20px' }}
                    secondary={
                      company.companyParentsNames || company.companyName
                    }
                  />
                </ListItem>
                <Divider />
              </Box>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default SectionCompanies
