import serviceScheduler from '@services/serviceScheduler'
import { snackActions } from '@helpers/snackbarUtils';

export const fetchCategories = async (setCategoriesList, calcLang) => {
    const {data} = await serviceScheduler.getSchedulerCategories({
      pageSize: 100, 
      pageNumber: 0, 
      globalSearch: '', 
      search: [
        {
          searchField: 'idLanguage',
          searchMode: 'equalTo',
          searchValue: calcLang(),
      },
      ],
      order: [
          {
              orderField: 'CategoryName',
              orderAsc: true
          }
      ]
    })
    setCategoriesList(data)
}

export const getCategoryDetails = async (idCategory, setCategoryDetails) => {
  const { data, error } = await serviceScheduler.getSchedulerCategoryDetails(idCategory);
  if (!error) {
    setCategoryDetails(data)
  } else {
    snackActions.error(error);
  }
};

export const fetchDataEvents = async (dataQuery, setEvents, setEventSelected, setOpenLoading, setLoading) => {
    if (dataQuery.IdUsers[0] !== undefined) {
        setLoading(true)
        const { data, error } = await serviceScheduler.getSchedulerEvents(dataQuery);
        if (!error) {
            setEvents(data);
            // setEventSelected(null);
            setOpenLoading(false)
            setLoading(false)
        }
    } else {
        console.log("IdUsers[0] no est� definido en los datos proporcionados.");
    }
};

export const addNewEvent = async (dataEvent, setOpenLoading, setOpenEventDetails, setEventSelected) => {
    setOpenLoading(true)
    // const recurrent = dataEvent.isRecurrentEvent
    const { data, error } = await serviceScheduler.newSchedulerEvent(dataEvent);
    if (!error) {
      setOpenEventDetails(false)
      // setEventSelected(null)
    } else {
      snackActions.error(error);
    }
    setOpenLoading(false)
};

export const updateEvent = async (dataEvent, setOpenLoading, setOpenEventDetails, setEventSelected, recurrentTypeUpdate) => {
    setOpenLoading(true)
    const dataPayload = {
      modifyEvent: dataEvent,
      modifyType: recurrentTypeUpdate
    }
    const { data, error } = await serviceScheduler.updateSchedulerEvent(dataPayload);
    if (!error) {
      sessionStorage.removeItem('idEventSelected')
      setOpenEventDetails(false)
      // setEventSelected(null)
    } else {
      snackActions.error(error);
    }
    setOpenLoading(false)
};

export const deleteEvent = async (dataEvent, setOpenLoading, setEventSelected, setOpenEventDetails, recurrentTypeUpdate) => {
    setOpenLoading(true)
    const dataPayload = {
      modifyEvent: dataEvent,
      modifyType: recurrentTypeUpdate
    }
    const { data, error } = await serviceScheduler.deleteSchedulerEvent(dataPayload);
    if (!error) {
      sessionStorage.removeItem('idEventSelected')
      // setEventSelected(null)
      setOpenEventDetails(false)
    } else {
      snackActions.error(error);
    }
    setOpenLoading(false)
};

export const fetchFeeSlots = async (dataQuery, setFreeSlots, setOpenLoading, setLoading, setOpenFreeSlots) => {
  if (dataQuery.IdUsers[0] !== undefined) {
      setLoading(true)
      const { data, error } = await serviceScheduler.getFreeSlots(dataQuery);
      if (!error) {
        console.log(data);
          setFreeSlots(data);
          // setEventSelected(null);
          setOpenLoading(false)
          setLoading(false)
          setOpenFreeSlots(true)
      }
  } else {
      console.log("Error al buscar huecos");
  }
};