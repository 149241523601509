import React, { useState, useEffect } from 'react'
import Editor from '@components/CkEditor'
import LocaleTabs from '@components/LocaleTabs'
import {
  Grid,
  TextField,
} from '@mui/material'
import { useCommonStyles } from '@styles/common.style'
import { useTranslation } from 'react-i18next'
import Card from '@components/Card'

const TemplateMailingDetail = ({ mailData, setMailData, disabled }) => {
  const classes = useCommonStyles()
  const { t } = useTranslation()
  const [change, setChange] = useState(true)
  //const patternEmail = new RegExp('^(([a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}),{0,1})+$');
  const [email,setEmail] = useState(mailData.mails); 
console.log(mailData);

/*    useEffect(() => {
        console.log('mailData:', mailData);
        console.log('setMailData:', setMailData);
        console.log('disabled:', disabled);
    }, [mailData, setMailData, disabled]);
    */

  const handleLocaleChange = ( data, idLanguage, name) => {
    
    let localeLanguage = mailData?.locale || [];
    let index = localeLanguage.findIndex(
      (locale) => locale.idLanguage === idLanguage
    );
    if (index < 0) {
      localeLanguage.push({
        idLanguage: idLanguage,
        idConsultant: mailData.idConsultant,
      });
      index = localeLanguage.length - 1;
    }
    localeLanguage[index][name] = data;
    
    setMailData((prevState) => {
      return { ...prevState, 
        locale: localeLanguage, 
      };
    })
    setChange(!change)
  }

  useEffect(() => {
      if (email !== null && email !== undefined && email !== 'undefined') {
            const trimmedEmails = email.map((e) => (e != null ? e.trim() : ""));
            //a�adimos esta "�apa" porque si viene vac�o no estaba mostrando el campo 'Para'.
            if (trimmedEmails[0] === "") trimmedEmails[0] = "-"

            setMailData(prevFormData => ({
              ...prevFormData,
              mails: trimmedEmails
            }))
      }
   }, [email]
  )
  
  const handleChange = (event) => {
    const inputValue = event.target.value;
    const newEmailsArray = inputValue.split(/[;,]+/); // Divide la cadena por comas o puntos y comas
    setEmail(newEmailsArray);
  };

  return (
    <div className={classes.container}>
      <Grid container spacing={2}>
        {((mailData?.mails && mailData?.mails[0] !== '' && mailData?.mails?.length >= 1 ) || (mailData.typeMail === 3 && mailData.idCycle === undefined)) &&
        <Grid item xs={12} md={12}>
            <TextField
                fullWidth
                // error={!patternEmail.test(email)}
                margin='normal'
                label={t('MAILING.TO')}
                value={email || ''}
                size='small'
                onChange={handleChange}
                disabled={disabled}
            />
          </Grid>
        }
        <Grid item xs={12} mt={2}>
          <Card>
            <LocaleTabs
              locale={mailData.locale}
              activeLanguage={mailData?.idLanguage}
              size={change}
              content={(localeLanguage, lang) => {
                return (
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <TextField
                        fullWidth
                        required
                        margin='normal'
                        label={t('MAILING.SUBJECT')}
                        value={localeLanguage?.mailingSubject || ''}
                        size='small'
                        onChange={e => {
                          handleLocaleChange(
                            e.target.value,
                            lang.idLanguage,
                            'mailingSubject'
                          )
                        }}
                      />
                    </Grid>

                    <Grid container spacing={2} mt={2}>
                      <Grid item md={12} xs={12}>
                        <Editor
                          required
                          data={localeLanguage?.mailingBody || ''}
                          onChange={newData => {
                            handleLocaleChange(
                              newData,
                              lang.idLanguage,
                              'mailingBody'
                            )
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default TemplateMailingDetail
