import React,{useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import Loading from '@components/Loading';
import serviceMobileSheets from '@services/serviceMobileSheets'
import ReactDevicePreview from 'react-device-preview'
import { Hidden } from '@mui/material';
import ContentMobile from '@components/ContentMobile';

const Worksheet = () => {

    const { idWorksheet } = useParams();
    const [formData, setFormData] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = async (idWorksheet) => {
        const { data, error } = await serviceMobileSheets.getMobileSheetsDetails(idWorksheet);
        if (!error) {
            const orderItems = data.mobileSheetItems
            .sort((a, b) => a.mobileSheetItemOrder - b.mobileSheetItemOrder)
            data.mobileSheetItems = orderItems
            setFormData(data);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (formData.length === 0 ) {
            fetchData(idWorksheet);
        }      
        }, [idWorksheet, formData]);

   return (
  <>
    {!loading ? (
        <>
            <Hidden lgDown>
                <ReactDevicePreview device='nexus5' scale='1'>
                    <ContentMobile formData={formData} />
                </ReactDevicePreview>
            </ Hidden>
            <Hidden lgUp>
                <ContentMobile formData={formData} />
            </Hidden>
        </>
    ) : (
        <Loading height='50vh' />
        )}
    </>
    );
};

export default Worksheet;