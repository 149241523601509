import apiRequest from '@helpers/apiRequest';

// Función para realizar una solicitud a la API y gestionar la caché
const apiRequestWithCache = (method, endpoint) => {
    const cacheKey = `${method}-${endpoint}`;
    
    // Intentar obtener los datos de la caché
    const cachedData = sessionStorage.getItem(cacheKey);
    
    if (cachedData) {
        // Si los datos están en la caché, devolverlos
        return Promise.resolve(JSON.parse(cachedData));
    } else {
        // Si los datos no están en la caché, realizar la solicitud a la API
        return apiRequest(method, endpoint).then(response => {
            // Guardar los datos en la caché para futuras solicitudes
            sessionStorage.setItem(cacheKey, JSON.stringify(response));
            return response;
        });
    }
};

// Uso de la función apiRequestWithCache para obtener las listas
const getLanguagesList = data => {
    return apiRequestWithCache('GET', 'Languages/List');
};

const getCountriesList = data => {
    return apiRequestWithCache('GET', 'Countries/List');
};

const getCountriesListAll = data => {
    return apiRequestWithCache('GET', 'Countries/ListAll');
};

const getRolesList = data => {
    return apiRequestWithCache('GET', 'UserRoles/ListRoles');
};


const serviceList = {
    getLanguagesList,
    getCountriesList,
    getCountriesListAll,
    getRolesList
};

export default serviceList;