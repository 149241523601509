import React, { useRef } from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';

const Flags = ({ handleSelect, options }) => {
    const lang = useRef(sessionStorage.getItem('lang'));

    return (
        <FormControl fullWidth variant='filled'>
            {options && (
                <Select
                    labelId='languageLabel'
                    label='Idioma'
                    id='language'
                    defaultValue={lang.current}
                    onChange={handleSelect}
                    hiddenLabel
                    size='small'>
                    {options.map(({ idLanguage, languageCode, Icon }) => {
                        return (
                            <MenuItem key={idLanguage} value={languageCode}>
                                <Icon style={{ display: 'flex' }} />
                            </MenuItem>
                        );
                    })}
                </Select>
            )}
        </FormControl>
    );
};

export default Flags;