import * as React from 'react';
import Popover from '@mui/material/Popover';
import { Box, Typography, Tooltip, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import BackspaceIcon from '@mui/icons-material/Backspace';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from "react-i18next";

export default function BackspacePopover({ remove, tooltipTitle, isLoading, idParticipant }) {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isHovered, setIsHovered] = React.useState(false);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Tooltip title={tooltipTitle}>
                <IconButton
                    onClick={handleClick}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={{
                        backgroundColor: isHovered ? '#f5f5f5' : 'white',
                        borderRadius: '50%',
                        padding: '10px'
                    }}>
                    <BackspaceIcon style={{ color: '#1c6189' }} />
                </IconButton>
            </Tooltip>
            {isLoading && (
                <CircularProgress 
                    size={40}
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-20px',
                        marginLeft: '-80px'
                    }}
                />
            )}

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}>
                <Box>
                    <Typography align='center' p={1}>
                        {t("LABEL.DELETE")}
                    </Typography>
                    <Box pb={1} px={1}>
                        <Button
                            size='small'
                            variant='contained'
                            color='error'
                            onClick={handleClose}
                            sx={{ mr: 0.5 }}>
                            {t("LABEL.FALSE")}
                        </Button>
                        <Button
                            size='small'
                            variant='contained'
                            color='success'
                            onClick={() => {
                                remove();
                                handleClose();
                            }}
                            sx={{ ml: 0.5 }}>
                            {t("LABEL.TRUE")}
                        </Button>
                    </Box>
                </Box>
            </Popover>
        </div>
    );
}
