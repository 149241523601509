import React, { Fragment, useContext, useRef, useState, useEffect } from 'react'
import {
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  Box,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  CircularProgress
} from '@mui/material'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import Card from '@components/Card'
import { ListContext } from '@context/ListContext'
import CheckBox from '@components/CheckBox'
import { useTranslation } from 'react-i18next'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import moment from 'moment'

const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}))

const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}))

const SectionForm = ({
  formData,
  handleChange,
  setFormData,
  evaluatorTypeList
}) => {
  const { t } = useTranslation()
  const idEvaluatorTypeRef = useRef()

  const handleChangeSelect = e => {
    idEvaluatorTypeRef.current.value = e.target.value
    idEvaluatorTypeRef.current.name = e.target.name
    const { value, name } = idEvaluatorTypeRef.current
    setFormData({ ...formData, [name]: value })
  }

  const handleChangeDate = (fieldName, e) => {
    let fecha = e.target ? e.target.value : e.format('YYYY-MM-DDTHH:mm')
 
    setFormData({ ...formData, [fieldName]: fecha })

  };

  return (
    <Fragment>
      <Card>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            sx={{
              bgcolor: 'grey.main',
              paddingLeft: '20px'
            }}
          >
            <Typography variant='subtitle1'>
              {' '}
              {t('CYCLE_TYPES.TITLE_GLOBAL_CONFIG')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1.5} component='form'>
              <Grid item md={4}>
                <Typography variant='caption' component='div'>
                  {t('EVALUATOR.EVALUATOR_TYPE')}*
                </Typography>
                <Box display='flex' alignItems='center' flexWrap='wrap'>
                  <Select
                    id='idEvaluatorType'
                    error={!formData?.idEvaluatorType}
                    ref={idEvaluatorTypeRef}
                    size='small'
                    name='idEvaluatorType'
                    value={formData?.idEvaluatorType || ''}
                    onChange={handleChangeSelect}
                  >
                    {evaluatorTypeList.map((evaluatorType, i) => (
                      <MenuItem
                        key={evaluatorType.idEvaluatorType}
                        value={evaluatorType.idEvaluatorType}
                      >
                        {evaluatorType.evaluatorTypeName}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Grid>
              <Grid item sm={4}>
              <Typography variant='caption' component='div'>
                  {t('LABEL.DATE_INIT')}*
                </Typography>
              <DateTimePicker
                name='evaluatorDateInit'
                slotProps={{
                  textField: {
                    size: 'small'
                  }
                }}
                format='DD/MM/YYYY HH:mm' // formato de fecha y hora válido para el locale en español
                value={
                  formData?.evaluatorDateInit
                    ? moment(formData?.evaluatorDateInit)
                    : null // Usa null en lugar de cadena vacía '' para el valor de fecha y hora
                }
                onChange={(e) => handleChangeDate('evaluatorDateInit', e)}
              />
              </Grid>
              <Grid item sm={4}>
                
              </Grid>
              <Grid item sm={4}>
                <Typography variant='caption' component='div'>
                  {t('EVALUATOR.NAME')}*
                </Typography>
                <TextField
                  id='evaluatorName'
                  error={formData?.evaluatorName === ''}
                  name='evaluatorName'
                  size='small'
                  value={formData?.evaluatorName || ''}
                  onChange={e => {
                    handleChange(e)
                  }}
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item sm={4}>
              <Typography variant='caption' component='div'>
                  {t('LABEL.EV_END')}*
                </Typography>
              <DateTimePicker
                name='evaluatorDateEnd'
                slotProps={{
                  textField: {
                    size: 'small'
                  }
                }}
                format='DD/MM/YYYY HH:mm' // formato de fecha y hora válido para el locale en español
                value={
                  formData?.evaluatorDateEnd
                    ? moment(formData?.evaluatorDateEnd)
                    : null // Usa null en lugar de cadena vacía '' para el valor de fecha y hora
                }
                onChange={(e) => handleChangeDate('evaluatorDateEnd', e)}
              />
              </Grid>
              <Grid item container sm={4} spacing={1}>
                <Grid item display='flex' alignItems='end' sm={12}>
                  <CheckBox
                    id='isActive'
                    sx={{minWidth:'100%', display:'flex', justifyContent:'flex-end !important'}}
                    label={t('CYCLES.IS_ACTIVE')}
                    name='isActive'
                    value={formData?.isActive}
                    checked={formData.isActive}
                    onChange={e => {
                      handleChange(e)
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Card>
    </Fragment>
  )
}

export default SectionForm
