import React from 'react';
import { Star, StarBorder } from '@mui/icons-material';
import { Card, Grid, Box, IconButton, Typography } from '@mui/material';
import { Link } from "react-router-dom";
import { useStyles } from '@styles/owToolboxKitOw.style'

const WorksheetCard = ({ dataCard, handleFavorite }) => {
    const classes = useStyles()
    const { idMobileSheet, idMobileSheetFavorite, mobileSheetTitle, mobileSheetColor } = dataCard;

    const handleFavoriteClick = () => {
        handleFavorite(idMobileSheet, idMobileSheetFavorite);
    };

    return (
        <Card variant="outlined" className={classes.card} >
            <Grid container spacing={1} style={{ padding: '0px', marginBottom: '0px'}}>
                <Grid style={{ backgroundColor: mobileSheetColor }} item xs={1} display="flex" justifyContent="center" >
                    {idMobileSheetFavorite !== null ? (
                        <IconButton
                            className={classes.starIcon}
                            onClick={handleFavoriteClick}
                        >
                            <Star fontSize='inherit' />
                        </IconButton>
                    ) : (
                        <IconButton
                            className={classes.starIcon}
                            onClick={handleFavoriteClick}
                        >
                            <StarBorder fontSize='inherit' />
                        </IconButton>
                    )}
                </Grid>

                <Grid item xs={11}>
                    <Link to={'/workSheetKitOW/' + idMobileSheet}>
                        <Box className={classes.boxCard}>
                            <Grid item>
                                <Typography className={classes.textCard} style={{ lineHeight: '1.0' }}>
                                    {mobileSheetTitle}
                                </Typography>
                            </Grid>
                        </Box>
                    </Link>
                </Grid>
            </Grid>
        </Card>
    );
};

export default WorksheetCard;

