import apiRequest from '@helpers/apiRequest';

const getUserDetails = idUser => {
    return apiRequest('GET', `Users/Details/${idUser}`);
};

const updateUser = data => {
    const idUser = data.idUser;
    return apiRequest('PUT', `Users/Update/${idUser}`, data);
};

const newUser = data => {
    return apiRequest('POST', 'Users/New', data);
};

const deleteUser = data => {
    const idUser = data.idUser;
    return apiRequest('DELETE', `Users/Delete/${idUser}`);
};

const getUserRole = data => {
    return apiRequest('GET', `UserRoles/UserRoles/${data.idUser}`);
};

const serviceUsers = {
    getUserDetails,
    updateUser,
    newUser,
    deleteUser,
    getUserRole
};

export default serviceUsers;