import apiRequest from '@helpers/apiRequest';

const getCycleTypesList = data => {
    return apiRequest('POST', 'CycleTypes/List', data);
};
const getCycleTypeDetail = idCycleType => {
    if (idCycleType !== '')  return apiRequest('GET', `CycleTypes/Details/${idCycleType}`);
};

const updateCycleType = data => {
    const idCycleType = data.idCycleType;
    return apiRequest('PUT', `CycleTypes/Update/${idCycleType}`, data);
};
const newCycleType = data => {
    return apiRequest('POST', 'CycleTypes/New', data);
};
const deleteCycleType = data => {
    const idCycleType = data.idCycleType;
    return apiRequest('DELETE', `CycleTypes/Delete/${idCycleType}`);
};

const serviceCycleTypes = {
    getCycleTypesList,
    getCycleTypeDetail,
    updateCycleType,
    deleteCycleType,
    newCycleType,
};

export default serviceCycleTypes;