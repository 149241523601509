import React from 'react';
import { Grid, Typography, Box, Card } from '@mui/material';
import DynamicTable from '@components/DynamicTable';
import HeaderTitle from '@components/HeaderTitle';
import { useCommonStyles } from '@styles/common.style';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import moment from 'moment';

const Panel = () => {
    const classes = useCommonStyles();
    const { t } = useTranslation();

    const OPEN_INCIDENTS = [
        {
          label: t('INCIDENT.PROGRESS_INCIDENT'),
          value: '2'
        },
        {
          label: t('INCIDENT.OPEN_INCIDENT'),
          value: '1'
        },
        {
          label: t('INCIDENT.CLOSE_INCIDENT'),
          value: '0'
        }
      ]
      const getIncident = value => {
        let res = OPEN_INCIDENTS.filter(x => x.value == value)
        return res.length ? res[0].label : '|'
      }
      const filterMap = value => {
        if (value == '') return ''
        let res = OPEN_INCIDENTS.filter(x =>
          x.label.toLowerCase().includes(value.toLowerCase())
        )
        return res.length ? res[0].value : '|'
      }
    //Panel jornadas hoy
    const today = [
        {
            accessor: 'idCycle',
            Header: t('LABEL.ID'),
            filterable: false,
            width: '5%',
            hiddenColumn: true,
        },
        {
            accessor: 'cycleDateFrom',
            Header: t('LABEL.DATE'),
            Cell: props => <Typography variant='body2'><Moment format='DD[/]MM[/]yyyy'>{props.value}</Moment></Typography>,
        },
        {
            accessor: 'cycleName',
            Header: t('LABEL.NAME'),
            Cell: props => <Typography variant='body2'>{props.value}</Typography>,
        },
        {
            accessor: 'companyName',
            Header: t('LABEL.COMPANY'),
            Cell: props => <Typography variant='body2'>{props.value}</Typography>,
        },
    ];

    const todayf = [
        {
            searchField: 'cycleDateFrom',
            searchMode: 'Greater',
            searchValue: moment(Date.now()).format('yyyy[-]MM[-]DD 00:00:00')
        }
    ];

    //Panel Incidencias
    const incidents = [
        {
            accessor: 'idIncident',
            Header: t('LABEL.ID'),
            filterable: false,
            width: '5%',
            hiddenColumn: true,
        },
        {
            accessor: 'dateIncident',
            Header: t('LABEL.DATE'),
            Cell: props => <Typography variant='body2'><Moment format='DD[/]MM[/]yyyy'>{props.value}</Moment></Typography>,
        },
        {
            accessor: 'cycleName',
            Header: t('CYCLES.CYCLE_NAME'),
            Cell: props => <Typography variant='body2'>{props.value}</Typography>,
        },
        {
            accessor: 'participantName',
            Header: t('LABEL.NAME'),
            Cell: props => <Typography variant='body2'>{props.value}</Typography>,
        },
        {
            accessor: 'participantSurname',
            Header: t('LABEL.SURNAME'),
            Cell: props => <Typography variant='body2'>{props.value}</Typography>,
        },
        {
            accessor: 'descriptionIncident',
            Header: t('LABEL.DESCRIPTION'),
            Cell: props => <Typography variant='body2'>{props.value}</Typography>,
        },
        {
          accessor: 'openIncident',
          Header: t('LABEL.STATE'),
          filterMap: x => filterMap(x),
          Cell: props => (
            <Typography variant='body2'>{getIncident(props.value)}</Typography>
          )
        },
    ];

    const incidentsOr = [
        {
          orderField: 'dateIncident',
          orderAsc: false
        }
      ] 
      const incidentsSe = [
        {
            searchField: 'openIncident',
            SearchValue: 0,
            searchMode: 'Greater',
        }
      ]

    //Panel Incidencias
    const journeys = [
        {
            accessor: 'idCycleJourney',
            Header: t('LABEL.ID'),
            filterable: false,
            width: '5%',
            hiddenColumn: true,
        },
        {
            accessor: 'cycleJourneyDateFrom',
            Header: t('LABEL.DATE'),
            Cell: props => <Typography variant='body2'><Moment format='DD[/]MM[/]yyyy'>{props.value}</Moment></Typography>,
        },
        {
            accessor: 'cycleName',
            Header: t('LABEL.CYCLE'),
            Cell: props => <Typography variant='body2'>{props.value}</Typography>,
        },
        {
            accessor: 'consultantName',
            Header: t('CYCLES.JOURNEYS.CONSULTANT'),
            Cell: props => <Typography variant='body2'>{props.value + ' ' + props.row.original.consultantSurname}</Typography>,
        },
        {
            accessor: 'cycleJourneyCityCode',
            Header: t('LABEL.CITY'),
            Cell: props => <Typography variant='body2'>{props.value}</Typography>,
        }
    ];

    const journeysf = [
        {
            searchField: 'cycleJourneyDateFrom',
            searchMode: 'Greater',
            searchValue: moment(Date.now()).format('yyyy[-]MM[-]DD 00:00:00')
        }
    ];
  

    return (
        <div className={classes.container}>
            <HeaderTitle
                title={t('PANEL.TITLE')}
                subtitle={t('PANEL.SUBTITLE')}
            /><br />

            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <Card
                        sx={{
                            padding: '20px 20px 45px 20px',
                            boxShadow: 0.5,
                            height: 'calc(35vh + 10px)',
                        }}>
                        <Typography color='primary'
                            style={{marginBottom:'10px'}}>
                            {t('PANEL.NEXT_JOURNEYS')}
                        </Typography>
                        <Box style={{ maxHeight: '28vh', overflowY: 'auto'}}>
                        <DynamicTable
                            columns={journeys}
                            customFilter={journeysf}
                            endpoint='Cycles/JourneysList'
                            isOrdered={true}
                            isPaginated={false}
                        />
                        </Box>
                    </Card>
                </Grid>
                <Grid item md={6} xs={12}>
                <Card
                        sx={{
                            padding: '20px 20px 45px 20px',
                            boxShadow: 0.5,
                            height: 'calc(35vh + 10px)',
                        }}>
                        <Typography color='primary'
                        style={{marginBottom:'10px'}}>
                            {t('PANEL.NEXT_CYCLES')}
                        </Typography>
                        <Box style={{ maxHeight: '28vh', overflowY: 'auto'}}>
                        <DynamicTable
                            columns={today}
                            customFilter={todayf}
                            endpoint='Cycles/List'
                            isOrdered={true}
                            isPaginated={false}
                        />
                        </Box>
                    </Card>
                </Grid>
                <Grid item md={12} xs={12}>
                <Card
                        sx={{
                            padding: '20px 20px 45px 20px',
                            boxShadow: 0.5,
                            height: 'calc(35vh + 10px)',
                        }}>
                        <Typography color='primary'
                        style={{marginBottom:'10px'}}>
                            {t('PANEL.INCIDENCES')}
                        </Typography>
                        <Box style={{ maxHeight: '28vh', overflowY: 'auto'}}>
                        <DynamicTable
                            columns={incidents}
                            endpoint='Incidents/List'
                            isOrdered={true}
                            isPaginated={false}
                            columnSearchParam={incidentsSe}
                            orderColumn={incidentsOr}
                            
                        />
                        </Box>
                    </Card>
            </Grid>
            </Grid>
        </div>
    );
};

export default Panel;