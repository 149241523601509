import { useState, useEffect } from 'react';
import { FLAG_ITEMS } from '@constants/flagItems';

import serviceList from '@services/serviceList';
export const useFlags = () => {
    const [state, setState] = useState({ flagOptions: null });

    const { icon: FlagSelected } = FLAG_ITEMS.find(
        flag => flag.value === sessionStorage.getItem('lang')
    );

    useEffect(() => {
        serviceList.getLanguagesList().then(response => {
            if (!response.error) {
                var result = response.data.map(flag => {
                    const item = FLAG_ITEMS.find(
                        item => item.value === flag.languageCode,
                    );
                    return { ...flag, Icon: item.icon };
                });
                setState(prevState => {
                    return { ...prevState, flagOptions: result };
                });
            }
        });
    }, []);

    return {
        ...state,
        FlagSelected,
    };
};