const ASISTENCIA_Y_FALTAS = `

  <html>
    <head>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta http-equiv="Content-Style-Type" content="text/css" />
      <meta name="generator" content="Aspose.Words for .NET 24.8.0" />
      <title>
      </title>
      <style type="text/css">.asistencia-span { font-size: 16pt; }

        //body { line-height:108%; font-family:'Arial Nova'; font-size:13pt }
        h1, h2, h3, h4, h5, h6, p { margin:0pt 0pt 8pt }
        li { margin-top:0pt; margin-bottom:8pt }
        h1 { margin-top:18pt; margin-bottom:4pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:'Aptos Display'; font-size:22pt; font-weight:normal; color:#2e74b5 }
        h2 { margin-top:8pt; margin-bottom:4pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:'Aptos Display'; font-size:22pt; font-weight:normal; color:#2e74b5 }
        h3 { margin-top:8pt; margin-bottom:4pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:22pt; font-weight:normal; color:#2e74b5 }
        h4 { margin-top:4pt; margin-bottom:2pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:13pt; font-weight:normal; font-style:italic; color:#2e74b5 }
        h5 { margin-top:4pt; margin-bottom:2pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:13pt; font-weight:normal; color:#2e74b5 }
        h6 { margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:13pt; font-weight:normal; font-style:italic; color:#595959 }
        .Heading7 { margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:13pt; font-weight:normal; font-style:normal; color:#595959; -aw-style-name:heading7 }
        .Heading8 { margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:13pt; font-weight:normal; font-style:italic; color:#272727; -aw-style-name:heading8 }
        .Heading9 { margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:13pt; font-weight:normal; font-style:normal; color:#272727; -aw-style-name:heading9 }
        .IntenseQuote { margin:18pt 43.2pt; text-align:center; line-height:108%; border-top:0.75pt solid #2e74b5; border-bottom:0.75pt solid #2e74b5; padding-top:10pt; padding-bottom:10pt; font-size:13pt; font-style:italic; color:#2e74b5; -aw-border-bottom:0.5pt single; -aw-border-top:0.5pt single; -aw-style-name:intense-quote }
        .ListParagraph { margin-left:36pt; margin-bottom:8pt; line-height:108%; font-size:13pt; -aw-style-name:list-paragraph }.Quote { margin-top:8pt; margin-bottom:8pt; text-align:center; line-height:108%; font-size:13pt; font-style:italic; color:#404040; -aw-style-name:quote }
        .Subtitle { margin-bottom:8pt; line-height:108%; font-family:Aptos; font-size:22pt; letter-spacing:0.75pt; color:#595959; -aw-style-name:subtitle }
        .Title { margin-bottom:4pt; line-height:normal; font-family:'Aptos Display'; font-size:30pt; letter-spacing:-0.5pt; -aw-style-name:title }
        span.CitaCar { font-style:italic; color:#404040 }
        span.CitadestacadaCar { font-style:italic; color:#2e74b5 }
        span.IntenseEmphasis { font-style:italic; color:#2e74b5; -aw-style-name:intense-emphasis }
        span.IntenseReference { font-weight:bold; font-variant:small-caps; letter-spacing:0.25pt; color:#2e74b5; -aw-style-name:intense-reference }
        span.SubttuloCar { font-family:Aptos; font-size:22pt; letter-spacing:0.75pt; color:#595959 }
        span.Ttulo1Car { font-family:'Aptos Display'; font-size:22pt; color:#2e74b5 }
        span.Ttulo2Car { font-family:'Aptos Display'; font-size:22pt; color:#2e74b5 }
        span.Ttulo3Car { font-family:Aptos; font-size:22pt; color:#2e74b5 }
        span.Ttulo4Car { font-family:Aptos; font-style:italic; color:#2e74b5 }
        span.Ttulo5Car { font-family:Aptos; color:#2e74b5 }
        span.Ttulo6Car { font-family:Aptos; font-style:italic; color:#595959 }
        span.Ttulo7Car { font-family:Aptos; color:#595959 }
        span.Ttulo8Car { font-family:Aptos; font-style:italic; color:#272727 }
        span.Ttulo9Car { font-family:Aptos; color:#272727 }
        span.TtuloCar { font-family:'Aptos Display'; font-size:30pt; letter-spacing:-0.5pt }
        .Tablaconcuadrcula3 {  }.TableGrid {  }
      </style>
    </head>
    <body>
      <div>
        <br>
        <p style="margin-bottom:6pt; text-align:justify; line-height:normal">
        <span class="asistencia-span" style="color:#333333">La Metodología OW es una experiencia vivencial, lo que requiere rigor de asistencia a todas y cada una de las jornadas de trabajo en grupo. </span>
        </p>
        <p style="margin-bottom:12pt; text-align:justify; line-height:normal">
        <span class="asistencia-span" style="color:#333333">La ASISTENCIA MÍNIMA para la obtención de resultados y considerar realizado el Programa OW es del 90% de las jornadas grupales.</span>
        </p>
        <p style="margin-bottom:6pt; text-align:justify; line-height:normal">
        <span class="asistencia-span" style="color:#333333">OW conforme a su estándar de Calidad y los Compromisos Éticos cumplirá con rigor el compromiso de no hacer lo que no sirve para los participantes o que perjudique los resultados esperados. </span>
        </p>
        <p style="margin-bottom:12pt; text-align:justify; line-height:normal">
        <span class="asistencia-span" style="color:#333333">Si por causas de fuerza mayor (enfermedad, incidencia grave, etc.) algún participante no pudiera asistir al mínimo exigido, queda siempre a criterio del Consultor Top-senior OW que dirige el Ciclo, en función del grado de implicación, calidad de trabajo realizado, cumplimiento de objetivos marcados y compromiso con el proceso de desarrollo del participante, determinar las posibles opciones a aplicar.</span>
        </p>
        <p style="margin-bottom:6pt; text-align:justify; line-height:normal">
        <span class="asistencia-span" style="text-decoration:underline; color:#333333">Las alternativas válidas para continuar con la realización del Programa son las siguientes:</span>
        </p>
        <p style="margin-left:7.1pt; margin-bottom:0pt; text-align:justify; line-height:normal">
        <span class="asistencia-span" style="color:#333333; -aw-import:ignore">&#xa0;</span>
        </p>
        <p style="margin-left:18pt; margin-bottom:6pt; text-align:justify; line-height:normal; font-size:22pt">
        <span class="asistencia-span" style="font-family:'Arial Nova Cond Light'; font-size:13pt; color:#0070c0"> </span>
        <span class="asistencia-span" style="font-family:'Arial Nova Cond Light'; font-size:22pt; letter-spacing:-0.15pt; color:#0070c0">1.- Opciones que mantienen nivel de dinámica y resultados del Ciclo OW:</span>
        </p>
        <p style="margin-left:54pt; margin-bottom:6pt; text-indent:-18pt; text-align:justify; line-height:normal; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:''; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:8.19pt">
        <span class="asistencia-span" style="-aw-import:ignore">
        <span class="asistencia-span" style="font-weight:bold; color:#262626">
        <span class="asistencia-span" style="font-family:'Wingdings 3'; font-weight:normal; color:#ff9933"></span>
        </span>
        <span class="asistencia-span" style="width:8.19pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
        </span>
        <span class="asistencia-span" style="color:#333333">La óptima: asistir a la misma jornada en otro grupo en fecha próxima, preferiblemente en otro grupo de la misma empresa que esté realizando en paralelo el mismo Programa OW, o en un grupo abierto que tenga disponibilidad y una secuencia de fechas compatible.</span>
        </p>
        <p style="margin-left:54pt; margin-bottom:6pt; text-indent:-18pt; text-align:justify; line-height:normal; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:''; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:8.19pt">
        <span class="asistencia-span" style="-aw-import:ignore">
        <span class="asistencia-span" style="font-weight:bold; color:#262626">
        <span class="asistencia-span" style="font-family:'Wingdings 3'; font-weight:normal; color:#ff9933"></span>
        </span>
        <span class="asistencia-span" style="width:8.19pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
        </span>
        <span class="asistencia-span" style="color:#333333">Realizar o completar el Ciclo OW más adelante en un futuro grupo, sea de la misma empresa o en abierto, cuando pueda garantizar su plena asistencia.</span>
        </p>
        <p style="margin-left:54pt; margin-bottom:6pt; text-indent:-18pt; text-align:justify; line-height:normal; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:''; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:8.19pt">
        <span class="asistencia-span" style="-aw-import:ignore">
        <span class="asistencia-span" style="font-weight:bold; color:#262626">
        <span class="asistencia-span" style="font-family:'Wingdings 3'; font-weight:normal; color:#ff9933"></span>
        </span>
        <span class="asistencia-span" style="width:8.19pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
        </span>
        <span class="asistencia-span" style="color:#333333">En caso de que se prevean varias ausencias a una jornada, se plantea reprogramar la fecha de esa jornada para el grupo completo a una nueva fecha, pudiendo ser también, la fecha de la siguiente jornada programada y añadir una nueva fecha posterior para la última sesión.</span>
        <span class="asistencia-span" style="color:#ff0000"> </span>
        <span class="asistencia-span" style="color:#333333">Esta opción queda condicionada a la disponibilidad de agenda del Director de Ciclo, debiendo consensuar el nuevo calendario con el área de planificación de OW. Si el aviso de aplazamiento se produce con menos de una semana de antelación a la celebración de la fecha programada, la cancelación de esa jornada tendrá un coste de 760.-€</span>
        </p>
        <p style="margin-left:28.35pt; margin-bottom:0pt; text-align:justify; line-height:normal">
        <span class="asistencia-span" style="color:#333333; -aw-import:ignore">&#xa0;</span>
        </p>
        <p style="margin-left:18pt; margin-bottom:6pt; text-align:justify; line-height:normal; font-size:22pt">
        <span class="asistencia-span" style="font-family:'Arial Nova Cond Light'; font-size:22pt; letter-spacing:-0.15pt; color:#0070c0">2.- Opciones limitadas: </span>
        </p>
        <p style="margin-left:18pt; margin-bottom:6pt; text-align:justify; line-height:normal">
        <span class="asistencia-span" style="color:#333333">Estas alternativas permiten ver lo tratado en la jornada con una importante pérdida de vivencia, impacto y contenido, con lo justo para poder retomar el ciclo. </span>
        </p>
        <p style="margin-left:18pt; margin-bottom:6pt; text-align:justify; line-height:normal">
        <span class="asistencia-span" style="color:#333333">Para ello se realiza una </span>
        <span class="asistencia-span" style="font-weight:bold; color:#333333">Sesión de Enganche Reducida (S.E.R). </span>
        <span class="asistencia-span" style="color:#333333">Con esta sesión tan sólo se obtiene unos mínimos para poder “engancharse” con el grupo y continuar. Estas opciones sólo se podrán hacer UNA SOLA VEZ, por tanto, en caso de realizar una de ellas supone que, en caso de faltar a alguna otra jornada, total o parcialmente, supone la baja en el Ciclo porque se rompe la secuencia vivencial y el proceso de transformación.</span>
        </p>
        <p style="margin-left:18pt; margin-bottom:6pt; text-align:justify; line-height:normal">
        <span class="asistencia-span" style="font-weight:bold; color:#333333">El Precio por una S.E.R. es de 430.-€ y se concretará en función de agendas de consultores OW.</span>
        </p>
        <p style="margin-left:18pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:6pt">
        <span class="asistencia-span" style="color:#333333; -aw-import:ignore">&#xa0;</span>
        </p>
        <p style="margin-left:25.1pt; margin-bottom:6pt; text-align:justify; line-height:normal; font-size:12pt">
        <span class="asistencia-span" style="font-family:'Arial Nova Cond Light'; letter-spacing:-0.15pt; color:#0070c0">OPCIONES:</span>
        </p>
        <p style="margin-left:54pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:''; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:8.19pt">
        <span class="asistencia-span" style="-aw-import:ignore">
        <span class="asistencia-span" style="color:#333333">
        <span class="asistencia-span" style="font-family:'Wingdings 3'; font-size:13pt; color:#ff9933"></span>
        </span>
        <span class="asistencia-span" style="width:8.19pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
        </span>
        <span class="asistencia-span" style="color:#0070c0">Experiencias con el grupo + S.E.R.</span>
        <span class="asistencia-span" style="font-weight:bold; color:#0070c0"> </span>
        <span class="asistencia-span" style="color:#404040">(desde la 2ª jornada)</span>
        </p>
        <p style="margin-left:67.65pt; margin-bottom:6pt; text-align:justify; line-height:normal">
        <span class="asistencia-span" style="font-weight:bold; color:#333333">Asistir, presencial o virtualmente a la primera hora y media de la jornada</span>
        <span class="asistencia-span" style="color:#333333"> para escuchar, compartir y aportar las experiencias sobre los resultados obtenidos de la puesta en práctica de los objetivos que se marcó en la jornada anterior. Posteriormente se programará una S.E.R. de una duración mínima de hora y media en la que ver el mínimo suficiente que el resto del grupo ha trabajado en modo vivencial grupal para poder continuar con la realización del programa en la siguiente jornada.</span>
        </p>
        <p style="margin-left:54pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:''; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:8.19pt">
        <span class="asistencia-span" style="-aw-import:ignore">
        <span class="asistencia-span" style="color:#333333">
        <span class="asistencia-span" style="font-family:'Wingdings 3'; font-size:13pt; color:#ff9933"></span>
        </span>
        <span class="asistencia-span" style="width:8.19pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
        </span>
        <span class="asistencia-span" style="color:#0070c0">Sólo S.E.R. </span>
        <span class="asistencia-span" style="color:#333333">(Alternativa mínima). </span>
        </p>
        <p style="margin-left:67.65pt; margin-bottom:0pt; text-align:justify; line-height:normal">
        <span class="asistencia-span" style="color:#333333">En caso de que sean imposibles alternativas anteriores, el mínimo para poder continuar es realizar, en modo Live-Virtual o presencial, una </span>
        <span class="asistencia-span" style="font-weight:bold; color:#333333">S.E.R. con consultor top-senior OW</span>
        <span class="asistencia-span" style="color:#333333">, de una duración de hora y media, para ver lo justo que le permita continuar con la realización del programa en grupo y volver a enganchar con la dinámica vivencial en la siguiente jornada.</span>
        </p>
        <p style="margin-left:46.35pt; margin-bottom:0pt; text-align:justify; line-height:normal">
        <span class="asistencia-span" style="color:#333333; -aw-import:ignore">&#xa0;</span>
        </p>
        <p style="margin-left:46.35pt; margin-bottom:0pt; text-align:justify; line-height:normal">
        <span class="asistencia-span" style="color:#333333; -aw-import:ignore">&#xa0;</span>
        </p>
        <p style="margin-right:8.65pt; margin-left:25.1pt; text-align:justify">
        <span class="asistencia-span" style="font-weight:bold; color:#333333; -aw-import:ignore">&#xa0;</span>
        </p>
        <p style="margin-right:8.65pt; margin-left:7.1pt; text-align:justify">
        <span class="asistencia-span" style="font-weight:bold; color:#333333">Proceso de decisión para garantizar calidad:</span>
        <span class="asistencia-span" style="color:#333333"> Serán los Directores OW del Ciclo quienes determinen cómo proceder con cada persona, y en cada caso, en función del desarrollo del grupo y el perfil del participante afectado, para ayudar a continuar con la realización del programa en grupo manteniendo la dinámica y los resultados del Ciclo OW. Se respetará siempre el criterio del consultor OW para tomar la mejor decisión y opción.</span>
        </p>
        <p style="margin-left:7.1pt; text-align:justify">
        <span class="asistencia-span" style="color:#333333">En todo momento, en caso de incidencias (faltas, cambios de jornadas, etc…) Otto Walter informará y consensuará con la Empresa cliente las alternativas aplicables y los posibles costes adicionales que conlleven, si los hubiera.</span>
        </p>
      </div>
    </body>
  </html>

    `;

  export default ASISTENCIA_Y_FALTAS;