import React, { useState, useEffect } from 'react'
import serviceEvaluatorInfo from '@services/serviceEvaluatorInfo'
import { Grid, Card, Box, Button, Tooltip, Typography, Select, MenuItem, TextField } from '@mui/material'
import HeaderTitle from '@components/HeaderTitle';
import { useNavigate } from 'react-router-dom'
import { useCommonStyles } from '@styles/common.style'
import { useStyles } from '@styles/evaluator.style'
import { useTranslation } from 'react-i18next'
import Loading from '@components/Loading';

const AutoevaluationResponses = () => {
    const classes = useCommonStyles()
    const classes2 = useStyles()
    const { t } = useTranslation()  
    const navigate = useNavigate()
    const receiver = JSON.parse(sessionStorage.getItem('receiver'))
    const idEvaluatorParticipant= receiver.idEvaluatorParticipant
    const [responses , setResponses] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        fetchAutoevaluationResponses(idEvaluatorParticipant)
    }, [t])
    

    const fetchAutoevaluationResponses = async (id) => {
        const { data, error } = await serviceEvaluatorInfo.getEvaluatorAutoresponses(id);
        if (!error) {
            setResponses(data.evaluatorAutoEvaluationResponses);
            setLoading(false)
        }
    };

    const goBack = () => {
        navigate('/reports')
    }

  return (
    <>
        {!loading ? (
            <div className={classes.container}>
                <HeaderTitle
                    title={t('EVALUATOR.AUTOEVALUATION')}
                    subtitle={t('EVALUATOR.AUTOEV_SUBTITLE')}
                />
                <br />
                <Card className={classes2.card}>
                    <Grid container spacing={0} mt={0} p={0}>
                        {responses.length > 0 && responses.map((question, index) => (
                            <Grid container spacing={1} mt={0} p={0} key={index}>
                                <Grid item xs={1} md={0.5} className={classes2.blueOtto + ' ' + classes2.paddingReduced} >
                                    {question.orderPosition}.-
                                </Grid>
                                <Grid item xs={9.5} md={9.5} className={classes2.paddingReduced}>
                                    <Typography style={{textAlign: 'justify'}}>{question.questionText}</Typography>
                                </Grid>
                                <Grid item xs={2} display={'flex'} justifyContent={'center'} alignItems={'start'}>
                                    <Box className={`${classes2.responseBox} ${question.evaluatorLetter === 'A' ? classes2.responseBlue: ''}`}>A</Box>
                                    <Box className={`${classes2.responseBox} ${question.evaluatorLetter === 'B' ? classes2.responseBlue: ''}`}>B</Box>
                                    <Box className={`${classes2.responseBox} ${question.evaluatorLetter === 'C' ? classes2.responseBlue: ''}`}>C</Box>
                                </Grid>
                            </Grid>
                        ))} 
                    </Grid>
                    <Grid container spacing={2} mt={0} p={1} display={'flex'} justifyContent={'end'}>
                        <Grid item xs={12} md={4} display={'flex'} justifyContent={'center'}>
                            <Button className={classes2.btn} variant='outlined' size='large' onClick={() => goBack()}>
                                {t('TEXT.BACK')}
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
            </div>
            ) : (
            <Loading height='50vh' />
            )
        }
    </>
  )
}

export default AutoevaluationResponses