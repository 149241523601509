import React, {useEffect, useState} from 'react';
import {RadioGroup, FormControlLabel, Radio, Typography, Grid, Tooltip, Box} from '@mui/material';
import { useStyles } from '@styles/evaluator.style'
import { useTranslation } from 'react-i18next'

export const TypeQuestion = ({index, question, selectedOption, onOptionChange}) => {
  const classes2 = useStyles()  
  const { t } = useTranslation() 
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
 
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []) 
   
  return (
    <div key={index}>
        <Grid container spacing={2} style={{borderBottom: '1px dotted #0085b4'}} py={3}>
            <Grid item xs={1} md={0.45} className={classes2.blueOtto + ' ' + classes2.paddingReduced} sx={{fontSize: '1.1em'}}>
                {index}.-
            </Grid>
            <Grid item xs={11} md={9.5} className={classes2.paddingReduced}>
                <Typography style={{textAlign: 'justify', fontSize: '1.1em'}}>{question}</Typography>
            </Grid>
            <Grid item xs={12} md={1.5} ml={1} display={'flex'} justifyContent={{ xs: 'flex-end', md: 'center' }} alignItems={'center'}>
              <Box 
                className={`${classes2.responseBox} ${selectedOption === '5' ? classes2.plusW: classes2.plusB } ${classes2.plus}`}
                onClick={(event) => onOptionChange(event.target.attributes.value.value)}
                value={'5'}
              ></Box>
              <Box 
                className={`${classes2.responseBox} ${selectedOption === '2.5' ? classes2.arrowW: classes2.arrowB } ${classes2.arrow}`}
                onClick={(event) => onOptionChange(event.target.attributes.value.value)}
                value={'2.5'}
              ></Box>
              <Box 
                className={`${classes2.responseBox} ${selectedOption === '0' ? classes2.minusW: classes2.minusB } ${classes2.minus}`}
                onClick={(event) => onOptionChange(event.target.attributes.value.value)}
                value={'0'}
              ></Box>
            </Grid>
        </Grid>
    </div>
  );
}