export const authReducer = (state, action) => {
    switch (action.type) {
        case 'signUp':
            return {
                ...state,
                status: 'authenticated',
                token: action.payload.token,
                user: action.payload.user,
            };
        case 'logout':
        case 'notAuthenticated':
            return {
                ...state,
                status: 'not-authenticated',
                token: null,
                user: null,
            };
        default:
            return state;
    }
};