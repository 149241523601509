import React, { useState, useEffect } from 'react';
import { Grid, Card} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { useForm } from '@hooks/useForm';
import Avatar from '@components/Avatar';
import { useHandleImages } from "@hooks/useHandleImages";

const PhotoGroup = () => {
    const { t } = useTranslation();
    const { manageImages, infoData } = useHandleImages();

    const { formData, setFormData } = useForm({
        cycleImage:''
    });

    useEffect(() => {
        if ( infoData?.cycleImage !== undefined && infoData?.cycleImage !== null && infoData?.cycleImage.length !== 0 ) {
          setFormData((prevState) => {
            return { ...prevState, 
              cycleImage: infoData.cycleImage, 
            };
          });
        }
      }, [infoData])

    return (
        <div>
            <Grid container spacing={1} padding={1}>
                <Grid item xs={1} display="flex" justifyContent="center">
                    <Link to="/"><ArrowBack /></Link>
                </Grid>
                <Grid item xs={9}>
                    <h3>{t('TEXT.PHOTO_GROUP')}</h3>
                </Grid>
            </Grid>
            <hr /><br />
            
            <Card variant="outlined" style={{ marginBottom: '10px' }}>            
                <Grid container spacing={1} padding={1}>
                    <Grid item xs={12} lg={4} display="flex" justifyContent="center">
                        <Avatar
                            handleChange={e => manageImages(e, 'cycleImage')}
                            image={formData?.cycleImage}
                            width={200}
                            height={120}
                            idbutton={'upload-button-GroupImage'}
                        />
                    </Grid>
                </Grid>
            </Card>       
        </div>
    );
};

export default PhotoGroup;