import apiRequest from '@helpers/apiRequest';

const getCompanies = data => {
    return apiRequest('POST', 'Companies/List', data);
};
const getCompaniesDetails = idCompany => {
    return apiRequest('GET', `Companies/Details/${idCompany}`);
};

const updateCompany = data => {
    const idCompany = data.idCompany;
    return apiRequest('PUT', `Companies/Update/${idCompany}`, data);
};

const newCompany = data => {
    return apiRequest('POST', 'Companies/New', data);
};

const deleteCompany = data => {
    const idCompany = data.idCompany;
    return apiRequest('DELETE', `Companies/Delete/${idCompany}`);
};

const downloadCompanyReport = data => {
    //type = short o complete report
    //return apiRequest('GET', `Company/DownloadFile`, data);
};
const serviceCompanies = {
    getCompanies,
    getCompaniesDetails,
    updateCompany,
    newCompany,
    deleteCompany,
    downloadCompanyReport,
};

export default serviceCompanies;