import React from 'react';
import Loading from '@components/Loading';
import { useFlags } from '@hooks/useFlags';
import { useCommonStyles } from '@styles/common.style'
import { useStyles } from '@styles/evaluator.style'
import SignInEvaluator from './SignInEvaluator';
import { Grid } from '@mui/material'

const LoginEvaluatorPage = () => {
    const classes = useCommonStyles()
    const classes2 = useStyles()
    const { flagOptions } = useFlags();

    if (!flagOptions) return <Loading />;

    return (
        <Grid className={classes2.loginEvaluator} display={'flex'} justifyContent={'center'}>
            <SignInEvaluator flagOptions={flagOptions} />
        </Grid>
    );
};

export default LoginEvaluatorPage;