import React from 'react'
import DynamicTable from '@components/DynamicTable'
import { Checkbox } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
const TableParticipants = ({
  participantsSelected,
  setParticipantsSelected
}) => {
  const { t } = useTranslation()

  const handleCheckbox = (isChecked, value) => {
    if (!isChecked) {
      setParticipantsSelected(prevState => {
        return [...prevState, value]
      })
    } else {
      const newParticipants = participantsSelected.filter(
        participant => participant.idParticipant !== value.idParticipant
      )
      setParticipantsSelected(newParticipants)
    }
  }
  const columnSearch = [
    {
      SearchField: 'ParticipantActive',
      SearchValue: 1,
      SearchMode: 'EqualTo'
    }
  ]

  const ORDER = [
    {
      orderField: 'participantSurname',
      orderAsc: true
    }
  ]
  const COLUMNS = [
    {
      filterable: false,
      hideHeader: true,
      accessor: '',
      Header: 'Info',
      width: '5%',
      Cell: ({ row: { values } }) => {
        delete values['Info']
        const isChecked = participantsSelected?.some(
          participant => participant.idParticipant === values.idParticipant
        )

        return (
          <Checkbox
            disableRipple
            sx={{ p: 0 }}
            checked={isChecked}
            onClick={e => handleCheckbox(isChecked, values)}
          />
        )
      }
    },
    {
      hiddenColumn: true,
      accessor: 'idParticipant',
      Header: t('LABEL.ID'),
      width: '5%'
    },
    {
      accessor: 'participantName',
      Header: t('LABEL.NAME')
    },
    {
      accessor: 'participantSurname',
      Header: t('LABEL.SURNAME')
    },
    {
      accessor: 'participantEmail',
      Header: t('LABEL.EMAIL')
    },
    {
      hiddenColumn: true,
      accessor: 'participantActive',
      Header: t('LABEL.ACTIVE')
    }
  ]

  return (
    <Box flex={1}>
      <DynamicTable
        size='small'
        columns={COLUMNS}
        endpoint='Participants/List'
        isColumnsSearch={true}
        isOrdered={true}
        columnSearchParam={columnSearch}
        orderColumn={ORDER}
      />
    </Box>
  )
}

export default TableParticipants
