import React from 'react';
import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    paper: props => {
        return {
            padding: theme.spacing(2),
            // border: `1px solid ${theme.palette.grey.dark}`,
            boxShadow: `5px 5px 20px 0px rgba(0,0,0,0.12)`,
            borderRadius: 0,
            minHeight: props.fullHeight && '100%',
            ...props.style,
        };
    },

    flex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
}));

const Card = ({ children, ...props }) => {
    const classes = useStyles(props);
    return (
        <Paper
            elevation={0}
            className={`${classes.paper} ${props.flex && classes.flex}`}>
            {children}
        </Paper>
    );
};

export default Card;