import React, { useEffect, useState, Fragment } from 'react'
import MenuList from '@components/MenuList'
import menuCampusItems from '@constants/menuCampusItems'
import menuConsultantsItems from '@constants/menuConsultantsItems'
import menuParticipantsItems from '@constants/menuParticipantsItems'
import { Accordion, AccordionSummary, Grid, Hidden } from '@mui/material'
import { useStyles } from '@styles/menu.style'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const MenuVertical = ({ showMenu, handleShowMenu }) => {
  const { i18n, t } = useTranslation()
  const classes = useStyles()
  const navigate = useNavigate()
  const [menuItems, setMenuItems] = useState([])
  const handleClickItem = item => {
    handleShowMenu(!showMenu)
    navigate(item.url)
  }
  useEffect(() => {
    let area = sessionStorage.getItem('area')
    if (area.includes('consultant')) {
      setMenuItems(menuConsultantsItems(t))
    } else if (area.includes('participant')) {
      setMenuItems(menuParticipantsItems(t))
    } else if (area.includes('campus')) {
      setMenuItems(menuCampusItems(t))
    }
  }, [i18n.language])

  const menuList =
    menuItems.length > 0 &&
    menuItems.map((menu, index) => (
      <MenuList
        key={index}
        menuTitle={menu.menuTitle}
        items={menu.items}
        handleClick={handleClickItem}
      />
    ))

  return (
    <Fragment>
      <Hidden mdDown>
        <Grid className={classes.containerVertical}>{menuList}</Grid>
      </Hidden>

      <Hidden mdUp>
        <Accordion
          expanded={showMenu}
          onChange={() => handleShowMenu(!showMenu)}
          square
          className={classes.containerVertical}
        >
          <AccordionSummary sx={{ display: 'none' }}></AccordionSummary>
          {menuList}
        </Accordion>
      </Hidden>
    </Fragment>
  )
}

export default MenuVertical
