import React, { useState, useEffect } from 'react';
import { Grid, Card, Box, Avatar, Tabs, Tab, Typography, ListItemText, ListItem, List, Button, Divider, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useStyles } from '@styles/participants.style';
import serviceCycles from '@services/serviceCycles';
import serviceConsultants from '@services/serviceConsultants';
import serviceCycleTypes from '@services/serviceCycleTypes';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Info, People, BusinessCenter, NoteAlt, Chat } from '@mui/icons-material';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import LinkMaterial  from '@mui/material/Link';
import Loading from '@components/Loading';
import Moment from 'react-moment';
import Logo from '@assets/images/logoCampus.png';
import Fallback from '@assets/images/avatar.png'
import { FLAG_ITEMS } from '@constants/flagItems'
import { transformText } from '@helpers/common';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ASISTENCIA_Y_FALTAS from './documento_sp';
import ATTENDANCE_AND_ABSENCES from './documento_en';
import serviceParticipants from '@services/serviceParticipants';

// Funciones para las tabs
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ paddingTop:'10px'}}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
////

const CycleDetails = (props) => {
    const classes = useStyles();
    const { idCycle } = useParams();
    const isParticipant = props.isParticipant || false;
    const [addressesToShow, setAddressesToShow] = useState([]);
    const [value, setValue] = React.useState(0);
    const [details, setDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [idConsultants, setIdConsultants] = useState([]);
    const [infoConsultants, setInfoConsultants] = useState([]);
    const languageSelected = sessionStorage.getItem('lang');
    const area = sessionStorage.getItem('area');
    const [lang, setLang] = useState('');
    const [idCycleType, setIdCycleType] = useState('');
    const [cycleContent, setCycleContent] = useState([]);
    const { t } = useTranslation();
    const idLanguage = sessionStorage.getItem('lang') === 'en' ? 2 : 1;
    const content = idLanguage === 2 ? ATTENDANCE_AND_ABSENCES : ASISTENCIA_Y_FALTAS;
    const title = idLanguage === 2 ? 'RULES OF ATTENDANCE AND ABSENCES' : 'NORMAS ASISTENCIA Y FALTAS';

    const [participantImages, setParticipantImages] = useState({});


    const handleChangeTabs = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        const { id: idLangSelected } = FLAG_ITEMS.find(
            item => item.value === languageSelected
        )
        setLang(idLangSelected)
    }, [])

    const modality = [
        { title: t('LABEL.ONSITE'), id: 1 },
        { title: t('LABEL.DIGITAL'), id: 2 }
    ]

    // Recupera info del ciclo
    const fetchData = async (idCycle) => {
        const { data, error } = await serviceCycles.getCycleDetail(idCycle, true, false);
        if (!error) {
            setDetails(data);
            setLoading(false);
            setIdCycleType(data.idCycleType);
        }
    };

    // Recupera info del tipo de ciclo (para pestaña de opiniones y contenido)
    const fetchDataCycleTypes = async (idCycleType) => {
      if (!idCycleType) {
          console.error("Invalid ID provided for fetching cycle type details");
          setLoading(false);
          return;
      }
      try {
          const response = await serviceCycleTypes.getCycleTypeDetail(idCycleType);
          if (response && response.data) {
              const { data } = response;
              setCycleContent(data.cycleTypeLocales);
              setLoading(false);
          } else {
              console.error('No data returned from serviceCycleTypes.getCycleTypeDetail');
              setLoading(false);
          }
      } catch (error) {
          console.error('Failed to fetch cycle types:', error);
          setLoading(false);
      }
    };  

    // Recupero info del consultor
    const fetchDataConsultant = async (idConsultant) => {
        const { data, error } = await serviceConsultants.getConsultantsDetails(idConsultant);
        if (!error) {
            setInfoConsultants(prevState => {
                if (prevState !== data) {
                    return [...prevState, data]
                } else {
                    return [data]
                }
            })
        }
    }

    // Con este useEffect hago un array con los id de los consultores sin repetirlos
    useEffect(() => {
        if (details.cycleJourneys !== '' && details.cycleJourneys !== undefined) {
            const idConsultantsAll = details.cycleJourneys?.map( journeys => journeys.idConsultant )
            const idConsultantsToShow = idConsultantsAll.filter((item,index)=>{
                return idConsultantsAll.indexOf(item) === index;
            })
            setIdConsultants(idConsultantsToShow)
        }
    }, [details])

    // Este useEffect sirve para guardar las direcciones de las jornadas presenciales que sean distintas
    useEffect(() => {
        details?.cycleJourneys?.map((dataJourney, i) => {
            const faceJourneyAddress = {
                "address": dataJourney.cycleJourneyAddress,
                "detail": dataJourney.cycleJourneyDetails,
                "city": dataJourney.cycleJourneyCityCode
            }
            const stringFaceJourneyAddress = JSON.stringify(faceJourneyAddress)

            if (dataJourney.idCycleJourneyModality === 1) {
                setAddressesToShow( prevState => {
                    if (prevState.indexOf(stringFaceJourneyAddress) === -1) {
                        return [...prevState, stringFaceJourneyAddress]
                    } else {
                        return [...prevState]
                    }
                })
            }
        })

    }, [details])

    // Los siguientes useEffects son los que lanzan las funciones de recuperar info dependiendo de si cambia el idCyle, idCycleType o el idConsultants
    useEffect(() => {
        if (idCycle !== undefined) {
            fetchData(idCycle);
        }

    }, [idCycle]);

    useEffect(() => {
      // Asegúrate de que `idCycleType` es válido antes de hacer la llamada
      if (idCycleType) {
          fetchDataCycleTypes(idCycleType);
      }
  }, [idCycleType]);

    useEffect(() => {
        if (idConsultants !== undefined && idConsultants.length > 0 && infoConsultants.length === 0) {
            idConsultants.map( idConsultant => {
                fetchDataConsultant(idConsultant);
            })
        }
    }, [idConsultants]);

    useEffect(() => {
        // Función para obtener la imagen de cada participante
        const fetchParticipantImage = async (idParticipant) => {
            const { data, error } = await serviceParticipants.getParticipantImage(idParticipant);
            if (!error) {
                setParticipantImages(prevImages => ({
                    ...prevImages,
                    [idParticipant]: `data:image/png;base64,${data}`
                }));
            }
        };

        // Iteramos sobre los participantes para obtener sus imágenes
        details.cycleParticipants?.forEach(participant => {
            if (participant.idParticipant && !participantImages[participant.idParticipant]) {
                fetchParticipantImage(participant.idParticipant);
            }
        });
    }, [details]);


    return (
        <Grid sx={{padding:'0em 0.3em', marginBottom: '80px', width: {xs: '100vw', sm: '100%'}}}>
            <Grid container spacing={0} marginTop={0} sx={{display:'flex', justifyContent:'start', alignItems:'center'}}>
                <Grid item xs={10} sx={{display:'flex', justifyContent:'start', alignItems:'center'}}>
                    <Typography className={classes.title} sx={{ margin: '10px 5px'}}>{t('MENU_PARTICIPANTS.INFO')}</Typography>
                </Grid>
                <Grid item xs={2} paddingLeft={2} paddingTop={0} sx={{display:'flex', justifyContent:'start', alignItems:'center'}}>
                    <Box>
                        <img className={classes.image} src={Logo} alt='logoxs' />
                    </Box>
                </Grid>
            </Grid>


            {!loading ?
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom:'15px', width: '100%'}}>
                        <Tabs
                            value={value}
                            onChange={handleChangeTabs}
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            <Tooltip title={t('CYCLE_TYPES.JOURNEYS2')}>
                                <Tab sx={{minWidth:'20% !important', padding: '2px', textTransform: 'none'}}
                                    label={
                                        <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center'}}>
                                            <Typography sx={{width:'100%', fontSize: '0.8em', lineHeight: '0.8em', fontWeight: '800', letterSpacing: '0.0002em'}}>{t('CYCLE_TYPES.JOURNEYS2')}</Typography>
                                            <Info sx={{ color: '#0085b4', fontSize: '2em', marginTop: '5px'}} />
                                        </div>
                                    }
                                    {...a11yProps(0)}
                                />
                            </Tooltip>
                            <Tooltip title={t('MENU_ADMIN.PARTICIPANTS')}>
                                <Tab sx={{minWidth:'20% !important', padding: '2px', textTransform: 'none'}}
                                    label={
                                        <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center'}}>
                                            <Typography sx={{width:'100%', fontSize: '0.8em', lineHeight: '0.8em', fontWeight: '800', letterSpacing: '0.0002em'}}>{t('MENU_ADMIN.PARTICIPANTS')}</Typography>
                                            <People sx={{ color: '#0085b4', fontSize: '2em', marginTop: '6px' }}/>
                                        </div>
                                    }
                                    {...a11yProps(1)}
                                />
                            </Tooltip>
                            {isParticipant && (
                                <Tooltip title='Top-Senior OW'>
                                    <Tab
                                        sx={{ minWidth: '20% !important', padding: '2px', textTransform: 'none' }}
                                        label={
                                            <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center'}}>
                                                <Typography sx={{width:'100%', fontSize: '0.8em', lineHeight: '0.8em', fontWeight: '800', letterSpacing: '0.0002em'}}>Top-Senior OW</Typography>
                                                <Box className={classes.director}></Box>
                                            </div>
                                        }
                                        {...a11yProps(2)}
                                    />
                                </Tooltip>
                            )}
                            {isParticipant && (
                                <Tooltip title={t('PARTICIPANTS.CONTENTS2')}>
                                    <Tab
                                        sx={{ minWidth: '20% !important', padding: '2px', textTransform: 'none' }}
                                        label={
                                            <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center'}}>
                                                <Typography sx={{width:'100%', fontSize: '0.8em', lineHeight: '0.8em', fontWeight: '800', letterSpacing: '0.0002em'}}>{t('PARTICIPANTS.CONTENTS2')}</Typography>
                                                <BusinessCenter sx={{ color: '#0085b4', fontSize: '2em', marginTop: '5px' }} />
                                            </div>
                                        }
                                        {...a11yProps(3)}
                                    />
                                </Tooltip>
                            )}
                            {isParticipant && (
                                <Tooltip title='QTRs'>
                                    <Tab
                                        sx={{ minWidth: '20% !important', padding: '2px', textTransform: 'none' }}
                                        label={
                                            <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center'}}>
                                                <Typography sx={{width:'100%', fontSize: '0.8em', lineHeight: '0.8em', fontWeight: '800', letterSpacing: '0.0002em'}}>QTRs</Typography>
                                                <Chat sx={{ color: '#0085b4', fontSize: '2em', marginTop: '8px' }} />
                                            </div>
                                        }
                                        {...a11yProps(4)}
                                    />
                                </Tooltip>
                            )}
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <Typography className={classes.subtitle} marginBottom={1}>{t('CYCLE_TYPES.JOURNEYS')}</Typography>
                        <hr/>
                        <List disablePadding>                            
                          {details.cycleJourneys?.map((dataJourney, i) => {
                            return (
                                <div key={i}>
                                    <ListItem disableGutters>
                                        <ListItemText
                                            primary={
                                                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-around', alignItems: 'flex-start' }}>
                                                    <Button
                                                        variant="outlined"
                                                        size="small"
                                                        className={classes.btnsTab0}
                                                        disabled
                                                        sx={{
                                                            width:'5%', 
                                                            paddingX:'0px !important',
                                                            minWidth:'20px',
                                                            fontWeight: 'bold'
                                                        }}
                                                    >
                                                        {i+1}
                                                    </Button>
                                                    <Button
                                                      variant="outlined"
                                                      size="small"
                                                      className={dataJourney.idCycleJourneyModality === 2 ? classes.btnsTab02 : classes.btnsTab0}
                                                      disabled
                                                      sx={{
                                                          width:'31%', 
                                                          marginLeft:'3px',
                                                          fontWeight: 'bold'
                                                      }}
                                                    >
                                                      {modality.filter(journeyId => journeyId.id == dataJourney.idCycleJourneyModality)
                                                          .map(journeyTitle => (
                                                              <span key={`mod-${i}`}>{journeyTitle.title}</span>
                                                          ))
                                                      }
                                                    </Button>
                                                  
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '3px', width: '50%' }}>
                                                    {dataJourney.cycleJourneySessions.map((session, index) => (
                                                      <Box key={`${i}-${index}`}>
                                                          <Button
                                                              variant="outlined"
                                                              size="small"
                                                              className={classes.btnsTab0}
                                                              disabled
                                                              sx={{
                                                                width:'100%',
                                                                marginBottom: '2px', // Espacio entre el botón y el texto de hora
                                                              }}
                                                          >
                                                              <Moment locale={t('MOMENT.LOCALECODE')} format={t('MOMENT.ALMOSTFULLDATEFORMAT')} style={{ fontWeight: 'bold' }}>
                                                                  {session.cycleJourneyDateFrom}
                                                              </Moment>
                                                          </Button>
                                                          <Typography variant="body2" style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1rem' }}>
                                                          {t('TEXT.FROM')} {moment(session.cycleJourneyDateFrom).format('HH:mm')} {t('TEXT.TO')} {moment(session.cycleJourneyDateTo).format('HH:mm')}
                                                          </Typography>
                                                      </Box>
                                                  ))}
                                                  </Box>
                                                  {area === 'consultant' && (
                                                    <Tooltip 
                                                        title={
                                                        `${infoConsultants.find(consultant => consultant.idConsultant === dataJourney.idConsultant)?.consultantName} ${infoConsultants.find(consultant => consultant.idConsultant === dataJourney.idConsultant)?.consultantSurname}`
                                                        }
                                                    >
                                                    
                                                        <span>
                                                        <Button
                                                            variant="outlined"
                                                            size="small"
                                                            className={classes.btnsTab0}
                                                            disabled
                                                            sx={{
                                                            minWidth: 0, // quita el minWidth
                                                            width: '25px', // establece un ancho mínimo
                                                            //padding: '0px', // quita el padding
                                                            marginLeft: '3px',
                                                            fontWeight: 'bold'
                                                            }}
                                                        >
                                                            {infoConsultants.find(consultant => consultant.idConsultant === dataJourney.idConsultant)?.consultantCode}
                                                        </Button>
                                                        </span>
                                                    </Tooltip>
                                                  )}
                                              </Box>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                </div>
                            );
                          })}
                        </List>
                        <Grid marginY={1}>
                            {details?.cycleTypeMode !== 2 && (
                                <>
                                    <Typography sx={{ fontSize: '1.2rem' }}>
                                        {t('PARTICIPANTS.FACEJOURNEYEMPLACEMENT')}:
                                    </Typography>
                                    <List sx={{ marginLeft: '10px' }}>
                                        {addressesToShow?.map((data, i) => {
                                            const { address, detail, city } = JSON.parse(data);
                                            const result = details?.cycleJourneys.filter(element => (element.cycleJourneyAddress + element.cycleJourneyDetails + element.cycleJourneyCityCode) === address + detail + city && element.idCycleJourneyModality === 1);
                                            return (
                                                <ListItem key={`address-${i}`} sx={{ padding: '0px', marginBottom: '10px', marginLeft: '20px' }}>
                                                    <ListItemText
                                                        primary={
                                                            <Typography 
                                                                className={classes.subtitle2} 
                                                                sx={{ fontWeight: 'normal', lineHeight: '1.9' }}
                                                            >
                                                                {result.map((res, i) => (
                                                                    <span key={i}>
                                                                        {res.cycleJourneyNumber}ª
                                                                        {i !== result.length - 1 && ' - '}
                                                                    </span>
                                                                ))}
                                                                &nbsp;{t('CYCLE_TYPES.JOURNEY')}/s
                                                            </Typography>
                                                        }
                                                        secondary={
                                                            <>
                                                                <Typography 
                                                                  component={'span'} 
                                                                  sx={{ lineHeight: '1.3', fontSize: '1.2rem' }}
                                                                >
                                                                    <span
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: transformText(address)
                                                                        }}
                                                                    />
                                                                </Typography>
                                                                <Typography component={'span'} sx={{ lineHeight: '1.1', fontSize: '1.2rem' }}>
                                                                    {detail}
                                                                </Typography>
                                                                {/*<Typography component={'span'}>{city}</>*/}

                                                            </>
                                                        }
                                                    />
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                </>
                            )}
                        </Grid>



                        {area !== 'consultant' && ( //Documentos de NORMAS fuera del acordeón y no sale para el Consultor
                        <Box sx={{ marginLeft: '5px', marginRight: '5px', padding: '10px', backgroundColor: '#f5f5f5' }}>
                          <Typography sx={{ color: '#0085b4', fontWeight: 'bold', fontSize: '1.2em', textAlign: 'center' }}>
                            {title}
                          </Typography>
                          <div dangerouslySetInnerHTML={{ __html: content }} />
                        </Box>
                      )}
                    
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                        <Typography className={classes.subtitle} marginBottom={1}>{t('MENU_ADMIN.PARTICIPANTS')}</Typography>
                        <hr/>
                        {details.cycleParticipants?.map((dataParticipants, i) => (
                            <Card key={i} variant="outlined" style={{ marginBottom: '10px', overflow: 'hidden', maxWidth: '100%' }}>
                                <Grid container spacing={0} padding={0} display={'flex'} alignItems={'center'}>
                                    <Grid item xs={1.5} display="flex" justifyContent="center" alignItems={'center'}>
                                        <Avatar
                                            sx={{ width: '40px', height: '40px', marginTop: '10px'}}
                                            src={participantImages[dataParticipants.idParticipant] || Fallback}
                                        />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Box sx={{ backgroundColor: 'white', color: '#0085B4', borderRadius: '5px', marginTop: '8px', marginBottom: '10px', maxWidth: '100%', overflow: 'hidden' }}>
                                            <Typography noWrap sx={{color:'black', fontSize:'1.1em', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                                <Box component='span' sx={{color:'#0085B4', fontWeight:'bold', fontSize: '1.1em'}}>{dataParticipants.participantNickName} </Box>
                                                - {dataParticipants.participantName} {dataParticipants.participantSurname}
                                            </Typography>
                                            <Typography noWrap sx={{color:'black', fontSize:'1.1em', overflow: 'hidden', textOverflow: 'ellipsis'}}>{dataParticipants.participantPosition}</Typography>
                                            <Typography noWrap sx={{color:'black', fontSize:'1.1em', overflow: 'hidden', textOverflow: 'ellipsis'}}>{dataParticipants.companyName}</Typography>
                                            {(!isParticipant || (isParticipant && dataParticipants?.participantShareEmail == 1)) &&
                                                <LinkMaterial className={classes.colorSecondary} style={{ cursor: 'pointer', marginBottom: '10px' }} href={'mailto:'+dataParticipants.participantEmail} target="_blank">
                                                    <Typography noWrap sx={{color:'#0085B4', fontSize:'1.1em', overflow: 'hidden', textOverflow: 'ellipsis'}}>{dataParticipants.participantEmail}</Typography>
                                                </LinkMaterial>
                                            }
                                            {(!isParticipant || (isParticipant && dataParticipants?.participantSharePhone == 1)) &&
                                                <LinkMaterial className={classes.colorSecondary} style={{ cursor: 'pointer' }} href={'tel:'+dataParticipants.participantPhone} target="_blank">
                                                    <Typography noWrap sx={{color:'#e9a459', fontSize:'1.1em', overflow: 'hidden', textOverflow: 'ellipsis'}}>{dataParticipants.participantPhone}</Typography>
                                                </LinkMaterial>
                                            }
                                        </Box>
                                    </Grid>
                                    {/* <Grid item xs={1.5} sx={{padding: '0px !important', minHeight:'100% !important'}}>
                                          <Box style={{ 
                                              minHeight:'100% !important',
                                              textAlign: 'end', 
                                              display: 'flex',
                                              flexDirection: 'column',
                                              justifyContent: 'center' 
                                          }} 
                                          >
                                              {(!isParticipant || (isParticipant && dataParticipants?.participantShareEmail == 1)) &&
                                                  <LinkMaterial className={classes.colorSecondary} style={{ cursor: 'pointer', marginBottom: '10px' }} href={'mailto:'+dataParticipants.participantEmail}  target="_blank" >
                                                      <MailIcon />
                                                  </LinkMaterial>
                                              }
                                              {(!isParticipant || (isParticipant && dataParticipants?.participantSharePhone == 1)) &&
                                                  <LinkMaterial className={classes.colorSecondary} style={{ cursor: 'pointer' }} href={'tel:'+dataParticipants.participantPhone}  target="_blank" >
                                                      <PhoneIcon/>
                                                  </LinkMaterial>
                                              }
                                          </Box>
                                    </Grid> */}
                                </Grid>
                            </Card>
                        ))}
                    </TabPanel>

                    <TabPanel value={value} index={2}>
                        <Grid>
                            <Typography className={classes.subtitle} marginBottom={1}>{t('PARTICIPANTS.DIRECTORS')}</Typography>
                            <hr/>
                            <br/>
                            {infoConsultants.map((consultant, i) => {
                              // Establece un objeto predeterminado si `find` devuelve `undefined`
                              const { consultantJobTitle,consultantProfile } = consultant.locale.find(locale => locale.idLanguage === lang) || {consultantJobTitle: '',consultantProfile: ''}

                                return(
                                    <Grid key={`consultant-${i}`} container marginTop={1} marginBottom={4}>
                                        <Grid item xs={2}>
                                            <Avatar
                                                style={{ width: '55px', height: '55px'}}
                                                src={`data:image/png;base64,${consultant?.consultantImageProfile}` || Fallback}
                                            />
                                        </Grid>
                                        <Grid item xs={9.5} ml={1}>
                                            <Typography variant={'h6'}>{consultant.consultantName} {consultant.consultantSurname}</Typography>
                                            <Typography variant={'subtitle1'}>{consultantJobTitle}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div style={{ marginTop: '30px', marginBottom: '8px' }} dangerouslySetInnerHTML={{ __html: transformText(consultantProfile) }} >
                                            </div>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </TabPanel>

                    <TabPanel value={value} index={3}>
                        <Grid >
                            <Typography className={classes.subtitle} marginBottom={1}>{t('PARTICIPANTS.CONTENTS2')}</Typography>
                            <hr/>
                            <br/>
                            {cycleContent.map((content,i) => {
                                // Intenta encontrar contenido que coincida con el idioma seleccionado
                                const foundContent = cycleContent.find(locale => locale.idLanguage === lang)
                                if (foundContent) {
                                    const { cycleTypeTargets } = foundContent;
                                    if (cycleTypeTargets !== '' && cycleTypeTargets !== null) { // Verifica que los targets no estén vacíos o nulos
                                        if (i === 0) {
                                            return (
                                                <div key={`content-${i}`} style={{ marginY: '15px', padding: '0px 10px' }} dangerouslySetInnerHTML={{ __html: transformText(cycleTypeTargets) }}></div>
                                            )
                                        }
                                    } else { // Si los targets están vacíos o nulos.
                                        return (
                                            <Typography key={`nodata-${i}`}>{t('PARTICIPANTS.NODATA')}</Typography>
                                        )
                                    }
                                }
                                // Devuelve null si no se encuentra contenido que coincida con el idioma seleccionado
                                return null
                            })}

                        </Grid>
                    </TabPanel>
                    
                    <TabPanel value={value} index={4}>
                        <Grid>
                            <Typography className={classes.subtitle} marginBottom={1}>{t('PARTICIPANTS.OPINIONS')}</Typography>
                            <hr/>
                            <br/>
                            {cycleContent.map((content, i) => {
                                // Intenta encontrar el contenido que coincide con el idioma seleccionado
                                const foundContent = cycleContent.find(locale => locale.idLanguage === lang);
                                if (foundContent) {
                                    // Desestructura cycleTypeQtr sólo si se encuentra contenido
                                    const { cycleTypeQtr } = foundContent;
                                    if (cycleTypeQtr !== '' && cycleTypeQtr !== null) {
                                        if (i === 0) {
                                            return (
                                            <div key={`contentOpinion-${i}`} style={{ marginY:'15px', padding:'0px 10px'}} dangerouslySetInnerHTML={{ __html: transformText(cycleTypeQtr) }}></div>
                                            )
                                        }
                                    } else {
                                        return (
                                            <Typography key={`nodata-opinion-${i}`}>{t('PARTICIPANTS.NODATA')}</Typography>
                                        )
                                    }
                                }
                                // Devuelve null si no se encuentra el contenido adecuado
                                return null
                            })}

                        </Grid>
                    </TabPanel>
                </Box>
                :
                <Loading height='50vh' />
            }

        </Grid>
    );
};

export default CycleDetails;