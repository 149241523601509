import React from 'react'
import { Grid } from '@mui/material'
import MenuVertical from './MenuVertical'

const Menu = ({
  handleShowMenu,
  showMenu,
}) => {
  return (
    <Grid container>      
        <Grid item xs={12}>
          <MenuVertical handleShowMenu={handleShowMenu} showMenu={showMenu} />
        </Grid>
    </Grid>
  )
}

export default Menu
 