import React, { Fragment, useContext } from 'react'
import Logo from '@assets/images/logo.png'
import { useStyles } from '@styles/owToolboxKitOw.style'
import MenuIcon from '@mui/icons-material/Menu'

import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar
} from '@mui/material'

const Header = ({ handleShowMenu }) => {
  const classes = useStyles()

  return (
    <Fragment>  
        <AppBar position='static'>
          <Toolbar className={classes.header}>
            <Box>
              <Hidden lgUp>
                <IconButton onClick={() => handleShowMenu(prevState => !prevState)}>
                  <MenuIcon className={classes.icon}/>
                </IconButton>
              </Hidden>
            </Box>
            <Box>
              <img src={Logo} alt='logoxs' width={200} />
            </Box>    
            <Box>
              <IconButton size='large'></IconButton>
            </Box>
          </Toolbar>
        </AppBar>
    </Fragment>
  )
}

export default Header
