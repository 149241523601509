import React from 'react';
import AddButton from '@components/AddButton';
import DynamicTable from '@components/DynamicTable';
import HeaderTitle from '@components/HeaderTitle';
import { IconButton, Typography, Box, Tooltip } from '@mui/material';
import serviceCompanies from '@services/serviceCompanies';
import { useCommonStyles } from '@styles/common.style';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Article, Assessment, EditRounded } from '@mui/icons-material';
import { snackActions } from '@helpers/snackbarUtils';

const Companies = () => {
    const classes = useCommonStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const ORDER = [
        {
            "orderField": "idCompany",
            "orderAsc": true
        }
    ];
    const COLUMNS = [
        {
            accessor: '',
            Header: 'Info',
            filterable: false,
            hideHeader: true,
            width: '5%',
            Cell: ({ row: { values } }) => (
                <Box display='flex' flexDirection='row'>
                    <Tooltip title={t('LABEL.EDIT')}>
                        <IconButton
                            aria-label='info'
                            color='icon_blue'
                            style={{
                                padding: '0px',
                                marginRight: 15,
                            }}
                            onClick={() => {
                                handleShowCompanyDetail({
                                    idCompany: values.idCompany,
                                    type: 'UPDATE',
                                });
                            }}>
                            <EditRounded fontSize='inherit' />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },
        {
            accessor: 'idCompany',
            Header: t('LABEL.ID'),
            filterable: false,
            width: '5%',
        },
        {
            accessor: 'companyName',
            Header: t('LABEL.COMPANY'),
            Cell: props => <Typography variant='body2'>{props.value}</Typography>,
        },
        {
            accessor: 'companyCity',
            Header: t('LABEL.CITY'),
            Cell: props => <Typography variant='body2'>{props.value}</Typography>,
        },
        { accessor: 'companyContactPhone', Header: t('LABEL.PHONE') },
        {
            accessor: 'companyEmail',
            Header: t('LABEL.EMAIL'),
            Cell: props => <Typography variant='body2'>{props.value}</Typography>,
        },
    ];

    const handleShowCompanyDetail = ({ idCompany, type }) => {
        const userSession = JSON.parse(sessionStorage.getItem('userSession'))
        if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 2)) {
            serviceCompanies.getCompaniesDetails(idCompany).then(response => {
                if (!response.error) {
                    navigate(`/companyDetail`, {
                        state: { data: response.data, type, idCompany },
                    });
                }
            });
        } else {
            snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'));
        }   
    };

    const handleNewCompany = () => {
        const userSession = JSON.parse(sessionStorage.getItem('userSession'))
        if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 2)) {
            navigate(`/companyDetail`, { state: { data: {}, type: 'NEW' } });
        } else {
            snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'));
        }  
    };
    return (
        <div className={classes.container}>
            <HeaderTitle
                title={t('MENU_ADMIN.COMPANIES')}
                subtitle={t('TEXT.SUBTITLE_COMPANIES')}>
                <AddButton
                    text={t('BUTTON.NEW_COMPANY')}
                    handleOnclick={handleNewCompany}
                />
            </HeaderTitle>
            <DynamicTable
                columns={COLUMNS}
                endpoint='Companies/List'
                isGlobalSearch={true}
                isColumnsSearch={true}
                isOrdered={true}
                viewExcel={true}
                nameExcel={t('MENU_ADMIN.COMPANIES')}
                titleBodyExcel={t('COMPANY.BODY_EXCEL')}
                orderColumn={ORDER}
            />
        </div>
    );
};

export default Companies;