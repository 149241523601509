import React, { useEffect, useState } from 'react'
import { useStyles } from '@styles/menu.style'
import { Grid } from '@mui/material'
import menuCampusItems from '@constants/menuCampusItems'
import menuConsultantsItems from '@constants/menuConsultantsItems'
import menuParticipantsItems from '@constants/menuParticipantsItems'
import { useNavigate } from 'react-router-dom'
import MenuButton from '@components/MenuButton'
import { useTranslation } from 'react-i18next'

const MenuHorizontal = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [menuItems, setMenuItems] = useState([])
  const navigate = useNavigate()

  const handleClickItem = item => {
    navigate(item.url)
  }

  useEffect(() => {
    let area = sessionStorage.getItem('area')
    if (area.includes('consultant')) {
      setMenuItems(menuConsultantsItems(t))
    } else if (area.includes('participant')) {
      setMenuItems(menuParticipantsItems(t))
    } else if (area.includes('campus')) {
      setMenuItems(menuCampusItems(t))
    }
  }, [])

  return (
    <Grid className={classes.containerHorizontal}>
      {menuItems.map((menu, index) => (
        <MenuButton
          key={index}
          menuTitle={menu.menuTitle}
          items={menu.items}
          handleClick={handleClickItem}
        />
      ))}
    </Grid>
  )
}

export default MenuHorizontal
