import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => {
    return {
        containerImage: props => {
            return {
                height: props.height || '100%',
                width: props.width || '100%',
                borderRadius: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                '&:hover': {
                    '& $layoutButton': {
                        opacity: 0.6,
                    },
                },
            };
        },

        containerNoImage: props => {
            return {
                height: props.height || '100%',
                width: props.width || '100%',
                borderRadius: 5,
                display: 'flex',
                background: 'white',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px dashed grey',
                position: 'absolute',
            };
        },

        layoutButton: props => {
            return {
                height: props.height || '100%',
                width: props.width || '100%',
                borderRadius: 5,
                display: 'flex',
                background: 'white',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px dashed grey',
                position: 'absolute',
                opacity: 0,
                transition: '.5s ease',
            };
        },

        image: props => {
            return {
                height: props.height || '100%',
                width: props.width || '100%',
                borderRadius: 5,
                objectFit: 'cover',
            };
        },
    };
});