import { BottomNavigation, BottomNavigationAction, Box } from "@mui/material";
import { AuthContext } from '@context/auth/AuthContext';
import React, { useState, useContext } from "react";
import menuParticipantsItems from '@constants/menuParticipantsItems';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { useOWStyles } from '@styles/ottowalter.style';

const Footer = (props) => {

    const owclass = useOWStyles();
    const { t } = useTranslation();
    const [value, setValue] = useState(0);
    const menuItems = menuParticipantsItems(t);
    const { logout } = useContext(AuthContext);

    return (
        <Box sx={{ width: '100%'}}>
            <hr/>
            <BottomNavigation 
                style={{height: '60px', padding:'10px 20px'}}
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    if (menuItems[newValue].url === '/logout') logout();
                }}
            >
                {menuItems.map((menu, index) => (
                    <BottomNavigationAction 
                        key={"menu-"+index} 
                        label={menu.title} 
                        icon={menu.icon} 
                        component={Link} 
                        to={menu.url === '/cycledetails' 
                            ? '/cycledetails/'+props.idCycle 
                            : menu.url }
                            style={{ maxWidth: '100px', whiteSpace: 'nowrap' }} 
                            classes={{ root: owclass.footerButtonFontSize }}
                    />
                ))}
            </BottomNavigation>
        </Box>
    )
};

export default Footer;