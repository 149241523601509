import React from 'react';
import Loading from '@components/Loading';
import { useFlags } from '@hooks/useFlags';
import { useStyles } from '@styles/loginConsultants.style';
import SignInConsultant from './SignInConsultant';

const LoginConsultantsPage = () => {
    const classes = useStyles();
    const { flagOptions } = useFlags();

    if (!flagOptions) return <Loading />;

    return (
        <div className={classes.loginConsultants}>
            <SignInConsultant flagOptions={flagOptions} />
        </div>
    );
};

export default LoginConsultantsPage;