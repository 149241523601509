import React, { useState } from 'react'
import { Box, Typography, Select } from '@mui/material'
import Card from '@components/Card'
import { useCommonStyles } from '@styles/common.style'
import { useTranslation } from 'react-i18next'
import DialogEmail from '@components/DialogEmail'

const SectionTemplateRemind = ({
  formData,
  handleChange,
  showEmailItems,
  allEmails
}) => {
  const classes = useCommonStyles()
  const { t } = useTranslation()
  const [showEmailModal, setShowEmailModal] = useState({
    open: false,
    loading: true,
    content: {}
  })
  return (
    <Card fullHeight>
      {showEmailModal.open && (
        <DialogEmail
          showEmailModal={showEmailModal}
          setShowEmailModal={setShowEmailModal}
        />
      )}
      <Typography
        color='primary'
        variant='subtitle1'
        mb={1}
        textAlign='center'
        className={classes.cardTitle}
      >
        {t('CYCLE_TYPES.TITLE_REMIND_CONFIG').toUpperCase()}
      </Typography>
      {(formData.cycleTypeMode == undefined ||
        formData.cycleTypeMode == 0 ||
        formData.cycleTypeMode == 1) && (
        <Box my={1}>
          <Typography variant='caption'>
            {t('CYCLE_TYPES.FACETOFACE')}
          </Typography>
          <Select
            className={classes.select}
            fullWidth
            size='small'
            name='cycleTypeRemindPIdMailing'
            value={formData?.cycleTypeRemindPIdMailing}
            onChange={handleChange}
            renderValue={selected => {
              const name = allEmails.find(e => e.idMailing === selected)
              return name.mailingName
            }}
          >
            {showEmailItems({ key: 3, setShowEmailModal })} {/* RECP */}
          </Select>
        </Box>
      )}
      {(formData.cycleTypeMode == undefined ||
        formData.cycleTypeMode == 0 ||
        formData.cycleTypeMode == 2) && (
        <Box>
          <Typography variant='caption' width={70}>
            {t('CYCLE_TYPES.DIGITAL')}
          </Typography>
          <Select
            className={classes.select}
            fullWidth
            size='small'
            name='cycleTypeRemindDIdMailing'
            value={formData?.cycleTypeRemindDIdMailing}
            onChange={handleChange}
            renderValue={selected => {
              const name = allEmails.find(e => e.idMailing === selected)
              return name.mailingName
            }}
          >
            {showEmailItems({ key: 4, setShowEmailModal })} {/* RECD */}
          </Select>
        </Box>
      )}
      {(formData.cycleTypeMode == undefined ||
        formData.cycleTypeMode == 0 ||
        formData.cycleTypeMode == 3) && (
        <Box>
          <Typography variant='caption' width={70}>
            {t('CYCLE_TYPES.MIXED')}
          </Typography>
          <Select
            className={classes.select}
            fullWidth
            size='small'
            name='cycleTypeRemindMIdMailing'
            value={formData?.cycleTypeRemindMIdMailing}
            onChange={handleChange}
            renderValue={selected => {
              const name = allEmails.find(e => e.idMailing === selected)
              return name.mailingName
            }}
          >
            {showEmailItems({ key: 11, setShowEmailModal })} {/* RECM */}
          </Select>
        </Box>
      )}
    </Card>
  )
}

export default SectionTemplateRemind
