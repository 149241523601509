import { snackActions } from '@helpers/snackbarUtils'

const apiRequest = (method, endpoint, body = null) =>
  new Promise((resolve, reject) => {
    const API_URL = process.env.REACT_APP_URL_API

    const bodyString = body === null ? null : JSON.stringify(body);
    console.log("Tamaño del body > "+bodyString?.length);

    fetch(API_URL + endpoint, {
      method: method,
      headers: {
        'Accept-Language': sessionStorage.getItem('lang'),
        Authorization: JSON.parse(sessionStorage.getItem('loginToken'))
          ? 'Bearer ' + JSON.parse(sessionStorage.getItem('loginToken'))
          : '',
        'Content-Type': 'application/json'
      },
      body: body === null ? null : JSON.stringify(body)
    })
      .then(response => {
        return processFetch(response)
      })
      .then(responseJson => {
        console.log(responseJson)
        resolve(processCheck(responseJson))
      })
      .catch(error => {
        reject(error)
      })
  })

const processFetch = response => {
  var result = { error: true }
  switch (response.status) {
    case 200:
      result = response.json()
      break
    case 401:
      sessionStorage.removeItem('userSession')
      sessionStorage.removeItem('loginToken')
      // eslint-disable-next-line no-restricted-globals
      location.href = '/'
      break
    default:
      break
  }
  return result
}

const processCheck = responseJson => {
  if (responseJson.error) {
    if (responseJson.publicMessage) {
      snackActions.error(responseJson.message)
    } else {
        console.log(responseJson.message);
        snackActions.error('Error en la conexión con el servidor')
    }
  } else {
    if (responseJson.publicMessage) {
      snackActions.success(responseJson.message)
    }
  }
  return responseJson
}

export default apiRequest
