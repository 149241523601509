import React from 'react'
import AddButton from '@components/AddButton'
import DynamicTable from '@components/DynamicTable'
import HeaderTitle from '@components/HeaderTitle'
import { EditRounded } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import servicePreparation from '@services/servicePreparation'
import { useCommonStyles } from '@styles/common.style'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { snackActions } from '@helpers/snackbarUtils';

const Preparation = () => {
  const classes = useCommonStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const ORDER = [
    {
      orderField: 'PreparationName',
      orderAsc: true
    }
  ]
  const COLUMNS = [
    {
      accessor: '',
      Header: 'Info',
      filterable: false,
      hideHeader: true,
      width: '5%',
      Cell: ({ row: { values } }) => (
        <IconButton
          aria-label='info'
          color='info'
          style={{
            padding: '0px'
          }}
          onClick={() => {
            handleShowPreparationDetail(values.idPreparation)
          }}
        >
          <EditRounded fontSize='inherit' />
        </IconButton>
      )
    },
    {
      accessor: 'idPreparation',
      Header: t('LABEL.ID'),
      filterable: false,
      width: '5%'
    },
    {
      accessor: 'preparationName',
      Header: t('LABEL.NAME')
    }
  ]

  const handleShowPreparationDetail = idPreparation => {
    const userSession = JSON.parse(sessionStorage.getItem('userSession'))
    if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 4)) {
      servicePreparation.getPreparationDetails(idPreparation).then(response => {
        if (!response.error) {
          navigate('/preparationDetail', {
            state: { data: response.data, type: 'UPDATE' }
          })
        }
      })
    } else {
      snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'));
    }     
  }

  const handleNewPreparation = () => {
    const userSession = JSON.parse(sessionStorage.getItem('userSession'))
    if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 4)) {
      navigate('/preparationDetail', { state: { data: {}, type: 'NEW' } })
    } else {
      snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'));
    }     
  }
  return (
    <div className={classes.container}>
      <HeaderTitle
        title={t('CONFIG_JOURNEYS.PREPARATION.PREPARATION')}
        subtitle={t('CONFIG_JOURNEYS.PREPARATION.CONFIG')}
      >
        <AddButton
          text={t('BUTTON.NEW_PREPARATION')}
          handleOnclick={handleNewPreparation}
        />
      </HeaderTitle>
      <DynamicTable
        columns={COLUMNS}
        endpoint='Preparation/List'
        isGlobalSearch={true}
        isColumnsSearch={true}
        isOrdered={true}
        orderColumn={ORDER}
      />
    </div>
  )
}

export default Preparation
