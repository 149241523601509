import React, { useState } from 'react';

const WorksheetsListContext = React.createContext();

export const WorksheetsListProvider = ({ children }) => {
  const [refresh, setRefresh] = useState(false);

  const refreshWorksheetsList = () => {
    setRefresh(!refresh);
  };

  return (
    <WorksheetsListContext.Provider value={{ refreshWorksheetsList }}>
      {children}
    </WorksheetsListContext.Provider>
  );
};

export default WorksheetsListContext;