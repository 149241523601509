import React, { useState, useEffect } from "react";
import WorksheetFavoriteCard from "./WorksheetFavoriteCard";
import serviceMobileSheets from "../../../../services/serviceMobileSheets";
import Loading from '@components/Loading';
import { useTranslation } from 'react-i18next'

const WorksheetsFavorite = () => {
    const { i18n, t } = useTranslation()
    const [formData, setFormData] = useState([]);
    const [loading, setLoading] = useState(true);
    const[langId, setLangId] = useState(1)

    const idParticipant = JSON.parse(sessionStorage.getItem('userSession')).idParticipant;
    //const idParticipant = 1028

    const fetchData = async (idParticipant) => {
        const { data, error } = await serviceMobileSheets.getMobileSheetsListByParticipant({
            IdParticipant: idParticipant,
            IdLanguage: langId,
            kitOW: true
        });
        if (!error) {
          const filteredFormData = data?.filter((dataCard, index, array) =>
          array.findIndex((card) => card.idMobileSheet === dataCard.idMobileSheet && card.idLanguage === dataCard.idLanguage) === index
          );
            const dataFavorite = filteredFormData.filter(dataCard => dataCard.idMobileSheetFavorite !== null).filter(dataCard => dataCard.idMobileSheetsKitOW !== null).filter(dataCard => dataCard.idLanguage === langId).sort((a, b) => a.mobileSheetName.localeCompare(b.mobileSheetName))
            setFormData(dataFavorite);
            setLoading(false);
        }
    };
    
    useEffect(() => {
      const tempLang = sessionStorage.getItem("lang")
      if (tempLang === 'es') {
        setLangId(1)
      } else if (tempLang === 'en') {
        setLangId(2)
      } else if (tempLang === 'it') {
        setLangId(3)
      }
        
        
    }, [i18n, t]);
  
    useEffect(() => {
      if (idParticipant !== undefined) {
        fetchData(idParticipant);
    }
    }, [langId]);

    const handleFavorite = async (idMobileSheetFavorite) => {
        const { error } = await serviceMobileSheets.deleteMobileSheetFavorite(idMobileSheetFavorite);
            if (!error) {
                fetchData(idParticipant);
                setLoading(false);
            }

    }
    return (
        <>
          {!loading ? (
            <div style={{padding: '8px'}}>
              {formData?.map((dataCard, i) => {
                  return (
                      <WorksheetFavoriteCard key={`card${i}`} {...dataCard} handleFavorite={handleFavorite}/>
                  )
              })}
            </div>
          ) : (
            <Loading height='50vh' />
          )}
        </>
      );
    };
export default WorksheetsFavorite;