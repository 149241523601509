import React, { useState } from 'react';

import { Menu, MenuItem, ListItemIcon, Button } from '@mui/material';

const MenuButton = ({ menuTitle, items, handleClick }) => {
    const [state, setState] = useState({
        anchorEl: null,
    });

    const handleMenu = event => {
        setState({ ...state, anchorEl: event.currentTarget });
    };

    const handleClose = () => {
        setState({ ...state, anchorEl: null });
    };

    const { anchorEl } = state;
    const open = Boolean(anchorEl);

    const listItems = items.map((item, index) => {
        return (
            <MenuItem
                key={index}
                onClick={() => {
                    handleClose();
                    handleClick(item);
                }}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                {item.title}
            </MenuItem>
        );
    });

    return (
        <div>
            <Button onClick={handleMenu}>{menuTitle}</Button>

            <Menu
                id='menu-appbar-horizontal'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                {listItems}
            </Menu>
        </div>
    );
};

export default MenuButton;