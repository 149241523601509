import React, { useState, useEffect } from "react";
import {
  TableBody,
  TableRow,
  Table,
  TableContainer,
  TableCell,
  Typography,
  Tooltip,
} from "@mui/material";
import { AssignmentInd, Warning, Grading, ChatBubbleRounded, School, Build, NextWeek, Flag, Summarize, Feed, MailRounded } from '@mui/icons-material';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import { useTranslation } from "react-i18next";
import ParticipantsData from "./tracing/ParticipantsData";
import { useCommonStyles } from "@styles/common.style";
import { useStyles } from "@styles/table.style";

const TracingTable = ({formData, setFormData, evaluatorTypeList}) => {

  const { t } = useTranslation();
  const classesCommon = useCommonStyles();
  const classes = useStyles();
  const [closeJourney, setCloseJourney] = useState([]);
  const [listReload, setListReload] = useState(0);

  useEffect(() => {
    const tempArray = (formData.cycleJourneys?.map(() => 4) ?? []).concat([5, 6]);
    setCloseJourney(tempArray);
    
  }, [formData]);

const handleJourneyClick = (i) =>{
  let tempArray = closeJourney;
  if(closeJourney[i]=== 2){
    if(i===(closeJourney.length - 2)){
      tempArray[i]= 5;
    } else if (i===(closeJourney.length - 1)){
      tempArray[i]=6;
    } else{
      tempArray[i]=4;
    }
  } else{
    tempArray[i]=2;
  }
    setCloseJourney(tempArray)
    setListReload(Math.random())
}

  return (
    <TableContainer sx={{ paddingTop: "20px"}} >
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell className={classes.stickyTableCell}
              sx={{
                backgroundColor: '#ffff !important'
                }}
              align="center">
            </TableCell>           
            {formData?.cycleJourneys?.map((journey, index) => {
              return (
                <React.Fragment key={`journeyName${index}`}>
                {(formData.cycleJourneys.length - 1) > index && (
                <TableCell
                  key= {listReload}
                  align="center"
                  colSpan={closeJourney[index]}
                  className={classesCommon.bgPrimary}
                  onClick={() => handleJourneyClick(index)}
                  sx={{borderRight: "1px solid #ffff",}}
                >
                  <Typography
                    color="secondary.light"
                    variant="button"
                    fontWeight="bold"
                  >
                    {t("CYCLE_TYPES.JOURNEY")} {index + 1}
                  </Typography>
                  {/* Mostrar la fecha debajo de la jornada */}
                  <Typography
                    color="secondary.light"
                    variant="button"
                    sx={{ display: "block", fontWeight:'bold' }}
                  >
                    {new Date(journey.cycleJourneyDateFrom).toLocaleDateString()}
                  </Typography>
                </TableCell>
                )}
                {(formData.cycleJourneys.length - 1) === index && (
                <TableCell
                  align="center"
                  colSpan={2}
                  className={classesCommon.bgPrimary}
                  sx={{borderRight: "1px solid #ffff",}}
                >
                  <Typography
                    color="secondary.light"
                    variant="button"
                    fontWeight="bold"
                  >
                    {t("CYCLE_TYPES.JOURNEY")} {index + 1}
                  </Typography>
                  {/* Mostrar la fecha debajo de la jornada */}
                  <Typography
                    color="secondary.light"
                    variant="button"
                    sx={{ display: "block", fontWeight:'bold' }}
                  >
                    {new Date(journey.cycleJourneyDateFrom).toLocaleDateString()}
                  </Typography>
                </TableCell>
                )}
                </React.Fragment>
              )
            })}
            
            <TableCell
              className={classesCommon.bgPrimary}
              align="center"
              colSpan={closeJourney[closeJourney.length-2]}
              onClick={() => handleJourneyClick(closeJourney.length-2)}
              sx={{borderRight: "1px solid #ffff",}}
            >
              <Typography
                color="secondary.light"
                variant="button"
                fontWeight="bold"
              >
                {t("LABEL.FINAL_FEEDBACK_JOURNEY")}
              </Typography>
            </TableCell>
            <TableCell
              className={classesCommon.bgPrimary}
              align="center"
              colSpan={closeJourney[closeJourney.length-1]}
              onClick={() => handleJourneyClick(closeJourney.length-1)}
              sx={{borderRight: "1px solid #ffff",}}
            >
              <Typography
                color="secondary.light"
                variant="button"
                fontWeight="bold"
              >
                {t("LABEL.MEASURER")}
              </Typography>
            </TableCell>
            <TableCell
              className={classesCommon.bgPrimary}         
              align="center"
              colSpan={1}
              sx={{borderRight: "1px solid #ffff",}}
            >
              <Typography
                color="secondary.light"
                variant="button"
                fontWeight="bold"
              >
                {t("LABEL.VALUATION")}
              </Typography>
            </TableCell>
            <TableCell
              className={classesCommon.bgPrimary}       
              align="center"
              colSpan={1}
            >
              <Typography
                color="secondary.light"
                variant="button"
                fontWeight="bold"
              >
                {t("LABEL.FEEDBACK")}
              </Typography>
            </TableCell>
            <TableCell
                className={classesCommon.bgPrimary}
                align="center"
                colSpan={1}
            >
                <Typography
                    color="secondary.light"
                    variant="button"
                    fontWeight="bold"
                >
                    {t("LABEL.CERTIFICATE")}
                </Typography>
            </TableCell>
          </TableRow>
          <TableRow className={classes.tableBodySearch}>
            <TableCell className={classes.stickyTableCell}
              sx={{
                backgroundColor: '#ededed !important',
                fontWeight: 'bold',
              }}
              align="center">
              {t("LABEL.NAME")}
            </TableCell>

            {formData?.cycleJourneys?.map((journey, index) => {
              return (
                <React.Fragment key={`journey${index}`}>
                  <TableCell  align="center">
                    <Tooltip title={t("TEXT.ATTENDANCE")}>
                      <AssignmentInd/>
                    </Tooltip>
                  </TableCell>
                  {closeJourney[index] === 2 && (
                  <TableCell sx={{borderRight :'1px solid #0085B4'}} align="center">
                    <Tooltip title={t("MENU_ADMIN.INCIDENTS")}>
                      <Warning/>
                    </Tooltip>                    
                  </TableCell>
              )}
              {closeJourney[index] !== 2 && (
                  <TableCell  align="center">
                    <Tooltip title={t("MENU_ADMIN.INCIDENTS")}>
                      <Warning/>
                    </Tooltip>                    
                  </TableCell>
              )}
                  {(formData.cycleJourneys.length - 1) > index &&  closeJourney[index] !== 2 && (<>
                  <TableCell  align="center">
                    <Tooltip title={t("LABEL.VALUATION")}>
                      <Grading/>
                    </Tooltip>                    
                  </TableCell>                 
                  <TableCell sx={{borderRight :'1px solid #0085B4'}} align="center">
                    <Tooltip title={t("LABEL.FEEDBACK_TRACING")}>
                      <ChatBubbleRounded/>
                    </Tooltip>
                  </TableCell>
                  </>)}
                </React.Fragment>
              );
            })}

            <TableCell sx={{borderLeft :'1px solid #0085B4'}} align="center">
              <Tooltip title= {t("LABEL.UTILITY_PRACTICE")}>
                <Build/>
              </Tooltip> 
            </TableCell>
            {closeJourney[closeJourney.length-2] === 2 &&(
              <TableCell sx={{borderRight :'1px solid #0085B4'}} align="center">
                <Tooltip title= {t("LABEL.GRADE_GLOBAL")}>
                  <School/>
                </Tooltip> 
            </TableCell>
            )}
            {closeJourney[closeJourney.length-2] !== 2 &&(
              <>
            <TableCell align="center">
              <Tooltip title= {t("LABEL.GRADE_GLOBAL")}>
                <School/>
              </Tooltip> 
            </TableCell>
            <TableCell  align="center">            
              <Tooltip title={t("LABEL.FINAL_FEEDBACK")}>
                <Feed/>
              </Tooltip> 
            </TableCell>
            <TableCell  align="center">
              <Tooltip title={t("LABEL.TAKE_CYCLE")}>
                <NextWeek/>
              </Tooltip> 
            </TableCell>
            <TableCell sx={{borderRight :'1px solid #0085B4', fontWeight: 'bold'}} align="center">
              {t("LABEL.QTR")}
            </TableCell>
            </>
            )}
            <TableCell  align="center">
              <Tooltip title= {t("LABEL.GRADE_GLOBAL")}>
                <School/>
              </Tooltip> 
            </TableCell>
            {closeJourney[closeJourney.length-1] === 2 &&(
            <TableCell sx={{borderRight :'1px solid #0085B4', fontWeight: 'bold'}} align="center">
              {t("LABEL.GRADE_QTR")}
            </TableCell>
            )}
            {closeJourney[closeJourney.length-1] !== 2 &&(
              <>
              <TableCell sx={{fontWeight: 'bold'}} align="center">
              {t("LABEL.GRADE_QTR")}
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="center">
                {t("LABEL.GRADE_AUTOEVAL")}
            </TableCell>
            <TableCell align="center">              
              <Tooltip title={t("LABEL.REPORT")}>
                <Summarize/>
              </Tooltip>
            </TableCell>
            <TableCell align="center">
                <Tooltip title={t("BUTTON.SEND_REPORT")}>
                    <MailRounded />
                </Tooltip>
            </TableCell>
            <TableCell sx={{borderRight :'1px solid #0085B4', fontWeight: 'bold'}}  align="center">              
            {t("EVALUATOR.EVALUATOR_TYPE")}
            </TableCell>
            </>
          )}
            <TableCell sx={{borderRight :'1px solid #0085B4', fontWeight: 'bold'}} align="center">
              {t("LABEL.ABC")}
            </TableCell>
            <TableCell align="center">              
              <Tooltip title={t("LABEL.INDIVIDUAL_REPORT")}>
                <Flag/>
              </Tooltip>
            </TableCell>
            <TableCell align="center">
              <Tooltip title={t("LABEL.CERTIFICATE")}>
                <CardMembershipIcon />
              </Tooltip>
            </TableCell>
          </TableRow>

          <ParticipantsData
            formData={formData}
            setFormData={setFormData}
            evaluatorTypeList={evaluatorTypeList}
            // journey={formData.cycleJourneys}
            // participant={formData.cycleParticipants} 
            closeJourney={closeJourney}
          />
        
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TracingTable;
