import { createTheme } from '@mui/material';

const primaryColor = {
    light: '#53A2D0',
    main: '#0085B4',
    dark: '#1c6189',
};

const secondaryColor = {
    light: '#ffffff',
    main: '#e9a459',
    dark: '#0085B4',
};

const errorColor = {
    light: '#FF6347',
    main: '#da4f49',
    dark: '#D9391C',
};

const customTheme = createTheme({
    palette: {
        primary: {
            light: primaryColor.light,
            main: primaryColor.main,
            dark: primaryColor.dark,
        },
        secondary: {
            light: secondaryColor.light,
            main: secondaryColor.main,
            dark: secondaryColor.dark,
        },
        white: {
            main: '#ffffff',
        },
        grey: {
            main: '#f1f1f1',
            dark: '#cccccc',
        },
        light: {
            main: '#f1f1f1',
        },
        error: {
            main: errorColor.main,
        },
        icon_light_green: {
            main: '#2B8A9A',
        },
        icon_dark_green: {
            main: '#165c6c',
        },
        icon_grey: {
            main: '#B1A9A9',
        },
        icon_blue: {
            main: '#0085B4',
        },
    },
});

export default customTheme;