import React from 'react';
import HeaderTitle from '@components/HeaderTitle';
import { useCommonStyles } from '@styles/common.style';
import { useTranslation } from 'react-i18next';

const Mailing = () => {
    const classes = useCommonStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.container}>
            <HeaderTitle title={t('MENU_ADMIN.MAILING')} subtitle={t('MENU_ADMIN.LIST_MAILING')} />
        </div>
    );
};

export default Mailing;