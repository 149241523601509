import React, { useState, useEffect } from 'react';
import { Grid, Card, Button, Box, Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useParams } from 'react-router-dom';
import serviceCycles from '@services/serviceCycles';
import Loading from '@components/Loading';
import CheckBox from '@components/CheckBox';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { useForm } from '@hooks/useForm';
import serviceIncidents from '@services/serviceIncidents';
import moment from 'moment';
import getCurrentJourney from '@helpers/currentJourney';

const CycleIncidents = () => {

    const navigate = useNavigate();
    const { idCycle } = useParams();
    const [dataParticipants, setDataParticipants] = useState([]);
    const [dataParticipantsAdd, setDataParticipantsAdd] = useState([]);
    const [loading, setLoading] = useState(true);

    const { t } = useTranslation();

    const { formData, descriptionIncident,handleChange } = useForm({
        descriptionIncident: '',
        idCycle: idCycle,
        idCycleJourney: '',
        idParticipant: '',
        idUser: '',
        dateIncident: ''
    });

    const handleSubmit = e => {
        e.preventDefault();
        formData.idCycleJourney = dataParticipants.idCycleJourney;
        dataParticipantsAdd.forEach(element => { 
            formData.idParticipant = element;
            serviceIncidents.newIncident(formData).then(response => {
                if (!response.error) {
                    navigate(-1);
                }
            })
                
        });

    };

    const fetchDataParticipants = async (idCycle) => {
        const { data, error } = await serviceCycles.getCycleDetail(idCycle, true, false);
        if (!error) {
            setDataParticipants(getCurrentJourney(data));
            setLoading(false);
        }
    };

    useEffect(() => {
        if (idCycle !== undefined) {
            fetchDataParticipants(idCycle);
        }
    }, [idCycle]);

    //Botón CREAR que se habilite cuando se ha seleccionado mínmimo un participante y se haya escrito la DescriptionIncident
    const handleCheckbox = (idParticipant) => {
        if (dataParticipantsAdd.includes(idParticipant)) {
            setDataParticipantsAdd(dataParticipantsAdd.filter(element => element !== idParticipant));
        } else {
            setDataParticipantsAdd([...dataParticipantsAdd, idParticipant]);
        }

    };

    return (
        <><div>
            <Grid container spacing={1} padding={1}>
                <Grid item xs={1} display="flex"  justifyContent="flex-start">
                    <Link to={"/cycledetails/" + idCycle}><ArrowBack /></Link>
                </Grid>
                <Grid item xs={10} display="flex"justifyContent="center">
                    <h3>{t('TEXT.NEW_INCIDENT_CYCLE')}</h3>
                </Grid>
            </Grid>
            <hr /><br />
        </div>
        {!loading ?
        <div>
        <Box paddingBottom={1}>{t('TEXT.LIST_OR_PARTICIPANTS')}</Box>
                <form onSubmit={handleSubmit} >
                        {dataParticipants.cycleJourneyParticipants?.map(participant => {
                                return (

                                    <Card variant="outlined" style={{ marginBottom: '10px' }}>
                                        <Grid container spacing={1} padding={1}>
                                            <Grid item xs={2} display="flex" justifyContent="center">
                                                <Avatar
                                                    sx={{ width: '55px', height: '55px' }}
                                                    variant="rounded" />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Box style={{ backgroundColor: 'white', color: '#0085B4', borderRadius: '5px', marginTop: '8px' }} padding={0}>
                                                    <strong>{participant.participantName} {participant.participantSurname}</strong><br />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={2}  display="flex" justifyContent="flex-end">
                                                <CheckBox
                                                style={{backgroundColor:'white'}}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                disableRipple
                                                    onClick={e => handleCheckbox(participant.idParticipant)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                );
                            })
                    }
                    <Box paddingTop={2}>{t('TEXT.INCIDENT_DETAILS')}</Box>
                            <>
                                <TextareaAutosize
                                    minRows={8}
                                    style={{ width:'100%', border:'1px solid lightgray', borderRadius:'5px', margin:'5px 0px', fontSize:'14px', padding:'10px' }}
                                    id='descriptionIncident'
                                    name='descriptionIncident'
                                    value={descriptionIncident}
                                    onChange={handleChange}
                                />
                            </>

                    <Button 
                        variant='contained' 
                        fullWidth 
                        type='submit' 
                        size='large'
                        disabled={dataParticipantsAdd.length === 0 || descriptionIncident.trim() === ''}
                      >
                        {t('LABEL.CREATE')}
                    </Button>
                </form>
            </div>
        : 
        <Loading height='50vh' />
    }
            </>
        
    );
};

export default CycleIncidents;