import React, { useState } from 'react'
import { Box, Typography, Select } from '@mui/material'
import Card from '@components/Card'
import { useCommonStyles } from '@styles/common.style'
import { useTranslation } from 'react-i18next'
import DialogEmail from '@components/DialogEmail'

const SectionTemplateCalls = props => {
  const {
    formData,
    handleChange,
    showEmailItems,

    allEmails
  } = props
  const classes = useCommonStyles()
  const { t } = useTranslation()
  const [showEmailModal, setShowEmailModal] = useState({
    open: false,
    loading: true,
    content: {}
  })

  return (
    <Card fullHeight>
      {showEmailModal.open && (
        <DialogEmail
          showEmailModal={showEmailModal}
          setShowEmailModal={setShowEmailModal}
        />
      )}
      <Typography
        textAlign='center'
        color='white'
        variant='subtitle1'
        mb={1}
        className={classes.cardTitle}
      >
        {t('CYCLE_TYPES.TITLE_EMAIL_CONFIG').toUpperCase()}
      </Typography>
      {(formData.cycleTypeMode == undefined ||
        formData.cycleTypeMode == 0 ||
        formData.cycleTypeMode == 1) && (
        <Box my={1}>
          <Typography variant='caption'>
            {t('CYCLE_TYPES.FACETOFACE')}
          </Typography>
          <Select
            className={classes.select}
            fullWidth
            size='small'
            name='cycleTypeCallPIdMailing'
            value={formData?.cycleTypeCallPIdMailing}
            onChange={handleChange}
            renderValue={selected => {
              const name = allEmails.find(e => e.idMailing === selected)
              return name.mailingName
            }}
          >
            {showEmailItems({ key: 1, setShowEmailModal })} {/* CONP */}
          </Select>
        </Box>
      )}
      {(formData.cycleTypeMode == undefined ||
        formData.cycleTypeMode == 0 ||
        formData.cycleTypeMode == 2) && (
        <Box>
          <Typography variant='caption'>{t('CYCLE_TYPES.DIGITAL')}</Typography>
          <Select
            className={classes.select}
            fullWidth
            size='small'
            name='cycleTypeCallDIdMailing'
            value={formData?.cycleTypeCallDIdMailing}
            onChange={handleChange}
            renderValue={selected => {
              const name = allEmails.find(e => e.idMailing === selected)
              return name.mailingName
            }}
          >
            {showEmailItems({ key: 2, setShowEmailModal })} {/* COND */}
          </Select>
        </Box>
      )}
      {(formData.cycleTypeMode == undefined ||
        formData.cycleTypeMode == 0 ||
        formData.cycleTypeMode == 3) && (
        <Box>
          <Typography variant='caption'>{t('CYCLE_TYPES.MIXED')}</Typography>
          <Select
            className={classes.select}
            fullWidth
            size='small'
            name='cycleTypeCallMIdMailing'
            value={formData?.cycleTypeCallMIdMailing}
            onChange={handleChange}
            renderValue={selected => {
              const name = allEmails.find(e => e.idMailing === selected)
              return name.mailingName
            }}
          >
            {showEmailItems({ key: 10, setShowEmailModal })} {/* CONM */}
          </Select>
        </Box>
      )}
    </Card>
  )
}

export default SectionTemplateCalls
