import { makeStyles } from '@mui/styles';
import Image from '@assets/images/background_login_participants.png';
export const useStyles = makeStyles(theme => {
    return {
        signUpParticipants: {
            backgroundImage: `linear-gradient(90deg, rgba(4,139,197,0.6) 0%, rgba(117,206,224,0.9) 100%),url(${Image})`,
            backgroundSize: 'cover',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            [theme.breakpoints.down('sm')]: {
                backgroundImage: 'none',
                alignItems: 'flex-start'
            }
        },
        
        card: {
            maxWidth: '450px',
            background: 'white',
            padding: '1.5em 2em',
            // [theme.breakpoints.down('sm')]: {
            //     margin: '20px',
            //     padding: '40px 25px',
            // },
        },
        header: {
            textAlign: 'center',
        },
        title: {
            marginTop: '15px',
            marginBottom: '30px',
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            fontSize: '1.5em'
        },
        subtitle: {
            margin: '0px 5px'
        },
        subtitle2: {
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            fontSize: '0.8em',
            textAlign: 'center'
        },
        image: {
            width: '30%',
            [theme.breakpoints.down('sm')]: {
                width: '25%',
            },
        }
        
    };
});