import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '@context/auth/AuthContext';
import serviceLogin from '@services/serviceLogin';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loading from '@components/Loading';

const SimulatedLogin = () => {
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const loginSimulado = e => {
       sessionStorage.setItem('loginToken', searchParams.get("loginToken"));
       
       const dataSignSimulated = {
           loginEmail: searchParams.get("email"),
           loginMode: 'LoginConsultant'
       };

       serviceLogin.postSignInSimulated(dataSignSimulated).then(response => {
           const data = response.data;
           if (!response.error) {
               sessionStorage.setItem('area', 'consultant');
               const url = process.env.REACT_APP_PUBLIC_URL_CONSULTOR;
               login(data);
               navigate(url);
            }
        });
    };

    useEffect(() => {
        loginSimulado();
    }, []);

        return <Loading />;

};

export default SimulatedLogin;