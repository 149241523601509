import React, { createContext, useReducer, useEffect } from 'react';
import serviceLogin from '@services/serviceLogin';
import { authReducer } from './authReducer';
export const AuthContext = createContext({});

const authInitialState = {
    status: 'checking',
    user: null,
    userRoles: null,
    token: null,
};

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, authInitialState);
    const area = sessionStorage.getItem("area");

    useEffect(() => {
        const token = sessionStorage.getItem('loginToken');
        if (!token) return dispatch({ type: 'notAuthenticated' });
        const changeArea = sessionStorage.getItem("changeArea")
        
        if (changeArea){
            sessionStorage.removeItem('changeArea');
            logoutHandler();
        // } else if (area === "evaluator") {
        //     // Si el área es "evaluator", establece el estado como autenticado directamente
        //     dispatch({
        //         type: 'signUp',
        //         payload: { token: 'anonimous', user: 'anonimous', userRoles: [] }
        //     });
        } else {
            let tempLoginMode; 
            const area = sessionStorage.getItem("area")
            if (area === "consultant") {
                tempLoginMode = "LoginConsultant"
            }
            else if (area === "participant") {
                tempLoginMode = "LoginParticipant"
            }
            else if (area === "campus") {
                tempLoginMode = "LoginWorker"
            }
            else if (area === "owtoolbox") {
                tempLoginMode = "LoginOWToolbox"
            }
            else if (area === "kitow") {
                tempLoginMode = "LoginKitOW"
            } 
            else if (area === "evaluator") {
                tempLoginMode = "LoginEvaluator"
            }
            else if (area === "scheduler") {
                tempLoginMode = "LoginScheduler" 
            }
            serviceLogin
            .checkIsAuthenticated(tempLoginMode)
            .then(user => {
                dispatch({
                    type: 'signUp',
                    payload: { token, user: user.data.userSession, userRoles: user.data.userSession.userIdsRoles }
                });
            })
            .catch(() => {
                logoutHandler();
            });
        }
    }, []);

    const loginHandler = user => {
        if ( area === 'evaluator' ) {
            console.log(user);
            sessionStorage.setItem('userSession', JSON.stringify('anonimous'));
            sessionStorage.setItem('loginToken', JSON.stringify('anonimous'));
            sessionStorage.setItem('surveyCode', user.surveyCode);
            dispatch({
                type: 'signUp',
                payload: { token: 'anonimous', user: 'anonimous', userRoles: 'anonimous'}
            });
        } else {
            sessionStorage.setItem('userSession', JSON.stringify(user.userSession));
            sessionStorage.setItem('loginToken', JSON.stringify(user.loginToken.token));
            dispatch({
                type: 'signUp',
                payload: { token: user.loginToken.token, user: user.userSession, userRoles: user.userSession.userIdsRoles }
            });
        }
    };

    const logoutHandler = () => {
        sessionStorage.removeItem('userSession');
        sessionStorage.removeItem('loginToken');
        dispatch({ type: 'logout' });
    };

    const contextValue = {
        ...state,
        authState: state,
        login: loginHandler,
        logout: logoutHandler,
    };
    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    );
};