import { useEffect, useState } from 'react';

const DEBOUNCE_TIME = 500;

export const useDebounce = (input = '', time = DEBOUNCE_TIME) => {
    const [debounceValue, setDebounceValue] = useState('');

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebounceValue(input);
        }, time);

        return () => {
            clearTimeout(handler);
        };
    }, [input, time]);

    return debounceValue;
};