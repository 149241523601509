import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => {
    return {
        header: {
            display: "flex",
            justifyContent: "space-between",
            height: "50px",
            background: `linear-gradient(90deg, ${theme.palette.primary.main}, 60%, ${theme.palette.primary.light} 70%)`,
        },
        icon: {
            color: 'white',
            fontSize: '1.5em !important'
        },
        card: {
           marginBottom: '8px',
           borderRadius: '2px !important'
        },
        starIcon: {
            color: 'white'
        },
        trashIcon: {
            color: '#DA4F49',
            marginRight: '15px',
            padding: '0px !important'
        },
        boxCard: {
            backgroundColor: 'white',
            color: '#0085B4',
            margin: '2px'
        },
        textCard: {
            fontFamily: 'Archivo Narrow',
            lineHeight: '1.6em',
            fontWeight: '800',
            fontSize: '1.8em'
        },
        title: {
            margin: '20px 0 10px',
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            fontSize:'1.2em',
            textAlign:'center'
        },
        titleScore: {
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            fontSize:'1em',
            textAlign:'justify'
        },
        title2: {
            margin: '20px 0 10px',
            color: theme.palette.secondary.main,
            fontWeight: 'bold',
            fontSize:'1.2em',
            textAlign:'center'
        },
        subtitle: {
            margin: '5px 5px',
            color: theme.palette.primary.main,
        },
        btn: {
            textTransform: 'none',
        },
        image: {
            width: '90%',
        },
        box1: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '2em',
            width: 'fit-content',
            padding: '0px 10px',
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            borderRadius: '3px'
        },
        box2: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '2em',
            width: 'fit-content',
            padding: '0px 10px',
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
            borderRadius: '3px'
        },
        bold: {
            fontWeight: '700'
        },
        justified: {
            textAlign: 'justify'
        },
        centeredGrid: {
            display: 'flex',
            justifyContent: 'center', 
            alignItems: 'start',
            [theme.breakpoints.up('md')]: {
              justifyContent: 'flex-end', 
            },
        },
        blueOtto: {
            color: theme.palette.primary.main,
        },
        orangeOtto: {
            color: theme.palette.secondary.main,
        },
        bgOrangeOtto: {
            backgroundColor: theme.palette.secondary.main
        },
        bgBlueOtto: {
            backgroundColor: theme.palette.primary.main
        },
        paddingReduced: {
            paddingLeft: '8px !important'
        }
    };
});