import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => {
    return {
        globalSearchContainer: {
            display: 'flex',
            justifyContent: 'end',
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
            },
        },
        search: props => ({
            borderRadius: props.global ? 5 : 3,
            border: '1px solid #d7d7d7',
            outline: 'none',
            paddingLeft: 35,
            fontSize: 16,
            height: props.global ? '40px' : '35px',
            width: props.global && 250,
        }),
        searchContainer: {
            position: 'relative',
        },
        searchIcon: props => ({
            position: 'absolute',
            left: 6,
            top: props.global ? 9 : 6,
            color: '#d7d7d7',
        }),

        footer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
            },
        },
        footerResult: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'row',
                marginBottom: '15px',
            },
        },
        footerPagination: {
            display: 'flex',
            alignItems: 'center',
        },

        tableBodySearch: {
            background: '#ededed',
        },
        tableBodyRows: {
            background: '#f1f1f152',
        },
        stickyTableCell: {
            position: 'sticky',
            left: 0,
            zIndex: 100,
            whiteSpace: "nowrap",
            borderRight: "1px solid #0085B4",
            backgroundColor: '#ffff',
            '&::after': { // Utilizando un pseudo-elemento para simular el borde
                content: '""',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                width: '1px',
                backgroundColor: '#0085B4',
            }
        },
    };
});