import { makeStyles } from '@mui/styles'

export const useCommonStyles = makeStyles(theme => {
  return {
    container: {
      padding: '25px 40px',
      [theme.breakpoints.down('sm')]: {
        padding: '25px 20px'
      }
    },
    buttonIcon: {
      minWidth: '45px',
      padding: '6px',
      [theme.breakpoints.down('sm')]: {
        minWidth: '25px'
      }
    },
    overflowX: {
      overflowX: 'auto'
    },

    bgPrimary: {
      background: theme.palette.primary.main
    },

    select: {
      fontSize: 13,
      padding: '2px 0 2px 0'
    },

    menuItem: {
      fontSize: 13
    },

    cardTitle: {
      backgroundColor: theme.palette.grey.main,
      color: theme.palette.primary.main,
      borderRadius: '4px',
      fontSize: 14,
      fontWeight: 'bold',
      padding: '7px 0 7px 0'
    },
    hidden: {
      display: 'none'
    },
    strong: {
      fontFamily: 'inherit !important'
    },
    i: {
      fontFamily: 'inherit !important'
    },
    tryEmail: {
      position: 'relative',
      transform: 'translateY(2rem)',
      display: 'flex',
      justifyContent: 'flex-end',
      zIndex: 999
    }
  }
})
