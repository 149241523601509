import React,{useContext,useState} from 'react'
import Dialog from '@components/Dialog'
import FormActions from '@components/FormActions'
import HeaderTitle from '@components/HeaderTitle'
import Loading from '@components/Loading'
import {ListContext} from '@context/ListContext'
import {useForm} from '@hooks/useForm'
import {Box,Button,Grid,TextField,Typography} from '@mui/material'
import serviceTemplates from '@services/serviceTemplates'
import {useCommonStyles} from '@styles/common.style'
import {useLocation,useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import Card from '@components/Card'
import DeleteIcon from '@mui/icons-material/Delete'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import {snackActions} from '@helpers/snackbarUtils'
import DownloadIcon from '@mui/icons-material/Download'

const TemplateWorksheetsDetail=() => {
  const classes=useCommonStyles()
  const {t}=useTranslation()
  const navigate=useNavigate()
  const [openDialog,setOpenDialog]=useState(false)
  const [loadingDelayRequest,setLoadingDelayRequest]=useState(false)
  const {languageList}=useContext(ListContext)

  const {
    state: {data: TEMPLATE_WORKSHEET_DATA,type: TYPE}
  }=useLocation()

  const HEADER_TITLE=
    TYPE==='NEW'? t('WORKSHEET.NEW_TITLE'):t('WORKSHEET.UPDATE_TITLE')
  const HEADER_SUBTITLE=
    TYPE==='NEW'
      ? t('WORKSHEET.NEW_SUBTITLE')
      :t('WORKSHEET.UPDATE_SUBTITLE')

  const values=TYPE==='NEW'? {locale: []}:TEMPLATE_WORKSHEET_DATA

  const {formData,setFormData}=useForm(values)

  const disabledSaveButton=TEMPLATE_WORKSHEET_DATA===formData

  const handleSubmit=type => {
    setLoadingDelayRequest(true);
  
    const validateFormData = () => {
      let allMissing=formData?.locale.every(item => !item.workSheetLocaleName&&!item.workSheetLocaleFile);
      let missingName=formData?.locale.some(item => item.workSheetLocaleFile&&!item.workSheetLocaleName);
      let missingFile=formData?.locale.some(item => item.workSheetLocaleName&&!item.workSheetLocaleFile);

      if(allMissing) {
        snackActions.error(t('TEXT.ERROR_MESSAGE_NAME_REQUIRED')+', '+t('TEXT.ERROR_MESSAGE_FILE_REQUIRED'));
        return false;
      } else if(missingName||missingFile) {
        let errorMessages=[];
        if(missingName) {
          errorMessages.push(t('TEXT.ERROR_MESSAGE_NAME_REQUIRED'));
        }
        if(missingFile) {
          errorMessages.push(t('TEXT.ERROR_MESSAGE_FILE_REQUIRED'));
        }

        snackActions.error(errorMessages.join(', '));
        return false;
      }
      return true;
    };
  
    if (type !== 'DELETE' && !validateFormData()) {
      setLoadingDelayRequest(false);
      return;
    }
  
    switch(type) {
      case 'NEW':
        serviceTemplates.newWorksheet(formData).then(response => handleResponse(response, 'SAVE'));
        break;
      case 'UPDATE':
        serviceTemplates.updateWorksheet(formData).then(response => handleResponse(response, 'SAVE'));
        break;
      case 'DELETE':
        serviceTemplates.deleteWorksheet({idWorkSheet: formData.idWorkSheet}).then(response => handleResponse(response, 'DELETE'));
        break;
      default:
        setLoadingDelayRequest(false);
        break;
    }
  };

  const handleResponse = (response, actionType) => {
    setLoadingDelayRequest(false);
    if (!response.error) {
      if (actionType === 'DELETE') { //Al Eliminar vuelve a la página anterior
        setOpenDialog(false);
        navigate('/templateWorksheets');
      } else if (actionType === 'SAVE') {
        if (TYPE === 'NEW') {
          setFormData({ ...formData, idWorkSheet: response.data.idWorkSheet });
          navigate('/templateWorksheetsDetail', {
            state: { data: { ...formData, idWorkSheet: response.data.idWorkSheet }, type: 'UPDATE' }
          });
        } else {
          if (!snackActions.success.hasBeenCalled) {
            snackActions.success(response.message);
            snackActions.success.hasBeenCalled = true;
          }
        }
      }
    } else {
      snackActions.error(response.message);
    }
  }
  

  const handleLocaleChange=(e,idLanguage) => {
    let localeLanguage=formData?.locale||[]

    let index=localeLanguage.findIndex(
      locale => locale.idLanguage===idLanguage
    )

    if(index<0) {
      localeLanguage.push({
        idLanguage: idLanguage,
        idWorkSheet: formData.idWorkSheet
      })
      index=localeLanguage.length-1
    }
    localeLanguage[index][e.target.name]=e.target.value
    setFormData({...formData,locale: localeLanguage})
  }

  const handleLocalePdfChange=(e,idLanguage) => {
    let localeLanguage=formData?.locale||[]

    let index=localeLanguage.findIndex(
      locale => locale.idLanguage===idLanguage
    )

    if(index<0) {
      localeLanguage.push({
        idLanguage: idLanguage,
        idWorkSheet: formData.idWorkSheet
      })
      index=localeLanguage.length-1
    }

    const reader=new FileReader()
    const name=e.target.name

    reader.addEventListener(
      'load',
      () => {
        localeLanguage[index][name]=reader.result
        setFormData({...formData,locale: localeLanguage})
      },
      false
    )

    let tipo=e.target.files[0].type
      if (tipo === 'application/pdf' || tipo === 'application/msword' || tipo === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      let size=e.target.files[0].size
      if(size<5120000) {
        reader.readAsDataURL(e.target.files[0])
      } else {
        snackActions.error(t('WORKSHEET.FILE_SIZE'))
      }
    } else {
      snackActions.error(t('WORKSHEET.FILE_TYPE'))
    }
  }

  const handleLocalePdfDelete=idLanguage => {
    let localeLanguage=formData?.locale||[]

    let index=localeLanguage.findIndex(
      locale => locale.idLanguage===idLanguage
    )

    if(index<0) {
      localeLanguage.push({
        idLanguage: idLanguage,
        idWorkSheet: formData.idWorkSheet
      })
      index=localeLanguage.length-1
    }

    localeLanguage[index]['workSheetLocaleFile']=''
    setFormData({...formData,locale: localeLanguage})
  }

  const handleLocalePdfDownload=idLanguage => {
    let localeLanguage=formData?.locale
    let index=localeLanguage.findIndex(
      locale => locale.idLanguage===idLanguage
    )
    const linkSource=localeLanguage[index]['workSheetLocaleFile']
    const downloadLink=document.createElement('a')
    const fileName=localeLanguage[index]['workSheetLocaleName']
    downloadLink.href=linkSource
    downloadLink.download=fileName
    downloadLink.click()
  }

  if(loadingDelayRequest) {
    return <Loading showMessageDelayRequest='true' />
  }

  return (
    <div className={classes.container}>
      <Dialog
        open={openDialog}
        hasCloseBtn={false}
        height={200}
        title={t('CONFIRM.TITLE_WORKSHEETS')}
        subtitle={t('CONFIRM.UNRECOVERY_DELETE2')}
        close={() => setOpenDialog(false)}
        actions={
          <Box display='flex' alignItems='center'>
            <Box mr={1}>
              <Button
                variant='contained'
                onClick={() => setOpenDialog(false)}
                color='error'
              >
                {t('BUTTON.CANCEL')}
              </Button>
            </Box>
            <Button variant='contained' onClick={() => handleSubmit('DELETE')}>
              {t('BUTTON.DELETE')}
            </Button>
          </Box>
        }
      />

      <HeaderTitle title={HEADER_TITLE} subtitle={HEADER_SUBTITLE}>
        <FormActions
          show={TYPE==='UPDATE'}
          handleDelete={() => setOpenDialog(true)}
          handleUndo={() => {}}
          handleSave={() => handleSubmit(TYPE)}
          disabledButton={disabledSaveButton}
          urlBack='/templateWorksheets'
        />
      </HeaderTitle>

      <Grid container spacing={2}>
        <Grid item sm={6} lg={6} xs={12} /* style={{ background: 'red' }} */>
          <Card>
            <Typography color='primary' variant='subtitle1' mb={1}>
              {t('WORKSHEET.NAME_BY_COUNTRY')}
            </Typography>
            {languageList?.map(lang => {
              const localeLanguage=formData?.locale?.find(
                value => value.idLanguage===lang.idLanguage
              )
              return (
                <Box display='flex' key={lang.idLanguage}>
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    mr={2}
                    mt={1}
                  >
                    {lang.Icon()}
                  </Box>

                  <TextField
                    name='workSheetLocaleName'
                    fullWidth
                    size='small'
                    margin='dense'
                    value={localeLanguage?.workSheetLocaleName||''}
                    onChange={e => {
                      handleLocaleChange(e,lang.idLanguage)
                    }}
                  />
                  {!localeLanguage?.workSheetLocaleFile&&(
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      ml={2}
                    >
                      <Button variant='contained' component='label'>
                        {t('WORKSHEET.UPLOAD')}&nbsp; <CloudUploadIcon />
                        <input
                          hidden
                          accept='application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                          name='workSheetLocaleFile'
                          onChange={e => {
                            handleLocalePdfChange(e,lang.idLanguage)
                          }}
                          multiple
                          type='file'
                        />
                      </Button>
                    </Box>
                  )}
                  {localeLanguage?.workSheetLocaleFile&&(
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      ml={2}
                    >
                      <Button
                        variant='contained'
                        name='workSheetLocaleFile'
                        color='success'
                        onClick={() => handleLocalePdfDownload(lang.idLanguage)}
                        component='label'
                      >
                        {t('WORKSHEET.DOWNLOAD')} <DownloadIcon />
                      </Button>
                    </Box>
                  )}
                  {localeLanguage?.workSheetLocaleFile&&(
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      ml={2}
                    >
                      <Button
                        variant='contained'
                        name='workSheetLocaleFile'
                        color='error'
                        onClick={() => handleLocalePdfDelete(lang.idLanguage)}
                        component='label'
                      >
                        <DeleteIcon />
                      </Button>
                    </Box>
                  )}
                </Box>
              )
            })}
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default TemplateWorksheetsDetail
