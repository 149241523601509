import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => {
    return {
        card: {
            maxWidth: '450px',
            background: 'white',
            // padding: '1.5em 2em !important',
            // [theme.breakpoints.down('sm')]: {
            //     margin: '20px',
            //     padding: '40px 25px',
            // },
        },
        // header: {
        //     height: '100%',
        //     textAlign: 'center',
        //     backgroundColor: '#0085B4',
        //     boxShadow: 'none !important'  ,
        //     maxWidth: '700px', 
        //     margin: '0px auto',
        //     zIndex:'99999'  
        // },
        header: {
            display: "flex",
            justifyContent: "space-between",
            height: "50px !important",
            background: `linear-gradient(90deg, white, 90%, ${theme.palette.primary.light} 150%)`,
            width: '100vw'
        },
        headerTitle: {
            color: "white",
            fontSize: 25,
            fontWeight: "bold",
        },
        btn: {
            cursor: "pointer",
            textTransform: 'none',
            "&:hover": {
                opacity: 0.7,
            },
        },
        title: {
            marginTop: '15px',
            marginBottom: '30px',
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            fontSize: '1.5em'
        },
        subtitle: {
            margin: '10px 5px 10px 20px',
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            fontSize: '1.5em'
        },
        subtitle2: {
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            fontSize: '1.1em',
            textAlign: 'left'
        },
        image: {
            width: '45px',
            // [theme.breakpoints.down('sm')]: {
            //     width: '25%',
            // },
        },
        targets: {
            padding: '0.5em 1em !important',
            display: "flex",
            justifyContent: "center", 
            alignItems: 'center',
            margin: '0px 0px 0px 10px !important'
        },
        checkbox: {
            width:'2em', 
            height:'2em',
            stroke: 'rgb(255,255,255) !important'           
        },
        padding0: {
            padding: '0px !important'
        },
        colorPrimary: {
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main
        },
        colorSecondary: {
            color: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main
        },
        imageHeader: {
            objectFit:'cover',
            objectPosition:'center center',
            height:'100%',
            width: '100%',
            borderRadius: '3px 3px 0px 0px'   
        },
        btnsTab0: {
            backgroundColor:'#F7F7F7',
            cursor: 'default',
            color: '#0085B4 !important',
            borderColor: '#0085B4 !important'
        },
        btnsTab02: {
            backgroundColor:'#F7F7F7',
            cursor: 'default',
            color: '#e9a459 !important',
            borderColor: '#e9a459 !important'
        },
        btnSecondary: {
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
            fontWeight: 'bold',
            fontSize: '1.1em'
        },
        radio: {
            height: '1.8em'
        },
        containerVertical: {
            backgroundColor: theme.palette.grey.main,
            // padding:'0.5em',

            [theme.breakpoints.up('md')]: {
                padding: '15px 25px',
                height: 'calc(100vh - 50px)',
                overflowY: 'scroll',
                overflowX: 'hidden',
            },
        },
        selectedOption: {
            backgroundColor: 'red !important'
        },
        selectedMenuItem: {
            backgroundColor: 'green !important'
        },
        '& .css-xctwkd-MuiButtonBase-root-MuiMenuItem-root .Mui-selected': {
            backgroundColor: 'red !important'
        }
    };
});