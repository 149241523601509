import { TextField } from '@mui/material'
import React, { useState, useEffect, useCallback } from 'react'
import Autocomplete from '@mui/material/Autocomplete'

import { useDebounce } from '@hooks/useDebounce'
import { useTranslation } from 'react-i18next'

//const initialSearch = {
//  globalSearch: '',
//  order: [],
//  pageNumber: 0,
//  pageSize: 0,
//  search: [],
//};
const AutocompleteSearch = props => {
  const { t } = useTranslation()
  const {
    handleSelect,
    setOptionSelected,
    optionSelected,
    setDisabledAddButton,
    disabled = false,
    fetch,
    optionsDisabled,
    optionsLabel,
    pageSize = 4,
    initialSearch = {
      globalSearch: '',
      order: [],
      pageNumber: 0,
      pageSize: 0,
      search: []
    }
  } = props

  const [options, setOptions] = useState([])

  const [search, setSearch] = useState(initialSearch)
  const [loading, setLoading] = useState(false)
  const debounceValue = useDebounce(search)

  const fetchData = useCallback(async () => {
    const { data, error } = await fetch(debounceValue)
    console.log(data, error)
    if (!error) {
      setOptions(data)
      if (data.length === 1) {
        setOptionSelected(data[0])
        handleSelect(data[0])
      }
    }
    setLoading(false)
  }, [debounceValue, fetch])

  useEffect(() => {
    if (
      debounceValue &&
      (debounceValue?.globalSearch.length > 0 ||
        debounceValue?.search.length > 0)
    ) {
      fetchData()
      return
    }
    setOptions([])
    setLoading(false)
  }, [debounceValue, fetchData])

  const handleChangeSearch = e => {
    setLoading(true)
    setSearch({
      ...search,
      pageSize: pageSize,
      globalSearch: e.target.value
    })
  }
  console.log(options)
  console.log(optionSelected)
  return (
    <Autocomplete
      value={
        options?.length === 0
          ? ''
          : optionSelected === null || optionSelected === undefined
          ? ''
          : optionSelected
      }
      disabled={disabled}
      loading={loading}
      onChange={(_, value) => {
        setDisabledAddButton && setDisabledAddButton(false)
        setOptionSelected(value)
      }}
      id='combo-box-demo'
      loadingText={t('AUTOCOMPLETE.SEARCH')}
      noOptionsText={t('AUTOCOMPLETE.SEARCH_NOT_OPTIONS')}
      options={options ?? []}
      getOptionLabel={option => optionsLabel(option)}
      getOptionDisabled={option => optionsDisabled(option)}
      filterOptions={options => options}
      renderInput={params => {
        return (
          <TextField {...params} onChange={handleChangeSearch} size='small' />
        )
      }}
    />
  )
}

export default AutocompleteSearch
