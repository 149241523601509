import React, { useState, useEffect } from 'react'
import Card from '@components/Card'
import Dialog from '@components/Dialog'

import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import TableFiles from './TableFiles'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeletePopover from '@components/DeletePopover'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'

const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}))

const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}))

const SectionFiles = ({ formData, setFormData }) => {
  const { t } = useTranslation()

  const [openTable, setOpenTable] = useState(false)
  const [fileSelected, setFileSelected] = useState()

  useEffect(() => {
    setFileSelected(formData?.mailFiles ? formData.mailFiles : [])
  }, [])

  const removeFileFromList = id => {
    const newFile = formData?.mailFiles.filter(file => file.idFileAttach !== id)

    setFormData(newFile)
    setFileSelected(newFile)
  }

  return (
    <Card>
      {openTable && (
        <Dialog
          disabled
          open={openTable ? true : false}
          width='50%'
          height='75%'
          hasCloseBtn={false}
          title={t('FILEATTACH.SEARCH')}
          close={() => setOpenTable(false)}
          content={
            <TableFiles
              setFileSelected={setFileSelected}
              fileSelected={fileSelected}
            />
          }
          actions={
            <Box display='flex' width='100%' justifyContent='flex-end'>
              <Button
                sx={{ mr: 1 }}
                variant='contained'
                color='error'
                onClick={() => {
                  setOpenTable(false)
                }}
                aria-label='move selected left'
              >
                {t('CYCLES.OUT')}
              </Button>
              <Button
                variant='contained'
                color='success'
                onClick={() => {
                  setFormData(fileSelected)
                  setOpenTable(false)
                }}
                aria-label='move selected left'
              >
                {t('CYCLES.SAVE_FORM_CHANGES')}
              </Button>
            </Box>
          }
        />
      )}

      <Accordion>
        <AccordionSummary
          sx={{
            bgcolor: 'grey.main',
            paddingLeft: '20px'
          }}
        >
          <Typography variant='subtitle1'>{t('FILEATTACH.ATTACH')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='caption'>
            {!formData?.mailFiles?.length
              ? t('FILEATTACH.ADD')
              : t('FILEATTACH.ATTACH')}
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                startIcon={<VisibilityIcon />}
                onClick={() => setOpenTable(true)}
                variant='contained'
                color='primary'
                fullWidth
              >
                <Typography
                  variant='button'
                  sx={{
                    textTransform: 'none'
                  }}
                >
                  {t('FILEATTACH.LIST')}
                </Typography>
              </Button>
            </Grid>
          </Grid>

          <List>
            {formData?.mailFiles?.map((file, index) => (
              <Box key={file.idFileAttach}>
                <ListItem
                  sx={{
                    bgcolor: 'theme.palette.primary.main'
                  }}
                  secondaryAction={
                    <DeletePopover
                      remove={() => {
                        removeFileFromList(file.idFileAttach)
                      }}
                    />
                  }
                >
                  <ListItemText secondary={file.name} />
                </ListItem>
                <Divider />
              </Box>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </Card>
  )
}

export default SectionFiles
