import React from 'react'
import DynamicTable from '@components/DynamicTable'
import { IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
const TableParticipantsJourney = ({
  addParatrooper
}) => {
  const { t } = useTranslation()

  const columnSearch = [
    {
      SearchField: 'ParticipantActive',
      SearchValue: 1,
      SearchMode: 'EqualTo'
    }
  ]

  const ORDER = [
    {
      orderField: 'participantSurname',
      orderAsc: true
    }
  ]

  const COLUMNS = [
    {
      accessor: '',
      Header: t('BUTTON.CHANGE'),
      filterable: false,
      hideHeader: false,
      width: '15%',
      Cell: ({ row: { values } }) => (
        <IconButton
          aria-label='info'
          color='info'
          style={{
            padding: '0px',
            marginLeft: '25%'
          }}
          onClick={() => {
            addParatrooper(values.idParticipant)
          }}
        >
          <AddBoxRoundedIcon fontSize='inherit' />
        </IconButton>
      )
    },
    {
      hiddenColumn: true,
      accessor: 'idParticipant',
      Header: t('LABEL.ID'),
      width: '5%'
    },
    {
      accessor: 'participantName',
      Header: t('LABEL.NAME')
    },
    {
      accessor: 'participantSurname',
      Header: t('LABEL.SURNAME')
    },
    {
      accessor: 'idCompanyNavigation.companyName',
      Header: t('LABEL.COMPANY')
    },
    {
      accessor: 'participantEmail',
      Header: t('LABEL.EMAIL')
    },
    {
      hiddenColumn: true,
      accessor: 'participantActive',
      Header: t('LABEL.ACTIVE')
    }
  ]

  return (
    <Box flex={1}>
      <DynamicTable
        size='small'
        columns={COLUMNS}
        endpoint='Participants/List'
        isColumnsSearch={true}
        isOrdered={true}
        columnSearchParam={columnSearch}
        orderColumn={ORDER}
      />
    </Box>
  )
}

export default TableParticipantsJourney
