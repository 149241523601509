import React, { createContext, useState, useEffect } from 'react';
import { FLAG_ITEMS } from '@constants/flagItems';
import serviceList from '@services/serviceList';

export const ListContext = createContext({});

export const ListProvider = ({ children }) => {
    const [state, setState] = useState({
        languageList: null,
        countriesList: null,
    });

    const contextValue = {
        ...state,
        state,
        setLanguagesList: setState,
    };

    useEffect(() => {
        getLanguagesList();
        getCountriesList();
    }, []);

    const getLanguagesList = () => {
        serviceList.getLanguagesList().then(response => {
            if (!response.error) {
                var result = response.data.map(flag => {
                    const item = FLAG_ITEMS.find(
                        item => item.value === flag.languageCode,
                    );
                    return { ...flag, Icon: item.icon };
                });
                setState(prevState => {
                    return { ...prevState, languageList: result };
                });
            }
        });
    };

    const getCountriesList = () => {
        serviceList.getCountriesList().then(response => {
            if (!response.error) {
                setState(prevState => {
                    return { ...prevState, countriesList: response.data };
                });
            }
        });
    };

    return (
        <ListContext.Provider value={contextValue}>{children}</ListContext.Provider>
    );
};