import React, {useEffect} from 'react'
import { AuthContext } from '@context/auth/AuthContext'
import Home from '@areas/Evaluator/Home'
import LoginEvaluatorPage from '@login/LoginEvaluatorPage'
import LoginPage from '@login/LoginPage'
import Panel from '@areas/Evaluator/sections/panel/Panel'
import Survey from '@areas/Evaluator/sections/surveys/Survey'
import Reports from '@areas/Evaluator/sections/reports/Reports'
import AutoevaluationReport from '@areas/Evaluator/sections/reports/components/AutoevaluationReport'
import AutoevaluationResponses from '@areas/Evaluator/sections/reports/components/AutoevaluationResponses'
import { useContext } from 'react'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from 'react-router-dom'


const EvaluatorRoutes = () => {
  const { status } = useContext(AuthContext) 
  
  const PrivateRoute = () => { 
    return status === 'authenticated' ? <Home /> : <Navigate to='/' /> }

  return (
    <Router>
      <Routes>
        <Route
          path='/'
          element={
            status === 'authenticated' ? (
              <Navigate to='/panel' />
            ) : (
              <LoginEvaluatorPage />
              // <LoginPage />
            )
          }
        />
        <Route path='*' element={<Navigate to='/' />} />
        <Route element={<PrivateRoute />}>
          <Route path='panel' element={<Panel />} />
          <Route path='survey' element={<Survey />} />
          <Route path='reports' element={<Reports />} />
          <Route path='reports/autoevresp' element={<AutoevaluationResponses />} />
          <Route path='reports/autoevrep' element={<AutoevaluationReport />} />
        </Route>
      </Routes>
    </Router>
  )
}

export default EvaluatorRoutes
