const calculatePagesQuestions = (paginationRecords) => {
    let recordsPerPage = 0;
    let totalPageNumber = 0;
    
    if (paginationRecords <= 15) {
        recordsPerPage = paginationRecords;
        totalPageNumber = 1;
    } else if (paginationRecords <= 40) {
        recordsPerPage = 10;
        totalPageNumber = Math.ceil(paginationRecords / recordsPerPage);
    } else if (paginationRecords <= 60) {
        recordsPerPage = 12;
        totalPageNumber = Math.ceil(paginationRecords / recordsPerPage);
    } else {
        recordsPerPage = 16;
        totalPageNumber = Math.ceil(paginationRecords / recordsPerPage);
    }

    return { recordsPerPage, totalPageNumber };
}

export default calculatePagesQuestions