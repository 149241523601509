const ATTENDANCE_AND_ABSENCES = `

<html>
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <meta http-equiv="Content-Style-Type" content="text/css" />
  <meta name="generator" content="Aspose.Words for .NET 24.7.0" />
  <title></title>
  <style type="text/css">
.asistencia-span { font-size: 16pt; }

    body {
      line-height: 108%;
      font-family: 'Arial Nova';
      font-size: 11pt;
    }
    h1, h2, h3, h4, h5, h6, p {
      margin: 0pt 0pt 8pt;
    }
    li {
      margin-top: 0pt;
      margin-bottom: 8pt;
    }
    h1 {
      margin: 8pt 0 4pt;
      page-break-inside: avoid;
      page-break-after: avoid;
      line-height: 108%;
      font-family: 'Aptos Display';
      font-size: 20pt;
      font-weight: normal;
      color: #2e74b5;
    }
    h2 {
      margin-top: 8pt;
      margin-bottom: 4pt;
      page-break-inside: avoid;
      page-break-after: avoid;
      line-height: 108%;
      font-family: 'Aptos Display';
      font-size: 16pt;
      font-weight: normal;
      color: #2e74b5;
    }
    h3 {
      margin-top: 8pt;
      margin-bottom: 4pt;
      page-break-inside: avoid;
      page-break-after: avoid;
      line-height: 108%;
      font-family: Aptos;
      font-size: 14pt;
      font-weight: normal;
      color: #2e74b5;
    }
    h4 {
      margin-top: 4pt;
      margin-bottom: 2pt;
      page-break-inside: avoid;
      page-break-after: avoid;
      line-height: 108%;
      font-family: Aptos;
      font-size: 11pt;
      font-weight: normal;
      font-style: italic;
      color: #2e74b5;
    }
    h5 {
      margin-top: 4pt;
      margin-bottom: 2pt;
      page-break-inside: avoid;
      page-break-after: avoid;
      line-height: 108%;
      font-family: Aptos;
      font-size: 11pt;
      font-weight: normal;
      color: #2e74b5;
    }
    h6 {
      margin-top: 2pt;
      margin-bottom: 0pt;
      page-break-inside: avoid;
      page-break-after: avoid;
      line-height: 108%;
      font-family: Aptos;
      font-size: 11pt;
      font-weight: normal;
      font-style: italic;
      color: #595959;
    }
    .Heading7 {
      margin-top: 2pt;
      margin-bottom: 0pt;
      page-break-inside: avoid;
      page-break-after: avoid;
      line-height: 108%;
      font-family: Aptos;
      font-size: 11pt;
      font-weight: normal;
      font-style: normal;
      color: #595959;
      -aw-style-name: heading7;
    }
    .Heading8 {
      margin-bottom: 0pt;
      page-break-inside: avoid;
      page-break-after: avoid;
      line-height: 108%;
      font-family: Aptos;
      font-size: 11pt;
      font-weight: normal;
      font-style: italic;
      color: #272727;
      -aw-style-name: heading8;
    }
    .Heading9 {
      margin-bottom: 0pt;
      page-break-inside: avoid;
      page-break-after: avoid;
      line-height: 108%;
      font-family: Aptos;
      font-size: 11pt;
      font-weight: normal;
      font-style: normal;
      color: #272727;
      -aw-style-name: heading9;
    }
    .IntenseQuote {
      margin: 18pt 43.2pt;
      text-align: center;
      line-height: 108%;
      border-top: 0.75pt solid #2e74b5;
      border-bottom: 0.75pt solid #2e74b5;
      padding-top: 10pt;
      padding-bottom: 10pt;
      font-size: 11pt;
      font-style: italic;
      color: #2e74b5;
      -aw-border-bottom: 0.5pt single;
      -aw-border-top: 0.5pt single;
      -aw-style-name: intense-quote;
    }
    .ListParagraph {
      margin-left: 36pt;
      margin-bottom: 8pt;
      line-height: 108%;
      font-size: 11pt;
      -aw-style-name: list-paragraph;
    }
    .Quote {
      margin-top: 8pt;
      margin-bottom: 8pt;
      text-align: center;
      line-height: 108%;
      font-size: 11pt;
      font-style: italic;
      color: #404040;
      -aw-style-name: quote;
    }
    .Subtitle {
      margin-bottom: 8pt;
      line-height: 108%;
      font-family: Aptos;
      font-size: 14pt;
      letter-spacing: 0.75pt;
      color: #595959;
      -aw-style-name: subtitle;
    }
    .Title {
      margin-bottom: 4pt;
      line-height: normal;
      font-family: 'Aptos Display';
      font-size: 28pt;
      letter-spacing: -0.5pt;
      -aw-style-name: title;
    }
    span.CitaCar {
      font-style: italic;
      color: #404040;
    }
    span.CitadestacadaCar {
      font-style: italic;
      color: #2e74b5;
    }
    span.IntenseEmphasis {
      font-style: italic;
      color: #2e74b5;
      -aw-style-name: intense-emphasis;
    }
    span.IntenseReference {
      font-weight: bold;
      font-variant: small-caps;
      letter-spacing: 0.25pt;
      color: #2e74b5;
      -aw-style-name: intense-reference;
    }
    span.SubttuloCar {
      font-family: Aptos;
      font-size: 14pt;
      letter-spacing: 0.75pt;
      color: #595959;
    }
    span.Ttulo1Car {
      font-family: 'Aptos Display';
      font-size: 20pt;
      color: #2e74b5;
    }
    span.Ttulo2Car {
      font-family: 'Aptos Display';
      font-size: 16pt;
      color: #2e74b5;
    }
    span.Ttulo3Car {
      font-family: Aptos;
      font-size: 14pt;
      color: #2e74b5;
    }
    span.Ttulo4Car {
      font-family: Aptos;
      font-style: italic;
      color: #2e74b5;
    }
    span.Ttulo5Car {
      font-family: Aptos;
      color: #2e74b5;
    }
    span.Ttulo6Car {
      font-family: Aptos;
      font-style: italic;
      color: #595959;
    }
    span.Ttulo7Car {
      font-family: Aptos;
      color: #595959;
    }
    span.Ttulo8Car {
      font-family: Aptos;
      font-style: italic;
      color: #272727;
    }
    span.Ttulo9Car {
      font-family: Aptos;
      color: #272727;
    }
    span.TtuloCar {
      font-family: 'Aptos Display';
      font-size: 28pt;
      letter-spacing: -0.5pt;
    }
  </style>
</head>
<body>
  <div>
  <br>
    <p style="margin-bottom:6pt; text-align:justify">
      <span class="asistencia-span" style="color:#333333">The OW Methodology is an experiential experience, which requires rigorous attendance to each and every one of the group workdays.</span>
    </p>
    <p style="margin-bottom:12pt; text-align:justify">
      <span class="asistencia-span" style="color:#333333">The MINIMUM ATTENDANCE to obtain results and consider the OW Program completed is 90% of the group workdays.</span>
    </p>
    <p style="margin-bottom:6pt; text-align:justify">
      <span class="asistencia-span" style="color:#333333">OW, in accordance with its Quality standard and OW Ethical Commitments, will rigorously comply with the commitment to never do anything that does not serve the participants or that harms the expected results.</span>
    </p>
    <p style="margin-bottom:6pt; text-align:justify">
      <span class="asistencia-span" style="color:#333333">If in case of “force majeure” reasons (illness, serious incident, etc.) a participant cannot attend the minimum required sessions, it is always at the discretion of the OW Top-senior Consultant who directs the Cycle, depending on the degree of involvement, quality of work, compliance with set objectives and commitment to the participant's development process, to determine the possible options to apply.</span>
    </p>
    <p style="margin-bottom:6pt; text-align:justify">
      <span class="asistencia-span" style="text-decoration:underline; color:#333333">The alternatives to be able to continue carrying out the Cycle, compatible with the development process, are the following:</span>
    </p>
    <p style="text-align:justify">
      <span class="asistencia-span" style="color:#333333; -aw-import:ignore">&#xa0;</span>
    </p>
    <p style="margin-bottom:6pt; text-align:justify; line-height:108%; font-size:18pt">
      <span class="asistencia-span" style="line-height:108%; font-family:'Arial Black'; font-size:11pt; color:#0070c0"> </span>
      <span class="asistencia-span" style="line-height:108%; font-family:'Swis721 Cn BT'; font-size:22pt; letter-spacing:-0.15pt; color:#0070c0">1.- Options that maintain the level of dynamics and results of the OW Cycle</span>
      <span class="asistencia-span" style="line-height:108%; font-size:12pt; font-weight:bold; color:#0070c0">:</span>
    </p>
    <p class="ListParagraph" style="margin-top:6pt; margin-left:46.35pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:''; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:8.19pt">
      <span class="asistencia-span" style="-aw-import:ignore">
        <span class="asistencia-span" style="color:#333333"><span class="asistencia-span" style="font-family:'Wingdings 3'; color:#ff9933"></span></span>
        <span class="asistencia-span" style="width:8.19pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
      </span>
      <span class="asistencia-span" style="color:#333333">The optimal: to attend the same work session, another day in another group in the near future, preferably in another group of the same company that is carrying out the same OW Program in parallel, or in an open group that has availability and a compatible date sequence.</span>
    </p>
    <p class="ListParagraph" style="margin-top:6pt; margin-left:46.35pt; margin-bottom:6pt; text-indent:-18pt; text-align:justify; line-height:normal; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:''; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:8.19pt">
      <span class="asistencia-span" style="-aw-import:ignore">
        <span class="asistencia-span" style="color:#333333"><span class="asistencia-span" style="font-family:'Wingdings 3'; color:#ff9933"></span></span>
        <span class="asistencia-span" style="width:8.19pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
      </span>
      <span class="asistencia-span" style="color:#333333">Carry out or complete the OW Cycle later in a future group, whether in-company or open group, when full attendance is guaranteed.</span>
    </p>
    <p class="ListParagraph" style="margin-left:46.35pt; margin-bottom:6pt; text-indent:-18pt; text-align:justify; line-height:normal; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:''; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:8.19pt">
      <span class="asistencia-span" style="-aw-import:ignore">
        <span class="asistencia-span" style="color:#333333"><span class="asistencia-span" style="font-family:'Wingdings 3'; color:#ff9933"></span></span>
        <span class="asistencia-span" style="width:8.19pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
      </span>
      <span class="asistencia-span" style="color:#333333">If several absences are expected in one day, OW considers rescheduling the date of that workday for the entire group to a new date, which could also be the date of the next scheduled workday and add a new date for the last workday of the Cycle. This option is conditioned to the OW Cycle Director´s schedule, and the new schedule must be agreed with the OW planning area. If the announcement of postponement occurs less than one week before the scheduled date, the cancellation of that work session will have a cost of € 735.</span>
    </p>
    <p style="margin-left:10.35pt; text-align:justify">
      <span class="asistencia-span" style="color:#333333; -aw-import:ignore">&#xa0;</span>
    </p>
    <p style="margin-bottom:6pt; text-align:justify; line-height:108%; font-size:16pt">
      <span class="asistencia-span" style="line-height:108%; font-family:'Swis721 Cn BT'; font-size:22pt; letter-spacing:-0.15pt; color:#0070c0">2.- Limited Options:</span>
    </p>
    <p style="margin-bottom:6pt; text-align:justify">
      <span class="asistencia-span" style="color:#333333">These alternatives allow you to partially see the workday with a significant loss of dynamics, experience, and content, with just enough to be able to resume the cycle.</span>
    </p>
    <p style="margin-bottom:6pt; text-align:justify">
      <a name="_Hlk145924449">
        <span class="asistencia-span" style="color:#333333">A Reduced Engagement Session (R.E.S)</span>
        <span class="asistencia-span" style="font-weight:bold; color:#333333">, </span>
        <span class="asistencia-span" style="color:#333333">a short session, where only the basic content and ideas are shared, </span>
      </a>
      <span class="asistencia-span" style="color:#333333">you only obtain a minimum, enough to be able to "engage" with the group and continue. These options can be used ONLY ONE TIME, therefore, if a participant receives a R.E.S, it means that it will no longer be possible to miss any other workday, either totally or partially, because it would mean breaking the experiential sequence of the OW Cycle and the transformation process.</span>
    </p>
    <p style="margin-bottom:6pt; text-align:justify">
      <span class="asistencia-span" style="font-weight:bold; color:#333333">The Price for a R.E.S is € 420 and will be determined based on the OW Consultants' agendas.</span>
    </p>
    <p style="text-align:justify; line-height:108%; font-size:6pt">
      <span class="asistencia-span" style="color:#333333; -aw-import:ignore">&#xa0;</span>
    </p>
    <p style="margin-bottom:6pt; text-indent:24.55pt; text-align:justify; line-height:108%; font-size:12pt">
      <span class="asistencia-span" style="font-family:'Swis721 Cn BT'; font-weight:bold; text-transform:uppercase; letter-spacing:-0.15pt; color:#0070c0">Options</span>
      <span class="asistencia-span" style="line-height:108%; font-size:11pt; color:#333333">:</span>
    </p>
    <p class="ListParagraph" style="margin-bottom:6pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:''; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:8.19pt">
      <span class="asistencia-span" style="-aw-import:ignore">
        <span class="asistencia-span" style="font-size:11pt; color:#333333"><span class="asistencia-span" style="font-family:'Wingdings 3'; color:#ff9933"></span></span>
        <span class="asistencia-span" style="width:8.19pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
      </span>
      <span class="asistencia-span" style="font-weight:bold; color:#0070c0">Experiences with the group + </span>
      <a name="_Hlk145924499"><span class="asistencia-span" style="font-weight:bold; color:#0070c0">R.E.S. </span></a>
      <span class="asistencia-span" style="font-size:16pt; color:#333333">(from 2</span>
      <span class="asistencia-span" style="font-size:7.33pt; vertical-align:super; color:#333333">nd</span>
      <span class="asistencia-span" style="font-size:16pt; color:#333333"> workday forward)</span>
    </p>
    <p style="margin-left:35.45pt; margin-bottom:6pt; text-align:justify">
      <span class="asistencia-span" style="font-weight:bold; color:#333333">Attend, in-person or virtually, the first hour and a half of the day </span>
      <span class="asistencia-span" style="color:#333333">to listen, share and contribute experiences of the results obtained from the implementation of the objectives that were set in the previous workday. Subsequently, a R.E.S. will be scheduled for an hour and a half to see the minimum enough from the group workday that the rest of the group has worked to be able to continue with the program on the following group workday.</span>
    </p>
    <p class="ListParagraph" style="margin-bottom:6pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:''; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:8.19pt">
      <span class="asistencia-span" style="-aw-import:ignore">
        <span class="asistencia-span" style="font-size:11pt; color:#333333"><span class="asistencia-span" style="font-family:'Wingdings 3'; color:#ff9933"></span></span>
        <span class="asistencia-span" style="width:8.19pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
      </span>
      <span class="asistencia-span" style="font-weight:bold; color:#0070c0">Only R.E.S. </span>
      <span class="asistencia-span" style="font-size:16pt; font-weight:bold; color:#333333">(Minimal alternative) </span>
    </p>
    <p style="margin-left:35.45pt; margin-bottom:6pt; text-align:justify; line-height:normal">
      <span class="asistencia-span" style="color:#333333">In case that the previous alternative is not possible, the minimum to be able to continue is an On-Line (R.E.S.) with an OW Top-Senior Consultant, lasting an hour and a half, to see the essential that allows you to continue with the realization of the Program engage with the experiential dynamics on the following Group workday.</span>
    </p>
    <p style="margin-left:28.35pt; text-align:justify">
      <span class="asistencia-span" style="color:#333333; -aw-import:ignore">&#xa0;</span>
    </p>
    <p style="margin-left:28.35pt; text-align:justify">
      <span class="asistencia-span" style="color:#333333; -aw-import:ignore">&#xa0;</span>
    </p>
    <p style="margin-left:7.1pt; text-align:justify">
      <span class="asistencia-span" style="font-weight:bold; color:#333333">Decision process to guarantee quality</span>
      <span class="asistencia-span" style="color:#333333">: It will be the OW Cycle Director who determines how to proceed with each person, and in each case, depending on the development of the group and the profile of the affected participant, to help continue carrying out the Program maintaining the dynamics and results of the OW Cycle. The criteria of the OW consultant will always be respected to make the best decision and option.</span>
    </p>
    <p style="margin-left:7.1pt; text-align:justify">
      <span class="asistencia-span" style="color:#333333">At all times, in the event of incidences (absences, workday date changes, etc…) Otto Walter will inform and agree with the </span>
      <span class="asistencia-span" style="color:#0070c0">Client Company </span>
      <span class="asistencia-span" style="color:#333333">on the applicable alternatives and the possible additional costs involved, if any.</span>
    </p>
  </div>
</body>
</html>


    `;

  export default ATTENDANCE_AND_ABSENCES;