import React, { Fragment, useState, useContext, useEffect } from 'react';
import Logo from '@assets/images/logo.png';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useStyles } from '@styles/owToolboxKitOw.style'

import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Button,
  TextField,
} from '@mui/material';
import Dialog from '@components/Dialog';
import serviceMobileSheets from '../../../services/serviceMobileSheets';
import WorksheetsListContext from '@context/WorksheetsListContext';

const Header = ({ handleShowMenu }) => {
  const [openAdd, setOpenAdd] = useState(false);
  const { i18n, t } = useTranslation();
  const [enteredCode, setEnteredCode] = useState('');
  const classes = useStyles();
  const idParticipant = JSON.parse(sessionStorage.getItem('userSession')).idParticipant;
  const { refreshWorksheetsList } = useContext(WorksheetsListContext);

  useEffect(() => {
    if (openAdd === false) {
      setEnteredCode('')
    }
  }, [openAdd])

  const addMobileSheet = async () => {   
    const tempData = {
      IdParticipant: idParticipant,
      MobileSheetCode: enteredCode,
    };

    const { error } = await serviceMobileSheets.newMobileSheetKitOW(tempData);
    if (!error) {
      setOpenAdd(false);
      refreshWorksheetsList(); // Llama a la función para recargar el componente WorksheetsList
    }
  };

  const codeChangeHandler = (event) => {
    const inputValue = event.target.value;
    const regex = /^[0-9]*$/;

    if (regex.test(inputValue)) {
      setEnteredCode(inputValue);
    }
  };

  return (
    <Fragment>
      <Dialog
        open={openAdd}
        hasCloseBtn={false}
        title={t('TEXT.MOBILESHEET_CODE')}
        width={300}
        content={
          <TextField
            label={t('LABEL.CODE')}
            name='mobileSheetCode'
            size='small'
            fullWidth
            value={enteredCode}
            onChange={codeChangeHandler}
          />
        }
        actions={
          <Box display='flex' alignItems='center'>
            <Box mr={1}>
              <Button variant='contained' onClick={() => setOpenAdd(false)} color='error'>
                {t('BUTTON.CANCEL')}
              </Button>
            </Box>
            <Button variant='contained' onClick={addMobileSheet}>
              {t('BUTTON.OK')}
            </Button>
          </Box>
        }
      />
      <AppBar position='static'>
        <Toolbar className={classes.header}>
          <Box>
            <Hidden lgUp>
              <IconButton onClick={() => handleShowMenu((prevState) => !prevState)}>
                <MenuIcon className={classes.icon}/>
              </IconButton>
            </Hidden>
          </Box>
          <Box>
            <img src={Logo} alt='logoxs' width={200} />
          </Box>
          <IconButton onClick={() => setOpenAdd(true)}>
            <AddCircleOutlineOutlined className={classes.icon}/>
          </IconButton>
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};

export default Header;
