import React, { Fragment, useEffect, useState } from 'react'
import Card from '@components/Card'
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material'
import EmailConfiguration from '../CycleTypesJourneysTabsSections/EmailConfiguration'
import TargetConfiguration from '../CycleTypesJourneysTabsSections/TargetConfiguration'
import WorksheetConfiguration from '../CycleTypesJourneysTabsSections/WorksheetConfiguration'
import MobileSheetConfiguration from '../CycleTypesJourneysTabsSections/MobileSheetConfiguration'
import PreparationConfiguration from '../CycleTypesJourneysTabsSections/PreparationConfiguration'
import servicePreparation from '@services/servicePreparation'
import { useTranslation } from 'react-i18next'

function TabPanel (props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  )
}

const SectionJourneysTabs = props => {
  const { t } = useTranslation()
  const [value, setValue] = useState(0)
  const { formData } = props
  const { cycleTypeJourneys } = formData
  const [preparations, setPreparations] = useState()
  
  useEffect(() => {
    servicePreparation.getPreparations({ search: [] }).then(response => {
      if (!response.error) {
        // const noPreparation = [{idPreparation: null, preparationLocale: [], preparationName: t('CONFIG_JOURNEYS.PREPARATION.NOPREPARATION')}]
        // const finalPreparationList = noPreparation.concat(response.data);
        setPreparations(response.data)
      }
    })
  }, [])

  useEffect(() => {
    if (formData?.cycleTypeJourneys && value !== 0) {
      const existIndex = cycleTypeJourneys[value]
      !existIndex && setValue(0)
    }
  }, [formData.cycleTypeJourneys, cycleTypeJourneys, value])

  
  const getNewJourneys = (newValues, key) => {
    const newJourneys = formData.cycleTypeJourneys.map((journey, i) => {
      if (i === value) {
        return {
          ...journey,
          [key]: newValues
        }
      }
      return journey
    })
    return newJourneys
  }

  if (cycleTypeJourneys.length === 0) {
    return (
      <Typography pt={2} fontWeight='bold'>
        {t('CYCLE_TYPES.NO_JOURNEYS_SELECTED')}
      </Typography>
    )
  }

  return (
    <Fragment>
      <Tabs
        variant='scrollable'
        textColor='primary'
        value={value}
        selectionFollowsFocus
        indicatorColor='primary'
        allowScrollButtonsMobile
        sx={{
          borderBottom: 1,
          borderColor: 'divider'
        }}
        onChange={(_, newValue) => {
          setValue(newValue)
        }}
        aria-label='basic tabs example'
      >
        {cycleTypeJourneys.map((_, i) => {
          return (
            <Tab
              key={`item${i}`}
              id={`simple-tab-${i}`}
              aria-labelledby={`simple-tab-${i}`}
              label={`${t('CYCLE_TYPES.JOURNEY')} ${i + 1}`}
              sx={{
                '&.Mui-selected': {
                  color: 'white',
                  bgcolor: 'grey.main'
                }
              }}
            />
          )
        })}
      </Tabs>

      {cycleTypeJourneys.map((journey, index) => {
        return (
          <TabPanel value={value} index={index} key={index}>
            <Grid container spacing={2} mt={0}>
              <Grid item lg={6} xs={12}>
                <Card fullHeight>
                  <EmailConfiguration
                    {...props}
                    index={index}
                    journey={journey}
                    getNewJourneys={getNewJourneys}
                  />
                </Card>
              </Grid>

              <Grid item lg={6} xs={12}>
                <Card fullHeight>
                  <TargetConfiguration
                    {...props}
                    index={index}
                    journey={journey}
                    getNewJourneys={getNewJourneys}
                  />
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={0}>
              <Grid item lg={3} xs={12}>
                <Card fullHeight>
                  <WorksheetConfiguration
                    {...props}
                    index={index}
                    journey={journey}
                    getNewJourneys={getNewJourneys}
                  />
                </Card>
              </Grid>

              <Grid item lg={3} xs={12}>
                <Card fullHeight>
                  <MobileSheetConfiguration
                    {...props}
                    index={index}
                    journey={journey}
                    getNewJourneys={getNewJourneys}
                  />
                </Card>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Card fullHeight>
                  <PreparationConfiguration
                    {...props}
                    preparations={preparations}
                    index={index}
                    journey={journey}
                    getNewJourneys={getNewJourneys}
                  />
                </Card>
              </Grid>
            </Grid>
          </TabPanel>
        )
      })}
    </Fragment>
  )
}

export default SectionJourneysTabs
