//CREA UN NUEVO OBJETO DE CICLO INCLUYENDO LOS EMAILS - WORKSHEETS - TARGETS DEL TIPO DE CICLO SELECCIONADO EN EL CICLO, CON LOS KEYS ACTUALIZADAS A LA API DE CICLOS
export const transformCycleTypeJourneysToCyclesJourneys = cycleTypes => {
  const { cycleTypeJourneys } = cycleTypes

  const newObject = cycleTypeJourneys.map((journey,index) => {
      console.log(journey);
    const {
      cycleTypeJourneyMailings,
      cycleTypeJourneyWorkSheets,
      cycleTypeJourneyMobileSheets,
      cycleTypeJourneyTargets,
      cycleTypeJourneyPIdPreparation,
      cycleTypeJourneyDIdPreparation,
      cycleTypeJourneyTargetsNum
    } = journey
    const newCycleEmails = cycleTypeJourneyMailings.map(mail => {
      const transformEmails = {
        // idCycleJourneysMailing: mail.idCycleTypesJourneysMailing,
        // idCycleJourney: mail.idCycleTypeJourney,
        idMailing: mail.idMailing,
        mailingDays: mail.mailingDays,
        sendToParatrooper: mail.sendToParatrooper
      }
      return transformEmails
    })

    const newCycleWorkSheets = cycleTypeJourneyWorkSheets.map(workSheet => {
      const transformWorkSheets = {
        //  idCycleJourneysWorkSheet: workSheet.idCycleTypeJourneysWorkSheet,
        // idCycleJourney: workSheet.idCycleTypeJourney,
        idWorkSheet: workSheet.idWorkSheets
      }
      return transformWorkSheets
    })
    const newCycleMobileSheets = cycleTypeJourneyMobileSheets.map(workSheet => {
      const transformWorkSheets = {
        //  idCycleJourneysWorkSheet: workSheet.idCycleTypeJourneysWorkSheet,
        // idCycleJourney: workSheet.idCycleTypeJourney,
        idMobileSheets: workSheet.idMobileSheets
      }
      return transformWorkSheets
    })
    const newCycleTargets = cycleTypeJourneyTargets.map(target => {
      const transformTargets = {
        //   idCycleJourneysTarget: target.idCycleTypeJourneysTarget,
        // idCycleJourney: target.idCycleTypeJourney,
        targetIdParent: target.targetIdParent,
        idTarget: target.idTarget
      }
      return transformTargets
    })
    /*const newCycleTypeJourneyPreparation = cycleTypeJourneyPreparation.map(
      preparation => {
        const transformPreparation = {
          cycleTypeJourneyPreparationLocale:
            preparation.cycleTypeJourneyPreparationLocale.map(locale => ({
              idLanguage: locale.idLanguage,
              preparationBody: locale.preparationBody,
              preparationSubject: locale.preparationSubject
            }))
        }
        return transformPreparation
      }
    )*/

      var tmpModality = 1;
      //console.log(index + " Modalidad del ciclo -> " + cycleTypes.cycleTypeMode);
      //En funci�n de la modalidad del tipo de ciclo establecemos las modalidades de las jornadas.
      switch (cycleTypes.cycleTypeMode) {
          case 0: //Si el tipo de ciclo es General
              tmpModality = 1;
              break;
          case 3: //Si el tipo de ciclo es mixto.
              //Ponemos presenciales la primera y la �ltima jornada.
              if (index == 0 || index == cycleTypes.cycleTypeJourneys.length -1)
                  tmpModality = 1;
              else
                  tmpModality = 2;
              break;
          default: //En cualquier otro caso la modalidad de las jornadas coincide con la del tipo de ciclo.
              tmpModality = cycleTypes.cycleTypeMode;
      }
      //console.log(index +" Ponemos modalidad a la jornada -> " + tmpModality);

      //En funci�n de la modalidad de la jornada cogemos una preparaci�n u otra de las preparaciones configuradas en el tipo de ciclo.
      var idPreparation;
      switch (tmpModality) {
          case 1:
              //console.log("Cogemos la preparaci�n de PRESENCIAL");
              idPreparation = cycleTypeJourneyPIdPreparation;
              break;
          case 2:
              //console.log("Cogemos la preparaci�n de VIRTUAL");
              idPreparation = cycleTypeJourneyDIdPreparation;
              break;
          default:
              //console.log("Cogemos la preparaci�n de PRESENCIAL **");
              idPreparation = cycleTypeJourneyPIdPreparation;
      }
    return {
      // cycleJourneyDateFrom: moment.utc().format(),
      // cycleJourneyDateTo: moment.utc().format(),
      idCycleTypeJourney: journey.idCycleTypeJourney,
      idPreparation: idPreparation,
      idCycleJourneyModality: tmpModality,
      cycleTarget: false,
      cycleJourneyParticipants: [],
      cycleJourneyMailings: newCycleEmails,
      cycleJourneyWorkSheets: newCycleWorkSheets,
      cycleJourneyMobileSheets: newCycleMobileSheets,
      cycleJourneyTargets: newCycleTargets,
      cycleJourneySessions: [
        { cycleJourneyDateFrom: '', cycleJourneyDateTo: '' }
      ],
      cycleJourneyPrivate: false, // si es true lo pinto en jornadas SIP
      cycleTargetMinNum: journey.cycleTypeJourneyTargetsNum
    }
  })

  return newObject
}
