import { AuthContext } from '@context/auth/AuthContext';
import Home from '@areas/KitOW/Home'; 
import WorksheetsList from '../sections/worksheetsList/WorksheetsList';
import WorksheetsFavorite from '../sections/worksheetsFavorite/WorksheetsFavorite';
import Worksheet from '../sections/worksheet/Worksheet'
import LostPassword from '@login/LostPassword';
import NewPassword from '@login/NewPassword';
import LoginKitOWPage from '@login/LoginKitOWPage';
import { useContext } from 'react';
import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
} from 'react-router-dom';


const KitOWRoutes = () => {
    const PrivateRoute = () => {
        const { status } = useContext(AuthContext);
        return status === 'authenticated' ? <Home /> : <Navigate to='/' />;
    };
    const { status } = useContext(AuthContext);

    return (
        <Router>
            <Routes>
            <Route
                    path='/'
                    element={
                        status === 'authenticated' ? (
                            <Navigate to='/workSheetsListKitOW' />
                        ) : (
                            <LoginKitOWPage />
                        )
                    }
                />
                <Route path='*' element={<Navigate to='/' />} />
                <Route path='/lostPassword' element={<LostPassword />} />
                <Route path='/newPassword' element={<NewPassword />} />
                <Route element={<PrivateRoute />}>
                <Route path='workSheetsListKitOW' element={<WorksheetsList />} />
                <Route path='worksheetsFavoriteKitOW' element={<WorksheetsFavorite />} />
                <Route path='workSheetKitOW/:idWorksheet' element={<Worksheet />} />
                </Route>
            </Routes>
        </Router>
    );
};

export default KitOWRoutes;