import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import { Grid } from '@mui/material';
import { useOWStyles } from '@styles/ottowalter.style';

const Home = () => {
    const owclass = useOWStyles();

    return (
        <>
            <Grid container className={owclass.backGray}>
                <Grid item xs={12} style={{ height:'70px'}}
                className={owclass.grayShadowWhite}
                >
                    <Header />
                </Grid>
                <Grid className={owclass.outletZoneConsultores} style={{padding:'0px'}} item xs={12} >
                    <Outlet />
                </Grid>
               
            </Grid>
        </>
    );
};

export default Home;