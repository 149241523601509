import React from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button, Hidden } from '@mui/material';
import { useStyles } from '@styles/form.style';
import AddIcon from '@mui/icons-material/Add'

const AddButton = ({ text, handleOnclick }) => {
    const classes = useStyles();
    return (
        <div>
            <Hidden smDown>
                <Button variant='contained' startIcon={<AddIcon />} onClick={handleOnclick}>
                    {text}
                </Button>
            </Hidden>
            <Hidden smUp>
                <Button
                    variant='contained'
                    onClick={handleOnclick}
                    className={classes.buttonIcon}>
                    <AddRoundedIcon color='white' />
                </Button>
            </Hidden>
        </div>
    );
};

export default AddButton;