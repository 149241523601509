import React, { useState } from 'react';
import AddButton from '@components/AddButton';
import DynamicTable from '@components/DynamicTable';
import HeaderTitle from '@components/HeaderTitle';
import { EditRounded } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useCommonStyles } from '@styles/common.style';
import ValuationDetail from './ValuationDetail';
import { useTranslation } from 'react-i18next';
import { snackActions } from '@helpers/snackbarUtils';

const Valuations = () => {
    const classes = useCommonStyles();
    const { t } = useTranslation();

    const [openValuationDetail, setOpenValuationDetail] = useState({
        valuationSelected: null,
        openDetail: false,
    });
    const [key, setKey] = useState(0);
    const { openDetail, valuationSelected } = openValuationDetail;

    const ORDER = [
        {
            "orderField": "valuationCode",
            "orderAsc": true
        }
    ];
    const COLUMNS = [
        {
            accessor: '',
            Header: 'Info',
            filterable: false,
            hideHeader: true,
            width: '5%',
            Cell: ({ row: { values } }) => (
                <Box display='flex' flexDirection='row'>
                    <Tooltip title={t('LABEL.EDIT')}>
                        <IconButton
                            aria-label='info'
                            color='icon_blue'
                            style={{
                                padding: '0px',
                                marginRight: 15,
                            }}
                            onClick={() => {
                                handleShowDetail({
                                    valuationCode: values.valuationCode,
                                    idValuation: values.idValuation,
                                });
                            }}>
                            <EditRounded fontSize='inherit' />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },
        {
            accessor: 'idValuation',
            Header: t('LABEL.ID'),
            width: '5%',
            display: 'none',
        },
        {
            accessor: 'valuationCode',
            Header: t('LABEL.VALUATIONS'),
        },
    ];

    const handleShowDetail = item => {
        const userSession = JSON.parse(sessionStorage.getItem('userSession'))
        if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 4)) {
            setOpenValuationDetail({ openDetail: true, valuationSelected: item });
        } else {
            snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'));
        }  
    };

    const handleReload = () => {
        setKey(Math.random());
    };
    return (
        <div className={classes.container}>
            {openDetail && (
                <ValuationDetail
                    handleReload={handleReload}
                    data={valuationSelected}
                    open={openDetail}
                    handleClose={() => {
                        setOpenValuationDetail({
                            openDetail: false,
                            valuationSelected: null,
                        });
                    }}
                />
            )}
            <HeaderTitle
                title={t('TEXT.TITLE_VALUATIONS')}
                subtitle={t('TEXT.SUBTITLE_VALUATIONS')}>
                <AddButton
                    text={t('BUTTON.NEW_VALUATION')}
                    handleOnclick={() =>{
                        const userSession = JSON.parse(sessionStorage.getItem('userSession'))
                        if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 4)) {
                            setOpenValuationDetail({
                                ...openValuationDetail,
                                openDetail: true,
                            })
                        } else {
                            snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'));
                        }  
                    }}
                />
            </HeaderTitle>
            <DynamicTable
                key={key}
                columns={COLUMNS}
                endpoint='Valuations/List'
                isGlobalSearch={true}
                isColumnsSearch={false}
                isOrdered={true}
                orderColumn={ORDER}
            />
        </div>
    );
};

export default Valuations;