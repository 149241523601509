import React, { useState, useRef, useEffect } from 'react'
import moment from 'moment'
import Dialog from '@components/Dialog'
import FormActions from '@components/FormActions'
import HeaderTitle from '@components/HeaderTitle'
import { useForm } from '@hooks/useForm'
import {
  Grid,
  TextField,
  Typography,
  Box,
  Button,
  MenuItem,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material'

import serviceCycleJourneys from '@services/serviceCycleJourneys'

import serviceCycleTypes from '@services/serviceCycleTypes'
import serviceConsultants from '@services/serviceConsultants'
import { useCommonStyles } from '@styles/common.style'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Card from '@components/Card'
import { cycleToCycleType } from '@helpers/common'

import ParticipantsCycleJourney from './ParticipantsCycleJourney'
import EmailConfiguration from './CycleTypesJourneysTabsSections/EmailConfiguration'
import TargetConfiguration from './CycleTypesJourneysTabsSections/TargetConfiguration'
import WorksheetConfiguration from './CycleTypesJourneysTabsSections/WorksheetConfiguration'
import MobileSheetConfiguration from './CycleTypesJourneysTabsSections/MobileSheetConfiguration'
import PreparationConfiguration from './CycleTypesJourneysTabsSections/PreparationConfiguration'
import { useDataCycleTypes } from '@hooks/useDataCycleTypes'
import { cycleTypeToCycle } from '@helpers/common'
import Editor from "@components/CkEditor";
import Loading from '@components/Loading'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckBox from '@components/CheckBox'


const JourneySerDetail = () => {
  const {
    state: { data: JOURNEY_DATA, type: TYPE, idCycleJourney: ID_JOURNEY }
  } = useLocation()
  const navigate = useNavigate()
  const classes = useCommonStyles()
  const { t } = useTranslation()
  const idCycleTypeRef = useRef()
  const idCycleJourneyRef = useRef()
  const [cycleTypeData, setCycleTypeData] = useState({})
  const [journey, setJourney] = useState({})
  const [openDialog, setOpenDialog] = useState(false)
    
  const { formData, setFormData, handleChange } = useForm(JOURNEY_DATA)

  const {
    emailList,
    allEmails,
    targetList,
    worksheetList,
    mobilesheetList,
    categories,
    loading,
    setLoading,
    showEmailItems,
    showEmailModal,
    setShowEmailModal
  } = useDataCycleTypes(TYPE, setFormData, formData)
  
  const props = {
    formData,
    setFormData,
    handleChange,
    emailList,
    targetList,
    worksheetList,
    mobilesheetList,
    showEmailItems,
    categories,
    allEmails,
    isUpdate: TYPE === 'UPDATE',
    showEmailModal,
    setShowEmailModal
  }
  const HEADER_TITLE =
    TYPE === 'NEW' ? t('TEXT.DETAIL_SER') : t('TEXT.DETAIL_SER')
  const HEADER_SUBTITLE =
    TYPE === 'NEW' ? t('COMPANY.NEW_SUBTITLE') : t('COMPANY.UPDATE_SUBTITLE')

  const disabledSaveButton = JOURNEY_DATA === formData && formData === journey
  const disabledInput = TYPE === 'INFO'
  const columnSearch = [
    {
      SearchField: 'idCycleJourney',
      SearchValue: ID_JOURNEY,
      SearchMode: 'EqualTo'
    }
  ]

  const [cycleTypesList, setcycleTypesList] = useState([])
  const [journeyList, setjourneyList] = useState([])

  const [consultantsList, setConsultantsList] = useState([]);
  const [isConsultantValid, setIsConsultantValid] = useState(true);
  
  const [expanded, setExpanded] = useState("");

  const handleAccordionToggle = (panelId) => (e, isExpanded) => {
    setExpanded(isExpanded ? panelId : "");
  };

  const selectStyle = {
      color: !isConsultantValid  ? 'darkred' : 'black'
  };

  useEffect(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      ...JOURNEY_DATA,
      serOffer: JOURNEY_DATA.serOffer || "",
      isDateBlocked: JOURNEY_DATA.isDateBlocked || false
    }));
  }, [JOURNEY_DATA]);
  

  // Cargamos los tipos de ciclo
  useEffect(() => {
      const fetchData = async () => {
          const cycleTypesList = await fetchCycleTypes();
          setcycleTypesList(cycleTypesList);
      };

      fetchData();
  // }, [formData]);
  }, []);



  // Cargamos los consultores
  useEffect(() => {
      const fetchData = async () => {
          const data = await fetchConsultants();
          setConsultantsList(data);

          const foundConsultant = data.find(
              consultant => consultant.idConsultant === formData.idConsultant
          );
          setIsConsultantValid(!!foundConsultant);
      };

      fetchData();
  }, [formData.idConsultant]);

  useEffect(() => {}, [formData])
  // Al cargar los tipos de ciclo si hay datos cargamos sus jornadas
  useEffect(() => {
    if (formData.idCycleType) {
      chargeJourney(formData.idCycleType)
    }
  }, [cycleTypesList])

  // Cuando cambia el ciclo cargamos sus datos y actualizamos jornadas
  useEffect(() => {
    const fetchData = async () => {
      if (formData.idCycleType) {
        await chargeJourney(formData.idCycleType);
        const { data } = await serviceCycleTypes.getCycleTypeDetail(formData.idCycleType);
        setCycleTypeData(data);
  
        // Aquí se asegura que los datos correctos se establezcan
        if (formData.cycleJourneyIdRecovery && data.cycleTypeJourneys) {
          const journeyData = data.cycleTypeJourneys.find(
            journey => journey.idCycleJourney === formData.cycleJourneyIdRecovery
          );
          setFormData(prevFormData => ({
            ...prevFormData,
            ...journeyData
          }));
          setJourney(journeyData || {});
        }
      }
    };
  
    fetchData();
  }, [formData.idCycleType, formData.cycleJourneyIdRecovery]);
  
  

  /* esto debería sobrar, creo */
  useEffect(() => {
    if (
      false &&
      formData?.cycleJourneyIdRecovery &&
      cycleTypeData.cycleTypeJourneys
    ) {
      setJourney(
        cycleTypeData.cycleTypeJourneys[formData.cycleJourneyIdRecovery - 1]
      )
    }
  }, [formData.cycleJourneyIdRecovery, cycleTypeData])

  // Cargar tipos de ciclo
  const fetchCycleTypes = async () => {
    const { data } = await serviceCycleTypes.getCycleTypesList({
      //pageSize: 20,
      pageNumber: 0,
      globalSearch: '',
      search: [],
      order: [
        {
          orderField: 'cycleTypeName',
          orderAsc: true
        }
      ]
    })

    return data
  }

  const handleChangeURL = e => {
    setFormData({ ...formData, cycleJourneyDetailsURL: e.target.value });
  };
  

  // Cargar consultores
  const fetchConsultants = async () => {
      const { data } = await serviceConsultants.getConsultantsList({
          pageSize: 50,
          pageNumber: 0,
          globalSearch: '',
          search: [
              {
                  searchField: 'consultantActive',
                  searchMode: 'equalTo',
                  searchValue: 1,
              },
          ],
          order: [
              {
                  orderField: 'consultantName',
                  orderAsc: true,
              },
          ],
      });

      return data;
  };



  // Fin funciones para select con autobúsqueda empresas
  const handleChangeSelect = async e => {
    idCycleTypeRef.current.value = e.target.value
    idCycleTypeRef.current.name = e.target.name
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  // Ponemos en la lista del select las jornadas
  const chargeJourney = idCycleType => {
    let cycleType = cycleTypesList.filter(x => x.idCycleType == idCycleType)
    if (cycleType.length > 0) {
      let num = cycleType[0].cycleTypeJourneysNum
      let journeys = []
      for (let i = 1; i <= num; i++) {
        journeys.push({ idCycleJourney: i })
      }
      setjourneyList(journeys)
    }
  }

  // Los datos de la jornada no lo guardamos en el formdata sino en journey
  const handleChangeJourney = e => {
    const { name, value, type, checked } = e.target;
    
    const updatedValue = type === 'checkbox' ? checked : value;
    
    if (name === 'cycleJourneyIdRecovery') {
      const selectedJourneyId = value;
      idCycleJourneyRef.current.value = selectedJourneyId;
      idCycleJourneyRef.current.name = name;
    
      if (cycleTypeData.cycleTypeJourneys) {
        const selectedJourney = cycleTypeData.cycleTypeJourneys[selectedJourneyId - 1];
        
        // Verificación de existencia de selectedJourney antes de acceder a sus propiedades
        if (selectedJourney) {
          const newFormData = {
            ...formData,
            ...selectedJourney,
            [name]: selectedJourneyId,
            idCycleTypeJourney: selectedJourney.idCycleTypeJourney || formData.idCycleTypeJourney,
            idPreparation: formData.idCycleJourneyModality === 1 
              ? selectedJourney.cycleTypeJourneyPIdPreparation 
              : formData.idCycleJourneyModality === 2 
                ? selectedJourney.cycleTypeJourneyDIdPreparation 
                : null
          };
  
          // Verificar y asegurar idPreparation
          if (!newFormData.idPreparation) {
            newFormData.idPreparation = ''; // Asignar valor por defecto si es necesario
          }
  
          setFormData(newFormData);
          setJourney(newFormData);  // Actualiza el estado journey también
        } else {
          console.error(`Journey with id ${selectedJourneyId} not found.`);
        }
      } else {
        console.error('No journey data available in cycleTypeData.');
      }
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: updatedValue
      }));
    }
  };

  const handleModalityChange = (e) => {
    // Llamada al handleChange original de useForm para manejar cambios generales
    handleChange(e);
  
    // Lógica adicional para manejar idCycleJourneyModality
    if (e.target.name === 'idCycleJourneyModality') {
      let selectedPreparation = '';
      if (e.target.value === 1) {
        selectedPreparation = formData.cycleTypeJourneyPIdPreparation;
      } else if (e.target.value === 2) {
        selectedPreparation = formData.cycleTypeJourneyDIdPreparation;
      }
  
      setFormData(prevState => ({
        ...prevState,
        idPreparation: selectedPreparation
      }));
    }
  };
  
  
  

  const handleChangeDateFrom = e => {
    const newDateFrom = e.target.value;

    // Si ya hay una fecha y hora en el campo cycleJourneyDateTo, conservamos la hora
    let newDateTo = formData.cycleJourneyDateTo;
    if (newDateTo) {
        const [hour, minutes] = moment(newDateTo).format('HH:mm').split(':');
        const unix = new Date(newDateFrom).setHours(hour, minutes, 0);
        newDateTo = moment(unix).format('YYYY-MM-DDTHH:mm');
    }

    setFormData({
        ...formData,
        cycleJourneyDateFrom: newDateFrom,
        cycleJourneyDateTo: newDateTo // Actualizamos también la fecha de fin si es necesario
    });
  }


  const handleChangeDateTo = e => {
    const [hour, minutes] = e.target.value.split(':')
    const unix = new Date(formData.cycleJourneyDateFrom).setHours(
      hour,
      minutes,
      0
    )
    const newDate = moment(unix).format('YYYY-MM-DDTHH:mm')

    setFormData({ ...formData, cycleJourneyDateTo: newDate })
  }

  const handleSubmit = type => {
    setLoading(true);
    let newJourney 
    if (type === 'UPDATE') {
      newJourney = { ...formData, 'idCycleJourney': ID_JOURNEY,  ...journey };
    } else {
      newJourney = { ...formData, ...journey };
    }
    newJourney = cycleTypeToCycle(newJourney);
  
    switch (type) {
      case 'NEW':
        serviceCycleJourneys.newCycleJourney(newJourney).then(response => handleResponse(response, 'SAVE'));
        break;
      case 'UPDATE':
        serviceCycleJourneys.updateCycleJourney(newJourney).then(response => handleResponse(response, 'SAVE'));
        break;
      case 'DELETE':
        serviceCycleJourneys.deleteCycleJourney(newJourney).then(response => handleResponse(response, 'DELETE'));
        break;
      default:
        break;
    }
  };
  

  const handleResponse = (response, actionType) => {
    setLoading(false);
    if (!response.error) {
      if (actionType === 'DELETE') {
        navigate('/journeysser');
      } else if (actionType === 'SAVE' && TYPE === 'NEW') {
        const userSession = JSON.parse(sessionStorage.getItem('userSession'))
        const idCycleJourney = response.data.idCycleJourney
        const type = 'UPDATE'
        if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 4)) {
          serviceCycleJourneys
            .getCycleJourneyDetails(idCycleJourney)
            .then(response => {
              if (!response.error) {
                response.data.idCycleJourney = idCycleJourney
                response.data.showJourney = true
                navigate(`/journeySerDetail`, {
                  state: {
                    data: cycleToCycleType(response.data),
                    type,
                    idCycleJourney
                  }
                })
              }
            })
       }
    } else {
      // Manejar el error aquí
    }
    }
  }
  

  const handleChangeCKEditor = (value, name) => {
      setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    if (!ID_JOURNEY) {
      // Si ID_JOURNEY no está definido se expande el acordeón
      setExpanded('panelNewJourney');
    }
  }, []);
  

  if (loading) {
    return <Loading />
  }

  return (
    <div className={classes.container}>
      <Dialog
        open={openDialog}
        hasCloseBtn={false}
        height={200}
        title={t('CONFIRM.TITLE_SER')}
        subtitle={t('CONFIRM.UNRECOVERY_DELETE2')}
        close={() => setOpenDialog(false)}
        actions={
          <Box display='flex' alignItems='center'>
            <Box mr={1}>
              <Button
                variant='contained'
                onClick={() => setOpenDialog(false)}
                color='error'
              >
                {t('BUTTON.CANCEL')}
              </Button>
            </Box>
            <Button variant='contained' onClick={() => handleSubmit('DELETE')}>
              {t('BUTTON.DELETE')}
            </Button>
          </Box>
        }
      />

      <HeaderTitle title={HEADER_TITLE} subtitle={HEADER_SUBTITLE}>
        {!disabledInput && (
          <FormActions
            show={TYPE === 'UPDATE'}
            handleDelete={() => setOpenDialog(true)}
            handleUndo={() => setFormData(JOURNEY_DATA)}
            handleSave={() => handleSubmit(TYPE)}
            disabledButton={disabledSaveButton}
            urlBack='/journeysser'
          />
        )}
      </HeaderTitle>

      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Card fullHeight>
            <Grid container spacing={2}>
              <Grid item lg={12} xs={12}>
                <Typography color='primary' variant='subtitle1' mb={1}>
                  {t('CONFIG_JOURNEYS.JOURNEYSER.RECOVERY_JOURNEY')}
                </Typography>
                <Grid container spacing={1}>
                  <Grid item md={12}>
                    <Typography variant='caption' component='div'>
                      {t('CYCLES.CYCLE_TYPE')}*
                    </Typography>
                    <Box display='flex' alignItems='center' flexWrap='wrap'>
                      <Select
                        error={!formData?.idCycleType}
                        ref={idCycleTypeRef}
                        fullWidth
                        size='small'
                        name='idCycleType'
                        value={formData?.idCycleType || ''}
                        onChange={handleChangeSelect}
                      >
                        {cycleTypesList
                          .filter(cycleType => cycleType.isVisible || cycleType.idCycleType === formData?.idCycleType)
                          .map((cycleType, i) => (
                          <MenuItem
                            key={cycleType.idCycleType}
                            value={cycleType.idCycleType}
                          >
                            {cycleType.cycleTypeCode}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Grid>

                  <Grid item md={12}>
                    <Typography variant='caption' component='div'>
                      {t('CONFIG_JOURNEYS.JOURNEYSER.RECOVERY_JOURNEY')}*
                    </Typography>
                    <Box display='flex' alignItems='center' flexWrap='wrap'>
                      <Select
                        error={!formData?.cycleJourneyIdRecovery}
                        ref={idCycleJourneyRef}
                        fullWidth
                        size='small'
                        name='cycleJourneyIdRecovery'
                        value={formData?.cycleJourneyIdRecovery || ''}
                        onChange={handleChangeJourney}
                      >
                        {journeyList.map((journey, i) => (
                          <MenuItem
                            key={journey.idCycleJourney}
                            value={journey.idCycleJourney}
                          >
                            {t('CYCLE_TYPES.JOURNEY') + journey.idCycleJourney}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item sm={12} md={6}>
                        <Typography variant='caption'>
                          {t('CYCLES.JOURNEYS.DATETIME_START')}*
                        </Typography>
                        <TextField
                          error={!formData?.cycleJourneyDateFrom}
                          fullWidth
                          id='datetime-local'
                          size='small'
                          type='datetime-local'
                          value={
                            formData?.cycleJourneyDateFrom
                              ? moment(formData?.cycleJourneyDateFrom).format(
                                  'YYYY-MM-DDTHH:mm'
                                )
                              : ''
                          }
                          onChange={handleChangeDateFrom}
                        />
                      </Grid>
                      <Grid item sm={12} md={6}>
                        <Typography variant='caption'>
                          {t('CYCLES.JOURNEYS.TIME_END')}*
                        </Typography>
                        <TextField
                          error={
                            !formData?.cycleJourneyDateTo ||
                            moment(formData?.cycleJourneyDateTo).isBefore(
                              formData?.cycleJourneyDateFrom
                            )
                          }
                          fullWidth
                          size='small'
                          id='time'
                          type='time'
                          name='cycleJourneyDateTo'
                          value={
                            formData?.cycleJourneyDateTo
                              ? moment(formData?.cycleJourneyDateTo).format(
                                  'HH:mm'
                                )
                              : ''
                          }
                          InputLabelProps={{
                            shrink: true
                          }}
                          onChange={handleChangeDateTo}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item lg={6} xs={12}>
                        <Typography variant='caption'>
                          {t('CYCLES.JOURNEYS.JOURNEY_TYPE')}*
                        </Typography>
                        <Box display='flex' alignItems='center' flexWrap='wrap'>
                          <Select
                            fullWidth
                            error={!formData?.idCycleJourneyModality}
                            size='small'
                            name='idCycleJourneyModality'
                            value={formData?.idCycleJourneyModality || ''}
                            onChange={handleModalityChange}
                          >
                            {[
                              { title: t('LABEL.ONSITE'), id: 1 },
                              { title: t('LABEL.DIGITAL'), id: 2 }
                            ].map((journeyType, i) => (
                              <MenuItem
                                key={journeyType.id}
                                value={journeyType.id}
                              >
                                {journeyType.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <Typography variant='caption'>
                          {t('CYCLES.JOURNEYS.CONSULTANT')}*
                        </Typography>
                        <Box display='flex' alignItems='center' flexWrap='wrap'>
                            <Select
                                error={!isConsultantValid}
                                fullWidth
                                size='small'
                                name='idConsultant'
                                value={formData?.idConsultant || ''}
                                id={`idConsultant`}
                                onChange={handleChange}
                                style={selectStyle}
                            >
                                {consultantsList.map(consultant => (
                                    <MenuItem
                                        key={consultant.idConsultant}
                                        value={consultant.idConsultant}
                                    >
                                        {consultant.consultantName} {consultant.consultantSurname}
                                    </MenuItem>
                                ))}
                                {!isConsultantValid && (
                                    <MenuItem
                                        key={formData.idConsultant}
                                        value={formData.idConsultant}
                                        style={selectStyle}
                                    >
                                      {formData.consultantName} {formData.consultantSurname} ({t('CONSULTANT.INACTIVE')})
                                    </MenuItem>
                                )}
                            </Select>
                        </Box>
                      </Grid>

                      <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                        {/* Checkbox Fechas Bloqueadas */}
                        <Grid item xs={5} sm={4}>
                          <CheckBox
                            id='isDateBlocked'
                            sx={{ minWidth: '100%', display: 'flex', justifyContent: 'center', height: '3em', alignItems: 'center' }}
                            label={t('CYCLES.BLOCKED')}
                            name='isDateBlocked'
                            value={formData.isDateBlocked}
                            checked={!!formData.isDateBlocked}
                            onChange={e => {
                              handleChange(e)
                              setFormData(prevState => ({ ...prevState, isDateBlocked: e.target.checked }))
                            }}
                          />
                        </Grid>

                        {/* Checkbox Activar Objetivos */}
                        <Grid item xs={5} sm={4} marginLeft={'10px'}>
                          <CheckBox
                            id='activateTargets'
                            sx={{ minWidth: '100%', display: 'flex', justifyContent: 'center', height: '3em', alignItems: 'center' }}
                            label={t('BUTTON.ACTIVATE_TARGETS')}
                            name='cycleTarget'
                            value={formData.cycleTarget || false}
                            checked={!!formData.cycleTarget}
                            onChange={handleChangeJourney}
                            style={{
                              minWidth: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Accordion
                        expanded={expanded === 'panelNewJourney'}
                        onChange={handleAccordionToggle('panelNewJourney')}
                        sx={{ width: '100%', marginTop: '10px' }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panelNewJourneybh-content"
                          id="panelNewJourneybh-header"
                          sx={{
                            flexDirection: 'row-reverse',
                            backgroundColor: 'grey.main',
                            color: 'white',
                            minHeight: 48,
                            '&.Mui-expanded': {
                              minHeight: 48,
                            },
                            '& .MuiAccordionSummary-content': {
                              margin: '8px',
                              '&.Mui-expanded': {
                                margin: '8px',
                              },
                            },
                            '& .MuiAccordionSummary-expandIconWrapper': {
                              marginRight: '-8px',
                              transform: 'rotate(-90deg)',
                              '&.Mui-expanded': {
                                transform: 'rotate(0deg)',
                              },
                            },
                          }}
                        >
                          <Typography>{t('TEXT.MORE_INFO')}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>


                      {formData?.idCycleJourneyModality == 1 && (
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item lg={6} xs={12}>
                              <Typography variant='caption'>
                                {t('CYCLES.JOURNEYS.ADDRESS')}
                              </Typography>
                              <Editor
                                    name='cycleJourneyAddress'
                                    data={formData.cycleJourneyAddress || ''}
                                    onChange={(value) =>handleChangeCKEditor(value, 'cycleJourneyAddress')}
                                    id={`address`}
                              />
                              {/* <TextField
                                fullWidth
                                name='cycleJourneyAddress'
                                value={formData?.cycleJourneyAddress || ''}
                                size='small'
                                id={`address`}
                                onChange={handleChange}
                                inputProps={{ maxLength: 100 }}
                              />*/}
                            </Grid>

                            <Grid item lg={6} xs={12}>
                              <Grid container spacing={1}>
                                <Grid item lg={6} xs={12}>
                                  <Typography variant='caption'>
                                    {t('CYCLES.JOURNEYS.CITY')}
                                  </Typography>

                                  <TextField
                                    fullWidth
                                    name='cycleJourneyCityCode'
                                    value={formData?.cycleJourneyCityCode || ''} //TODO: Este no es el nombre
                                    size='small'
                                    id={`cityCode`}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 50 }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant='caption'>
                                  {t('LABEL.CITY_SHORT')}
                                </Typography>
                                <TextField
                                  fullWidth
                                  name='cycleJourneyCityShortCode'
                                  value={formData?.cycleJourneyCityShortCode || ''}
                                  size='small'
                                  id={`cityShortCode`}
                                  onChange={handleChange}
                                  inputProps={{ maxLength: 50 }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <Typography variant='caption'>
                          {formData?.idCycleJourneyModality == 1
                            ? t('CYCLES.JOURNEYS.DETAILS')
                            : t('CYCLES.JOURNEYS.PLATFORM')}
                        </Typography>

                        <TextField
                          name='cycleJourneyDetails'
                          value={formData.cycleJourneyDetails || ''} //TODO: Este no es el nombre
                          size='small'
                          id={`details`}
                          onChange={handleChange}
                          fullWidth
                          inputProps={{ maxLength: 255 }}
                        />
                      </Grid>

                      {formData.idCycleJourneyModality !== 1 && (
                        <Grid item xs={6}>
                          <Typography variant='caption'>
                            {t('CYCLES.JOURNEYS.URL')}
                          </Typography>
                          <TextField
                            name='cycleJourneyDetailsURL'
                            value={formData.cycleJourneyDetailsURL || ''}
                            size='small'
                            id='detailsURL'
                            onChange={handleChangeURL}
                            fullWidth
                          />
                        </Grid>
                      )}

                      <Grid item xs={6}>
                        <Typography variant='caption'>
                          {t('CYCLES.JOURNEYS.CONTACT')}
                        </Typography>

                        <TextField
                          name='cycleJourneyContactName'
                          value={formData.cycleJourneyContactName || ''}
                          size='small'
                          id={`contactName`}
                          onChange={handleChange}
                          fullWidth
                          inputProps={{ maxLength: 100 }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='caption'>
                          {t('CYCLES.JOURNEYS.PHONE')}
                        </Typography>

                        <TextField
                          name='cycleJourneyContactPhone'
                          value={formData.cycleJourneyContactPhone || ''}
                          size='small'
                          id={`contactPhone`}
                          onChange={handleChange}
                          fullWidth
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>

                      <Grid item lg={12} xs={12}>
                        <Typography variant='caption'>
                          {t('LABEL.POSTIT')}
                        </Typography>
                        <Editor
                            name='cycleJourneyRemarks'
                            data={formData.cycleJourneyRemarks || ''}
                            onChange={(value) =>handleChangeCKEditor(value, 'cycleJourneyRemarks')}
                            id={`cycleJourneyRemarks`}
                        />
                       {/*<TextField
                          name='cycleJourneyRemarks'
                          value={formData.cycleJourneyRemarks || ''}
                          size='small'
                          rows={6}
                          multiline
                          fullWidth
                          onChange={handleChange}
                        />*/}
                      </Grid>
                      </AccordionDetails>
                    </Accordion>

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item md={6} xs={12}>
          <Typography color='primary' variant='subtitle1' mb={1}>{t('TEXT.SUBTITLE_PARTICIPANTS')}</Typography>
          <ParticipantsCycleJourney
            data={formData}
            setData={setFormData}
            handleChange={handleChange}
          />
          <Typography color='primary' variant='subtitle1' style={{ marginTop: '24px', marginBottom: '16px' }}>
            {t('CONFIG_JOURNEYS.JOURNEYSER.SER_OFFER')}
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={formData.serOffer || ""}
            onChange={(e) => setFormData({ ...formData, serOffer: e.target.value })}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={0}>
        {formData?.cycleTypeJourneyMailings && props.emailList && (
          <Grid item lg={6} xs={12}>
            <Card fullHeight>
              <EmailConfiguration
                {...props}
                journey={formData}
                setJourney={setFormData}
              />
            </Card>
          </Grid>
        )}
        {formData?.cycleTypeJourneyTargets && props.targetList && (
          <Grid item lg={6} xs={12}>
            <Card fullHeight>
              <TargetConfiguration
                {...props}
                journey={formData}
                setJourney={setFormData}
              />
            </Card>
          </Grid>
        )}
      </Grid>

      <Grid container spacing={2} mt={0}>
        {formData?.cycleTypeJourneyWorkSheets && props.worksheetList && (
          <Grid item lg={4} xs={12}>
            <Card fullHeight>
              <WorksheetConfiguration
                {...props}
                journey={formData}
                setJourney={setFormData}
              />
            </Card>
          </Grid>
        )}
        {formData?.cycleTypeJourneyMobileSheets && props.mobilesheetList && (
          <Grid item lg={4} xs={12}>
            <Card fullHeight>
              <MobileSheetConfiguration
                {...props}
                journey={formData}
                setJourney={setFormData}
              />
            </Card>
          </Grid>
        )}

        {formData?.cycleJourneyIdRecovery && formData?.idPreparation !== undefined && ( // Condición para mostrar PreparationConfiguration
            <Grid item lg={4} xs={12}>
              <Card fullHeight>
                <PreparationConfiguration
                  {...props}
                  journey={formData}
                  setJourney={setFormData}
                />
              </Card>
            </Grid>
        )}
      </Grid>
    </div>
  )
}

export default JourneySerDetail
