import React, {useContext, useState, useEffect} from 'react'
import Dialog from '@components/Dialog'
import FormActions from '@components/FormActions'
import HeaderTitle from '@components/HeaderTitle'
import Loading from '@components/Loading'
import {ListContext} from '@context/ListContext'
import {useForm} from '@hooks/useForm'
import {Box, Button, Grid, TextField, Typography} from '@mui/material'
import serviceFiles from '@services/serviceFiles'
import {useCommonStyles} from '@styles/common.style'
import {useLocation, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import Card from '@components/Card'
import DeleteIcon from '@mui/icons-material/Delete'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import {snackActions} from '@helpers/snackbarUtils'
import DownloadIcon from '@mui/icons-material/Download'

const TemplateFilesAttachDetail = () => {
    const classes = useCommonStyles()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [openDialog, setOpenDialog] = useState(false)
    const [loadingDelayRequest, setLoadingDelayRequest] = useState(false)
    
    const [idFileAttach, setIdFileAttach] = useState(null) // Estado para idFileAttach
    let fileAttachNew = JSON.parse(sessionStorage.getItem('fileAttachNew'))

    const {
        state: {data: TEMPLATE_FILEATTACH_DATA, type: TYPE}
    } = useLocation()

    const HEADER_TITLE =
        TYPE === 'NEW' ? t('FILEATTACH.NEW_TITLE') : t('FILEATTACH.UPDATE_TITLE')
    const HEADER_SUBTITLE =
        TYPE === 'NEW'
            ? t('FILEATTACH.NEW_SUBTITLE')
            : t('FILEATTACH.UPDATE_SUBTITLE')

    const values = TYPE === 'NEW' ? {} : TEMPLATE_FILEATTACH_DATA
    const {formData, setFormData} = useForm(values)

    useEffect(() => {
        if (fileAttachNew) {
            setFormData(fileAttachNew)
            setIdFileAttach(fileAttachNew.idFileAttach) // Establecer idFileAttach
            sessionStorage.removeItem('fileAttachNew')
        } else if (TYPE === 'UPDATE') {
            setFormData(TEMPLATE_FILEATTACH_DATA)
            setIdFileAttach(TEMPLATE_FILEATTACH_DATA.idFileAttach) // Establecer idFileAttach
        }
    }, [])

    const disabledSaveButton = TEMPLATE_FILEATTACH_DATA === formData

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData(prevFormData => ({
          ...prevFormData,
          [name]: value
      }));
    };
    

    const handleSubmit = type => {
      setLoadingDelayRequest(false)
      // Si la acción es DELETE, procesar inmediatamente
      if(type==='DELETE') {
        serviceFiles.deleteFilesAttach({ ...formData, idFileAttach }).then(response => handleResponse(response, 'DELETE'));
        return;
      }

      let errorMessages=[];

      // Validar si el campo name está vacío para NEW y UPDATE
      if((type==='NEW' || type==='UPDATE') && (!formData.name || formData.name.trim()==='')) {
        errorMessages.push(t('TEXT.ERROR_MESSAGE_NAME_REQUIRED'));
      }

       // Para 'NEW', validar la presencia de 'FileAttach'  
      if (type === 'NEW' && !formData.FileAttach) {
          errorMessages.push(t('TEXT.ERROR_MESSAGE_FILE_REQUIRED'));
      }
      // Mostrar los errores juntos si los hay
      if(errorMessages.length>0) {
        snackActions.error(errorMessages.join(", "));
        return;
      }

      // Continuar con las acciones NEW o UPDATE  
      setLoadingDelayRequest(true);
      switch(type) {
        case 'NEW':
          serviceFiles.newFilesAttach(formData).then(response => {
              if (!response.error) {
                  const newFormData = {
                      ...formData,
                      idFileAttach: response.data.idFileAttach // Agregar idFileAttach al formData
                  }
                  setIdFileAttach(response.data.idFileAttach) // Establecer idFileAttach
                  handleResponse({ ...response, data: newFormData }, 'SAVE')
              } else {
                  handleResponse(response, 'SAVE')
              }
          })
          break
        case 'UPDATE':
          serviceFiles.updateFilesAttach({
                ...formData,
                idFileAttach: idFileAttach // Asegurar que idFileAttach esté presente al actualizar
            }).then(response => handleResponse(response, 'SAVE'))
          break
        default:
          break
      }
    };
    /*
    const handleResponse = response => {
			setLoadingDelayRequest(true)
        if (!response.error) {
            navigate('/templateFilesAttach')
        } else {
            setLoadingDelayRequest(false)
            snackActions.error(response.message)
        }
    }
    
    */

    const handleResponse = (response, actionType) => {
      setLoadingDelayRequest(false);
      if (!response.error) {
        if (actionType === 'DELETE') { //Al Eliminar vuelve a la página anterior
          navigate('/templateFilesAttach')
        } else {
            const newFormData = {
                ...formData,
                idFileAttach: response.data.idFileAttach || idFileAttach // Asegurar que idFileAttach se agrega al formData
            }
            setIdFileAttach(response.data.idFileAttach || idFileAttach) // Establecer idFileAttach
            sessionStorage.setItem('fileAttachNew', JSON.stringify(newFormData))
            if (actionType === 'SAVE' && TYPE === 'NEW') {
                navigate('/templateFilesAttachDetail', {
                    state: { data: newFormData, type: 'UPDATE' }
                })
            } else {
                snackActions.success(response.message)
            }
        }
      } else {
        snackActions.error(response.message)
      }
    }

    const handleFileChange = (e, idLanguage) => {
      const reader = new FileReader()
      const name = e.target.name

      reader.addEventListener(
        'load',
        () => {
            let file = reader.result
            const newFormData = {
                ...formData,
                FileAttach: file,
                filename: e.target.files[0].name
            }
            setFormData(newFormData)
        },
        false
      )
        reader.readAsDataURL(e.target.files[0])
    }

    const handleFileDelete = idLanguage => {
        setFormData({...formData, FileAttach: '', filename: ''})
    }

    const handleFileDownload = () => {
        const linkSource = formData.fileAttach
        const downloadLink = document.createElement('a')
        const fileName = formData.filename
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
    }

    if (loadingDelayRequest) {
        return <Loading showMessageDelayRequest='true' />
    }

    return (
        <div className={classes.container}>
            <Dialog
                open={openDialog}
                hasCloseBtn={false}
                height={200}
                title={t('CONFIRM.TITLE_FILEATTACH')}
                subtitle={t('CONFIRM.UNRECOVERY_DELETE2')}
                close={() => setOpenDialog(false)}
                actions={
                    <Box display='flex' alignItems='center'>
                        <Box mr={1}>
                            <Button
                                variant='contained'
                                onClick={() => setOpenDialog(false)}
                                color='error'
                            >
                                {t('BUTTON.CANCEL')}
                            </Button>
                        </Box>
                        <Button variant='contained' onClick={() => handleSubmit('DELETE')}>
                            {t('BUTTON.DELETE')}
                        </Button>
                    </Box>
                }
            />

            <HeaderTitle title={HEADER_TITLE} subtitle={HEADER_SUBTITLE}>
                <FormActions
                    show={TYPE === 'UPDATE'}
                    handleDelete={() => setOpenDialog(true)}
                    handleUndo={() => { }}
                    handleSave={() => handleSubmit(TYPE)}
                    disabledButton={disabledSaveButton}
                    urlBack='/templateFilesAttach'
                />
            </HeaderTitle>

            <Grid container spacing={2}>
                <Grid item sm={6} lg={6} xs={12} /* style={{ background: 'red' }} */>
                    <Card>
                        <TextField
                            name='name'
                            required
                            fullWidth
                            size='small'
                            label={t('LABEL.NAME')}
                            margin='dense'
                            value={formData.name || ''}
                            onChange={handleChange}
                            inputProps={{maxLength: 100}}
                        />
                        <TextField
                            name='description'
                            fullWidth
                            size='small'
                            label={t('LABEL.DESCRIPTION')}
                            margin='dense'
                            value={formData.description || ''}
                            onChange={handleChange}
                        />
                        <TextField
                            name='filename'
                            fullWidth
                            size='small'
                            margin='dense'
                            value={formData.filename || ''}
                            onChange={handleChange}
                            style={{backgroundColor: '#DDDDDD'}}
                            InputProps={{
                                readOnly: true
                            }}
                            inputProps={{maxLength: 255}}
                        />
                        {!formData.filename && (
                            <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='center'
                                ml={2}
                            >
                                <Button variant='contained' component='label'>
                                    {t('WORKSHEET.UPLOAD')}&nbsp; <CloudUploadIcon />
                                    <input
                                        hidden
                                        name='FileAttach'
                                        onChange={e => {
                                            handleFileChange(e)
                                        }}
                                        multiple
                                        type='file'
                                    />
                                </Button>
                            </Box>
                        )}
                        {formData.filename && (
                            <Grid container spacing={2} justifyContent="center" alignItems="center">
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        name="FileAttach"
                                        color="success"
                                        onClick={() => handleFileDownload()}
                                        component="label"
                                    >
                                        {t('WORKSHEET.DOWNLOAD')} <DownloadIcon />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        name="FileAttachDelete"
                                        color="error"
                                        onClick={() => handleFileDelete()}
                                        component="label"
                                    >
                                        <DeleteIcon />
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default TemplateFilesAttachDetail
