import apiRequest from '@helpers/apiRequest';

// USERS
const getSchedulerUsers = data => {
    return apiRequest('POST', 'Scheduler/SchedulerUsers/List', data);
};

const updateUser = data => {
    const idUser = data.idUser
    return apiRequest('PUT', `Scheduler/SchedulerUsers/Update/${idUser}`, data)
}

// EVENTS
const getSchedulerEvents = data => {
    return apiRequest('POST', 'Scheduler/SchedulerEvents/List', data);
};

const newSchedulerEvent = data => {
    return apiRequest('POST', 'Scheduler/SchedulerEvents/New', data);
};

const updateSchedulerEvent = (data) => {
    return apiRequest('PUT', 'Scheduler/SchedulerEvents/Update', data);
};

const deleteSchedulerEvent = (data) => {
    return apiRequest('DELETE', `Scheduler/SchedulerEvents/Delete/`, data);
};

const getFreeSlots = data => {
    return apiRequest('POST', 'Scheduler/SchedulerEvents/FreeSlots', data);
};

// CATEGORIES
const getSchedulerCategories = data => {
    return apiRequest('POST', 'Scheduler/SchedulerCategories/List', data);
};

const getSchedulerCategoryDetails = idSchedulerCategory => {
    return apiRequest('GET', `Scheduler/SchedulerCategories/Details/${idSchedulerCategory}`);
};

const newSchedulerCategory = data => {
    return apiRequest('POST', 'Scheduler/SchedulerCategories/New', data);
};

const updateSchedulerCategory = data => {
  const {idCategory} = data;
  return apiRequest('PUT', `Scheduler/SchedulerCategories/Update/${idCategory}`, data);
};


const deleteSchedulerCategory = data => {
  const idCategory = data.idCategory;
  return apiRequest('DELETE', `Scheduler/SchedulerCategories/Delete/${idCategory}`);
};


const serviceScheduler = {
    getSchedulerUsers,
    updateUser,
    getSchedulerEvents,
    newSchedulerEvent,
    updateSchedulerEvent,
    deleteSchedulerEvent,
    getSchedulerCategories,
    getSchedulerCategoryDetails,
    newSchedulerCategory,
    updateSchedulerCategory,
    deleteSchedulerCategory,
    getFreeSlots
};

export default serviceScheduler;