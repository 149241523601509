import React from 'react';
import HeaderTitle from '@components/HeaderTitle';
import { useCommonStyles } from '@styles/common.style';
import { useTranslation } from 'react-i18next';

const Calls = () => {
    const classes = useCommonStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.container}>
            <HeaderTitle
                title={t('MENU_ADMIN.CALLS')}
                subtitle={t('MENU_ADMIN.LIST_CALLS')}
            />
        </div>
    );
};

export default Calls;