import React, { useEffect, useState, Fragment, useContext } from 'react'
import MenuList from '@components/MenuList'
import menuOWToolbox from '@constants/menuOWToolbox'
import { AuthContext } from '@context/auth/AuthContext'
import { Accordion, AccordionSummary, Grid, Hidden } from '@mui/material'
import { useStyles } from '@styles/menu.style'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFlags } from '@hooks/useFlags'

const MenuVertical = ({ showMenu, handleShowMenu }) => {
  const { i18n, t } = useTranslation()
  const classes = useStyles()
  const navigate = useNavigate()
  const [menuItems, setMenuItems] = useState([])
  const { user } = useContext(AuthContext)

  useEffect(() => {
      setMenuItems(menuOWToolbox(t))
  }, [i18n.language])

  const handleClickItem = item => {
    handleShowMenu(!showMenu)
    navigate(item.url)
  }

  const name = user?.userName +" " + user?.userSurname;

  const menuList =
    menuItems.length > 0 &&
    menuItems.map((menu, index) => (
      <MenuList
        key={index}
        menuTitle={name}
        items={menu.items}
        handleClick={handleClickItem}
      />
    ))

  return (
    <Fragment>
      <Hidden mdDown>
        <Grid className={classes.containerVertical}>
          {menuList}
        </Grid>
      </Hidden>
     
      <Hidden mdUp>
        <Accordion
          expanded={showMenu}
          onChange={() => handleShowMenu(!showMenu)}
          square
          className={classes.containerVertical}
        >
          <AccordionSummary sx={{ display: 'none' }}></AccordionSummary>
          {menuList}
        </Accordion>
      </Hidden>
    </Fragment>
  )
}

export default MenuVertical
