import React, { useState, useEffect } from 'react'
import serviceMailing from '@services/serviceMailing'
import serviceTemplates from '@services/serviceTemplates'
import serviceMobileSheets from '@services/serviceMobileSheets'
import servicePreparation from '@services/servicePreparation'
import { MenuItem, ListItemText, ListItemIcon, IconButton } from '@mui/material'
import { useCommonStyles } from '@styles/common.style'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { FLAG_ITEMS } from '@constants/flagItems'
import { useTranslation } from 'react-i18next'

export const useDataCycleTypes = () => {
  const { t } = useTranslation()
  const classes = useCommonStyles()
  const languageSelected = sessionStorage.getItem('lang')
  const [emailList, setEmailList] = useState()
  const [allEmails, setAllEmails] = useState()
  const [targetList, setTargetList] = useState()
  const [worksheetList, setWorksheetList] = useState()
  const [mobilesheetList, setMobilesheetList] = useState()
  const [preparationsList, setPreparationsList] = useState()
  const [categories, setCategories] = useState()
  const [loading, setLoading] = useState(true)
  const [showEmailModal, setShowEmailModal] = useState({
    open: false,
    loading: true,
    content: {}
  })
  const [showPreparationModal, setShowPreparationModal] = useState({
    open: false,
    loading: true,
    content: {}
  })

  useEffect(() => {
    let isCancelled = false
    if (!isCancelled) {
      getListValues()
    }
    return () => {
      isCancelled = true
    }
  }, [])

  const getListValues = async () => {
    const emails = await fetchEmails()
    const targets = await fetchTargets()
    const worksheets = await fetchWorksheets()
    const mobilesheets = await fetchMobilesheets()
    const preparations = await fetchPreparations()

    setEmailList(emails)
    setTargetList(targets)
    setWorksheetList(worksheets)
    setMobilesheetList(mobilesheets)
    setPreparationsList(preparations)
    setLoading(false)
  }

  const fetchEmails = async () => {
    const { data: categories } = await serviceMailing.getMailingsCategories({
      order: [
        {
          orderField: 'mailingTypeName',
          orderAsc: true
        }
      ]
    })
    const { data: emailList } = await serviceMailing.getMailingsList({
      order: [
        {
          orderField: 'mailingName',
          orderAsc: true
        }
      ]
    })

    setCategories(categories)
    setAllEmails(emailList)
    return transformEmails(categories, emailList)
  }

  const transformEmails = (categories, emailList) => {
    return categories.reduce((prevValue, value) => {
      const emails = emailList.filter(
        email => email.idMailingType === value.idMailingType
      )
      return { ...prevValue, [value.idMailingType]: emails }
    }, {})
  }

  const fetchPreparations = async () => {
    const { data } = await servicePreparation.getPreparations({
      pageSize: 100,
      pageNumber: 0,
      globalSearch: '',
      search: [],
      order: []
    })
    return data
  }
 
  const fetchPreparationContent = async (idPreparation, setShowPreparationModal) => {
    servicePreparation.getPreparationDetails(idPreparation).then(response => {
      if (!response.error) {
        const { id: idLangSelected } = FLAG_ITEMS.find(
          item => item.value === languageSelected
        )
        const { preparationBody, preparationSubject } = response.data.preparationLocale.find(
          e => e.idLanguage === idLangSelected
        )
        setShowPreparationModal(prevState => {
          return {
            ...prevState,
            loading: false,
            content: { preparationBody, preparationSubject }
          }
        })
      }
    })
  }

  const showPreparationItems = ({ setShowPreparationModal }) => {
    return preparationsList?.map(({ idPreparation, preparationName }) => {      
      return (
        <MenuItem
          className={classes.menuItem}
          key={idPreparation + '-' + Math.floor(Math.random() * 1000)}
          value={idPreparation}
        >
          <ListItemText
            disableTypography
            primary={idPreparation == 0 ? t(preparationName) : preparationName}
            sx={{ pr: 1 }}
          />
          <ListItemIcon
            onClick={e => {
              e.stopPropagation()
              setShowPreparationModal(prevState => {
                return {
                  ...prevState,
                  open: true
                }
              })
              fetchPreparationContent(idPreparation, setShowPreparationModal)
            }}
          >
            {idPreparation != 0 &&
            <IconButton size='small'>
              <VisibilityIcon sx={{ p: 0 }} />
            </IconButton>}
          </ListItemIcon>
        </MenuItem>
      )
    })
  }

  const fetchTargets = async () => {
    const { data } = await serviceTemplates.getTargetsList({
      pageSize: 100,
      pageNumber: 0,
      globalSearch: '',
      search: [],
      order: []
    })
    return data
  }
  

  const fetchWorksheets = async () => {
    const { data } = await serviceTemplates.getWorksheetsList({
      pageSize: 100,
      pageNumber: 0,
      globalSearch: '',
      search: [],
      order: []
    })
    return data
  }

  const fetchMobilesheets = async () => {
    const { data } = await serviceMobileSheets.getMobileSheets({
      pageSize: 100,
      pageNumber: 0,
      globalSearch: '',
      search: [],
      order: []
    })
    return data
  }

  const fetchEmailContent = async (idMailing, setShowEmailModal) => {
    serviceTemplates.getMailingDetail(idMailing).then(response => {
      if (!response.error) {
        const { id: idLangSelected } = FLAG_ITEMS.find(
          item => item.value === languageSelected
        )
        const { mailingBody, mailingSubject } = response.data.locale.find(
          e => e.idLanguage === idLangSelected
        )
        setShowEmailModal(prevState => {
          return {
            ...prevState,
            loading: false,
            content: { mailingBody, mailingSubject }
          }
        })
      }
    })
  }
  const showEmailItems = ({ key, setShowEmailModal }) => {
    return emailList[key]?.map(({ idMailing, mailingName }) => {
      return (
        <MenuItem
          className={classes.menuItem}
          key={idMailing + '-' + Math.floor(Math.random() * 1000)}
          value={idMailing}
        >
          <ListItemText
            disableTypography
            primary={mailingName}
            sx={{ pr: 1 }}
          />
          <ListItemIcon
            onClick={e => {
              e.stopPropagation()
              setShowEmailModal(prevState => {
                return {
                  ...prevState,
                  open: true
                }
              })
              fetchEmailContent(idMailing, setShowEmailModal)
            }}
          >
            <IconButton size='small'>
              <VisibilityIcon sx={{ p: 0 }} />
            </IconButton>
          </ListItemIcon>
        </MenuItem>
      )
    })
  }
  return {
    emailList,
    allEmails,
    targetList,
    worksheetList,
    mobilesheetList,
    categories,
    loading,
    setLoading,
    showEmailItems,
    showEmailModal,
    setShowEmailModal,
    showPreparationItems
  }
}
