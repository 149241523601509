import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './layout/Header';
import { Grid } from '@mui/material';

const Home = () => {
    return (
        <>
            <Grid container justifyContent={'center'}>
                <Grid item xs={12} sx={{
                        height: '60px', // Altura fija del contenedor
                        display: 'flex',
                        alignItems: 'center',
                        overflow: 'hidden'
                    }}>
                    <Header />
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Outlet />
                </Grid>
            </Grid>
        </>
    );
};

export default Home;