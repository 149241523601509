import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ContentMobile from '@components/ContentMobile';

const OwToolboxApp = () => {
    const { id, token } = useParams();
    const [data, setData] = useState();

    useEffect(() => {
        const API_URL = process.env.REACT_APP_URL_API;
        const END_POINT = `MobileSheets/Details/${id}`;

        fetch(API_URL + END_POINT, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(response =>{ const orderItems = response.mobileSheetItems
                .sort((a, b) => a.mobileSheetItemOrder - b.mobileSheetItemOrder) 
                response.mobileSheetItems = orderItems
                setData(response)});
    }, [id, token]);

    if (!data) return <></>;

    return <ContentMobile formData={data} />;
};

export default OwToolboxApp;