import React, { Fragment, useState, useEffect } from "react";
import { Grid, TextField, Button, Box, Select, Typography, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import CancelIcon from '@mui/icons-material/Cancel';

const AddInviteds = ({ guest, handleNewGuest, update, cycleCompanies }) => {

    // A�adir useEffect para registrar los valores de guest al entrar
    useEffect(() => {
        console.log('Guest data at entry:', guest);
    }, [guest]);

  const { t } = useTranslation();
  const [enteredName, setEnteredName] = useState(!update ? '' : guest.invitedName);
  const [enteredSurname, setEnteredSurname] = useState(!update ? '' : guest.invitedSurname);
  const [enteredPosition, setEnteredPosition] = useState(!update ? '' : guest.invitedPosition);
  const [enteredEmail, setEnteredEmail] = useState(!update ? '' : guest.invitedEmail);

  const nameChangeHandler = (event) => {
    setEnteredName(event.target.value);
  };
  const surnameChangeHandler = (event) => {
    setEnteredSurname(event.target.value);
  };
  const positionChangeHandler = (event) => {
    setEnteredPosition(event.target.value);
  };
  const emailChangeHandler = (event) => {
    setEnteredEmail(event.target.value);
  };

  const [selectedCompany, setSelectedCompany] = useState(!update ? (cycleCompanies.length > 0 ? cycleCompanies[0].idCompany : '') : guest.idCompany);
  const handleCompany = (event) => setSelectedCompany(event.target.value);


  const handleSubmit = (data) =>{
    if(data){
    const id = guest?.idCycle || guest;

    let newGuest = {
      idCycle: id,
      invitedName: enteredName,
      invitedSurname: enteredSurname,
      invitedPosition: enteredPosition,
      invitedEmail: enteredEmail,
      idCompany: selectedCompany
    } 
    if(update){
      newGuest = {
        ...newGuest,
        idInvited: guest.idInvited}
    }
      handleNewGuest(newGuest, false,update);
    
    } else {
      handleNewGuest("", true,update);
    }
    
  }
  

  return (
    <Fragment>
      <Grid container spacing={2} padding={1} alignItems="center">
        <Grid item md={12}>
          <TextField
            required
            fullWidth
            label={t("LABEL.NAME")}
            error={enteredName === ""}
            name="participantName"
            size="small"
            value={enteredName}
            onChange={nameChangeHandler}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item md={12}>
          <TextField
            required
            label={t("LABEL.SURNAME")}
            error={enteredSurname === ""}
            fullWidth
            name="participantSurname"
            size="small"
            value={enteredSurname}
            onChange={surnameChangeHandler}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item md={12}>
          <TextField
            fullWidth
            label={t("LABEL.POSITION")}
            name="participantPosition"
            size="small"
            value={enteredPosition}
            onChange={positionChangeHandler}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item md={12}>
          <TextField
            required
            fullWidth
            label={t("LABEL.EMAIL")}
            error={enteredEmail === ""}
            name="participantEmail"
            size="small"
            margin="dense"
            value={enteredEmail}
            onChange={emailChangeHandler}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>

        <Grid item md={12}>
          <Typography variant='caption' component='div'>
            {t('CYCLES.COMPANY_LIST')}
          </Typography>
          <Select
            fullWidth
            size='small'
            value={selectedCompany}
            onChange={handleCompany}
          >
            {cycleCompanies.map((company) => (
              <MenuItem key={company.idCompany} value={company.idCompany}>
                {company.companyName}
              </MenuItem>
            ))}
          </Select>
        </Grid>


       <Grid item md={12}>
      <Box display='flex' width='100%' justifyContent='flex-end'>
              <Button
                sx={{ mr: 1 }}
                variant='contained'
                color='error'
                onClick={() => {
                  handleSubmit(false)
                }}
                aria-label='move selected left'
                startIcon={<CancelIcon/>}
              >
                {t('BUTTON.CANCEL')}
              </Button>
              <Button
                
                variant='contained'
                onClick={() => {
                  handleSubmit(true)
                }}
                aria-label='move selected left'
                startIcon={<SaveRoundedIcon />}
              >
                  {t('BUTTON.SAVE')}
              </Button>
            </Box>
          </Grid>
        </Grid>
    </Fragment>
  );
};

export default AddInviteds;
