import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Loading = ({ showMessageDelayRequest = false, height = '100vh' }) => {

    const { t } = useTranslation();

    return (
        <div style={{
                display: 'flex',
                flexDirection: 'column',
                height: height,
                justifyContent: 'center',
                alignItems: 'center'
            }}>
            <Box>  
                <div>
                    <CircularProgress />
                </div>
            </Box>
            <Box>
                {showMessageDelayRequest &&
                    <div style={{
                            textAlign: '-webkit-center'
                        }}>
                            {t('TEXT.LOADING')}
                            <br />
                            {t('TEXT.LOADING_DELAY_REQUEST')}
                    </div>
                }
                {!showMessageDelayRequest &&
                    <div>
                        <br />
                        {t('TEXT.LOADING')}
                    </div>
                }
            </Box>
        </div>
    );
};

export default Loading;