import Spain from '@assets/images/spain.svg';
import Italy from '@assets/images/italy.svg';
import Uk from '@assets/images/united.svg';
export const FLAG_ITEMS = [
    {
        id: 1,
        value: 'es',
        icon: props => <img src={Spain} alt='logoxl' {...props} />,
    },
    {
        id: 2,
        value: 'en',
        icon: props => <img src={Uk} alt='logoxl' {...props} />,
    },
    {
        id: 3,
        value: 'it',
        icon: props => <img src={Italy} alt='logoxl' {...props} />,
    },
];