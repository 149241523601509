import React, { Fragment } from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { Box, Chip, MenuItem, Select, Typography, Grid } from '@mui/material'
import { useCommonStyles } from '@styles/common.style'
import { useTranslation } from 'react-i18next'
import DeletePopover from '@components/DeletePopover'

const WorksheetConfiguration = ({
  setJourney,
  worksheetList,
  isUpdate,
  setFormData,
  formData,
  journey
}) => {
  const classes = useCommonStyles()
  const { t } = useTranslation()

  const addWorksheet = item => {
    const newWorksheetValues = [
      ...item.cycleTypeJourneyWorkSheets,
      {
        idWorkSheets: worksheetList[0].idWorkSheet, //select
        ...(isUpdate && {
          idCycleTypeJourney: item.idCycleTypeJourney
        })
      }
    ]

    setJourney({
      ...journey,
      cycleTypeJourneyWorkSheets: newWorksheetValues
    })
  }
  const removeWorksheet = (journey, targetIndex) => {
    const newWorksheetsValues = journey.cycleTypeJourneyWorkSheets.filter(
      (_, index) => index !== targetIndex
    )
    setJourney({
      ...journey,
      cycleTypeJourneyWorkSheets: newWorksheetsValues
    })
  }
  const handleChangeWorksheets = (e, journey, index) => {
    const newWorksheetsValues = journey.cycleTypeJourneyWorkSheets.map(
      (worksheet, i) => {
        if (i === index) {
          return {
            ...worksheet,
            [e.target.name]: e.target.value
          }
        }
        return worksheet
      }
    )

    setJourney({
      ...journey,
      cycleTypeJourneyWorkSheets: newWorksheetsValues
    })
  }
  return (
    <Fragment>
      <Typography
        textAlign='center'
        color='white'
        variant='subtitle1'
        sx={{
          bgcolor: 'grey.main',
          color: 'primary.main',
          borderRadius: 1,
          marginBottom: 2,
          fontSize: 14,
          fontWeight: 'bold',
          py: 1
        }}
      >
        {t('CONFIG_JOURNEYS.WORKSHEETS.CONFIG_WORKSHEETS').toUpperCase()}
      </Typography>

      {journey.cycleTypeJourneyWorkSheets?.length !== 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Typography variant='caption' component='div'>
              {t('CONFIG_JOURNEYS.WORKSHEETS.WORKSHEET')}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Typography variant='caption' component='div'>
          {t('CONFIG_JOURNEYS.WORKSHEETS.NO_ASSIGNED_WORKSHEETS')}
        </Typography>
      )}

      {journey.cycleTypeJourneyWorkSheets.map((worksheet, i) => {
        return (
          <Grid container spacing={1} mb={1} key={i}>
            <Grid item xs={10}>
              <Select
                className={classes.select}
                fullWidth
                size='small'
                name='idWorkSheets'
                value={worksheet.idWorkSheets}
                onChange={e => handleChangeWorksheets(e, journey, i)}
              >
                {worksheetList.map(worksheet => {
                  return (
                    <MenuItem
                      className={classes.menuItem}
                      value={worksheet.idWorkSheet}
                      key={worksheet.idWorkSheet}
                    >
                      {worksheet.workSheetLocaleName}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>

            <Grid item xs={2}>
              <Box
                style={{
                  height: '100%',
                  alignItems: 'center',
                  display: 'flex'
                }}
              >
                <DeletePopover
                  remove={() => {
                    removeWorksheet(journey, i)
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        )
      })}
      <Box display='flex' justifyContent='start' mt={2}>
        <Chip
          size='small'
          onClick={() => {
            addWorksheet(journey)
          }}
          label={t('CONFIG_JOURNEYS.WORKSHEETS.ADD')}
          color='primary'
          sx={{ padding: 0 }}
          icon={<AddCircleIcon />}
        />
      </Box>
    </Fragment>
  )
}

export default WorksheetConfiguration
