import React, { Fragment, useContext, useState, useEffect } from 'react';
import Logo from '@assets/images/logoCampus.png';
import NoAvatar from '@assets/images/avatar.png';
import { AuthContext } from '@context/auth/AuthContext';
import {
    Avatar,
    Box,
    Grid,
} from '@mui/material';
import { useStyles } from '@styles/header.style';
import { useOWStyles } from '@styles/ottowalter.style';
import { Link, useParams } from 'react-router-dom';
import serviceConsultants from '@services/serviceConsultants';
import { useTranslation } from 'react-i18next';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useLocation } from 'react-router-dom';


const Header = () => {
    const classes = useStyles();
    const { user, logout } = useContext(AuthContext);
    const owclass = useOWStyles();
    const [consultantDetails, setConsultantDetails] = useState(false);
    const { t } = useTranslation();
    let { idCycle, idJourney } = useParams()
    const location = useLocation()
   
    // useEffect(() => {
    //     let idConsultant = 0; //Se indica cero ya que en esta parte de la pantalla, se desconoce dicho id, por tanto la API sacar� el idUser del UserSession
    //     serviceConsultants.getConsultantsDetails(idConsultant).then(response => {
    //         if (!response.error) {
    //             setConsultantDetails(response.data)
    //         }
    //     });
    // }, []);
    
    return (
        <>
            <Grid container padding={1} style={{ maxWidth: '700px', margin: '0 auto' }}>
                <Grid item xs={1.5} paddingTop={1} paddingLeft={1}>
                    {(idCycle || idJourney) && <Link to={location.pathname.includes('/journey') ? "/panel" : `/journey/${idCycle}`}>
                        <div style={{
                            marginLeft: '1em',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontWeight: 'bold'
                        }}>
                            {location.pathname.includes('/cycledetails') 
                                ? t('TEXT.BACK')  // Mostrar 'Volver' en CycleDetails.js
                                : t('TEXT.BACK_CONSULTANTS') // Mostrar 'Grupos' en Journey.js
                            }
                            <ArrowRightAltIcon style={{
                                color: '#0085B4',
                                fontSize: '2em',
                                transform: 'rotate(180deg)',
                                marginTop: '-0.2em'
                            }}/>
                        </div>
                    </Link>}
                </Grid>
                <Grid item xs={9} display="flex" justifyContent="center">
                    <Box>
                        <img src={Logo} alt='logoxs' height={60}/>
                    </Box>
                </Grid>
                <Grid item xs={1.5} paddingTop={1} display="flex" justifyContent="right">
                    <Link style={{ fontWeight: 'bold', marginRight: '1em' }} to={"/logout"} onClick={() => logout()}>
                        {t('TEXT.CLOSE_SESSION')}
                    </Link>
                </Grid>
            </Grid>
        </>
    );
};

export default Header;