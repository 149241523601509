import React from "react";
import { MenuItem, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { default as SelectMUI } from "@mui/material/Select";

const Select = ({ handleChange, items, defaultValue, id, hiddenLabel }) => {
    return (
        <FormControl fullWidth variant="filled">
            <SelectMUI
                id={id}
                defaultValue={defaultValue}
                onChange={handleChange}
                hiddenLabel={hiddenLabel}
            >
                {items.map((item) => (
                    <MenuItem value={item.value} key={item.label}>
                        <Typography>{item.label}</Typography>
                    </MenuItem>
                ))}
            </SelectMUI>
        </FormControl>
    );
};

export default Select;