import React, { useState } from 'react';
import DynamicTable from '@components/DynamicTable';
import { useTranslation } from 'react-i18next';
import { Typography, IconButton, Tooltip, Box, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import Dialog from "@components/Dialog";
import Moment from 'react-moment';
import { Flag } from "@mui/icons-material";
import serviceFiles from '@services/serviceFiles'
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import {CircularProgress} from '@mui/material';


const ParticipantCycles = (props) => {
    const { t } = useTranslation();

    const idParticipant = props.idParticipant;
    const [openDialog, setOpenDialog] = useState(false);
    const [contentDialog, setContentDialog] = useState('');
    const [openLoading, setOpenLoading] = useState(false);


    const modality = [
        { label: t('LABEL.ONSITE'), value: '1' },
        { label: t('LABEL.DIGITAL'), value: '2' },
        { label: t('LABEL.MIXED'), value: '1,2' }
    ];
    const getModality = value => {
        let res = modality.filter(x => x.value === value)
        return res.length ? res[0].label : t('LABEL.MIXED')
    };

    const filterMap = value => {
        if (value === '') return ''
        let res = modality.filter(x => x.label.toLowerCase().includes(value.toLowerCase()))
        return res.length ? res[0].value : t('LABEL.MIXED')
    };

    const downloadDiploma = (idCycle) => {
      setOpenLoading(true); // Activa la ventana de carga
      serviceFiles.getDiploma(idParticipant, idCycle, false)
        .then(() => {
          setOpenLoading(false); // Desactiva la ventana de carga tras la descarga
        })
        .catch(() => {
          setOpenLoading(false); // Desactiva la ventana de carga si hay un error
        });
    };


    const ORDER = [
        {
            "orderField": "cycleDateFrom",
            "orderAsc": false
        }
    ];

    const handleOpenDialog = (content) => {
        setContentDialog(content)
        setOpenDialog(true);
    };

    const columns = [
        {
            accessor: 'idCycle',
            Header: t('LABEL.ID'),
            filterable: false,
            width: '5%',
        },
        {
            accessor: 'cycleName',
            Header: t('CYCLES.CYCLE_NAME'),
            width: '15%',
            Cell: props => <Typography variant='body2'>{props.value}</Typography>,
        },
        {
            accessor: 'consultants',
            Header: t('LABEL.CONSULTANTS'),
            Cell: props => <Typography variant='body2'>{props.value}</Typography>,
        },
        {
            accessor: 'companyName',
            Header: t('LABEL.COMPANY'),
            Cell: props => <Typography variant='body2'>{props.value}</Typography>,
        },
        {
            accessor: 'cycleTypeCode',
            Header: t('CYCLE_TYPES.CYCLE_TYPE'),
            Cell: props => <Typography variant='body2'>{props.value}</Typography>,
        },
        {
            accessor: 'cycleModalities',
            Header: t('LABEL.MODALITY'),
            Cell: props => (
                <Typography variant='body2'> {getModality(props.value)}</Typography>
            ),
            width: '10%',
            searchMode: 'EqualTo',
            filterMap: x => filterMap(x)
        },
        {
            accessor: 'cycleDateFrom',
            Header: t('LABEL.DATE_INIT'),
            Cell: props => <Typography variant='body2'><Moment format='DD[/]MM[/]yyyy'>{props.value}</Moment></Typography>,
        },
        {
            accessor: 'valuationCode',
            Header: t('LABEL.ABC'),
            width: '5%',
            Cell: props => <Typography variant='body2'>{props.value}</Typography>,
        },
        {
            accessor: 'nota_QTR',
            Header: t('LABEL.GRADE_QTR'),
            width: '5%',
            Cell: props => <Typography variant='body2'>{props.value}</Typography>,
        },
        {
            accessor: 'cycleParticipantFeedback',
            Header: t('LABEL.FEEDBACK'),
            filterable: false,
            width: '5%',
            Cell: props => (
                props.value !== null ? (
                    <Box display='flex' flexDirection='row'>
                        <Tooltip title={t('LABEL.FEEDBACK')}>
                            <IconButton color="inherit" onClick={()=>handleOpenDialog(props.value)}>
                                <Flag />
                            </IconButton>
                        </Tooltip>
                    </Box>
                ) : null
            ),
        },
        {
          accessor: 'idCycleCertificate',
          Header: t('LABEL.CERTIFICATE'),
          filterable: false,
          width: '5%',
          Cell: props => (
            props.value !== null ? (
              <Box display='flex' flexDirection='row'>
                <Tooltip title={t('LABEL.CERTIFICATE')}>
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      setOpenLoading(true); // Activa la ventana de carga
                      serviceFiles.getDiploma(idParticipant, props.value, false)
                        .then(() => {
                          setOpenLoading(false); // Desactiva la ventana de carga tras la descarga
                        })
                        .catch(() => {
                          setOpenLoading(false); // Desactiva la ventana de carga si hay un error
                        });
                    }}
                  >
                    <CardMembershipIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : null
          ),
        },

      ];

    const filter = [
        {
            searchField: 'idParticipant',
            searchMode: 'EqualTo',
            searchValue: idParticipant
        }
    ];

    return (
        <>

            <Dialog
              title={t("TEXT.LOADING")}
              open={openLoading}
              hasCloseBtn={false}
              content={
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <Box>
                    <CircularProgress />
                  </Box>
                  <br />
                  <Box>
                    {t("EVALUATOR.LOADING_DATA")}
                  </Box>
                </div>
              }
            />

            <Dialog 
                open={openDialog}
                hasCloseBtn={true}
                height={350}
                width={500}
                title={t('LABEL.FEEDBACK')}
                content={
                    <Box sx={{width: '80%', wordWrap: 'break-word'}}>
                        <Typography sx={{whiteSpace: 'normal', maxWidth: '100%'}}>
                            {contentDialog}
                        </Typography>
                    </Box>
                }
                close={() => setOpenDialog(false)}
            />

            <DynamicTable
                columns={columns}
                customFilter={filter}
                endpoint='Participants/ParticipantsCycles'
                isOrdered={true}
                isPaginated={false}
                viewExcel={true}
                nameExcel={t('TEXT.PARTICIPANT_CYCLES')}
                titleBodyExcel={t('PARTICIPANT.BODY_EXCEL')}
                orderColumn={ORDER}
            />
        </>
    );
};

export default ParticipantCycles;
