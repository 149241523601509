import apiRequest from '@helpers/apiRequest';

const getValuation = data => {
    return apiRequest('GET', 'Valuations/List');
};

const updateValuation = data => {
    const { idValuation, valuationCode } = data;

    return apiRequest('PUT', `Valuations/Update/${idValuation}`, {
        valuationCode,
    });
};

const newValuation = data => {
    return apiRequest('POST', 'Valuations/New', data);
};

const deleteValuation = data => {
    const { idValuation } = data;
    return apiRequest('DELETE', `Valuations/Delete/${idValuation}`);
};
const serviceValuations = {
    getValuation,
    updateValuation,
    newValuation,
    deleteValuation,
};

export default serviceValuations;