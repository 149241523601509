import React, { useContext, useState } from 'react';
import LogoCampus from '@assets/images/logoScheduler.png';
import Flags from '@components/Flags';
import { AuthContext } from '@context/auth/AuthContext';
import { useForm } from '@hooks/useForm';
import { Box, Button, Grid, TextField, Typography, IconButton, InputAdornment } from '@mui/material';
import serviceLogin from '@services/serviceLogin';
import { useStyles } from '@styles/loginScheduler.style';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const SignInScheduler = ({ flagOptions }) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const { login } = useContext(AuthContext);

    const { formData, loginPassword, loginEmail, handleChange } = useForm({
        loginEmail: '',
        loginPassword: '',
        loginMode: 'LoginScheduler'
    });

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }
  
    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const handleSubmit = e => {
        e.preventDefault();
        serviceLogin.postSignIn(formData).then(response => {
            const data = response.data;
            if (!response.error) {
                login(data);
            }
        });
    };

    const handleChangeLanguage = e => {
        const newLanguage = e.target.value;
        sessionStorage.setItem('lang', newLanguage);
        i18n.changeLanguage(newLanguage);
    };

    return (
        <form onSubmit={handleSubmit} className={classes.card}>
            <div className={classes.header}>
                <img src={LogoCampus} alt='logo' className={classes.image}></img>
                <Typography className={classes.title}>{t('SCHEDULER.TITLE_LOGIN')}</Typography>
                <Typography className={classes.version}>{process.env.REACT_APP_VERSION}</Typography>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12} mb={1}>
                    <TextField
                        id='loginEmail'
                        label={t('LABEL.EMAIL')}
                        variant='filled'
                        name='loginEmail'
                        type='text'
                        fullWidth
                        value={loginEmail}
                        onChange={handleChange}
                        size='small'
                        inputProps={{ maxLength: 100 }}
                    />
                </Grid>

                <Grid item xs={12} sm={9} mb={1}>
                    <TextField
                        id='loginPassword'
                        label={t('LABEL.PASSWORD')}
                        variant='filled'
                        name='loginPassword'
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        value={loginPassword}
                        onChange={handleChange}
                        autoComplete='off'
                        size='small'
                        inputProps={{ maxLength: 100 }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Flags handleSelect={handleChangeLanguage} options={flagOptions} />
                </Grid>
            </Grid>
            <Box mt={3}>
                <Button variant='contained' fullWidth type='submit' size='large'>
                    {t('BUTTON.LOGIN')}
                </Button>

                <Box
                    mt={2}
                    display='flex'
                    sx={{
                        justifyContent: {
                            xs: 'center',
                            sm: 'end',
                        },
                    }}>
                    <Typography
                        component={RouterLink}
                        className={classes.subtitle}
                        to='/lostPassword'
                        color='secondary'
                        fontSize={16}
                    
                        align='center'>
                        {t('LOGIN.TAG_LOST_PASSWORD')}
                    </Typography>
                </Box>
            </Box>
            {/* <div className={classes.header}>
                <img src={LogoOW} alt='logo' className={classes.image}></img>
            </div> */}
        </form>
    );
};

export default SignInScheduler;