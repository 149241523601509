import apiRequest from '@helpers/apiRequest';

const getEvaluators = data => {
    return apiRequest('POST', 'EvaluatorData/Evaluator/List', data);
};
const getEvaluatorDetails = idEvaluator => {
    return apiRequest('GET', `EvaluatorData/Evaluator/Details/${idEvaluator}`);
};

/*const updateEvaluator = data => {
    debugger
    console.log(data)
    const idEvaluator = data.idEvaluator;
    return apiRequest('PUT', `EvaluatorData/Evaluator/Update/${idEvaluator}`, data);
};*/


const updateEvaluator = data => {
    // Tenemos que eliminar los campos no deseados de cada objeto dentro de evaluatorParticipants
    const updatedEvaluatorParticipants = data.evaluatorParticipants.map(participant => {
        const {
            nota_Total,
            nota_QTR,
            nota_AutoEval,
            idEvaluatorType,
            isAutoevalDone,
            numReceivers,
            totalReceiverResponses,
            ...rest // Resto de las propiedades
        } = participant;

        // Comprobar y establecer en 0 si numReceivers o totalReceiverResponses son null
        const updatedNumReceivers = numReceivers !== null ? numReceivers : 0;
        const updatedTotalReceiverResponses = totalReceiverResponses !== null ? totalReceiverResponses : 0;

        return {
            numReceivers: updatedNumReceivers,
            totalReceiverResponses: updatedTotalReceiverResponses,
            ...rest
        };
    });

    // Construir un nuevo objeto de datos con evaluatorParticipants actualizados
    const updatedData = {
        ...data,
        evaluatorParticipants: updatedEvaluatorParticipants
    };

    const idEvaluator = updatedData.idEvaluator;
    return apiRequest('PUT', `EvaluatorData/Evaluator/Update/${idEvaluator}`, updatedData);
};


const newEvaluator = data => {
    return apiRequest('POST', 'EvaluatorData/Evaluator/New', data);
};

const deleteEvaluator = data => {
    const idEvaluator = data.idEvaluator;
    return apiRequest('DELETE', `EvaluatorData/Evaluator/Delete/${idEvaluator}`);
};

const getSessions = data => {
    return apiRequest('POST', 'EvaluatorData/Sessions/List', data);
};
const getSessionsDetails = idEvaluatorSession => {
    return apiRequest('GET', `EvaluatorData/Sessions/Details/${idEvaluatorSession}`);
};

const updateSessions = data => {
    const idEvaluatorSession = data.idEvaluatorSession;
    return apiRequest('PUT', `EvaluatorData/Sessions/Update/${idEvaluatorSession}`, data);
};

const newSessions = data => {
    return apiRequest('POST', 'EvaluatorData/Sessions/New', data);
};

const deleteSessions = data => {
    const idEvaluatorSession = data.idEvaluatorSession;
    return apiRequest('DELETE', `EvaluatorData/Sessions/Delete/${idEvaluatorSession}`);
};

const getEvaluatorParticipantFromCode = code => {
    return apiRequest('GET', `EvaluatorData/Participants/GetCode/${code}`);
};

const getEvaluatorParticipantsDetails = (idParticipant, idEvaluator) => {
    return apiRequest('GET', `EvaluatorData/Participants/Details/${idParticipant}/${idEvaluator}`)
}

const updateEvaluatorParticipant = data => {
    const IdEvaluatorParticipant = data.idEvaluatorParticipant;
    return apiRequest('PUT', `EvaluatorData/Participants/Update/${IdEvaluatorParticipant}`, data);
}

const deleteEvaluatorParticipant = IdEvaluatorParticipant => {
    return apiRequest('DELETE', `EvaluatorData/Participants/Delete/${IdEvaluatorParticipant}`);
}


const serviceEvaluatorData = {
    getEvaluators,
    getEvaluatorDetails,
    updateEvaluator,
    newEvaluator,
    deleteEvaluator,
    getSessions,
    getSessionsDetails,
    updateSessions,
    newSessions,
    deleteSessions,
    getEvaluatorParticipantFromCode,
    getEvaluatorParticipantsDetails,
    updateEvaluatorParticipant,
    deleteEvaluatorParticipant
};

export default serviceEvaluatorData;