import React, { useState, useContext, useEffect } from 'react';
import Avatar from '@components/Avatar';
import Dialog from '@components/Dialog';
import FormActions from '@components/FormActions';
import HeaderTitle from '@components/HeaderTitle';
import { useForm } from '@hooks/useForm';
import { AuthContext } from '@context/auth/AuthContext';
import CheckBox from '@components/CheckBox';
import {
    Box,
    Button,
    Grid,
    TextField,
    Typography,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Checkbox
} from '@mui/material';
import serviceWorkers from '@services/serviceWorkers';
import serviceLogin from '@services/serviceLogin';
import serviceList from '@services/serviceList'; 
import serviceUsers from '@services/serviceUsers';
import { useCommonStyles } from '@styles/common.style';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { snackActions } from '@helpers/snackbarUtils';
import Card from '@components/Card';
import Loading from '@components/Loading';
import { useHandleImages } from "@hooks/useHandleImages";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

const WorkerDetail = () => {
    const { t } = useTranslation();
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const classes = useCommonStyles();
    const [loading, setLoading] = useState(true);
    const [openDialogRequestPassword, setOpenDialogRequestPassword] = useState(false);
    const {
        state: { data: USER_DATA, type: TYPE },
    } = useLocation();
    const [openDialog, setOpenDialog] = useState(false);
    const { formData, setFormData, handleChange } = useForm(USER_DATA || { workerActive: true })

    const HEADER_TITLE =
        TYPE === 'NEW' ? t('WORKER.NEW_TITLE') : t('WORKER.UPDATE_TITLE');
    const HEADER_SUBTITLE =
        TYPE === 'NEW' ? t('WORKER.NEW_SUBTITLE') : t('WORKER.UPDATE_SUBTITLE');

    const disabledSaveButton = USER_DATA === formData;
    const [roles, setRoles] = useState(null);
    const [namesRolesSelected, setNamesRolesSelected] = useState([]);
    const { manageImages, infoData } = useHandleImages();

  const handleChangeDate = (fieldName, e) => {
    let fecha = e.target ? e.target.value : e.format('YYYY-MM-DDT00:00')

    setFormData({...formData, [fieldName]: fecha})

  };

    useEffect(() => {
        if (roles === null) {
            setRoles([]);
            serviceList.getRolesList().then(response => {
                if (!response.error) {
                    const dataUserRole = {
                        idUser: formData !== undefined ? formData.idUser : user.idUser
                    };
                    serviceUsers.getUserRole(dataUserRole).then(responseRolesUser => {
                        setLoading(false);
                        if (!responseRolesUser.error) {
                            let namesRolesSelect = [];
                            response.data.forEach(rol => {
                                let hasRolUser = responseRolesUser.data.filter(role => role.idRole === rol.idRole);
                                if (hasRolUser.length > 0 && TYPE !== 'NEW') {
                                    rol.checked = true;
                                    namesRolesSelect.push(t('ROLES.' + rol.roleName));
                                } else {
                                    rol.checked = false;
                                }
                            });
                            setRoles(response.data);

                            //Si no tiene ning�n rol seleccionar
                            if (namesRolesSelect.length === 0) {
                                namesRolesSelect.push(t('LABEL.SELECT_A_ROL'));
                            }
                            setNamesRolesSelected(namesRolesSelect);//Para mostrar en el inicio de la carga del componente en el Label cuales son los que tiene el user
                        }
                    });
                } else {
                    setLoading(false);
                }
            });
        }
    });

    useEffect(() => {
        if ( infoData?.workerImage !== undefined && infoData?.workerImage !== null && infoData?.workerImage.length !== 0 ) {
            setFormData((prevState) => {
            return { ...prevState, 
                workerImage: infoData.workerImage, 
            };
            });
        }
    }, [infoData])

    const checkedRoleFromList = idRole => {
        let rolesUpdated = [...roles];
        let idsRolesSelected = [];
        let namesRolesSelect = [];
        setNamesRolesSelected([]);//Seteamos a vacio
        rolesUpdated.forEach(rol => {
            if (rol.idRole === idRole) {
                rol.checked = !rol.checked;
            }
            if (rol.checked) {
                idsRolesSelected.push(rol.idRole);
                namesRolesSelect.push(t('ROLES.' + rol.roleName));
            }
        });

        //Si no se ha seleccionado ning�n Rol, se indica que selecciona uno
        if (namesRolesSelect.length === 0) {
            namesRolesSelect.push(t('LABEL.SELECT_A_ROL'));
        }
        setNamesRolesSelected(namesRolesSelect);
        //Se actualiza los roles del gestor
        setFormData(prevState => {
            return { ...prevState, idsRoles: idsRolesSelected };
        });
        setRoles(rolesUpdated);
    };

    const handleSubmit = type => {
        setLoading(true)
        //Se verifica si el usuario tiene permisos para realizar las acciones sobre un gestor
        const userSession = JSON.parse(sessionStorage.getItem('userSession'));
        if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 2)) {
            switch (type) {
                case 'NEW':
                    serviceWorkers.newWorker(formData).then(response => handleResponse(response, 'SAVE'));
                    break;
                case 'UPDATE':
                    serviceWorkers.updateWorker(formData).then(response => handleResponse(response, 'SAVE'));
                    break;
                case 'DELETE':
                    formData.workerActive = false;
                    serviceWorkers.deleteWorker(formData).then(response => handleResponse(response, 'DELETE'));
                    break;
                default:
                    break;
            }
        } else {
            snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'));
            setLoading(false);
        }     
    };

    const handleResponse = (response, actionType) => {
      setLoading(false);
      if (!response.error) {
        if (actionType === 'DELETE') { //Al Eliminar vuelve a la página anterior
          navigate('/workers');
        } else if (actionType === 'SAVE') { //Al Crear nuevo o Actualizar se queda en la página
          if (TYPE === 'NEW') {
            sessionStorage.setItem('workerNew', JSON.stringify(response.data));
            serviceWorkers.getWorkerDetails(response.data.idWorker).then(response => {
              if (!response.error) {
                setFormData(response.data); // Actualiza formData con los datos del trabajador incluyendo idWorker
                navigate('/workerDetail', {
                  state: { data: response.data, type: 'UPDATE' },
                });
              }
            });
          }
        }
      }
    }
    

    const handleSubmitRequestPassword = (email) => {
        setLoading(true);
        const data = {
            userEmail: email,
            requestChangePassword: true,
            area: 'campus'
        };
        serviceLogin.lostPassword(data).then(response => {
            setLoading(false);
            if (!response.error) {
                snackActions.success(t('TEXT.OK_SEND_CHANGE_PASSWORD'));
            } else {
                snackActions.error(response.message);
            }
            setOpenDialogRequestPassword(false);
        });
    };

    if (loading) {
        return <Loading />;
    }
    return (
        <div className={classes.container}>
            <Dialog
                open={openDialog}
                hasCloseBtn={false}
                close={() => setOpenDialog(false)}
                title={t('CONFIRM.TITLE_DESACTIVATED_WORKER')}
                subtitle={t('CONFIRM.SUBTITLE_WORKER_DELETE')}
                height={200}
                actions={
                    <Box display='flex' alignItems='center'>
                        <Box mr={1}>
                            <Button
                                variant='contained'
                                onClick={() => setOpenDialog(false)}
                                color='error'>
                                {t('BUTTON.CANCEL')}
                            </Button>
                        </Box>
                        <Button variant='contained' onClick={() => handleSubmit('DELETE')}>
                            {t('BUTTON.DELETE')}
                        </Button>
                    </Box>
                }
            />
            <Dialog
                open={openDialogRequestPassword}
                close={() => setOpenDialogRequestPassword(false)}
                title={t('CONFIRM.TITLE_SEND_REQUEST_CHANGE_PASSWORD')}
                subtitle={t('CONFIRM.SUBTITLE_SEND_REQUEST_CHANGE_PASSWORD')}
                width={300}
                actions={
                    <Box display='flex' alignItems='center'>
                        <Box mr={1}>
                            <Button
                                variant='contained'
                                onClick={() => setOpenDialogRequestPassword(false)}
                                color='error'>
                                {t('BUTTON.CANCEL')}
                            </Button>
                        </Box>
                        <Button
                            variant='contained'
                            onClick={() => handleSubmitRequestPassword(formData.workerEmail)}>
                            {t('BUTTON.OK')}
                        </Button>
                    </Box>
                }
            />

            <HeaderTitle title={HEADER_TITLE} subtitle={HEADER_SUBTITLE}>
                {TYPE !== 'NEW' &&
                    <Button
                        variant='contained'
                        style={{ transform: 'translateX(-5%)' }}
                        onClick={() => setOpenDialogRequestPassword(true)}
                        color='error'>
                        {t('BUTTON.RESET_PASSWORD')}
                    </Button>
                }
                <FormActions
                    show={TYPE === 'UPDATE'}
                    handleDelete={() => setOpenDialog(true)}
                    handleUndo={() => setFormData(USER_DATA)}
                    handleSave={() => handleSubmit(TYPE)}
                    disabledButton={disabledSaveButton}
                    urlBack='/workers'
                />
            </HeaderTitle>

            <Grid container spacing={2}>
                <Grid item lg={3} xs={12} sm={6}>
                    <Card
                        fullHeight
                        style={{
                            display: 'flex',
                            // alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                        }}>
                        <Typography color='primary' variant='subtitle1' mb={2} pl={1}>
                            {t('FORM.PROFILE_PICTURE')}
                        </Typography>
                        <Box
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            flex={1}>
                            <Avatar
                                handleChange={e => manageImages(e, 'workerImage')}
                                image={formData?.workerImage}
                                width={230}
                                height={250}
                                idbutton={'upload-button-workerImage'}
                            />
                        </Box>
                    </Card>
                </Grid>
                <Grid item lg={4} xs={12} sm={6}>
                    <Card
                        fullHeight
                        style={{
                            display: 'flex',
                            //alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                        }}>
                        <Typography color='primary' variant='subtitle1' mb={2}>
                            {t('FORM.PERSONAL_DATA')}
                        </Typography>
                        <Box flex={1}>
                            <TextField
                                required
                                error= {formData?.workerName === ''}
                                label={t('LABEL.NAME')}
                                name='workerName'
                                value={formData?.workerName || ''}
                                size='small'
                                onChange={handleChange}
                                fullWidth
                                margin='normal'
                                inputProps={{ maxLength: 100 }}
                            />
                            <TextField
                                required
                                error= {formData?.workerSurname === ''}
                                label={t('LABEL.SURNAME')}
                                name='workerSurname'
                                value={formData?.workerSurname || ''}
                                size='small'
                                onChange={handleChange}
                                fullWidth
                                margin='normal'
                                inputProps={{ maxLength: 100 }}
                            />
                            <TextField
                                required
                                error= {formData?.workerEmail === ''}
                                label={t('LABEL.EMAIL')}
                                name='workerEmail'
                                value={formData?.workerEmail || ''}
                                size='small'
                                onChange={handleChange}
                                fullWidth
                                margin='normal'
                                inputProps={{ maxLength: 100 }}
                            />

                            <Grid item xs={12} sm={5}>
                                <Typography variant='caption' component='div'>
                                    {t('LABEL.BIRTHDATE')} *
                                </Typography>
                                <DatePicker
                                    name='workerBirthdate'
                                    slotProps={{
                                        textField: {
                                            size: 'small'
                                        }
                                    }}
                                    format='DD/MM/YYYY' // formato de fecha y hora válido para el locale en español
                                    value={
                                        formData?.workerBirthdate
                                        ? moment(formData?.workerBirthdate)
                                            : null // Usa null en lugar de cadena vacía '' para el valor de fecha y hora
                                    }
                                    onChange={(e) => handleChangeDate('workerBirthdate', e)}
                                />
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={8}
                                    display='flex'
                                    justifyContent='start'
                                    alignItems='center'>
                                    <FormControl fullWidth size='small' margin='normal'>
                                        <InputLabel id='rol'>{namesRolesSelected.join(', ')}</InputLabel>
                                        <Select label={namesRolesSelected.join(', ')}>
                                            {roles?.map((rol, index) => (
                                                <Box>
                                                    <MenuItem value={rol.idRole} key={rol.idRole} onClick={e => checkedRoleFromList(rol.idRole)} onChange={handleChange}>
                                                        <Checkbox checked={rol.checked}/>
                                                        {t(`ROLES.${rol.roleName}` || rol.roleName)}
                                                    </MenuItem>
                                                </Box>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    display='flex'
                                    justifyContent='end'
                                    alignItems='center'
                                    style={{ marginTop : '10px' }} >
                                    <CheckBox
                                        fullWidth
                                        name='workerActive'
                                        label={t('LABEL.ACTIVE')}
                                        value={formData.workerActive}
                                        checked={TYPE === 'NEW' ? formData.workerActive = true : formData.workerActive}
                                        handleChange={e => {
                                            setFormData({ ...formData, workerActive: e.target.checked });
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default WorkerDetail;