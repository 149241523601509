import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Box, Button, Typography } from '@mui/material';
import { useForm } from '@hooks/useForm';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import TextField from '@mui/material/TextField';
import { Link, useNavigate } from 'react-router-dom';
import serviceTutorShip from '@services/serviceTutorShip';
import Loading from '@components/Loading';
import { useOutletContext } from 'react-router-dom';
import { useStyles } from '@styles/participants.style'
import Logo from '@assets/images/logoCampus.png';
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { snackActions } from '@helpers/snackbarUtils'

const Tutorship = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const [buttonDisabled, setButtonDisabled] = useState(false); // Nuevo estado

    const { formData, handleChange } = useForm({
        cycleName: '',
        email: '',
        name: '',
        subject: '',
        body: '',
        fileAttach: '',
        fileName: ''
    });
    const { cycleName, email, subject, body } = formData;


    const userSession = JSON.parse(sessionStorage.getItem('userSession'));
    const name = userSession.userName + ' ' + userSession.userSurname;
    const {
        cycle: [cycle, setCycle]
    } = useOutletContext();

    const handleSubmit = e => {
        setLoading(true);
        setButtonDisabled(true); // Deshabilita el bot�n al enviar el formulario
        e.preventDefault();
        formData.email = userSession.userEmail;
        formData.name = name;
        formData.cycleName = cycle.cycleName;
        serviceTutorShip.postSendMail(formData).then(response => {
            if (!response.error) {
                //TODO: veremos que hacer
            }
            setLoading(false);
        })
        setLoading(false);
    };

    const handleFileChange = (e, idLanguage) => {
        const reader = new FileReader();
        const files = e.target.files;

        if (files.length > 0) {
            const file = files[0];
            let fileSize = file.size;

            if (fileSize < 5120000) {
                reader.addEventListener('load', () => {
                    const updatedFormData = { ...formData };  // Clonar el objeto formData
                    updatedFormData.fileAttach = reader.result;
                    updatedFormData.fileName = file.name;

                    handleChange({
                        target: {
                            name: 'fileAttach',
                            value: updatedFormData.fileAttach,
                        },
                    });

                    handleChange({
                        target: {
                            name: 'fileName',
                            value: updatedFormData.fileName,
                        },
                    });

                    // Habilitar el bot�n Adjuntar
                    setButtonDisabled(false);
                }, false);

                reader.readAsDataURL(file);
            } else {
                snackActions.error(t('WORKSHEET.FILE_SIZE'))
            }
        }
    };


    const handleDeleteFile = () => {
        // Eliminar el archivo del formData
        handleChange({
            target: {
                name: 'fileAttach',
                value: '',
            },
        });

        handleChange({
            target: {
                name: 'fileName',
                value: '',
            },
        });

        // Volver a habilitar el bot�n Adjuntar
        setButtonDisabled(false);
    };


    return (
        <div style={{ padding: '0em 1em 5em 1em' }}>
            <Grid container spacing={0} marginTop={1} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                <Grid item xs={10} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <Typography className={classes.title} sx={{ margin: '10px 5px' }}>{t('MENU_PARTICIPANTS.TUTOR')}</Typography>
                </Grid>
                <Grid item xs={2} paddingLeft={2} paddingTop={0} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <Box>
                        <img className={classes.image} src={Logo} alt='logoxs' />
                    </Box>
                </Grid>
            </Grid>
            <div>
                <form onSubmit={handleSubmit} >
                    <Typography className={classes.subtitle} sx={{ fontWeight: 'normal !important' }}>{t('TEXT.SEND_TUTORSHIP')}</Typography>

                    <TextField 
                        style={{ backgroundColor: 'white' }} 
                        id="filled-basic" 
                        label={t('TEXT.FROM')}
                        defaultValue={name}
                        InputProps={{
                            readOnly: true,
                            sx: { fontSize: '1.5rem' }
                        }}
                        InputLabelProps={{
                          sx: { fontSize: '1.5rem' }
                        }}
                        variant="outlined" />
                    <br />
                    <br />
                    <TextField
                        style={{ backgroundColor: 'white' }}
                        id="subject"
                        name="subject"
                        label={t('TEXT.SUBJECT')}
                        value={subject}
                        variant="outlined"
                        onChange={handleChange}
                        InputProps={{
                            sx: { fontSize: '1.5rem' } // Cambia el tamaño del texto ingresado
                        }}
                        InputLabelProps={{
                            sx: { fontSize: '1.5rem' } // Cambia el tamaño del label
                        }}
                    />

                    <Box paddingTop={2} color="grey" sx={{ fontSize: '1.5rem' }}>{t('TEXT.MESSAGE')}</Box>

                    <TextareaAutosize
                        minRows={8}
                        style={{ width: '100%', border: '1px solid lightgray', borderRadius: '5px', margin: '5px 0px', fontSize: '1.5rem', padding: '10px' }}
                        id='body'
                        name='body'
                        value={body}
                        onChange={handleChange}
                    />

                    <Box
                        display='flex'
                        flexDirection='column'  // Agregar esta l�nea
                        alignItems='flex-start'  // Alinear a la izquierda
                        justifyContent='center'
                        ml={2}
                    >
                        {/* Mostrar el nombre del archivo y el bot�n Eliminar si ya se ha adjuntado un archivo */}
                        {formData.fileName && (
                            <Box display="flex" alignItems="center">
                                {/* A�adir el icono de fichero */}
                                <InsertDriveFileIcon sx={{ marginRight: 1 }} />

                                {/* A�adir title para el tooltip */}
                                <Typography
                                    sx={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                    title={formData.fileName}
                                >
                                    {formData.fileName}
                                </Typography>

                                <Button variant="outlined" color="error" onClick={handleDeleteFile} sx={{ marginLeft: 1 }}>
                                    {t('BUTTON.DELETE')}
                                </Button>
                            </Box>
                        )}

                        {/* Mostrar el bot�n Adjuntar si no hay archivo adjunto */}
                        {!formData.fileName && (
                            <Button variant='contained' component='label' sx={{ marginTop: 1, fontWeight: 'bold' }}>
                                {t('WORKSHEET.UPLOAD')}&nbsp;&nbsp; <CloudUploadIcon />
                                <input
                                    hidden
                                    name='FileAttach'
                                    onChange={(e) => handleFileChange(e)}
                                    multiple
                                    type='file'
                                />
                            </Button>
                        )}
                    </Box>

                    {loading && (
                        <Loading height='20vh' />
                    )}
                    {!loading && (
                        <Button variant='contained' type='submit' size='large' sx={{ marginLeft: '70% !important', marginTop: '20px', fontWeight: 'bold' }}
                            disabled={buttonDisabled} // Usa el estado para deshabilitar el bot�n
                        >
                            {buttonDisabled ? t('BUTTON.SEND_DONE') : t('BUTTON.SEND')}
                        </Button>
                    )}
                </form>

                <div style={{ marginTop: '40px' }}>
                    <Typography className={classes.subtitle2} sx={{ fontSize: '1.3rem' }}>
                        {(t('PARTICIPANTS.CONTACTOW')).toUpperCase()}
                    </Typography>
                    <div style={{ margin: '4px 0px 0px 2em', fontSize: '1.3rem' }}>
                        <p>+34 91 547 83 00</p>
                        <p>{t('PARTICIPANTS.HORARIO1')}</p>
                        <p>{t('PARTICIPANTS.HORARIO2')}</p>
                        <a href='mailto:owtutor@ottowalter.com'>owtutor@ottowalter.com</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Tutorship;