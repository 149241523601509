import React, { useState, useEffect } from 'react';
import { Grid, TextField, Card, Box, Avatar, Button, CircularProgress } from '@mui/material';
import { useCommonStyles } from '@styles/common.style';
import { useTranslation } from 'react-i18next';
import serviceConsultants from '@services/serviceConsultants';
import NoImage from '@assets/images/noImage.png';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import Fallback from '@assets/images/avatar.png'
import serviceLogin from '@services/serviceLogin';
import { useNavigate } from 'react-router-dom';
import { useForm } from '@hooks/useForm';
import Loading from '@components/Loading';

const Profile = () => {
    const classes = useCommonStyles();
    const { t } = useTranslation();
    const [consultantDetails, setConsultantDetails] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const { formData, userEmail } = useForm({
        userEmail: consultantDetails.consultantEmail,
    });

    useEffect(() => {
        let idConsultant = 0; //Se indica cero ya que en esta parte de la pantalla, se desconoce dicho id, por tanto la API sacará el idUser del UserSession
        serviceConsultants.getConsultantsDetails(idConsultant).then(response => {
            if (!response.error) {
                setConsultantDetails(response.data);
            }
        });
    }, []);

    const handleSubmit = e => {
        e.preventDefault();
        setLoading(true);
        formData.userEmail = consultantDetails.consultantEmail;
        serviceLogin.lostPassword(formData).then(response => {
            if (!response.error) {
                navigate('/newPassword', { replace: true });
            }
            setLoading(false);
        });
    };

    return (
        <div>
            <Grid container spacing={1} padding={1}>
                <Grid item xs={1} display="flex" justifyContent="center">
                    <Link to="/"><ArrowBack /></Link>
                </Grid>
                <Grid item xs={9}>
                    <h3>{t('TEXT.PROFILE_HEADER')}</h3>
                </Grid>
            </Grid>
            <hr /><br />
            
            <Card variant="outlined" style={{ marginBottom: '10px' }}>
                { consultantDetails ?
                <Grid container spacing={1} padding={1}>
                    <Grid item xs={12} lg={4} display="flex" justifyContent="center">
                        <Avatar
                            sx={{ width: '200px', height: '300px' }}
                            src={`data:image/png;base64,${consultantDetails?.consultantImageProfile}` || Fallback}
                            idbutton={'upload-button-userImage'}
                            variant="square"
                        />
                    </Grid>
                    <Grid item xs={12} lg={8}>
                        <Box style={{ backgroundColor: 'white', color: '#0085B4', borderRadius: '5px', marginTop: '8px' }} padding={0}>
                            <TextField
                                label={t('LABEL.CODE')}
                                name='consultantCode'
                                value={consultantDetails.consultantCode || ''}
                                size='small'
                                margin='normal'
                                fullWidth
                                disabled
                                inputProps={{ maxLength: 20 }}
                            />
                            <TextField
                                label={t('LABEL.NAME')}
                                name='consultantName'
                                value={consultantDetails.consultantName || ''}
                                size='small'
                                margin='normal'
                                fullWidth
                                disabled
                                inputProps={{ maxLength: 100 }}
                            />
                            <TextField
                                label={t('LABEL.SURNAME')}
                                name='consultantSurame'
                                value={consultantDetails.consultantSurname || ''}
                                size='small'
                                margin='normal'
                                fullWidth
                                disabled
                                inputProps={{ maxLength: 100 }}
                            />
                            <TextField
                                label={t('LABEL.EMAIL')}
                                name='consultantEmail'
                                value={consultantDetails.consultantEmail || ''}
                                size='small'
                                margin='normal'
                                fullWidth
                                disabled
                                inputProps={{ maxLength: 100 }}
                            />
                            <TextField
                                label={t('LABEL.POSITION')}
                                name='consultantJobTitle'
                                value={consultantDetails.consultantJobTitle || ''}
                                size='small'
                                margin='normal'
                                fullWidth
                                disabled
                            />
                            <TextField
                                label={t('LABEL.PROFILE_DETAILS')}
                                name='consultantProfile'
                                value={consultantDetails.consultantProfile.replace(/<[^>]*>?/gm, '') || ''}
                                size='small'
                                margin='normal'
                                fullWidth
                                disabled
                                multiline
                            />

                        </Box>
                    </Grid>
                </Grid>
                :
                <Loading height='60vh' />
                }
            </Card>
            <br />
            <form onSubmit={handleSubmit} className={classes.card}>
                <Box mt={3}>
                    <Button variant='contained' fullWidth type='submit' size='large'>
                        <Box display='flex' alignItems='center'>
                            {loading && <CircularProgress size={25} color='grey' />}
                            {!loading && t('BUTTON.RECOVER_PASSWORD')}
                        </Box>
                    </Button>
                </Box>
             </form>
        </div>
    );
};

export default Profile;