import React from 'react';
import { Box, Divider, Typography } from '@mui/material';

const HeaderTitle = ({ title, subtitle, children }) => {
    return (
        <Box mb={2}>
            <Box display='flex' justifyContent='space-between' mb={2}>
                <Box>
                    <Typography sx={{ typography: { xs: 'h6', sm: 'h5' } }}>
                        {title}
                    </Typography>
                    <Typography variant='subtitle2' color='primary' sx={{fontSize: '0.9em'}}>
                        {subtitle}
                    </Typography>
                </Box>

                <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='flex-end'
                    flex={1}>
                    {children}
                </Box>
            </Box>
            <Divider />
        </Box>
    );
};

export default HeaderTitle;