import React from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import ChangeImage from '@components/ChangeImage'

const CycleImage = (props) => {
    const { idCycle } = useParams();
    const isParticipant = props.isParticipant || false;

    const { t } = useTranslation();

    return (
        <div style={{padding: '1em'}}>
            <Grid container spacing={1} padding={1}>
                {!isParticipant &&
                    <Grid item xs={1} display="flex" justifyContent="center">
                        <Link to={"/journey/" + idCycle}><ArrowBack /></Link> 
                    </Grid>
                }
                <Grid item xs={10} display="flex" justifyContent="center">
                    <h3>{t('TEXT.CYCLE_PHOTO')}</h3>
                </Grid>
            </Grid>
            <hr/><br />
            <ChangeImage idCycle={idCycle} imageName={'cycleImage'}/>
        </div>
    );
};

export default CycleImage;
