import apiRequest from '@helpers/apiRequest';

const getListByJourney = (idParticipant, idCycleJourney) => {
    return apiRequest('GET', `ParticipantsTargets/ByJourney/${idParticipant}/${idCycleJourney}`);
};

const getListByJourneyAndParticipant = data => {
    return apiRequest('POST', `ParticipantsTargets/ByJourneyAndParticipant/`, data)
}

// const getListByJourneyAndParticipant = (idParticipant, idCycleJourney) => {
//     return apiRequest('GET', `ByJourneyAndParticipant/${idParticipant}/${idCycleJourney}`)
// }

const postUpdateJourneyTargets = data => {
    return apiRequest('POST', `ParticipantsTargets/UpdateJourneyTargets`, data);
};

const serviceParticipantsTargets = {
    getListByJourney,
    postUpdateJourneyTargets,
    getListByJourneyAndParticipant
};

export default serviceParticipantsTargets;