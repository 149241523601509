import { useState } from "react";
import { snackActions } from "@helpers/snackbarUtils";
import { useTranslation } from "react-i18next";
import imageCompression from 'browser-image-compression'; // Importar la librer�a

export const useHandleImages = () => {
    const { t } = useTranslation();
    const [state, setState] = useState({});
    const maxImgSize = 5120000; // Tama�o m�ximo permitido para las im�genes

    const manageImages = async (e, formDataImgType) => {
        const typeImg = e.type.split("/");

        if (typeImg[0] === "image") {
            try {
                // Opciones de compresi�n
                const options = {
                    maxSizeMB: 1, // Tama�o m�ximo en MB
                    maxWidthOrHeight: 1024, // Resoluci�n m�xima permitida
                    useWebWorker: true,
                };

                // Comprimir la imagen
                const compressedFile = await imageCompression(e.file, options);

                // Verificar si el archivo comprimido a�n excede el tama�o permitido
                if (compressedFile.size < maxImgSize) {
                    const base64 = await imageCompression.getDataUrlFromFile(compressedFile); // Convertir a base64
                    const image = base64.replace("data:" + compressedFile.type + ";base64,", "");

                    // Guardar la imagen comprimida en el estado
                    setState((prevState) => {
                        return { ...prevState, [formDataImgType]: image };
                    });
                } else {
                    // Si el archivo comprimido sigue siendo demasiado grande, mostrar un error
                    snackActions.error(t("WORKSHEET.FILE_SIZE"));
                }
            } catch (error) {
                console.error("Error al comprimir la imagen:", error);
                snackActions.error(t("WORKSHEET.FILE_SIZE"));
            }
        } else {
            snackActions.error(t("WORKER.NOT_IMAGE"));
        }
    }

    return {
        ...state,
        infoData: state,
        setInfoData: setState,
        maxImgSize,
        manageImages
    };
};
