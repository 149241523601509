import apiRequest from '@helpers/apiRequest';

const getMobileSheets = data => {
    return apiRequest('POST', 'MobileSheets/List', data);
};
const getMobileSheetsDetails = idMobileSheet => {
    return apiRequest('GET', `MobileSheets/Details/${idMobileSheet}`);
};

const updateMobileSheet = data => {
    const idMobileSheet = data.idMobileSheet;
    return apiRequest('PUT', `MobileSheets/Update/${idMobileSheet}`, data);
};

const newMobileSheet = data => {
    return apiRequest('POST', 'MobileSheets/New', data);
};

const deleteMobileSheet = data => {
    const idMobileSheet = data.idMobileSheet;
    return apiRequest('DELETE', `MobileSheets/Delete/${idMobileSheet}`);
};

const getListMobileSheet = () => {
    return apiRequest('GET', `MobileSheets/ListMobileSheetTypes`);
};

const getListMobileSheetByUser = idUser =>{
    return apiRequest('GET', `MobileSheets/ListMobileSheetByUser/${idUser}`);
}

const newMobileSheetFavorite = data => {
    return apiRequest('POST', 'MobileSheets/NewFavorite', data);
};

const deleteMobileSheetFavorite = IdMobileSheetFavorite => {
    return apiRequest('DELETE', `MobileSheets/DeleteFavorite/${IdMobileSheetFavorite}`);
};

const getMobileSheetsListByParticipant = data => {  
    return apiRequest('POST', `MobileSheets/ListByParticipant`, data);
};

const newMobileSheetKitOW = data => {
    return apiRequest('POST', 'MobileSheets/NewMobileSheetKitOW', data);
};

const serviceMobileSheets = {
    getMobileSheets,
    getMobileSheetsDetails,
    updateMobileSheet,
    newMobileSheet,
    deleteMobileSheet,
    getListMobileSheet,
    getListMobileSheetByUser,
    newMobileSheetFavorite,
    deleteMobileSheetFavorite,
    getMobileSheetsListByParticipant,
    newMobileSheetKitOW
};

export default serviceMobileSheets;