import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Button, ButtonGroup, TextareaAutosize, Typography, Box } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import { useStyles } from '@styles/participants.style'
import getCurrentLastJourney from '@helpers/currentLastJourney';
import isLastJourney from '@helpers/isLastJourney';
import serviceCycleJourneysParticipants from '@services/serviceCycleJourneysParticipants';
import serviceCycleParticipants from '@services/serviceCycleParticipants';
import serviceCycles from '@services/serviceCycles';
import { useForm } from '@hooks/useForm';
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from '@assets/images/logoCampus.png';
import { snackActions } from '@helpers/snackbarUtils'
import Loading from '@components/Loading'

const ScoreJourney = () => {
    const { t } = useTranslation();

    const classes = useStyles();
    const {
        cycle: [cycle, setCycle],
      } = useOutletContext();
    const [selected, setSelected] = useState(null);
    const [selectedLast, setSelectedLast] = useState(null);
    const [cycleJourney, setCycleJourney] = useState();
    const [cycleParticipant, setCycleParticipant] = useState();
    const [lastJourney, setLastJourney] = useState(false);
    const navigate = useNavigate();
    const {
        state: {journey: journey, idJourney: idJourney}
    } = useLocation()
    const [existParticipantInJourney, setExist] = useState(true);
    const [loading, setLoading] = useState(true)

    const { formData, setFormData, handleChange } = useForm({
        cycleJourneyParticipantRemarks: '',
        cycleParticipantRemarks: ''
    });

    const lists = [
        { id: 1, score: "1" },
        { id: 2, score: "2" },
        { id: 3, score: "3" },
        { id: 4, score: "4" },
        { id: 5, score: "5" },
        { id: 6, score: "6" },
        { id: 7, score: "7" },
        { id: 8, score: "8" },
        { id: 9, score: "9" },
        { id: 10, score: "10" }
    ];
    
    console.log(formData);

    const handleColor = (row) => {
        setSelected(row.id);
    };

    const handleColorLast = (row) => {
        setSelectedLast(row.id);
    };

    useEffect(() => {
        serviceCycles.getCycleDetail(journey.idCycle, true, false).then(response1 => {
            if (!response1.error) {
                const isLastJourneyBool = isLastJourney(cycle)
                let journeyToUse = journey
                if (isLastJourney || journey.cycleJourneyPrivate == true) {
                    journeyToUse = getCurrentLastJourney(response1.data).idCycleJourney
                }
                serviceCycleJourneysParticipants.cycleJourneParticipantDetailsByJourney(journeyToUse).then((response) => {
                    if (!response.error) {
                        if (null != response.data) {
                            setCycleJourney(response.data);
                            setSelected(response.data.cycleJourneyParticipantRate);
                            setFormData(prevState => {
                                return {
                                    ...prevState,
                                    cycleJourneyParticipantRemarks: response.data.cycleJourneyParticipantRemarks,
                                };
                            });
                            if (isLastJourneyBool) {
                                serviceCycleParticipants.getCycleParticipantsDetailsByIdCycle(response1.data.idCycle).then(response => {
                                    if (!response.error) {
                                        setLastJourney(true);
                                        setCycleParticipant(response.data);
                                        setSelectedLast(response.data.cycleParticipantRate);
                                        setFormData(prevState => {
                                            return {
                                                ...prevState,
                                                cycleParticipantRemarks: response.data.cycleParticipantRemarks,
                                            };
                                        });
                                    }
                                })
                            }
                            setLoading(false)
                        } else {
                            setExist(false);
                            setLoading(false)
                        }
                    }
                });
            }
        });
    }, []);

    const handleSubmit = e => {
        if (lastJourney) { 
            cycleJourney.cycleJourneyParticipantRate = selectedLast;
        } else {
            cycleJourney.cycleJourneyParticipantRate = selected;
        }
        cycleJourney.cycleJourneyParticipantRemarks = formData.cycleJourneyParticipantRemarks;
        e.preventDefault(); 
        if (!formData.cycleJourneyParticipantRemarks || selected === 0) {
            snackActions.error(t('PARTICIPANTS.ERROR_SCORE'))
        } else {
            serviceCycleJourneysParticipants.updateCycleJourneParticipant(cycleJourney).then(response => {
                if (!response.error) {
                    if (lastJourney) {
                        cycleParticipant.cycleParticipantRate = selected;
                        cycleParticipant.cycleParticipantRemarks = formData.cycleParticipantRemarks;
                        serviceCycleParticipants.updateCycleParticipant(cycleParticipant).then(response => {
                            if (!response.error) {
                                // Si es la última jornada, siempre navega a targets
                                navigate("/targets");
                            }
                        })
                    } else {
                        // Si no es la última jornada
                        if (journey.idPreparation === 0) {
                            // Si idPreparation es 0, navegar a targets
                            navigate("/targets");
                        } else {
                            // Si no, navegar a activeJourney
                            navigate("/activeJourney")
                        }
                    }
                }
            })
        }
    };

    if (loading) {
        return <Loading />
      }

    return (
        <Grid style={{padding:'0em 0.8em 5em 0.8em', width:'100%'}}>
            <Grid container spacing={0} marginTop={1}>
                <Grid item xs={12} display={'flex'} justifyContent={'end'}>
                    <Box>
                        <img className={classes.image} src={Logo} alt='logoxs' />
                    </Box>
                </Grid>
            </Grid>

            {existParticipantInJourney ?
            <>
                <Typography className={classes.subtitle2} style={{ margin:'20px 10px 8px' }}>
                    <strong>{t('TEXT.FEEDBACK_JOURNEY')}</strong>
                </Typography>
                <TextareaAutosize
                    required
                    minRows={6}
                    style={{ width: '100%', border: '1px solid lightgray', borderRadius: '5px', margin: '5px 0px', fontSize: '1em', padding: '10px' }}
                    id='cycleJourneyParticipantRemarks'
                    name='cycleJourneyParticipantRemarks'
                    value={formData?.cycleJourneyParticipantRemarks || ''}
                    onChange={handleChange}
                />
            {lastJourney && (
                <>
                    <Typography className={classes.subtitle2} style={{ margin:'20px 10px 8px' }}>
                        <strong>{t('TEXT.FEEDBACK_CYCLE')}</strong>
                    </Typography>
                    <TextareaAutosize
                        required
                        minRows={8}
                        style={{ width: '100%', border: '1px solid lightgray', borderRadius: '5px', margin: '5px 0px', fontSize: '1em', padding: '10px' }}
                        id='cycleParticipantRemarks'
                        name='cycleParticipantRemarks'
                        value={formData?.cycleParticipantRemarks || ''}
                        onChange={handleChange} 
                    />
                </>
            )}


            {lastJourney && (
                <>
                    <Typography className={classes.subtitle2} style={{ margin:'20px 10px 8px' }}>
                        {t('TEXT.RATE_CYCLE')}
                    </Typography>
                    <ButtonGroup
                        variant="contained" 
                        color="white"
                        size='medium' 
                        fullWidth
                        sx={{
                            display:'flex',
                            justifyContent: 'space-between',
                            boxShadow: 'none !important',
                            borderRadius: 0,
                            color: "#1c6189",
                        }}
                    >
                        {lists.map((list) => (
                            <Button
                                key={list.id}
                                onClick={() => handleColorLast(list)}
                                sx={{
                                    display:'flex',
                                    justifyContent: 'center',
                                    fontSize: '1.1em',
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    backgroundColor: list.id === selectedLast ? "#1c6189 !important" : "white",
                                    border: '1px solid #0085B4',
                                    boxShadow: 'none !important',
                                    color: list.id === selectedLast ? "white !important" : "#1c6189 !important",
                                    width: '1.2em',
                                    minWidth: '1em !important',
                                    height: '2em'
                                }}
                            >
                                {list.score}
                            </Button>
                        ))}
                    </ButtonGroup>
                    <Grid item xs={12} className={classes.colorPrimary} display={'flex'} justifyContent={'space-between'}>
                        <Typography>Min.</Typography>
                        <Typography>Max.</Typography>
                    </Grid>
                </>
            )}


            <Typography className={classes.subtitle2} style={{ margin:'20px 10px 8px' }}>
                {lastJourney ?
                    t('TEXT.RATE_JOURNEY_CYCLE')
                    :
                    t('TEXT.RATE_JOURNEY')
                }
            </Typography>

            <ButtonGroup
                variant='contained'            
                size='medium'
                color="white"
                fullWidth
                sx={{
                    display:'flex',
                    justifyContent: 'space-between',
                    boxShadow: 'none !important',
                    borderRadius: 0,
                    color: "#1c6189",
                }}
            >
                {lists.map((list) => (
                    <Button
                        key={list.id}
                        onClick={() => handleColor(list)}
                        sx={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            justifyContent: 'center',
                            fontSize: '1.1em',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            backgroundColor: list.id === selected ? "#1c6189 !important" : "white",
                            border: '1px solid #0085B4',
                            boxShadow: 'none !important',
                            color: list.id === selected ? "white !important" : "#1c6189 !important",
                            width: '1.2em',
                            minWidth: '1em !important',
                            height: '2em'
                        }}
                        >
                        {list.score}
                    </Button>
                ))}
            </ButtonGroup>
            <Grid item xs={12} className={classes.colorPrimary} display={'flex'} justifyContent={'space-between'}>
                <Typography>Min.</Typography>
                <Typography>Max.</Typography>
            </Grid>

            <br />
            <br />
            <br />

            <Button onClick={handleSubmit}
                variant='contained'
                disabled={lastJourney ?
                selected === null ||
                selectedLast === null ||
                formData.cycleJourneyParticipantRemarks === '' ||
                formData.cycleParticipantRemarks === '' : selected === null ||
                formData.cycleJourneyParticipantRemarks === ''}
                fullWidth 
                size='large'
            >
              {t('BUTTON.SUBMIT_FEEDBACK')}
            </Button></>
                : <strong>{t('TEXT.ASSIGNED_JOURNEY')}</strong>
        }
        </Grid>
    );
};

export default ScoreJourney;