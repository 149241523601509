import apiRequest from '@helpers/apiRequest';

const postSignIn = data => {
    return apiRequest('POST', 'Login/SignIn', data);
};

const postSignInSimulated = data => {
    return apiRequest('POST', 'Login/SignInSimulated', data);
};

const lostPassword = data => {
    return apiRequest('POST', 'Login/LostMyPassword', data);
};

const setNewPassword = data => {
    return apiRequest('POST', 'Login/SetNewPassword', data);
};

const checkIsAuthenticated = (loginMode) => {
    return apiRequest('GET', `Login/Ping/${loginMode}`);
};
const serviceLogin = {
    postSignIn,
    postSignInSimulated,
    lostPassword,
    setNewPassword,
    checkIsAuthenticated,
};

export default serviceLogin;