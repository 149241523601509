import { AuthContext } from '@context/auth/AuthContext';
import Home from '@areas/Participants/Home';
import LostPassword from '@login/LostPassword';
import SignUpParticipantsPage from '@login/SignUpParticipantsPage';
import NewPassword from '@login/NewPassword';
import LoginParticipantsPage from '@login/LoginParticipantsPage';
import { useContext } from 'react';
import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
} from 'react-router-dom';
import Targets from '@areas/Participants/sections/targets/targets';
import ActiveJourney from '@areas/Participants/sections/activeJourney/activeJourney';
import CycleDetails from '@areas/Participants/sections/cycleDetails/CycleDetails';
// import CycleDetails from '@areas/Consultants/sections/cycleDetails/CycleDetails'; ESTE ES EL DE CONSULTORES
import Myprofile from '@areas/Participants/sections/myProfile/myprofile';
import Tutorship from '@areas/Participants/sections/tutorship/tutorship';
import SimulatedLogin from '@areas/Participants/sections/simulatedLogin/SimulatedLogin';
import ScoreJourney from '@areas/Participants/sections/scoreJourney/scoreJourney';

const ParticipantsRoutes = () => {
    const PrivateRoute = () => {
        const { status } = useContext(AuthContext);
        return status === 'authenticated' ? <Home /> : <Navigate to='/' />;
    };
    const { status } = useContext(AuthContext);

    return (
        <Router>
            <Routes>
                <Route
                    path='/'
                    element={
                        status === 'authenticated' ? (
                            <Navigate to='/targets' />
                        ) : (
                            <LoginParticipantsPage />
                        )
                    }
                />
                <Route path='*' element={<Navigate to='/' />} />
                <Route path='/participants/simulatedLogin' element={<SimulatedLogin />} />
                <Route path='/simulatedLogin' element={<SimulatedLogin />} />
                <Route path='/lostPassword' element={<LostPassword />} />
                <Route path='/signUpParticipant/:key' element={<SignUpParticipantsPage />} />
                <Route path='/newPassword' element={<NewPassword />} />
                <Route element={<PrivateRoute />}>
                    <Route path='targets' element={<Targets />} />
                    <Route path='activeJourney' element={<ActiveJourney />} />
                    <Route path='cycledetails/:idCycle' element={<CycleDetails isParticipant={true} />} />
                    <Route path='myprofile' element={<Myprofile />} />
                    <Route path='tutorship' element={<Tutorship />} />
                    <Route path='scoreJourney' element={<ScoreJourney />} />
                </Route>
            </Routes>
        </Router>
    );
};

export default ParticipantsRoutes;