import React, { useState } from 'react'
import AddButton from '@components/AddButton'
import DynamicTable from '@components/DynamicTable'
import HeaderTitle from '@components/HeaderTitle'
import { Box, Button, Checkbox, IconButton } from '@mui/material'
import serviceWorkers from '@services/serviceWorkers'
import { useCommonStyles } from '@styles/common.style'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { EditRounded } from '@mui/icons-material'
import { snackActions } from '@helpers/snackbarUtils'
import Loading from '@components/Loading'
import TableTemplateMailing from '@components/TableTemplateMailing'
import Dialog from '@components/Dialog'
import AddIcon from '@mui/icons-material/Add'

const Workers = () => {
  const classes = useCommonStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [workersSelected, setWorkersSelected] = useState([])
  const [showTemplatesDialog, setShowTemplatesDialog] = useState(false)

  const handleCheckbox = (isChecked, value) => {
    if (!isChecked) {
      setWorkersSelected(prevState => {
        return [...prevState, value]
      })
    } else {
      const newWorkers = workersSelected.filter(
        worker => worker.idWorker !== value.idWorker
      )
      setWorkersSelected(newWorkers)
    }
  }
  const ORDER = [
    {
      orderField: 'workerSurname',
      orderAsc: true
    }
  ]
  const COLUMNS = [
    {
      accessor: '',
      Header: 'Info',
      filterable: false,
      hideHeader: true,
      width: '5%',
      Cell: ({ row: { values } }) => (
        <IconButton
          aria-label='info'
          color='info'
          style={{
            padding: '0px'
          }}
          onClick={() => {
            handleShowWorkerDetail(values.idWorker)
          }}
        >
          <EditRounded fontSize='inherit' />
        </IconButton>
      )
    },
    {
      accessor: 'idWorker',
      Header: t('LABEL.ID'),
      filterable: false,
      width: '5%'
    },
    {
      accessor: 'workerName',
      Header: t('LABEL.NAME')
    },
    {
      accessor: 'workerSurname',
      Header: t('LABEL.SURNAME'),
      Cell: props => <p>{props.value}</p>
    },
    { accessor: 'workerEmail', Header: t('LABEL.EMAIL') },
  ]

  const handleShowWorkerDetail = idWorker => {
    //Se verifica si el usuario tiene permisos para realizar las acciones sobre un gestor
    const userSession = JSON.parse(sessionStorage.getItem('userSession'))
    if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 2)) {
      setLoading(true)
      serviceWorkers.getWorkerDetails(idWorker).then(response => {
        setLoading(false)
        if (!response.error) {
          navigate('/workerDetail', {
            state: { data: response.data, type: 'UPDATE' }
          })
        }
      })
    } else {
      snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'))
    }
  }

  const sendEmail = () => {
    if (workersSelected.length > 0) {
      setShowTemplatesDialog(true)
    } else {
      snackActions.error(t('CYCLES.JOURNEYS.SELECT_PARTICIPANT'))
    }
  }
  const sendEmailParticipants = () => {
    //TODO
  }
  const handleNewWorker = () => {
    //Se verifica si el usuario tiene permisos para realizar las acciones sobre un gestor
    const userSession = JSON.parse(sessionStorage.getItem('userSession'))
    if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 2)) {
      navigate('/workerDetail', { state: { data: {}, type: 'NEW' } })
    } else {
      snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'))
    }
  }

  if (loading) {
    return <Loading />
  }
  return (
    <div className={classes.container}>
      {showTemplatesDialog && (
        <Dialog
          width='70%'
          height='80%'
          open={showTemplatesDialog}
          hasCloseBtn={false}
          title={t('CYCLES.SEND_MAIL')}
          close={() => setShowTemplatesDialog(false)}
          content={<TableTemplateMailing disabled={false}/>}
          actions={
            <Box display='flex' width='100%' justifyContent='flex-end'>
              <Button
                sx={{ mr: 1 }}
                variant='contained'
                color='error'
                onClick={() => {
                  setShowTemplatesDialog(false)
                }}
                aria-label='move selected left'
              >
                {t('BUTTON.CLOSE')}
              </Button>
              <Button
                variant='contained'
                onClick={() => sendEmailParticipants()}
                aria-label='move selected left'
              >
                {t('CYCLES.SEND_MAIL')}
              </Button>
            </Box>
          }
        />
      )}
      <HeaderTitle
        title={t('TEXT.TITLE_MANAGERS')}
        subtitle={t('TEXT.SUBTITLE_MANAGERS')}
      >
        <Button
          variant='contained'
          color='primary'
          sx={{ mt: 2 }}
          onClick={handleNewWorker}
          startIcon={<AddIcon />}
        >
          {t('BUTTON.NEW_WORKER')}
        </Button>
      </HeaderTitle>
      <DynamicTable
        columns={COLUMNS}
        endpoint='Workers/List'
        isGlobalSearch={true}
        isColumnsSearch={true}
        isOrdered={true}
        orderColumn={ORDER}
      />
    </div>
  )
}

export default Workers
