import React, { useState } from 'react'
import LocaleTabs from '@components/LocaleTabs'
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone'
import { TextField, Typography, Grid, Button, Box } from '@mui/material'
import Dialog from '@components/Dialog'
import Editor from '@components/CkEditor'
import Card from '@components/Card'
import { useTranslation } from 'react-i18next'

const SectionLocale = ({ formData, setFormData }) => {
  const [openDialogQTR, setOpenDialogQTR] = useState(false)
  const [openDialogTarget, setOpenDialogTarget] = useState(false)
  const [openDialogRemarks, setOpenDialogRemarks] = useState(false)
  const { t } = useTranslation()

  const handleLocaleChange = (data, idLanguage, name) => {
    let localeLanguage = formData?.cycleTypeLocales || []
    let index = localeLanguage.findIndex(
      locale => locale.idLanguage === idLanguage
    )
    if (index < 0) {
      localeLanguage.push({
        idLanguage: idLanguage,
        idCycleType: formData.idCycleType
      })
      index = localeLanguage.length - 1
    }
    localeLanguage[index][name] = data

    setFormData({ ...formData, cycleTypeLocales: localeLanguage })
  }
  return (
    <Card fullHeight>
      <LocaleTabs
        locale={formData?.cycleTypeLocales}
        content={(localeLanguage, lang) => {
          return (
            <Grid container spacing={2}>
              <Dialog
                hasCloseBtn={false}
                width='80%'
                open={openDialogQTR}
                title={t('CYCLE_TYPES.QTR')}
                content={
                  <Editor
                    data={localeLanguage?.cycleTypeQtr || ''}
                    onChange={newData => {
                      handleLocaleChange(
                        newData,
                        lang.idLanguage,
                        'cycleTypeQtr'
                      )
                    }}
                  />
                }
                actions={
                  <Box display='flex' alignItems='center'>
                    <Button
                      variant='contained'
                      onClick={() => {
                        setOpenDialogQTR(false)
                      }}
                    >
                      Ok
                    </Button>
                  </Box>
                }
              />
              <Dialog
                width='80%'
                open={openDialogTarget}
                hasCloseBtn={false}
                title={t('CYCLE_TYPES.OBJECTIVES')}
                content={
                  <Editor
                    data={localeLanguage?.cycleTypeTargets || ''}
                    onChange={newData => {
                      handleLocaleChange(
                        newData,
                        lang.idLanguage,
                        'cycleTypeTargets'
                      )
                    }}
                  />
                }
                actions={
                  <Box display='flex' alignItems='center'>
                    <Button
                      variant='contained'
                      onClick={() => {
                        setOpenDialogTarget(false)
                      }}
                    >
                      Ok
                    </Button>
                  </Box>
                }
              />
              <Dialog
                width='80%'
                open={openDialogRemarks}
                hasCloseBtn={false}
                title={t('CYCLE_TYPES.REMARKS')}
                content={
                  <Editor
                    data={localeLanguage?.cycleTypeRemarks || ''}
                    onChange={newData => {
                      handleLocaleChange(
                        newData,
                        lang.idLanguage,
                        'cycleTypeRemarks'
                      )
                    }}
                  />
                }
                actions={
                  <Box display='flex' alignItems='center'>
                    <Button
                      variant='contained'
                      onClick={() => {
                        setOpenDialogRemarks(false)
                      }}
                    >
                      Ok
                    </Button>
                  </Box>
                }
              />
              <Grid item xs={12}>
                <Typography variant='caption' component='div' mt={1.5}>
                  {t('CYCLE_TYPES.TITLE_LOCALE')}
                </Typography>
                <TextField
                  size='small'
                  name='cycleTypeName'
                  value={localeLanguage?.cycleTypeName || ''}
                  onChange={e =>
                    handleLocaleChange(
                      e.target.value,
                      lang.idLanguage,
                      'cycleTypeName'
                    )
                  }
                  fullWidth
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              
              <Grid item xs={12}>
                <Typography variant='caption' component='div' mt={1.5}>
                  {t('CYCLE_TYPES.TITLE_DIPLOMA')}
                </Typography>
                <TextField
                  size='small'
                  name='cycleTypeDiplomaName'
                  value={localeLanguage?.cycleTypeDiplomaName || ''}
                  onChange={e =>
                    handleLocaleChange(
                      e.target.value,
                      lang.idLanguage,
                      'cycleTypeDiplomaName'
                    )
                  }
                  fullWidth
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>

              <Grid item xs>
                <Button
                  fullWidth
                  onClick={() => {
                    setOpenDialogQTR(true)
                  }}
                  sx={{
                    textTransform: 'none',
                    fontSize: 12,
                    background: '#f1f1f1',
                    height: 42
                  }}
                  startIcon={<InsertDriveFileTwoToneIcon />}
                >
                  {t('CYCLE_TYPES.QTR')}
                </Button>
              </Grid>
              <Grid item xs>
                <Button
                  fullWidth
                  onClick={() => {
                    setOpenDialogTarget(true)
                  }}
                  sx={{
                    fontSize: 12,
                    height: 40,
                    background: '#f1f1f1',
                    textTransform: 'none'
                  }}
                  startIcon={<InsertDriveFileTwoToneIcon />}
                >
                  {t('CYCLE_TYPES.OBJECTIVES')}
                </Button>
              </Grid>
              <Grid item xs>
                <Button
                  fullWidth
                  onClick={() => {
                    setOpenDialogRemarks(true)
                  }}
                  sx={{
                    fontSize: 12,
                    height: 40,
                    background: '#f1f1f1',
                    textTransform: 'none'
                  }}
                  startIcon={<InsertDriveFileTwoToneIcon />}
                >
                  {t('CYCLE_TYPES.REMARKS')}
                </Button>
              </Grid>
            </Grid>
          )
        }}
      />
    </Card>
  )
}

export default SectionLocale
