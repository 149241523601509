import React, { useState } from 'react'
import Dialog from '@components/Dialog'
import FormActions from '@components/FormActions'
import HeaderTitle from '@components/HeaderTitle'
import { useForm } from '@hooks/useForm'
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem
} from '@mui/material'
import serviceIncidents from '@services/serviceIncidents'
import { useCommonStyles } from '@styles/common.style'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Card from '@components/Card'
import moment from 'moment'
import Loading from "@components/Loading"

const IncidentsDetail = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const classes = useCommonStyles()

  const [openDialog, setOpenDialog] = useState(false)
  const [loading, setLoading] = useState(false);

  const {
    state: { data: INCIDENT_DATA, type: TYPE }
  } = useLocation()

  const HEADER_TITLE = t('INCIDENT.UPDATE_TITLE')
  const HEADER_SUBTITLE = t('INCIDENT.UPDATE_SUBTITLE')

  const { formData, setFormData, handleChange } = useForm(INCIDENT_DATA)

  const disabledSaveButton = INCIDENT_DATA === formData

  const handleSubmit = type => {
    setLoading(true)
    switch (type) {
      case 'UPDATE':
        serviceIncidents.updateIncident(formData).then(response => handleResponse(response, 'SAVE'))
        break
      case 'DELETE':
        serviceIncidents.deleteIncident(formData).then(response => handleResponse(response, 'DELETE'))
        break
      default:
        break
    }
  }

  const handleResponse = (response, actionType) => {
    setLoading(false);
    if (!response.error) {
      if (actionType === 'DELETE') { //Al Eliminar vuelve a la página anterior
        navigate('/incidents');
      } else if (actionType === 'SAVE') { //Al Crear nuevo o Actualizar se queda en la página
      }
    } else {
    }
  }

  const handleChangeSelect = e => {
    setFormData(prevState => {
      return {
        ...prevState,
        openIncident: e.target.value
      }
    })
  }

  const OPEN_INCIDENTS = [
    {
      label: t('INCIDENT.OPEN_INCIDENT'),
      value: '1'
    },
    {
      label: t('INCIDENT.CLOSE_INCIDENT'),
      value: '0'
    }
  ]

  return (
    <div className={classes.container}>
      <Dialog
        open={openDialog}
        hasCloseBtn={false}
        height={150}
        title={t('CONFIRM.TITLE_INCIDENT')}
        //subtitle={t('CONFIRM.UNRECOVERY_DELETE')}
        close={() => setOpenDialog(false)}
        actions={
          <Box display='flex' alignItems='center'>
            <Box mr={1}>
              <Button
                variant='contained'
                onClick={() => setOpenDialog(false)}
              >
                {t('BUTTON.CANCEL')}
              </Button>
            </Box>
            <Button
                variant='contained'
                onClick={() => handleSubmit('DELETE')}
                color='error'
            >
              {t('BUTTON.DELETE')}
            </Button>
          </Box>
        }
      />

      <HeaderTitle title={HEADER_TITLE} subtitle={HEADER_SUBTITLE}>
        <FormActions
          show={TYPE === 'UPDATE'}
          handleDelete={() => setOpenDialog(true)}
          handleUndo={() => {}}
          handleSave={() => handleSubmit(TYPE)}
          disabledButton={disabledSaveButton}
          urlBack='/incidents'
        />
      </HeaderTitle>

      <Grid container spacing={2} sx={{ marginTop: { xs: 1, md: 0 } }}>
        <Grid item lg={6} xs={12}>
          <Card>
            <Typography color='primary' variant='subtitle1'>
              {t('FORM.INCIDENT_DATA')}
            </Typography>

            <Grid container spacing={2} sx={{ marginTop: { xs: 1, md: 0 } }}>
              <Grid item xs={6} lg={6}>
                <TextField
                  label={t('CYCLES.CYCLE_NAME')}
                  name='cycleName'
                  value={formData.cycleName || ''}
                  size='small'
                  onChange={handleChange}
                  fullWidth
                  disabled={true}
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item xs={6} lg={6}>
                <TextField
                  label={t('LABEL.DATE')}
                  name='dateIncident'
                  value={
                    formData.dateIncident
                      ? moment(formData.dateIncident).format('DD[/]MM[/]YYYY')
                      : ''
                  }
                  size='small'
                  onChange={handleChange}
                  fullWidth
                  disabled={true}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ marginTop: { xs: 1, md: 0 } }}>
              <Grid item xs={6} lg={6}>
                <TextField
                  label={t('LABEL.NAME')}
                  name='participantName'
                  value={formData.participantName || ''}
                  size='small'
                  onChange={handleChange}
                  fullWidth
                  disabled={true}
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item xs={6} lg={6}>
                <TextField
                  label={t('LABEL.SURNAME')}
                  name='participantSurname'
                  value={formData.participantSurname || ''}
                  size='small'
                  onChange={handleChange}
                  fullWidth
                  disabled={true}
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: { xs: 1, md: 0 } }}>
              <Grid item xs={12} lg={12}>
                <TextField
                  multiline
                  fullWidth
                  label={t('LABEL.DESCRIPTION')}
                  name='descriptionIncident'
                  size='small'
                  rows={3}
                  value={formData.descriptionIncident || ''}
                  onChange={handleChange}
                  inputProps={{ maxLength: 255 }}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Card>
            <Typography color='primary' variant='subtitle1'>
              {t('INCIDENT.REMARKS_CLOSED')}
            </Typography>
            <Grid container spacing={2} sx={{ marginTop: { xs: 1, md: 0 } }}>
              <Grid item xs={12} lg={12}>
                <TextField
                  multiline
                  fullWidth
                  label={t('INCIDENT.REMARKS')}
                  name='incidentSolvedRemarks'
                  value={formData.incidentSolvedRemarks || ''}
                  size='small'
                  rows={5}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: { xs: 1, md: 0 } }}>
              <Grid item xs={6} lg={6}>
                <Select
                  size='small'
                  name='openIncident'
                  value={formData.openIncident ?? ''}
                  fullWidth
                  onChange={handleChange}
                >
                  {OPEN_INCIDENTS.map(incidents => (
                    <MenuItem key={incidents.value} value={incidents.value}>
                      {incidents.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default IncidentsDetail
