import { useContext, useState, useEffect } from 'react';
import { ListContext } from '@context/ListContext';
import { Button, ButtonGroup } from '@mui/material';
import Editor from '@components/CkEditor';

const FlagInput = ({ handleChange, valueInput }) => {
    const { languageList } = useContext(ListContext);

    // Function to get the stored language code from sessionStorage
    const getStoredLanguage = () => {
        const storedLang = sessionStorage.getItem('lang');
        console.log('storedLang', storedLang);
        const lang = languageList?.find((lang) => lang.languageCode === storedLang);
        const idLanguage = lang?.idLanguage || 1; // Default to 1 if not found
        console.log('idLanguage', idLanguage);
        return idLanguage;
    };

    const [value, setValue] = useState(getStoredLanguage());

    useEffect(() => {
        // Function to check and update language based on sessionStorage
        const checkSessionStorage = () => {
            const newIdLanguage = getStoredLanguage();
            if (newIdLanguage !== value) {
                setValue(newIdLanguage);
            }
        };

        // Check sessionStorage on component mount and every second thereafter
        checkSessionStorage();
        const intervalId = setInterval(checkSessionStorage, 1000);

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [value, languageList]);

    const handleLocaleChange = (idLanguage) => {
        setValue(idLanguage);
        sessionStorage.setItem('lang', languageList.find((lang) => lang.idLanguage === idLanguage)?.languageCode);
    };

    return (
        <>
            <ButtonGroup disableElevation variant='outlined' sx={{ mr: 2 }}>
                {languageList.map((lang, i) => (
                    <Button
                        key={`Btn${i}-${lang.idLanguage}`}
                        style={{
                            opacity: lang.idLanguage === value ? 1 : 0.5,
                        }}
                        onClick={() => {
                            handleLocaleChange(lang.idLanguage);
                        }}
                    >
                        {lang.Icon()}
                    </Button>
                ))}
            </ButtonGroup>
            <Editor
                data={valueInput.find(e => e.idLanguage === value)?.mobileSheetItemContent || ''}
                onChange={e => {
                    handleChange(e, value); // Use `value` directly
                }}
            />
        </>
    );
};

export default FlagInput;
