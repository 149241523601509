import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => {
    return {
        containerVertical: {
            backgroundColor: theme.palette.grey.main,

            [theme.breakpoints.up('md')]: {
                padding: '15px 25px',
                height: 'calc(100vh - 50px)',
                overflowY: 'scroll',
                overflowX: 'hidden',
            },
        },
        containerHorizontal: {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: theme.palette.grey.main,
        },
        listTitle: {
            background: theme.palette.grey.main,
            color: theme.palette.primary.main,
            fontSize: 16,
        },
        menuItem: {
            color: 'red',
            fontSize: '1.5em !important',
        },
    };
});