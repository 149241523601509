import React, { useState, useEffect } from 'react'
import serviceEvaluatorInfo from '@services/serviceEvaluatorInfo'
import { Grid, Card, Button, Tooltip, Typography } from '@mui/material'
import HeaderTitle from '@components/HeaderTitle';
import { useNavigate } from 'react-router-dom'
import { useCommonStyles } from '@styles/common.style'
import { useStyles } from '@styles/evaluator.style'
import { useTranslation } from 'react-i18next'
import Loading from '@components/Loading';
import Excelente from '@assets/images/scores/excelente_b.gif'
import Notable from '@assets/images/scores/notable_b.gif'
import Bien from '@assets/images/scores/bien_b.gif'
import Suficiente from '@assets/images/scores/sufic_b.gif'
import Insuficiente from '@assets/images/scores/insuf_b.gif'



const AutoevaluationReport = () => {
    const classes = useCommonStyles()
    const classes2 = useStyles()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const receiver = JSON.parse(sessionStorage.getItem('receiver'))
    const idEvaluatorParticipant = receiver.idEvaluatorParticipant
    const [loading, setLoading] = useState(true)
    const [dataAE, setDataAE] = useState(true)


    useEffect(() => {
        setLoading(true)
        fetchAutoevaluationReport(idEvaluatorParticipant)
    }, [t])

    const fetchAutoevaluationReport = async (id) => {
        const { data, error } = await serviceEvaluatorInfo.getEvaluatorAutoevaluationByCategories(id);
        if (!error) {
            console.log(data);
            setDataAE(data)
            setLoading(false)
        }
    };

    const goBack = () => {
        navigate('/reports')
    }

    const imageScore = (score) => {
        if (score >= 4.5) {
            return Excelente
        } else if (score < 4.5 && score >= 4) {
            return Notable
        } else if (score < 4 && score >= 3.5) {
            return Bien
        } else if (score < 3.5 && score >= 3) {
            return Suficiente
        } else if (score < 3) {
            return Insuficiente
        }
    }

    return (
        <>
            {!loading ? (
                <div className={classes.container} style={{ maxWidth: '1200px', margin: 'auto' }}>
                    <HeaderTitle
                        title={t('EVALUATOR.AUTOEVALUATION')}
                        subtitle={t('EVALUATOR.AUTOEV_BTN')}
                    />
                    <br />
          
                        <Grid container spacing={0.2} p={0}>
                            <Grid item xs={8}>
                                <Card className={`${classes2.card4} ${classes2.bgBlueOtto}`} >
                                    <Typography sx={{ color: 'white !important' }}><span style={{ fontWeight: 'bold' }}>{t('LABEL.COMPANY')}:</span> {dataAE.company}</Typography>
                                    <Typography sx={{ color: 'white !important' }}><span style={{ fontWeight: 'bold' }}>{t('EVALUATOR.BOSS')}:</span> {dataAE.name}</Typography>
                                    <Typography sx={{ color: 'white !important' }}><span style={{ fontWeight: 'bold' }}>{t('LABEL.POSITION')}:</span> {dataAE.position}</Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={4}>
                                <Card className={classes2.card4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography className={classes2.cardTitle}>{t('EVALUATOR.NOTE')}:</Typography>
                                    <Typography variant={'caption'}>{t('EVALUATOR.RANGE')}</Typography>
                                    <Typography className={classes2.score}>{dataAE.gradeGlobal}</Typography>
                                </Card>
                            </Grid>
                            
                            <Grid item xs={12}>                                                          
                              <Grid container spacing={0.2} p={2}>
                                <Grid item xs={1} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} mr={0.5}>
                                    <img src={Excelente} alt='logo' className={classes2.imageScore} ></img>
                                </Grid>
                                <Grid item xs={10.5}>
                                    <Typography>{t('EVALUATOR.PUNTUATION_EXCELLENT')}<span style={{ fontWeight: 'bold' }}>{t('EVALUATOR.TEXT_EXCELLENT')}</span></Typography>
                                </Grid>
                                <Grid item xs={1} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} mr={0.5}>
                                    <img src={Notable} alt='logo' className={classes2.imageScore} ></img>
                                </Grid>
                                <Grid item xs={10.5}>
                                    <Typography>{t('EVALUATOR.PUNTUATION_NOTABLE')}<span style={{ fontWeight: 'bold' }}>{t('EVALUATOR.TEXT_NOTABLE')}</span></Typography>
                                </Grid>
                                <Grid item xs={1} display={'flex'} justifyContent={'flex-end'} alignItems={'flex-start'} mr={0.5}>
                                    <img src={Bien} alt='logo' className={classes2.imageScore} ></img>
                                </Grid>
                                <Grid item xs={10.5}>
                                    <Typography>{t('EVALUATOR.PUNTUATION_GOOD')}<span style={{ fontWeight: 'bold' }}>{t('EVALUATOR.TEXT_GOOD')}</span></Typography>
                                </Grid>
                                <Grid item xs={1} display={'flex'} justifyContent={'flex-end'} alignItems={'flex-start'} mr={0.5}>
                                    <img src={Suficiente} alt='logo' className={classes2.imageScore} ></img>
                                </Grid>
                                <Grid item xs={10.5}>
                                    <Typography>{t('EVALUATOR.PUNTUATION_SUFFICIENT')}<span style={{fontWeight: 'bold'}}>{t('EVALUATOR.TEXT_SUFFICIENT')}</span></Typography>
                                </Grid>
                                <Grid item xs={1} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} mr={0.5}>
                                    <img src={Insuficiente} alt='logo' className={classes2.imageScore} ></img>
                                </Grid>
                                <Grid item xs={10.5}>
                                    <Typography>{t('EVALUATOR.PUNTUATION_INSUFFICIENT')}<span style={{fontWeight: 'bold'}}>{t('EVALUATOR.TEXT_INSUFFICIENT')}</span></Typography>
                                </Grid>
                              </Grid>                                                           
                            </Grid>
 
                            <Grid container spacing={2} style={{ marginTop: '10px' }}>
                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item xs={9} className={classes2.bgBlueOtto} sx={{ borderRadius: '4px 0px 0px 4px' }}>
                                    <Typography pt={0.7} pb={0.7} pl={4} sx={{ color: 'white !important', fontWeight: '600' }}>{t('EVALUATOR.VALUATION_BEHAVIORAL')}</Typography>
                                  </Grid>
                                  <Grid item xs={3} className={classes2.bgBlueOtto} sx={{ 
                                    borderRadius: '0px 4px 4px 0px', 
                                    display: 'flex', 
                                    justifyContent: 'flex-end', 
                                    alignItems: 'center',
                                    paddingRight: { xs: 3, sm: 4, md: 6, lg: 9 }
                                  }}>
                                    <Typography pt={0.7} pb={0.7} sx={{ color: 'white !important', fontWeight: '600' }}>{t('EVALUATOR.VALUE')}</Typography>
                                  </Grid>
                                </Grid>
                              </Grid>

                              {dataAE.evaluatorAutoevaluationByCategory.length > 0 && dataAE.evaluatorAutoevaluationByCategory.map((category, index) => (
                                <React.Fragment key={index}>
                                  <Grid item xs={12}>
                                    <Grid container alignItems="flex-start">
                                      <Grid item xs={9}>
                                        <Typography className={classes2.titleScore}>{category.idCategory}. {category.categoryName}</Typography>
                                      </Grid>
                                      <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                        <Typography className={classes2.titleScore}>
                                          {category.gradeCategory.toFixed(2)}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={imageScore(category.gradeCategory)} alt="score" className={classes2.imageScore} style={{ marginTop: '3px' }} />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  
                                  {category.evaluatorAutoevaluationByBehavior.length > 0 && category.evaluatorAutoevaluationByBehavior.map((behavior, index1) => (
                                    <Grid item xs={12} key={index1}>
                                    <Grid container alignItems="flex-start">
                                      <Grid item xs={9} style={{marginLeft:'20px', marginBottom: '-10px' }}>
                                        <Typography>{behavior.behaviorName}</Typography>
                                      </Grid>
                                      <Grid item xs={2} style={{ textAlign: 'right', marginLeft:'-20px' }}>
                                        <Typography display="inline">{behavior.gradeBehavior.toFixed(2)}</Typography>
                                      </Grid>
                                      <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
                                        <img src={imageScore(behavior.gradeBehavior)} alt="score" className={classes2.imageScore} style={{ marginTop: '3px' }} />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  ))}
                                </React.Fragment>
                              ))}
                            </Grid>                            
                        </Grid>
                        
                        <Grid container mt={0} p={1} justifyContent="center" style={{ marginTop: '20px' }}>
                          <Grid item>
                            <Button className={classes2.btn} variant='outlined' size='large' onClick={() => goBack()}>
                              {t('TEXT.BACK')}
                            </Button>
                          </Grid>
                        </Grid>


                </div>
            ) : (
                <Loading height='50vh' />
            )}
        </>
    )
}

export default AutoevaluationReport