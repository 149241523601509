import apiRequest from '@helpers/apiRequest';

//MAILING

const getMailingDetail = idMailing => {
    return apiRequest('GET', `Mailings/Details/${idMailing}`);
};

const updateMailing = data => {
    const idMailing = data.idMailing;
    return apiRequest('PUT', `Mailings/Update/${idMailing}`, data);
};

const newMailing = data => {
    return apiRequest('POST', 'Mailings/New', data);
};

const deleteMailing = data => {
    const idMailing = data.idMailing;
    return apiRequest('DELETE', `Mailings/Delete/${idMailing}`);
};

//TARGETS

const getTargetsList = data => {
    return apiRequest('POST', 'Target/List', data);
};
const getTargetDetail = idTarget => {
    return apiRequest('GET', `Target/Details/${idTarget}`);
};

const updateTarget = data => {
    const idTarget = data.idTarget;
    return apiRequest('PUT', `Target/Update/${idTarget}`, data);
};

const newTarget = data => {
    return apiRequest('POST', 'Target/New', data);
};

const deleteTarget = data => {
    const idTarget = data.idTarget;
    return apiRequest('DELETE', `Target/Delete/${idTarget}`);
};

// WORKSHEETS

const getWorksheetsList = data => {
    return apiRequest('POST', 'Worksheets/List', data);
};
const getWorksheetDetail = idWorksheet => {
    return apiRequest('GET', `Worksheets/Details/${idWorksheet}`);
};

const updateWorksheet = data => {
    const { idWorkSheet } = data;
    return apiRequest('PUT', `Worksheets/Update/${idWorkSheet}`, data);
};

const newWorksheet = data => {
    return apiRequest('POST', 'Worksheets/New', data);
};

const deleteWorksheet = data => {
    const idWorkSheet = data.idWorkSheet;
    return apiRequest('DELETE', `Worksheets/Delete/${idWorkSheet}`);
};
const serviceTemplates = {
    getMailingDetail,
    updateMailing,
    newMailing,
    deleteMailing,
    getTargetDetail,
    updateTarget,
    newTarget,
    deleteTarget,
    getWorksheetDetail,
    updateWorksheet,
    newWorksheet,
    deleteWorksheet,
    getTargetsList,
    getWorksheetsList,
};

export default serviceTemplates;