import React, {useState, useEffect} from 'react';
import { Outlet } from 'react-router-dom';
import Header from './layout/Header';
import { Grid } from '@mui/material';
import { AppProvider } from './context/SchedulerContext';

const Home = () => {  
  return (
    <AppProvider>
      <Grid container>
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item xs={12}>
          <Outlet />
        </Grid>
      </Grid>
    </AppProvider>
  );
};

export default Home;