import React from 'react';
import Loading from '@components/Loading';
import { useFlags } from '@hooks/useFlags';
import { useStyles } from '@styles/loginParticipants.style';
import SignInParticipant from './SignInParticipant';

const LoginParticipantsPage = () => {
    const classes = useStyles();
    const { flagOptions } = useFlags();
    sessionStorage.setItem('lang', 'es');

    if (!flagOptions) return <Loading />;

    return (
        <div className={classes.loginParticipants}>
            <SignInParticipant flagOptions={flagOptions} />
        </div>
    );
};

export default LoginParticipantsPage;