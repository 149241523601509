import React from 'react';
import { Box, Button, Hidden, Tooltip } from '@mui/material';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import { useStyles } from '@styles/form.style';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const FormActions = ({
    show,
    handleDelete,
    handleUndo,
    handleSave,
    disabledButton,
    urlBack,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (

        <Box display='flex' alignItems='center'>
            {show && (
                <Tooltip title={t('BUTTON.DELETE')} style={{ marginRight: '10vw', marginLeft : '10vw'}}>
                    <Box mr={1}>
                        <Button
                            variant='contained'
                            onClick={handleDelete}
                            color='error'
                            className={classes.buttonIcon}>
                            <DeleteRoundedIcon color='blue' />
                        </Button>
                    </Box>
                </Tooltip>
            )}

            {urlBack && (
                <Box mr={1}>
                    <Hidden smDown>
                        <Button
                            variant='contained'
                            onClick={() => {
                                navigate(urlBack);
                            }}
                            size='medium'
                            startIcon={<ArrowBackIcon />}>
                            {t('TEXT.BACK')}
                        </Button>
                    </Hidden>
                    <Hidden smUp>
                        <Button
                            variant='contained'
                            onClick={() => {
                                navigate(urlBack);
                            }}
                            className={classes.buttonIcon}>
                            <ArrowBackIcon />
                        </Button>
                    </Hidden>
                </Box>
            )}


            <Hidden smDown>
                <Tooltip title={t('BUTTON.SAVE')}>
                    <Box mr={1}>
                        <Button
                            color='success'
                            variant='contained'
                            onClick={handleSave}
                            disabled={disabledButton}
                            className={classes.buttonIcon}>
                            <SaveRoundedIcon />
                        </Button>
                    </Box>
                </Tooltip>
            </Hidden>
            <Hidden smUp>
                <Button
                    color='success'
                    variant='contained'
                    onClick={handleSave}
                    disabled={disabledButton}
                    className={classes.buttonIcon}>
                    <SaveRoundedIcon color='white' />
                </Button>
            </Hidden>
         </Box>
    );
};

export default FormActions;