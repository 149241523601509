import React, { useContext, useState, useEffect } from 'react';
import Dialog from '@components/Dialog';
import FormActions from '@components/FormActions';
import HeaderTitle from '@components/HeaderTitle';
import { ListContext } from '@context/ListContext';
import { useForm } from '@hooks/useForm';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import serviceTemplates from '@services/serviceTemplates';
import { useCommonStyles } from '@styles/common.style';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Card from '@components/Card';
import Loading from "@components/Loading";

const TemplateTargetDetail = () => {
    const classes = useCommonStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);
    const { languageList } = useContext(ListContext);
    const [loading, setLoading] = useState(false);
  
    let targetNew = JSON.parse(sessionStorage.getItem('targetNew'));

    const {
        state: { data: TEMPLATE_TARGET_DATA, type: TYPE },
    } = useLocation();

    const HEADER_TITLE =
        TYPE === 'NEW'
            ? t('TARGET.NEW_TITLE')
            : t('TARGET.UPDATE_TITLE');
    const HEADER_SUBTITLE =
        TYPE === 'NEW'
            ? t('TARGET.NEW_SUBTITLE')
            : t('TARGET.UPDATE_SUBTITLE');

    const values = TYPE === 'NEW' ? { locale: [] } : TEMPLATE_TARGET_DATA;

    const { formData, setFormData } = useForm(values);

    useEffect(() => {
        if (targetNew) {
            setFormData(TEMPLATE_TARGET_DATA);
            sessionStorage.removeItem('targetNew');
        }
    }, [formData])
    
    const areLocaleNamesEmpty = () => {
      return formData.locale.every(locale => !locale.targetLocaleName);
    };

    const disabledSaveButton = TEMPLATE_TARGET_DATA === formData || areLocaleNamesEmpty();

    const handleSubmit = type => {
        setLoading(true)
        switch (type) {
            case 'NEW':
                serviceTemplates.newTarget(formData).then(response => handleResponse(response, 'SAVE'))
                break;
            case 'UPDATE':
                serviceTemplates.updateTarget(formData).then(response => handleResponse(response, 'SAVE'))
                break;
            case 'DELETE':
                serviceTemplates.deleteTarget(formData).then(response => handleResponse(response, 'DELETE'))
                break;
            default:
                break;
        }
    };

    const handleResponse = (response, actionType) => {
      setLoading(false);
      if (!response.error) {
        sessionStorage.setItem('targetNew', JSON.stringify(response.data));
        if (actionType === 'DELETE') { //Al Eliminar vuelve a la página anterior
          navigate('/templateTargets');
        } else if (actionType === 'SAVE' && TYPE === 'NEW') { //Al Crear nuevo o Actualizar se queda en la página
            serviceTemplates.getTargetDetail(response.data.idTarget).then(response => {
                if (!response.error) {
                    navigate('/templateTargetDetail', {
                        state: { data: response.data, type: 'UPDATE' },
                    });
                }
            });
        }
      } 
    }

    const handleLocaleChange = (e, idLanguage) => {
        sessionStorage.setItem('idLanguageLocale', idLanguage);
        let localeLanguage = formData?.locale || [];

        let index = localeLanguage.findIndex(
            locale => locale.idLanguage === idLanguage,
        );
        if (index < 0) {
            localeLanguage.push({
                idLanguage: idLanguage,
                idTarget: formData.idTarget,
            });
            index = localeLanguage.length - 1;
        }
        localeLanguage[index][e.target.name] = e.target.value;
        setFormData({ ...formData, locale: localeLanguage });
    };

    return (
        <div className={classes.container}>
            <Dialog
                open={openDialog}
                hasCloseBtn={false}
                height={200}
                title={t('CONFIRM.TITLE_TARGETDETAILS')}
                subtitle={t('CONFIRM.UNRECOVERY_DELETE2')}
                close={() => setOpenDialog(false)}
                actions={
                    <Box display='flex' alignItems='center'>
                        <Box mr={1}>
                            <Button
                                variant='contained'
                                onClick={() => setOpenDialog(false)}
                                color='error'>
                                {t('BUTTON.CANCEL')}
                            </Button>
                        </Box>
                        <Button variant='contained' onClick={() => handleSubmit('DELETE')}>
                            {t('BUTTON.DELETE')}
                        </Button>
                    </Box>
                }
            />

            <HeaderTitle title={HEADER_TITLE} subtitle={HEADER_SUBTITLE}>
                <FormActions
                    show={TYPE === 'UPDATE'}
                    handleDelete={() => setOpenDialog(true)}
                    handleUndo={() => { }}
                    handleSave={() => handleSubmit(TYPE)}
                    disabledButton={disabledSaveButton}
                    urlBack='/templateTargets'
                />
            </HeaderTitle>

            <Grid container spacing={2}>
                <Grid item sm={6} lg={4} xs={12}>
                    <Card>
                        <Typography color='primary' variant='subtitle1' mb={1}>
                            {t('TARGET.NAME_BY_COUNTRY')}
                        </Typography>
                        {languageList?.map(lang => {
                            const localeLanguage = formData?.locale?.find(
                                value => value.idLanguage === lang.idLanguage,
                            );
                            return (
                                <Box display='flex' key={lang.idLanguage}>
                                    <Box
                                        display='flex'
                                        alignItems='center'
                                        justifyContent='center'
                                        mr={2}
                                        mt={1}>
                                        {lang.Icon()}
                                    </Box>

                                    <TextField
                                        name='targetLocaleName'
                                        fullWidth
                                        size='small'
                                        margin='dense'
                                        value={localeLanguage?.targetLocaleName || ''}
                                        onChange={e => {
                                            handleLocaleChange(e, lang.idLanguage);
                                        }}
                                        inputProps={{ maxLength: 100 }}
                                    />
                                </Box>
                            );
                        })}
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default TemplateTargetDetail;