import React, { useState, useEffect } from "react";
import WorksheetCard from "./WorksheetCard";
import serviceMobileSheets from "../../../../services/serviceMobileSheets";
import Loading from '@components/Loading';
import { useTranslation } from 'react-i18next'
import { useStyles } from '@styles/owToolboxKitOw.style'

const WorksheetsList = () => {
  const { i18n, t } = useTranslation()
  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(true);
  const[langId, setLangId] = useState()
  const classes = useStyles()

 const idParticipant = JSON.parse(sessionStorage.getItem('userSession')).idParticipant;

  //const idParticipant = 1030

  const fetchData = async (idParticipant) => {
      const { data, error } = await serviceMobileSheets.getMobileSheetsListByParticipant({
          IdParticipant: idParticipant,
          IdLanguage: langId,
          kitOW: false
      });
    if (!error) {
      const filteredFormData = data?.filter((dataCard, index, array) =>
      array.findIndex((card) => card.idMobileSheet === dataCard.idMobileSheet && card.idLanguage === dataCard.idLanguage) === index
      );  
      //const orderFormData = filteredFormData.filter(dataCard => dataCard.idLanguage === langId).sort((a, b) => a.mobileSheetTitle.localeCompare(b.mobileSheetTitle));
      const orderFormData = filteredFormData.filter(dataCard => dataCard.idLanguage === langId);

      setFormData(orderFormData);
      setLoading(false);
    }
  };

  useEffect(() => {
    const tempLang = sessionStorage.getItem("lang")
    if (tempLang === 'es') {
      setLangId(1)
    } else if (tempLang === 'en') {
      setLangId(2)
    } else if (tempLang === 'it') {
      setLangId(3)
    }
  }, [i18n, t]);
  
  useEffect(() => {
    if (idParticipant !== undefined && langId !== undefined) {
      fetchData(idParticipant);
    }
  }, [langId, idParticipant]);
  

  const handleFavorite = async (idMobileSheet, favorite) => {
    if (favorite === null) {
      const tempData = {
        IdParticipant: idParticipant,
        IdMobileSheet: idMobileSheet
      };

      const { error } = await serviceMobileSheets.newMobileSheetFavorite(tempData);
      if (!error) {
        fetchData(idParticipant);
      }
    } else {
      const { error } = await serviceMobileSheets.deleteMobileSheetFavorite(favorite);
      if (!error) {
        fetchData(idParticipant);
      }
    }
  };

 

  return (
    <>
      {!loading ? (
        <div style={{padding: '8px'}}>
          {formData.map((dataCard, i) => (
            <WorksheetCard
              key={`card${i}`}
              dataCard={dataCard}
              handleFavorite={handleFavorite}
            />
          ))}
        </div>
      ) : (
        <Loading height='50vh' />
      )}
    </>
  );
};

export default WorksheetsList;
