import React, { useState, useEffect } from 'react'
import Editor from '@components/CkEditor'
import Dialog from '@components/Dialog'
import FormActions from '@components/FormActions'
import HeaderTitle from '@components/HeaderTitle'
import LocaleTabs from '@components/LocaleTabs'
import { useForm } from '@hooks/useForm'
import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Snackbar,
  Alert,
  Tooltip,
  FormControl,
  InputLabel,
  Select
} from '@mui/material'
import servicePreparation from '@services/servicePreparation'
import { useCommonStyles } from '@styles/common.style'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import serviceMailing from '@services/serviceMailing'
import Card from '@components/Card'
import Loading from '@components/Loading'
import { snackActions } from '@helpers/snackbarUtils'

const PreparationDetail = () => {
  const classes = useCommonStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [openDialog, setOpenDialog] = useState(false)
  const [copied, setCopied] = useState(false)
  const [tags, setTags] = useState(null)
  const [loading, setLoading] = useState(true)

  const {
    state: { data: TEMPLATE_PREPARATION_DATA, type: TYPE }
  } = useLocation()

  const HEADER_TITLE =
    TYPE === 'NEW'
      ? t('BUTTON.NEW_PREPARATION')
      : t('CONFIG_JOURNEYS.PREPARATION.UPDATE_PREPARATION')
  const HEADER_SUBTITLE =
    TYPE === 'NEW' ? t('MAILING.NEW_SUBTITLE') : t('MAILING.UPDATE_SUBTITLE')

  const values = TYPE === 'NEW' ? { preparationLocale: [] } : TEMPLATE_PREPARATION_DATA
  const { formData, setFormData, handleChange } = useForm(values)
  const disabledSaveButton = TEMPLATE_PREPARATION_DATA === formData

  let preparationNew = JSON.parse(sessionStorage.getItem('preparationNew'))

  useEffect(() => {
    if (preparationNew) {
      setFormData(preparationNew)
      sessionStorage.removeItem('preparationNew')
    }
  }, [])

  useEffect(() => {
    serviceMailing.getTagsDetail().then(response => {
      if (!response.error) {
        setTags(response.data)
      }
      setLoading(false)
    })
  }, [])

  const handleSubmit = type => {
    setLoading(true)
    let hasError = false
  
    if (type !== 'DELETE' && !formData.preparationName) {
      snackActions.error(t('TEXT.ERROR_MESSAGE_TEMPLATE_CODE'))
      hasError = true
    }
  
    // Validación para asegurar que haya al menos un PreparationSubject
    const hasAtLeastOneSubject = formData.preparationLocale.some(
      locale => locale.preparationSubject && locale.preparationSubject.trim() !== ''
    )
  
    if (!hasAtLeastOneSubject) {
      snackActions.error(t('TEXT.ERROR_MESSAGE_SUBJECT'))
      hasError = true
    }
  
    if (hasError) {
      setLoading(false)
      return
    }
  
    const cleanPreparationLocale = locales => {
      return locales.map(locale => {
        const { idPreparationLocale, ...rest } = locale
        return rest
      })
    }
  
    switch (type) {      
      case 'NEW':
        const newFormData = {
          ...formData,
          preparationLocale: cleanPreparationLocale(formData.preparationLocale)
        }
        servicePreparation.newPreparation(newFormData)
          .then(response => {
            if (!response.error) {
              setFormData({
                ...formData,
                idPreparation: response.data.idPreparation,
                preparationLocale: response.data.preparationLocale
              })
              handleResponse(response, 'SAVE')
            } else {
              handleResponse(response, 'ERROR')
            }
          })
          .catch(() => {
            setLoading(false)
          })
        break
      case 'UPDATE':
        servicePreparation.updatePreparation(formData)
          .then(response => {
            if (!response.error) {
              handleResponse(response, 'SAVE')
            } else {
              handleResponse(response, 'ERROR')
            }
          })
          .catch(() => {
            setLoading(false)
          })
        break
      case 'DELETE':
        if (formData.idPreparation) {
          servicePreparation.deletePreparation(formData)
            .then(response => handleResponse(response, 'DELETE'))
            .catch(() => {
              setLoading(false)
            })
        } else {
          setLoading(false)
        }
        break
      default:
        setLoading(false)
        break
    }
  }
  
  const handleResponse = (response, actionType) => {
    setLoading(false);
    if (!response.error) {
      if (actionType === 'DELETE') { //Al Eliminar vuelve a la página anterior
        navigate('/preparations');
      } else if (actionType === 'SAVE' && TYPE === 'NEW') {  //Al Crear nuevo o Actualizar navega al caso UPDATE
        servicePreparation.getPreparationDetails(response.data.idPreparation).then(response => {
          if (!response.error) {
            setFormData(response.data)
            navigate('/preparationDetail', {
              state: { data: response.data, type: 'UPDATE' }
            })
          }
        })
      } else if (actionType === 'SAVE' && TYPE === 'UPDATE') {
        // Manejar la lógica de actualización si es necesario
      }
    }
  }
  


  const handleLocaleChange = (data, idLanguage, name) => {
    let localeLanguage = formData?.preparationLocale || []
    let index = localeLanguage.findIndex(
      locale => locale.idLanguage === idLanguage
    )
    if (index < 0) {
      localeLanguage.push({
        idLanguage: idLanguage,
        idPreparation: formData.idPreparation
      })
      index = localeLanguage.length - 1
    }
    localeLanguage[index][name] = data
    setFormData({ ...formData, preparationLocale: localeLanguage })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <div className={classes.container}>
      <Dialog
        open={openDialog}
        hasCloseBtn={false}
        height={200}
        title={t('CONFIRM.TITLE_PREPARATION')}
        subtitle={t('CONFIRM.UNRECOVERY_DELETE')}
        close={() => setOpenDialog(false)}
        actions={
          <Box display='flex' alignItems='center'>
            <Box mr={1}>
              <Button
                variant='contained'
                onClick={() => setOpenDialog(false)}
                color='error'
              >
                {t('BUTTON.CANCEL')}
              </Button>
            </Box>
            <Button variant='contained' onClick={() => handleSubmit('DELETE')}>
              {t('BUTTON.DELETE')}
            </Button>
          </Box>
        }
      />

      <HeaderTitle title={HEADER_TITLE} subtitle={HEADER_SUBTITLE}>
        <FormActions
          show={TYPE === 'UPDATE' && values.idMailingType != 12 && formData?.preparationLocale?.[0]?.idPreparation !== 0}
          handleDelete={() => setOpenDialog(true)}
          handleUndo={() => {}}
          handleSave={() => handleSubmit(TYPE)}
          disabledButton={disabledSaveButton}
          urlBack='/preparations'
        />
      </HeaderTitle>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={copied}
        autoHideDuration={500}
        onClose={() => setCopied(false)}
      >
        <Alert
          variant='filled'
          sx={{
            width: 300,
            color: 'success'
          }}
          onClose={() => setCopied(false)}
          severity='success'
        >
          {t('MAILING.COPY_TO_CLIPBOARD_SUCCESS')}
        </Alert>
      </Snackbar>
      <Card style={{ width: '100%' }}>
        <Grid container spacing={2}>
          <Grid item md={3} xs={12}>
            <TextField
              label={t('LABEL.TEMPLATE_CODE')}
              name='preparationName'
              value={formData?.preparationName || ''}
              size='small'
              onChange={handleChange}
              margin='normal'
              fullWidth
              inputProps={{ maxLength: 100 }}
              required
            />
          </Grid>
        </Grid>
      </Card>
      <Grid container spacing={2}>
        <Grid item xs={12} mt={2}>
          <Card>
            <LocaleTabs
              locale={formData?.preparationLocale}
              content={(localeLanguage, lang) => (
                <Grid container>
                  <Grid item xs={12} md={5} mt={2}>
                    <TextField
                      fullWidth
                      margin='normal'
                      label={t('MAILING.SUBJECT')}
                      value={localeLanguage?.preparationSubject || ''}
                      size='small'
                      required
                      onChange={e => {
                        handleLocaleChange(
                          e.target.value,
                          lang.idLanguage,
                          'preparationSubject'
                        )
                      }}
                    />
                  </Grid>

                  <Grid container spacing={2} mt={2}>
                    <Grid item md={8} xs={12}>
                      <Editor
                        data={localeLanguage?.preparationBody || ''}
                        onChange={newData => {
                          handleLocaleChange(
                            newData,
                            lang.idLanguage,
                            'preparationBody'
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TableContainer
                        style={{
                          border: '1px solid #c4c4c4',
                          borderRadius: 4
                        }}
                      >
                        <Table>
                          <TableHead
                            style={{
                              background: '#f1f1f1',
                              height: '40px',
                              padding: '3px'
                            }}
                          >
                            <TableRow padding='none'>
                              <TableCell
                                padding='none'
                                style={{ paddingLeft: '20px' }}
                              >
                                {t('MAILING.COPY_TO_CLIPBOARD')}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell style={{ padding: '10px' }}>
                                {tags?.map((item, i) => (
                                  <CopyToClipboard
                                    key={i}
                                    text={item.tagName}
                                    onCopy={() => {
                                      setCopied(true)
                                    }}
                                  >
                                    <Tooltip
                                      title={t(
                                        'MAILINGS_TAGS.' + item.tagNameLiteral
                                      )}
                                      arrow
                                      placement='right'
                                    >
                                      <Button
                                        style={{ margin: '3px' }}
                                        variant='contained'
                                        size='medium'
                                        color='primary'
                                        endIcon={<ContentCopyIcon />}
                                      >
                                        {item.tagName}
                                      </Button>
                                    </Tooltip>
                                  </CopyToClipboard>
                                ))}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            />
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default PreparationDetail
