import React, { createContext, useContext, useState, useEffect } from 'react';
import serviceScheduler from '@services/serviceScheduler'

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [idUserSelected, setIdUserSelected] = useState();
  const [userList, setUserList] = useState([])
  const [someChange, setSomeChange] = useState(true)

  useEffect(() => {
    if (someChange) {
      fetchSchedulerUsers()
      setSomeChange(false)
    }
  }, [someChange])

  const fetchSchedulerUsers = async () => {
    const {data} = await serviceScheduler.getSchedulerUsers({
      pageSize: 100, 
      pageNumber: 0, 
      globalSearch: '', 
      search: [
          {
              searchField: 'isAdminScheduler',
              searchMode: 'equalTo',
              searchValue: 0,
          },
      ],
      order: [
          {
              orderField: 'name',
              orderAsc: true
          }
      ]
    })
    setUserList(data)
    setSomeChange(false)
  }

  return (
    <AppContext.Provider value={{ idUserSelected, setIdUserSelected, userList, setUserList, someChange, setSomeChange }}>
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, useAppContext };