import { ListContext } from '@context/ListContext'
import { Box, Tab, Tabs } from '@mui/material'
import React, { Fragment, useContext, useState, useEffect } from 'react'

function TabPanel(props) {
    const { children, value, index, ...other } = props
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    )
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const LocaleTabs = ({ locale, content, activeLanguage }) => {
    const { languageList } = useContext(ListContext)
    const getStoredLanguage = () => {
        const storedLang = sessionStorage.getItem('lang')
        // console.log('storedLang', storedLang);
        var idLanguage = languageList?.find((lang) => lang.languageCode === storedLang).idLanguage
        // console.log('idLanguage', idLanguage);
        return idLanguage;
    }

    const tabLang = parseInt(sessionStorage.getItem('tabLang'));
    let activeTabIndex;

    if (tabLang && tabLang !== undefined && tabLang !== null) {
        activeTabIndex = tabLang; 
    } else if (activeLanguage === undefined) {
        const storedLanguage = getStoredLanguage();  // Llama a getStoredLanguage si activeLanguage es undefined
        activeTabIndex = languageList?.findIndex((lang) => lang.idLanguage == storedLanguage);
    }else {
        activeTabIndex = languageList?.findIndex((lang) => lang.idLanguage == activeLanguage);
    }

    // Si `activeTabIndex` es -1 (no se encontr�), usa 0 como valor por defecto
    const validTabIndex = activeTabIndex >= 0 ? activeTabIndex : 0

    const [value, setValue] = useState(validTabIndex)  

    useEffect(() => {
         setValue(validTabIndex)
    }, [activeTabIndex])

    return (
        <Fragment>
            <Tabs
                scrollButtons
                textColor="inherit"
                value={value}
                selectionFollowsFocus
                sx={{ borderBottom: 1, borderColor: 'divider' }}
                onChange={(_, newValue) => {                    
                    setValue(newValue)                    
                    sessionStorage.setItem('tabLang', newValue)
                }}
                aria-label="basic tabs example"
            >
                {languageList?.map((lang, i) => (
                    <Tab
                        key={`item${i}`}
                        {...a11yProps(i)}
                        icon={lang.Icon()}
                        sx={{
                            p: 0,
                            '&.Mui-selected': {
                                color: 'white',
                                bgcolor: 'grey.main',
                            },
                        }}
                    />
                ))}
            </Tabs>

            {languageList?.map((lang, i) => {
                const localeLanguage = locale?.find(
                    (value) => value.idLanguage === lang.idLanguage
                )

                return (
                    <TabPanel value={value} index={i} key={i}>
                        {content(localeLanguage, lang)}
                    </TabPanel>
                )
            })}
        </Fragment>
    )
}

export default LocaleTabs
