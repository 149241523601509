import apiRequest from '@helpers/apiRequest';

const getSurveyQuestions = data => {
    return apiRequest('POST', 'EvaluatorInfo/SurveyQuestions/List', data);
};
const getSurveyQuestionsDetails = idEvaluatorSurveyQuestion => {
    return apiRequest('GET', `EvaluatorInfo/SurveyQuestions/Details/${idEvaluatorSurveyQuestion}`);
};

const updateSurveyQuestions = data => {
    const idEvaluatorSurveyQuestion = data.idEvaluatorSurveyQuestion;
    return apiRequest('PUT', `EvaluatorInfo/SurveyQuestions/Update/${idEvaluatorSurveyQuestion}`, data);
};

const newSurveyQuestions = data => {
    return apiRequest('POST', 'EvaluatorInfo/SurveyQuestions/New', data);
};

const deleteSurveyQuestions = data => {
    const idEvaluatorSurveyQuestion = data.idEvaluatorSurveyQuestion;
    return apiRequest('DELETE', `EvaluatorInfo/SurveyQuestions/Delete/${idEvaluatorSurveyQuestion}`);
};

const getSurvey = data => {
    return apiRequest('POST', 'EvaluatorInfo/Surveys/List', data);
};
const getSurveyDetails = idEvaluatorSurvey => {
    return apiRequest('GET', `EvaluatorInfo/Surveys/Details/${idEvaluatorSurvey}`);
};

const updateSurvey = data => {
    const idEvaluatorSurvey = data.idEvaluatorSurvey;
    return apiRequest('PUT', `EvaluatorInfo/Surveys/Update/${idEvaluatorSurvey}`, data);
};

const newSurvey = data => {
    return apiRequest('POST', 'EvaluatorInfo/Surveys/New', data);
};

const deleteSurvey = data => {
    const idEvaluatorSurvey = data.idEvaluatorSurvey;
    return apiRequest('DELETE', `EvaluatorInfo/Surveys/Delete/${idEvaluatorSurvey}`);
};

const getTypesQuestions = data => {
    return apiRequest('POST', 'EvaluatorInfo/TypeQuestions/List', data);
};
const getTypesQuestionsDetails = idEvaluatorTypeQuestion => {
    return apiRequest('GET', `EvaluatorInfo/TypeQuestions/Details/${idEvaluatorTypeQuestion}`);
};

const updateTypesQuestions = data => {
    const idEvaluatorTypeQuestion = data.idEvaluatorTypeQuestion;
    return apiRequest('PUT', `EvaluatorInfo/TypeQuestions/Update/${idEvaluatorTypeQuestion}`, data);
};

const newTypesQuestions = data => {
    return apiRequest('POST', 'EvaluatorInfo/TypeQuestions/New', data);
};

const deleteTypesQuestions = data => {
    const idEvaluatorTypeQuestion = data.idEvaluatorTypeQuestion;
    return apiRequest('DELETE', `EvaluatorInfo/TypeQuestions/Delete/${idEvaluatorTypeQuestion}`);
};

const getTypes = data => {
    return apiRequest('POST', 'EvaluatorInfo/Types/List', data);
};

const getTypesDetails = id => {
    const idEvaluatorType = id;
    return apiRequest('GET', `EvaluatorInfo/Types/Details/${idEvaluatorType}`);
};

const updateTypes = data => {
    const idEvaluatorType = data.idEvaluatorType;
    return apiRequest('PUT', `EvaluatorInfo/Types/Update/${idEvaluatorType}`, data);
};

const newTypes = data => {
    return apiRequest('POST', 'EvaluatorInfo/Types/New', data);
};

const deleteTypes = data => {
    const idEvaluatorType = data.idEvaluatorType;
    return apiRequest('DELETE', `EvaluatorInfo/Types/Delete/${idEvaluatorType}`);
};

const getEvaluatorAutoresponses = id => {
    const IdEvaluatorParticipant = id;
    return apiRequest('GET', `EvaluatorInfo/AutoEvaluator/Responses/${IdEvaluatorParticipant}`);
};

const getEvaluatorAutoevaluationByCategories = id => {
    const IdEvaluatorParticipant = id;
    return apiRequest('GET', `EvaluatorInfo/AutoEvaluator/ByCategories/${IdEvaluatorParticipant}`);
}; 

const serviceEvaluatorInfo = {
    getSurveyQuestions,
    getSurveyQuestionsDetails,
    updateSurveyQuestions,
    newSurveyQuestions,
    deleteSurveyQuestions,
    getSurvey,
    getSurveyDetails,
    updateSurvey,
    newSurvey,
    deleteSurvey,
    getTypesQuestions,
    getTypesQuestionsDetails,
    updateTypesQuestions,
    newTypesQuestions,
    deleteTypesQuestions,
    getTypes,
    getTypesDetails,
    updateTypes,
    newTypes,
    deleteTypes,
    getEvaluatorAutoresponses,
    getEvaluatorAutoevaluationByCategories
 
};

export default serviceEvaluatorInfo;