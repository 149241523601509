import apiRequest from '@helpers/apiRequest';


const getCycleParticipantsDetailsByIdCycle = IdCycle => {
    return apiRequest('GET', `CycleParticipants/DetailsByIdCycle/${IdCycle}`);
};

const updateCycleParticipant = data => {
    const IdCycleParticipant = data.idCycleParticipant;
    return apiRequest('PUT', `CycleParticipants/Update/${IdCycleParticipant}`, data);
};

const getCycleActive = data => {
    return apiRequest('GET', `CycleParticipants/getCycleActive`);
};



const serviceCycleParticipants = {
    getCycleParticipantsDetailsByIdCycle,
    updateCycleParticipant,
    getCycleActive
};

export default serviceCycleParticipants;