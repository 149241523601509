import React, { Fragment, useState, useEffect } from 'react'
import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  ListItemIcon, 
  IconButton
} from '@mui/material'
import { useCommonStyles } from '@styles/common.style'
import { useTranslation } from 'react-i18next'
import Card from '@components/Card'
import DialogPreparations from '@components/DialogPreparations'
import VisibilityIcon from '@mui/icons-material/Visibility'

const PreparationConfiguration = ({
  getNewJourneys,
  isUpdate,
  setFormData,
  formData,
  journey,
  preparations,
  selectShownFace,
  selectShownVirtual,
  showPreparationItems
}) => {
  const classes = useCommonStyles();
  const { t } = useTranslation()
  const [lJourney, setLJourney] = useState(journey)
  
  const [showPreparationModal, setShowPreparationModal] = useState({
    open: false,
    loading: true,
    content: {}
  })
    
  const manageRenderValue = (selected) => {
    if (preparations != undefined) {
      const name = preparations.find(e => e.idPreparation === selected)
      if (selected == 0) {
        return t(name.preparationName)
      } else if (name != undefined) {
        return name.preparationName
      }
    }
  }

  useEffect(() => {
    manageRenderValue();
  },[])

  useEffect(() => {
    if (selectShownFace == false) {
      const name = 'cycleTypeJourneyPIdPreparation';
      removeKey(name);
    }
    if (selectShownVirtual == false) {
      const name = 'cycleTypeJourneyDIdPreparation';
      removeKey(name);
    }
  }, [selectShownFace,selectShownVirtual]);


  const removeKey = (name) => {
    setLJourney(current => {
      const {cycleTypeJourneyPIdPreparation, ...rest} = current;
      return rest;
    })
    const newJourneys = getNewJourneys('', name)
    setFormData({
      ...formData,
      cycleTypeJourneys: newJourneys
    }) 
  }

  const handleChangeSelect = (e) => {
    setLJourney({ ...lJourney, [e.target.name]: e.target.value })
    const newJourneys = getNewJourneys(e.target.value, e.target.name)
    setFormData({
      ...formData,
      cycleTypeJourneys: newJourneys
    })    
  }
 
 
  return (
    <Fragment>
      {showPreparationModal.open && (
        <DialogPreparations
          showPreparationModal={showPreparationModal}
          setShowPreparationModal={setShowPreparationModal}
        />
      )}
      <Typography
        textAlign='center'
        color='white'
        variant='subtitle1'
        sx={{
          bgcolor: 'grey.main',
          color: 'primary.main',
          borderRadius: 1,
          marginBottom: 2,
          fontSize: 14,
          fontWeight: 'bold',
          py: 1
        }}
      >
        {t('CONFIG_JOURNEYS.PREPARATION.CONFIG').toUpperCase()}
      </Typography>
      <Card fullHeight>
        <Grid item md={12} xs={12}>
          { selectShownFace && <FormControl fullWidth size='small' margin='normal'>          
            <Grid container spacing={2}>
                <Grid item xs={11}>
                    <Typography variant='caption' component='div'>
                    {t('CONFIG_JOURNEYS.PREPARATION.PREPARATION_FACETOFACE')}
                    </Typography>
                </Grid>
            </Grid>
            <Select
              className={classes.select}
              value={journey?.cycleTypeJourneyPIdPreparation || 0}
              onChange={e => handleChangeSelect(e)}
              name='cycleTypeJourneyPIdPreparation'
              renderValue={selected => manageRenderValue(selected)}
            >
              {showPreparationItems({ setShowPreparationModal })} {/* CONP */}
            </Select>
          </FormControl>}
        </Grid>
        <Grid item md={12} xs={12}>
            { selectShownVirtual && <FormControl fullWidth size='small' margin='normal'>
                <Grid container spacing={2}>
                    <Grid item xs={11}>
                        <Typography variant='caption' component='div'>
                        {t('CONFIG_JOURNEYS.PREPARATION.PREPARATION_DIGITAL')}
                        </Typography>
                    </Grid>
                </Grid>
                <Select
                  className={classes.select}
                  value={journey?.cycleTypeJourneyDIdPreparation || 0}
                  onChange={e => handleChangeSelect(e)}
                  name='cycleTypeJourneyDIdPreparation'
                  renderValue={selected => manageRenderValue(selected)}
                >
                  {showPreparationItems({ setShowPreparationModal })}
                </Select>
            </FormControl>}
        </Grid>
      </Card>
    </Fragment>
  )
}

export default PreparationConfiguration
