import React from 'react'
import AddButton from '@components/AddButton'
import DynamicTable from '@components/DynamicTable'
import HeaderTitle from '@components/HeaderTitle'
import { IconButton } from '@mui/material'
import serviceConsultants from '@services/serviceConsultants'
import { useCommonStyles } from '@styles/common.style'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { EditRounded } from '@mui/icons-material'
import { snackActions } from '@helpers/snackbarUtils';

const Consultants = () => {
  const classes = useCommonStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const BOOLEANS = [
    {
      label: t('LABEL.FALSE'),
      value: '0'
    },
    {
      label: t('LABEL.TRUE'),
      value: '1'
    }
  ]

  const filterMap = value => {
    if (value == '') return ''
    let res = BOOLEANS.filter(x =>
      x.label.toLowerCase().includes(value.toLowerCase())
    )
    return res.length ? res[0].value : '|'
  }

  const ORDER = [
    {
      orderField: 'consultantSurname',
      orderAsc: true
    }
  ]
  const COLUMNS = [
    {
      accessor: '',
      Header: 'Info',
      filterable: false,
      hideHeader: true,
      width: '5%',
      Cell: ({ row: { values } }) => (
        <IconButton
          aria-label='info'
          color='info'
          style={{
            padding: '0px'
          }}
          onClick={() => {
            handleShowConsultantDetail(values.idConsultant)
          }}
        >
          <EditRounded fontSize='inherit' />
        </IconButton>
      )
    },
    {
      accessor: 'idConsultant',
      Header: t('LABEL.ID'),
      filterable: false,
      width: '5%'
    },
    {
      accessor: 'consultantCode',
      Header: t('LABEL.CODE')
    },
    {
      accessor: 'consultantName',
      Header: t('LABEL.NAME'),
      Cell: props => <p>{props.value}</p>
    },
    {
      accessor: 'consultantSurname',
      Header: t('LABEL.SURNAME'),
      Cell: props => <p>{props.value}</p>
    },
    {
      accessor: 'consultantJobTitle',
      Header: t('LABEL.POSITION'),
      Cell: props => <p>{props.value}</p>
    },
    {
      accessor: 'consultantEmail',
      Header: t('LABEL.EMAIL')
    },
    {
      accessor: 'consultantActive',
      Header: t('LABEL.ACTIVE'),
      filterMap: x => filterMap(x),
      size: 5,
      Cell: props => <p>{t('LABEL.' + props.value.toString().toUpperCase())}</p>
    }
  ]

  const handleShowConsultantDetail = idConsultant => {
    const userSession = JSON.parse(sessionStorage.getItem('userSession'))
    if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 2)) {
        serviceConsultants.getConsultantsDetails(idConsultant).then(response => {
          if (!response.error) {
            navigate(`/consultantDetail`, {
              state: { data: response.data, type: 'UPDATE' }
            })
          }
        })
      } else {
        snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'));
    }
  }

  const handleNewConsultant = () => {
    const userSession = JSON.parse(sessionStorage.getItem('userSession'))
    if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 2)) {
        navigate('/consultantDetail', { state: { data: {}, type: 'NEW' } })
      } else {
        snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'));
    }
  }
  return (
    <div className={classes.container}>
      <HeaderTitle
        title={t('MENU_ADMIN.CONSULTANTS')}
        subtitle={t('TEXT.SUBTITLE_CONSULTANTS')}
      >
        <AddButton
          text={t('BUTTON.NEW_CONSULTANT')}
          handleOnclick={handleNewConsultant}
        />
      </HeaderTitle>
      <DynamicTable
        columns={COLUMNS}
        endpoint='Consultants/List'
        isGlobalSearch={true}
        isColumnsSearch={true}
        isOrdered={true}
        viewExcel={true}
        nameExcel={t('MENU_ADMIN.CONSULTANTS')}
        titleBodyExcel={t('CYCLES.BODY_EXCEL')}
        orderColumn={ORDER}
      />
    </div>
  )
}

export default Consultants
