import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import DecoupledEditor from '@cgshealth/ckeditor5-build-decoupled-document-with-table-plugins'
import '@cgshealth/ckeditor5-build-decoupled-document-with-table-plugins/build/translations/es'
import '@cgshealth/ckeditor5-build-decoupled-document-with-table-plugins/build/translations/it'
import { useTranslation } from 'react-i18next'

const EditorUI = ({ data, onChange, disabled = false }) => {
  const { i18n } = useTranslation()

    const handleChange = (event, editor) => {
        const data = editor.getData()
        onChange(data) // Llama a la funci�n de devoluci�n de llamada pasada desde el componente padre con el contenido del editor.
    }

  const defaultConfig = {
    toolbar: {
      items: [
        'bold', 'italic', 'FontColor', 'fontBackgroundColor',
        '|',
        'FontFamily', 'FontSize', 'alignment', 'outdent', 'indent', 
        '|',
        'heading',
        '|',        
        'link',
        '|',
        'bulletedList', 'numberedList',
        '|',
        'undo', 'redo',
        '|',
        'insertTable'
      ]
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'tableProperties',
        'tableCellProperties'
      ]
    },
    fontSize: {
      options: [
        'default',
        {
          title: '10px',
          model: '10px'
        },
        {
          title: '11px',
          model: '11px'
        },
        {
          title: '12px',
          model: '12px'
        },
        {
          title: '14px',
          model: '14px'
        },
        {
          title: '18px',
          model: '18px'
        },
        {
          title: '20px',
          model: '20px'
        },
        {
          title: '22px',
          model: '22px'
        },
        {
          title: '25px',
          model: '25px'
        },
        {
          title: '30px',
          model: '30px'
        },
        {
          title: '40px',
          model: '40px'
        },
        {
          title: '50px',
          model: '50px'
        }
      ],
      supportAllValues: true
    },
    fontFamily: {
      options: [
        {
           title: 'Arial Nova',
           model: 'Arial Nova !important'
        },
        {
          title: 'ArchivoNarrow',
          model: 'ArchivoNarrow !important'
        },
        {
          title: 'Arial',
          model: 'Arial !important'
        },
        {
          title: 'Arvo',
          model: 'Arvo !important'
        },
        {
          title: 'Georgia',
          model: 'Georgia !important'
        },
        {
          title: 'PTSerif',
          model: 'PTSerif !important'
        },
        {
          title: 'Roboto',
          model: 'Roboto !important'
        },
        {
          title: 'Swiss921BT',
          model: 'Swiss921BT !important'
        },
        {
          title: 'Times New Roman',
          model: 'Times New Roman !important'
        },
        {
          title: 'Verdana',
          model: 'Verdana !important'
        },
        {
          title: 'Tahoma',
          model: 'Tahoma !important'
        }
      ],
      supportAllValues: true
    },
    language: i18n.language
  }

  const disabledConfig = {
    toolbar: {
      items: []
    },
    language: i18n.language
  }
  
  return (
    <div className='App' style={{ width: '100%' }}>
      <CKEditor
        onReady={editor => {
          // Insert the toolbar before the editable area.
          editor.ui
            .getEditableElement()
            .parentElement.insertBefore(
              editor.ui.view.toolbar.element,
              editor.ui.getEditableElement()
            )
          editor.isReadOnly = disabled
        }}
        onError={(error, { willEditorRestart }) => {
          // If the editor is restarted, the toolbar element will be created once again.
          // The `onReady` callback will be called again and the new toolbar will be added.
          // This is why you need to remove the older toolbar
          if (willEditorRestart) {
            this.editor.ui.view.toolbar.element.remove()
          }
        }}
        onChange={handleChange}
        editor={DecoupledEditor}
        data={data}
        config={!disabled ? defaultConfig : disabledConfig}
          />

    </div>
  )
}

export default EditorUI
