import React, { Fragment, useContext, useState, useEffect } from 'react'
import Fallback from '@assets/images/avatar.png'
import Dialog from '@components/Dialog'
import Flags from '@components/Flags'
import { AuthContext } from '@context/auth/AuthContext'
import { useFlags } from '@hooks/useFlags'
import Button from '@mui/material/Button'
import { Logout, ManageAccounts } from '@mui/icons-material'
import MenuIcon from '@mui/icons-material/Menu'
import {
  AppBar,
  Avatar,
  Box,
  Grid,
  Hidden,
  Icon,
  Stack,
  Toolbar,
  Typography,
  Select,
  MenuItem,
  IconButton,
  Accordion, 
  AccordionSummary,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon
} from '@mui/material'
import { useStyles } from '@styles/scheduler.style'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import serviceScheduler from '@services/serviceScheduler'
import Loading from "@components/Loading";
import { useAppContext } from '../context/SchedulerContext';

const Header = () => {
  const { i18n, t } = useTranslation()
  const navigate = useNavigate()
  const classes = useStyles()
  const { user, logout } = useContext(AuthContext)
  const { flagOptions, FlagSelected } = useFlags()
  const [openLanguage, setOpenLanguage] = useState(false)
  const [openUsers, setOpenUsers] = useState(false)
  const [language, setLanguage] = useState(null)
  const [userSelected, setUserSelected] = useState({
                                           idUser: user.idUser,
                                           name: user.userName,
                                           surname: user.userSurname,
                                           imageProfile: user.userImage   
                                          })
  const [loading, setLoading] = useState(false);
  const { idUserSelected, setIdUserSelected, userList, setUserList, someChange, setSomeChange } = useAppContext();
  const [showMenu, setShowMenu] = useState(false)
    
  useEffect(() => {
    if (userList?.length > 0 && someChange) {
      const found = userList?.find((userOfList) => userOfList.idUser === user.idUser);
      setUserSelected(found)
      setSomeChange(false)
    }
  }, [user, userList])

  useEffect(() => {
    if (someChange) {
      setIdUserSelected(userSelected.idUser)
      setSomeChange(false)
    }
  }, [userSelected])
  
  
  const handleChangeUser = idUserSelected => {
    if (userList?.length > 0) {
      const found = userList?.find((userOfList) => userOfList.idUser == idUserSelected);
      setUserSelected(found)
      setOpenUsers(false)
    }
    setShowMenu(false)
    setSomeChange(true)
    navigate('/userarea')
  }

  const handleChangeLanguage = () => {
    sessionStorage.setItem('lang', language)
    i18n.changeLanguage(language)
    setOpenLanguage(false)
    setShowMenu(false)
  }

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <Dialog
        open={openLanguage}
        close={() => setOpenLanguage(false)}
        title={t('TEXT.LANGUAGES')}
        subtitle={t('TEXT.SELECT_LANGUAGE')}
        width={300}
        content={
          <Flags
            options={flagOptions}
            handleSelect={e => {
              setLanguage(e.target.value)
            }}
          />
        }
        actions={
          <Box display='flex' alignItems='center'>
            <Box mr={1}>
              <Button
                variant='contained'
                onClick={() => setOpenLanguage(false)}
                color='error'
              >
                {t('BUTTON.CANCEL')}
              </Button>
            </Box>
            <Button
              variant='contained'
              onClick={handleChangeLanguage}
            >
              {t('BUTTON.OK')}
            </Button>
          </Box>
        }
      />
      <Dialog
        open={openUsers}
        close={() => setOpenUsers(false)}
        title={t('SCHEDULER.CHANGE_USER')}
        width={500}
        content={
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Typography sx={{fontWeight: 'bold'}} mr={2}>{t('SCHEDULER.USER')}:</Typography>
            <Select
              required
              id='idUserScheduler'
              fullWidth
              size='small'
              name='idUserScheduler'
              value={0}
              onChange={(e) => handleChangeUser(e.target.value)}
            >
              <MenuItem
                key={0}
                value={0}
              >
                {t('SCHEDULER.SELECT_USER')}
              </MenuItem>
              <MenuItem
                key={user.idUser}
                value={user.idUser}
              >
                {t('SCHEDULER.MY_USER')}
              </MenuItem>
              {userList?.map((userL, i) => (
                userL.idUser !== user.idUser ? (
                  <MenuItem key={userL.idUser} value={userL.idUser}>
                    {userL.name} {userL.surname}
                  </MenuItem>
                ) : null
              ))}
            </Select>
          </div>
        }
      />
      <Hidden mdDown>
        <AppBar position='static' className={classes.header}>
          <Toolbar sx={{display: "flex", justifyContent: "space-between", minHeight:'50px !important', maxWidth: '1024px', minWidth: '900px', marginLeft: 'auto', marginRight: 'auto'}}>
            <Grid onClick={() => console.log('el calendario navegaría al mes actual')} >
              <Avatar
                sx={{ width: 40, height: 40 }}
                src={`data:image/png;base64,${userSelected?.imageProfile}` || Fallback}
                idbutton={'upload-button-userImage'}
                onClick={() => navigate('/userarea')}
              />
            </Grid>
            <Grid item xs={6} onClick={() => setOpenUsers(true)}>
              <Typography className={classes.subtitle2} sx={{color: '#e9a459 !important', cursor: 'pointer'}}>
                {userSelected?.name} {userSelected?.surname}
              </Typography>
            </Grid>
            <Grid display='flex' alignItems='center'>
              <Grid display='flex' flexDirection='column' mr={3}>
                <Stack
                  direction='row'
                  justifyContent='end'
                  alignItems='center'
                  spacing={1}
                >
                  {userSelected.idUser === user.idUser && (
                    <Button mr={1} className={classes.btn} variant='contained' onClick={() => navigate('/profile')}>
                      {t('SCHEDULER.MY_ACCOUNT')}
                    </Button>
                  )}
                  <Button className={classes.btn} variant='contained' onClick={() => logout()}>
                    {t('SCHEDULER.EXIT')}
                  </Button>
                  <Icon
                    style={{
                      marginLeft: '20px',
                      marginRight: '-30px',
                      height: '30px',
                      width: '30px',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      setOpenLanguage(true)
                    }}
                  >
                    <FlagSelected />
                  </Icon>
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Hidden>
      <Hidden mdUp>
        <AppBar position='static'>
          <Toolbar className={classes.header}>
            <Grid onClick={() => console.log('el calendario navegaría al mes actual')} >
              <Avatar
                sx={{ width: 40, height: 40 }}
                src={`data:image/png;base64,${userSelected?.imageProfile}` || Fallback}
                idbutton={'upload-button-userImage'}
                onClick={() => navigate('/userarea')}
              />
            </Grid>
            <Grid item xs={7.5} onClick={() => setOpenUsers(true)}>
              <Typography className={classes.subtitle2} sx={{cursor: 'pointer'}}>
                {userSelected?.name} {userSelected?.surname}
              </Typography>
            </Grid>
            <IconButton
              size='extralarge'
              onClick={() => setShowMenu(prevState => !prevState)}
              sx={{color:'#3788D8'}}
            >
              <MenuIcon className={classes.colorPrimary} sx={{fontSize: '1.5em'}}/>
            </IconButton>
          </Toolbar>
        </AppBar>
      </Hidden>
      <Hidden mdUp>
        <Grid item xs={12}>
          <Accordion
            expanded={showMenu}
            onChange={() => setShowMenu(prevState => !prevState)}
            square
            className={classes.containerVertical}
          >
            <AccordionSummary sx={{ display: 'none' }}></AccordionSummary>
            {userSelected.idUser === user.idUser && (
              <ListItem>
                <ListItemButton
                  onClick={() => navigate('/profile')}>
                  <ListItemIcon> 
                    <ManageAccounts sx={{fontSize: '2em'}}/>
                  </ListItemIcon>
                  <ListItemText 
                    primary={t('SCHEDULER.MY_ACCOUNT')} 
                  />
                </ListItemButton>
              </ListItem>
            )}
            <ListItem>
              <ListItemButton
                onClick={() =>  setOpenLanguage(true)}>
                <ListItemIcon> 
                    <FlagSelected />
                </ListItemIcon>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ListItemText 
                    primary={t('LABEL.LANGUAGE')} 
                  />
                </div>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                onClick={logout}>
                <ListItemIcon> 
                  <Logout sx={{fontSize: '2em'}}/>
                </ListItemIcon>
                <ListItemText 
                  primary={t('SCHEDULER.EXIT')} 
                />
              </ListItemButton>
            </ListItem>
          </Accordion>
        </Grid>
      </Hidden>
    </>
  )
}

export default Header
