import apiRequest from '@helpers/apiRequest';

const getIncidentsList = data => {
    return apiRequest('POST', 'Incidents/List', data);
};
const updateIncident = data => {
    const idIncident = data.idIncident;
    return apiRequest('PUT', `Incidents/Update/${idIncident}`, data);
};
const newIncident = data => {
    return apiRequest('POST', 'Incidents/New', data);
};
const deleteIncident = data => {
    const idIncident = data.idIncident;
    return apiRequest('DELETE', `Incidents/Delete/${idIncident}`);
};

const getIncidentsDetail = idIncident => {
    return apiRequest('GET', `Incidents/Details/${idIncident}`);
};

const changeFromCycle = data => {  
    return apiRequest('POST', `Incidents/ChangeFromCycle`, data);
};

const serviceIncidents = {
    getIncidentsList,
    updateIncident,
    deleteIncident,
    newIncident,
    getIncidentsDetail,
    changeFromCycle
};

export default serviceIncidents;