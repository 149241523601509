import React, {useState, Fragment, useContext } from 'react';

import { useStyles } from '@styles/menu.style';
import {
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Chip,
    Button,
    Box
} from '@mui/material';
import { Logout } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import Dialog from '@components/Dialog'
import { useFlags } from '@hooks/useFlags'
import Flags from '@components/Flags'
import { AuthContext } from '@context/auth/AuthContext'


const MenuList = ({ menuTitle, items, handleClick }) => {
    const classes = useStyles();
    const [openLanguage, setOpenLanguage] = useState(false)
    const [language, setLanguage] = useState(null)
    const { i18n, t } = useTranslation()
    const { flagOptions, FlagSelected } = useFlags()
    const { logout } = useContext(AuthContext)

    
    const handleChangeLanguage = () => {
    sessionStorage.setItem('lang', language)
    i18n.changeLanguage(language)
    setOpenLanguage(false)
  }

  const area = sessionStorage.getItem('area');

    return (
        <Fragment>
      <Dialog
        open={openLanguage}
        close={() => setOpenLanguage(false)}
        title={t('TEXT.LANGUAGES')}
        subtitle={t('TEXT.SELECT_LANGUAGE')}
        width={300}
        content={
          <Flags
            options={flagOptions}
            handleSelect={e => {
              setLanguage(e.target.value)
            }}
          />
        }
        actions={
          <Box display='flex' alignItems='center'>
            <Box mr={1}>
              <Button
                variant='contained'
                onClick={() => setOpenLanguage(false)}
                color='error'
              >
                {t('BUTTON.CANCEL')}
              </Button>
            </Box>
            <Button
              variant='contained'
              onClick={handleChangeLanguage}
            >
              {t('BUTTON.OK')}
            </Button>
          </Box>
        }
      />
        <List dense disablePadding>
            <ListSubheader
                disableSticky
                component='div'
                id='list'
                className={classes.listTitle}
                style={
                  (area.includes("owtoolbox") || area.includes("kitow")) ? {
                  fontSize: 24,
                  textTransform: 'uppercase !important',
                  fontWeight: 'bold !important'
                  } : {}
                }
            >
                {menuTitle}
            </ListSubheader>
            {items.map((item, index) => (
                <ListItem key={index} disablePadding>
                    <ListItemButton
                        onClick={() => handleClick(item)}
                        disabled={item.status === 0}>
                        <ListItemIcon
                          style={
                            (area.includes("owtoolbox") || area.includes("kitow")) ? {
                            marginLeft: '10px'
                            } : {}
                          }
                        >
                          {item.icon}
                        </ListItemIcon>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            <ListItemText  
                              primary={item.title} 
                              primaryTypographyProps={
                                (area.includes("owtoolbox") || area.includes("kitow")) ? {
                                  fontSize: 20,
                                  fontWeight: 'medium',
                                  letterSpacing: 0,
                                } : {}
                              }
                            />
                            {item.status === 1 && (
                                <Chip
                                    style={{ color: 'orange', marginLeft: 10 }}
                                    size='small'
                                    label={'dev'}
                                />
                            )}
                        </div>
                    </ListItemButton>
                </ListItem>
            ))}
            {(area.includes("owtoolbox") || area.includes("kitow")) && (
              <>
               <ListItem disablePadding>
                <ListItemButton
                    onClick={logout}>
                    <ListItemIcon
                      style={
                        (area.includes("owtoolbox") || area.includes("kitow")) ? {
                        marginLeft: '10px'
                        } : {}
                      }
                    > 
                      <Logout />
                    </ListItemIcon>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                        <ListItemText 
                          primary={t('MENU_CONSULTANTS.LOGOUT')} 
                          primaryTypographyProps={
                            (area.includes("owtoolbox") || area.includes("kitow")) ? {
                              fontSize: 20,
                              fontWeight: 'medium',
                              letterSpacing: 0,
                            } : {}
                          }
                        />
                    </div>
                </ListItemButton>
            </ListItem>
             <ListItem disablePadding>
                    <ListItemButton
                        style={
                          (area.includes("owtoolbox") || area.includes("kitow")) ? {
                          marginBottom: '10px'
                          } : {}
                        }
                        onClick={() =>  setOpenLanguage(true)}>
                        <ListItemIcon
                          style={
                            (area.includes("owtoolbox") || area.includes("kitow")) ? {
                            marginLeft: '10px'
                            } : {}
                          }
                        > 
                            <FlagSelected />
                        </ListItemIcon>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <ListItemText 
                            primary={t('LABEL.LANGUAGE')} 
                            primaryTypographyProps={
                              (area.includes("owtoolbox") || area.includes("kitow")) ? {
                                fontSize: 20,
                                fontWeight: 'medium',
                                letterSpacing: 0,
                              } : {}
                            }
                          />
                        </div>
                    </ListItemButton>
                </ListItem>
            </>
            )}
        </List>
        </Fragment>
    );
};

export default MenuList;