import React, { useState } from 'react';
import Logo from '@assets/images/logoLogin.png';
import { useForm } from '@hooks/useForm';
import {
    Box,
    Button,
    CircularProgress,
    TextField,
    Typography,
} from '@mui/material';
import serviceLogin from '@services/serviceLogin';
import { useStyles } from '@styles/login.style';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const LostPassword = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const { userEmail, formData, handleChange } = useForm({
        userEmail: '',
    });

    const handleSubmit = e => {
        e.preventDefault();
        setLoading(true);
        serviceLogin.lostPassword(formData).then(response => {
            if (!response.error) {
                navigate('/newPassword', { replace: true });
            }
            setLoading(false);
        });
    };

    return (
        <div className={classes.login}>
            <form onSubmit={handleSubmit} className={classes.card}>
                <div className={classes.header}>
                    <img src={Logo} alt='logo' className={classes.image}></img>
                </div>

                <div>
                    <Typography color='secondary' align='left' mt={3} fontSize={14}>
                        {t('LOGIN.TAG_EMAIL')}
                    </Typography>
                    <TextField
                        label={t('LABEL.EMAIL')}
                        variant='filled'
                        name='userEmail'
                        type='email'
                        fullWidth
                        value={userEmail}
                        onChange={handleChange}
                        margin='dense'
                        size='small'
                        inputProps={{ maxLength: 100 }}
                    />
                </div>
                <Box mt={3}>
                    <Button variant='contained' fullWidth type='submit' size='large'>
                        <Box display='flex' alignItems='center'>
                            {loading && <CircularProgress size={25} color='grey' />}
                            {!loading && t('BUTTON.SEND')}
                        </Box>
                    </Button>
                </Box>
            </form>
        </div>
    );
};

export default LostPassword;