import { AuthContext } from '@context/auth/AuthContext'
import * as Campus from '@areas/Campus'
import Home from '@areas/Campus/Home'
import LoginPage from '@login/LoginPage'
import LostPassword from '@login/LostPassword'
import NewPassword from '@login/NewPassword'
import Panel from '@areas/Campus/sections/panel/Panel'
import { useContext } from 'react'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from 'react-router-dom'

const CampusRoutes = () => {
  const PrivateRoute = () => {
    const { status } = useContext(AuthContext)
    return status === 'authenticated' ? <Home /> : <Navigate to='/' />
  }
  const { status } = useContext(AuthContext)

  return (
    <Router>
      <Routes>
        <Route
          path='/'
          element={
            status === 'authenticated' ? (
              <Navigate to='/panel' />
            ) : (
              <LoginPage />
            )
          }
        />
        <Route path='/owApp/:id/:token' element={<Campus.OwToolboxApp />} />
        <Route path='*' element={<Navigate to='/' />} />
        <Route path='/lostPassword' element={<LostPassword />} />
        <Route path='newPassword' element={<NewPassword />} />
        <Route path='/reset' element={<NewPassword />} />
        <Route element={<PrivateRoute />}>
          <Route path='panel' element={<Panel />} />

          <Route path='search' element={<Campus.Searches />} />

          <Route path='participants' element={<Campus.Participants />} />
          <Route
            path='participantDetail'
            element={<Campus.ParticipantDetail />}
          />
          <Route path='companies' element={<Campus.Companies />} />
          <Route path='companyDetail' element={<Campus.CompanyDetail />} />
          <Route path='journeysser' element={<Campus.JourneysSer />} />
          <Route
            path='journeySerDetail'
            element={<Campus.JourneySerDetail />}
          />
          <Route path='cycles' element={<Campus.Cycles />} />
          <Route path='cycleDetail' element={<Campus.CycleDetail />} />

          <Route path='mailing' element={<Campus.Mailing />} />
          <Route path='calls' element={<Campus.Calls />} />

          <Route path='history/:filter' element={<Campus.History />} />

          <Route path='cycleTypes' element={<Campus.CycleTypes />} />
          <Route
            path='cycleTypesDetail'
            element={<Campus.CycleTypesDetail />}
          />

          <Route path='consultants' element={<Campus.Consultants />} />
          <Route
            path='consultantDetail'
            element={<Campus.ConsultantDetail />}
          />

          <Route path='workers' element={<Campus.Workers />} />
          <Route path='workerDetail' element={<Campus.WorkerDetail />} />

          <Route path='valuations' element={<Campus.Valuations />} />

          <Route path='templateMailing' element={<Campus.TemplateMailing />} />
          <Route
            path='templateMailingDetail'
            element={<Campus.TemplateMailingDetail />}
          />

          <Route path='preparations' element={<Campus.Preparations />} />
          <Route
            path='preparationDetail'
            element={<Campus.PreparationDetail />}
          />

          <Route path='templateTargets' element={<Campus.TemplateTargets />} />
          <Route
            path='templateTargetDetail'
            element={<Campus.TemplateTargetDetail />}
          />

          <Route
            path='templateWorksheets'
            element={<Campus.TemplateWorksheets />}
          />
          <Route
            path='templateWorksheetsDetail'
            element={<Campus.TemplateWorksheetsDetail />}
          />

          <Route
            path='templateFilesAttach'
            element={<Campus.TemplateFilesAttach />}
          />
          <Route
            path='templateFilesAttachDetail'
            element={<Campus.TemplateFilesAttachDetail />}
          />
          <Route path='owToolbox' element={<Campus.OwToolbox />} />
          <Route path='owToolboxDetail' element={<Campus.OwToolboxDetail />} />
          <Route path='incidents' element={<Campus.Incidents />} />
          <Route path='incidentsDetails' element={<Campus.IncidentsDetail />} />
          <Route path='evaluator' element={<Campus.Evaluator />} />
          <Route path="evaluatorDetail" element={<Campus.EvaluatorDetail />} />
          <Route path='schedulerCategories' element={<Campus.SchedulerCategories />} />
          <Route path='schedulerCategoryDetail' element={<Campus.SchedulerCategoryDetail />} />
        </Route>
      </Routes>
    </Router>
  )
}

export default CampusRoutes
