import React, { useEffect, useState } from 'react'
import DynamicTable from '@components/DynamicTable'
import HeaderTitle from '@components/HeaderTitle'
import { useCommonStyles } from '@styles/common.style'
import { useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import moment from 'moment';

import {
  Box,
  Tab,
  Tabs,
  Typography
} from '@mui/material'

export function TabPanel(props) {
  const {children, value, index, ...other} = props
  return (
      <div
          role='tabpanel'
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
          {value === index && <Box sx={{p: 0}}>{children}</Box>}
      </div>
  )
}

export function a11yProps(index) {
  return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      key: index
  }
}

const History = () => {
  const commonClasses = useCommonStyles()
  const params = useParams()
  const location = useLocation()
  const TYPE = location.state?.type;
  const { t } = useTranslation()
  const [customFilter, setCustomFilter] = useState([])
  const url = window.location.href
  const [value, setValue] = useState(0)
  

  useEffect(() => {
    // Convocatorias tipos 5,6,7,8,9

    if (params.filter === 'call') {
      setCustomFilter([
        {
          searchField: 'idHistoryType',
          searchMode: 'in',
          searchValue: '5,6,7,8,9'
        }
      ])
    } else {
      // Convocatorias tipos 1,2,3,4,10 y 11
      if (params.filter === 'actions') {
        setCustomFilter([
          {
            searchField: 'idHistoryType',
            searchMode: 'in',
            searchValue: '1,2,3,4,10,11,12'
          }
        ])
      }
      
      // Todos los correos en el histórico cuyo participante no sea nulo
      if (params.filter === 'mailing') {
        setCustomFilter([
          { searchField: 'idParticipant', searchMode: 'Greater', searchValue: '0' }
        ])
      }
    }
  }, [params])

  const mainTitle = () => {
    if (url.includes('mailing')) {
      return t('MENU_ADMIN.MAILING')
    } else if (url.includes('call')) {
      return t('MENU_ADMIN.CALLS')
    } else if (url.includes('actions')) {
      return t('MENU_ADMIN.HISTORY')
    }
  }

  useEffect(() => {
    if (params.filter === 'actions') {
      setValue(0)
    }
  }, [params.filter])

  const todayf = [
    {
      searchField: 'schedulerDay',
      searchMode: 'Greater',
      searchValue: moment(Date.now()).format('yyyy[-]MM[-]DD 23:59:59')
    }
  ];

  const ORDER = [
    {
      orderField: 'historyDateTime',
      orderAsc: false
    }
  ]

  const ORDER1 = [
    {
        orderField: 'schedulerDay',
        orderAsc: true
    }
  ];

  var COLUMNS = [
    {
      accessor: 'idHistory',
      Header: 'ID',
      filterable: false,
      width: '1%'
    },
    {
      accessor: 'historyDateTime',
      Header: t('LABEL.DATE'),
      Cell: props => <Moment format='DD/MM/YY HH:mm'>{props.value}</Moment>,
      width: '8%'
    }
  ]

  if (params.filter === 'actions') {
    COLUMNS.push({ accessor: 'historyTypeName', Header: t('LABEL.TYPE') })
    COLUMNS.push({ accessor: 'cycleName', Header: t('MENU_ADMIN.CYCLES') })
    COLUMNS.push({ accessor: 'userFullName', Header: t('LABEL.USERNAME') })
  
  } else {
    COLUMNS.push({ accessor: 'cycleName', Header: t('MENU_ADMIN.CYCLES') })
    COLUMNS.push({ accessor: 'participantFullName', Header: t('MENU_ADMIN.PARTICIPANTS') })
    COLUMNS.push({ accessor: 'participantEmail', Header: 'E-Mail' })
    COLUMNS.push({ accessor: 'historyDesc', Header: t('LABEL.DETAILS') })
  }

  var COLUMNS1 = [
    {
      accessor: 'idMailing',
      Header: 'ID',            
      filterable: false,
      width: '2%'
    },
    {
      accessor: 'schedulerDay', 
      Header: t('LABEL.DATE'),           
      Cell: props => <Moment format='DD/MM/YY HH:mm'>{props.value}</Moment>,
      size: 11,
    },
    {
      accessor: 'cycleName',
      Header: t('MENU_ADMIN.CYCLES'),      
      size: 15,
    },
    {
      accessor: 'participantName',
      Header: t('MENU_ADMIN.PARTICIPANTS'),      
      Cell: (props) => `${props.row.original.participantName} ${props.row.original.participantSurname}`,
      width: '10%',
    },
    {
      accessor: 'mailTo',
      Header: 'E-Mail'          
    },
    {
      accessor: 'mailingSubject',
      Header: t('LABEL.DETAILS')
    },
    {
        accessor: 'mailingName',
        Header: t('LABEL.TEMPLATE_CODE')
    }
  ]


  return (
    <div className={commonClasses.container}>
      
      <HeaderTitle
        title={mainTitle()}
        subtitle={t('TEXT.SUBTITLE_HISTORY')}
      >
      </HeaderTitle>

      {params.filter !== 'actions' && (
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <Tabs
                value={value}
                onChange={(_, value) => setValue(value)}
                aria-label='basic tabs example'
            >
                {[
                    t('MAILING.SENT'),
                    TYPE !== 'NEW' && t('MAILING.TODAY'),
                    TYPE !== 'NEW' && t('MAILING.NEXT')
                ].map((label, i) => {
                    if (label.length > 1) {
                        return <Tab label={label} {...a11yProps(`cycleTab${i}`)} />
                    }
                })}
            </Tabs>
        </Box>
      )}

      <TabPanel value={value} index={0} key={`cycleTabPanel${0}`}>
          {customFilter.length > 0 && (
            <DynamicTable
              columns={COLUMNS}
              endpoint='History/List'
              customFilter={customFilter}
              isGlobalSearch={true}
              isColumnsSearch={true}
              isOrdered={true}
              viewExcel={true}
              nameExcel={
                params.filter === 'actions'
                  ? t('MENU_ADMIN.HISTORY')
                  : params.filter === 'call'
                  ? t('MENU_ADMIN.CALLS')
                  : t('MENU_ADMIN.MAILING')
              }
              titleBodyExcel={
                params.filter === 'actions'
                  ? t('HISTORY.BODY_EXCEL')
                  : params.filter === 'call'
                  ? t('CALLS.BODY_EXCEL')
                  : t('MAILING.BODY_EXCEL')
              }
              orderColumn={ORDER}
            />
          )}
      </TabPanel>

      {params.filter !== 'actions' && (
        <>
          <TabPanel value={value} index={1} key={`cycleTabPanel${1}`}>
            {customFilter.length > 0 && (
                <DynamicTable
                    columns={COLUMNS1}
                    endpoint='mailings/Scheduler/List/Today'
                    isGlobalSearch={true}
                    isColumnsSearch={true}
                    isOrdered={true}
                    viewExcel={true}
                    nameExcel={
                        params.filter === 'actions'
                            ? t('MENU_ADMIN.HISTORY')
                            : params.filter === 'call'
                            ? t('MENU_ADMIN.CALLS')
                            : t('MENU_ADMIN.MAILING')
                    }
                    titleBodyExcel={
                        params.filter === 'actions'
                            ? t('HISTORY.BODY_EXCEL')
                            : params.filter === 'call'
                            ? t('CALLS.BODY_EXCEL')
                            : t('MAILING.BODY_EXCEL')
                    }
                    orderColumn={ORDER1}
                />
            )}
        </TabPanel>

        <TabPanel value={value} index={2} key={`cycleTabPanel${2}`}>
            {customFilter.length > 0 && (
              <DynamicTable
                columns={COLUMNS1}
                endpoint='mailings/Scheduler/List'
                customFilter={todayf}
                isGlobalSearch={true}
                isColumnsSearch={true}
                isOrdered={true}
                viewExcel={true}
                nameExcel={
                  params.filter === 'actions'
                    ? t('MENU_ADMIN.HISTORY')
                    : params.filter === 'call'
                    ? t('MENU_ADMIN.CALLS')
                    : t('MENU_ADMIN.MAILING')
                }
                titleBodyExcel={
                  params.filter === 'actions'
                    ? t('HISTORY.BODY_EXCEL')
                    : params.filter === 'call'
                    ? t('CALLS.BODY_EXCEL')
                    : t('MAILING.BODY_EXCEL')
                }
                orderColumn={ORDER1}
              />
            )}
        </TabPanel>
      </>
    )}

    </div>
  )
}

export default History
