import React, { Fragment, useEffect, useState } from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import {
  MenuItem,
  Select,
  Typography,
  Grid,
  FormControl,
  InputLabel
} from '@mui/material'
import { useCommonStyles } from '@styles/common.style'
import { useTranslation } from 'react-i18next'
import Card from '@components/Card'

const PreparationConfiguration = ({
  getNewJourneys,
  journeyIndex,
  data,
  setData,
  preparations
}) => {
  const classes = useCommonStyles()
  const journey = data[journeyIndex]
  const [lJourney, setLJourney] = useState(journey)
 
  const { t } = useTranslation()

  const manageRenderValue = (selected) => {    
    if (preparations != undefined) {
      const name = preparations.find(e => e.idPreparation === selected)
      if (selected == 0) {
        return t(name.preparationName)
      } else if (name != undefined) {
        return name.preparationName
      }
    }
  }

  useEffect(() => {
    manageRenderValue();
  },[])

  useEffect(() => {
    setLJourney(journey);
  },[data, setData])

  const handleChange = e => {
    const newJourney = { ...journey, [e.target.name]: e.target.value }
    setLJourney({ ...lJourney, [e.target.name]: e.target.value })
    const newJourneys = getNewJourneys(
      e.target.value,
      e.target.name,
      journeyIndex
    )
    setData(newJourneys)
  }

  return (
    <Fragment>
      <Typography
        textAlign='center'
        color='white'
        variant='subtitle1'
        sx={{
          bgcolor: 'grey.main',
          color: 'primary.main',
          borderRadius: 1,
          marginBottom: 2,
          fontSize: 14,
          fontWeight: 'bold',
          py: 1
        }}
      >
        {t('CONFIG_JOURNEYS.PREPARATION.CONFIG').toUpperCase()}
      </Typography>
      <Card fullHeight>
        <Grid item md={12} xs={12}>
          <FormControl fullWidth size='small' margin='normal'>
            <InputLabel id='idPreparation'>
              {t('CONFIG_JOURNEYS.PREPARATION.PREPARATION')}
            </InputLabel>
            <Select
              labelId='idPreparation'
              label={t('CONFIG_JOURNEYS.PREPARATION.PREPARATION')}
              value={lJourney?.idPreparation || 0}
              onChange={handleChange}
              name='idPreparation'
              renderValue={selected => manageRenderValue(selected)}
            >
              {preparations?.map(preparation => (
                <MenuItem
                  value={preparation.idPreparation}
                  key={preparation.idPreparation}
                >
                  {preparation.idPreparation == 0 ? t(preparation.preparationName): preparation.preparationName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Card>
    </Fragment>
  )
}

export default PreparationConfiguration
