import React,{useState, useEffect} from 'react';
import { Grid } from '@mui/material';
import Loading from '@components/Loading';
import Calendar from './components/Calendar';
import { useAppContext } from '../../context/SchedulerContext';
import { fetchDataEvents, fetchCategories } from '../../services/schedulerServicesHelper'
import DialogEvent from './components/DialogEvent';
import formatEvents from '@common/helpers/eventFormatter';
import checkEventOverlap from '@common/helpers/checkEventOverlap';
import { useTranslation } from 'react-i18next'

const UserArea = () => {
    const { i18n, t } = useTranslation()
    const [loading, setLoading] = useState(true);
    const { idUserSelected, setIdUserSelected, userList } = useAppContext();
    const [openEventDetails, setOpenEventDetails] = useState(false)
    const [openEventSaveRecurrent, setOpenEventSaveRecurrent] = useState(false)
    const [openEventEditeVerification, setOpenEventEditeVerification] = useState(false)
    const [openFreeSlots, setOpenFreeSlots] = useState(false)
    const [openLoading, setOpenLoading] = useState(false);
    var now = new Date();
    var month = ("0" + (now.getMonth() + 1)).slice(-2);
    now.setDate(1); // Configura el día al primero del mes
    var day = ("0" + now.getDate()).slice(-2);
    var actual_date = now.getFullYear() + "-" + month + "-" + day;
    const [currentDate, setCurrentDate] = useState(actual_date); 
    const [resources, setResources] = useState([]); 
    const [currentMonth, setCurrentMonth] = useState(month); 
    const [currentYear, setCurrentYear] = useState(now.getFullYear()); 
    const firstDayOfCurrentMonth = new Date(Date.UTC(currentYear, parseInt(currentMonth) -1 , now.getDate(1)));
    const lastDayOfCurrentMonth = new Date(Date.UTC(currentYear, parseInt(currentMonth), 1));
    lastDayOfCurrentMonth.setUTCHours(23, 59, 59);
    lastDayOfCurrentMonth.setUTCDate(lastDayOfCurrentMonth.getUTCDate() - 1);

    const [events, setEvents] = useState([]);
    const [eventSelected, setEventSelected] = useState(null)
    const [idEventSelected, setIdEventSelected] = useState(sessionStorage.getItem('idEventSelected'))
    const [eventDateFrom, setEventDateFrom] = useState(sessionStorage.getItem('eventDateFrom'))  
    const [categoriesList, setCategoriesList] = useState([])
    const [freeSlots, setFreeSlots] = useState ([])
    const [page, setPage] = useState(1)
    const [eventsOverlapByDay, setEventsOverlapByDay] = useState();

    const calcLang = () => {
      const lang = sessionStorage.getItem('lang')
      if (lang === 'es') {
        return 1
      } else if (lang === 'en') {
        return 2
      } else {
        return 1
      }
    }
    
    useEffect(() => {
      fetchCategories(setCategoriesList, calcLang)
    }, [i18n, t])

    useEffect(() => {
      if (idEventSelected !== null && events.length > 0 && eventDateFrom !== null ) {
        const eventSelectedData = events.find((event) => event.idSchedulerEvent == idEventSelected && event.eventDateFrom == eventDateFrom )
        
        setEventSelected(eventSelectedData);
      }
    }, [idEventSelected, eventDateFrom])

    useEffect( () => {
      if (idUserSelected !== null && idUserSelected !== undefined && idUserSelected !== 'undefined' && idUserSelected !== 'null' ) {
          const dataQuery = { 
              IdUsers: [idUserSelected],
              StartDate: firstDayOfCurrentMonth, 
              EndDate: lastDayOfCurrentMonth 
          }  
          fetchDataEvents(dataQuery, setEvents, setEventSelected, setOpenLoading, setLoading)
          getResources() 
      } 
    }, [idUserSelected])
    
    useEffect(() => { 
      if (!openLoading && idUserSelected !== null && idUserSelected !== undefined && idUserSelected !== 'undefined' && idUserSelected !== 'null') {
        fetchDataEvents({ 
          IdUsers: [idUserSelected],
          StartDate: firstDayOfCurrentMonth, 
          EndDate: lastDayOfCurrentMonth 
        }, setEvents, setEventSelected, setOpenLoading, setLoading)
        getResources()
      }
    }, [openLoading])

    useEffect(() => {
       if (idUserSelected !== null && idUserSelected !== undefined && idUserSelected !== 'undefined' && idUserSelected !== 'null') {
         fetchDataEvents({ 
           IdUsers: [idUserSelected],
           StartDate: firstDayOfCurrentMonth, 
           EndDate: lastDayOfCurrentMonth 
         }, setEvents, setEventSelected, setOpenLoading, setLoading)
         getResources()
        } 
    }, [currentDate])

    useEffect(() => {
        if (openEventEditeVerification === false) {
            setCurrentDate(currentYear + "-" + currentMonth + "-01")
        }
    }, [currentMonth, currentYear, openEventEditeVerification])

    useEffect(() => {
        if (events?.length > 0 && events !== undefined) {
            const tieneStart = events.some(event => event && event.hasOwnProperty("start"));
            // formatea los eventos para que se vean en el calendario: start, end, resource y color según categoría
            if (!tieneStart) {
                formatEvents(events, setEvents, categoriesList)
            }
            const tempOverlap = checkEventOverlap(events)
            setEventsOverlapByDay(tempOverlap)
        }
    }, [events])

    useEffect(() => {
      if (!openEventDetails && page !== 1) {
        setTimeout(() => {
          setPage(1);
        }, 500);
      }
    }, [openEventDetails])

    const getResources = () => { 
      const daysInMonth = new Date(currentYear, parseInt(currentMonth) , 0).getDate();
      const weekdays = ['D', 'L', 'M', 'X', 'J', 'V', 'S'];

      const newResources= Array.from({ length: daysInMonth }, (_, i) => {
          const dayNumber = i + 1;
          const dayOfMonth = dayNumber < 10 ? `0${dayNumber}` : `${dayNumber}`;
          const currentDate = new Date(currentYear, currentMonth -1 , dayNumber);
          const dayOfWeekInitial = weekdays[currentDate.getDay()];

          return { 
            id: dayOfMonth, 
            title: dayOfMonth + ' ' + dayOfWeekInitial,
          };
      });

      setResources(newResources)
    }
    
   return (
    <>
        {!loading ? (
            <Grid container spacing={1} px={2}>
                <Calendar 
                    events={events}
                    resources={resources}
                    eventSelected={eventSelected}
                    setEventSelected={setEventSelected}
                    setOpenEventDetails={setOpenEventDetails}
                    setOpenLoading={setOpenLoading}
                    setOpenEventEditeVerification={setOpenEventEditeVerification}
                    currentMonth={currentMonth}
                    setCurrentMonth={setCurrentMonth}
                    setCurrentYear={setCurrentYear}
                    currentDate={currentDate}
                    idUserSelected={idUserSelected}
                    idEventSelected={idEventSelected}
                    setIdEventSelected={setIdEventSelected}
                    eventDateFrom={eventDateFrom}
                    setEventDateFrom={setEventDateFrom}
                    eventsOverlapByDay={eventsOverlapByDay}
                />
                <DialogEvent 
                    openEventDetails={openEventDetails}
                    setOpenEventDetails={setOpenEventDetails}
                    openEventSaveRecurrent={openEventSaveRecurrent}
                    setOpenEventSaveRecurrent={setOpenEventSaveRecurrent}
                    openLoading={openLoading}
                    setOpenLoading={setOpenLoading}
                    openEventEditeVerification={openEventEditeVerification}
                    setOpenEventEditeVerification={setOpenEventEditeVerification}
                    openFreeSlots={openFreeSlots}
                    setOpenFreeSlots={setOpenFreeSlots}
                    eventSelected={eventSelected}
                    setEventSelected={setEventSelected}
                    idUserSelected={idUserSelected}
                    idEventSelected={idEventSelected}
                    categoriesList={categoriesList}
                    userList={userList}
                    page={page}
                    setPage={setPage}
                    setLoading={setLoading}
                    freeSlots={freeSlots}
                    setFreeSlots={setFreeSlots}
                />
            </Grid>
            ) : (
            <Loading height='50vh' />
            )
        }
    </>
    );
};

export default UserArea;