import { useState, useCallback } from 'react';

export const useForm = initialState => {
    const [state, setState] = useState(initialState);

    const handleChange = useCallback((e, editor) => {
        let name, value;

        if (e == null || e === 'undefined' || e.target == null || e.target === 'undefined') {
            // This is a CKEditor event
            const element = e?.element;
            name = element?.getAttribute('name') !== undefined ? element?.getAttribute('name') : e.target.name;
            value = editor.getData ? editor.getData() : e.target.value; 
        } else {
            // This is a regular form input event
            name = e.target.name;
            value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        }

        if (!name) {
            console.error('Event target name is not defined');
            return;
        }

        setState(prevState => {
            return { ...prevState, [name]: value };
        });
    }, []);

    return {
        ...state,
        formData: state,
        setFormData: setState,
        handleChange,
    };
};
