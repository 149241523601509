import React, { Fragment, useEffect, useState, useRef } from 'react'
import Search from '@components/Search'
import apiRequest from '@helpers/apiRequest'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious'
import SkipNextIcon from '@mui/icons-material/SkipNext'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableContainer,
  Tooltip
} from '@mui/material'
import { useCommonStyles } from '@styles/common.style'
import { useStyles } from '@styles/table.style'
import { useExpanded, usePagination, useSortBy, useTable } from 'react-table'
import { useTranslation } from 'react-i18next'
import { Assessment, MailRounded } from '@mui/icons-material'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import Loading from '@components/Loading'

const DynamicTable = ({
  columns,
  isGlobalSearch = false,
  isColumnsSearch = false,
  isOrdered = false,
  isPaginated = true,
  customFilter = [],
  endpoint,
  reload = 0,
  size,
  nameExcel = 'hojaDeCalculo',
  viewExcel = false,
  viewSendMail = false,
  columnSearchParam = [],
  handleSendMail = null,
  handleGetSearch = null,
  pageSizeParam = 10,
  idDownloadButton = 'idDownloadButton',
  idTableToXls = 'idTableToXls',
  titleBodyExcel = '',
  orderColumn = [],
  ...props
}) => {
  const { t } = useTranslation()
  const globalRef = useRef(null)

  const getTableName = () => {
    return document.URL.split('/').pop()
  }
  const checkFilters = () => {
    if (loadFilter && !loading) {
      let filterData = localStorage.getItem(getTableName() + 'Filter')
      if (filterData) {
        let dataFilter = JSON.parse(filterData)
        setTimeout(() => {
          if (dataFilter.globalSearch) {
            setGlobalSearch(dataFilter.globalSearch)
          }
          if (dataFilter.columnSearch.length > 0) {
            setColumnSearch(dataFilter.columnSearch)
          }
          if (dataFilter.pageIndex != 0) {
            gotoPage(dataFilter.pageIndex)
          }
        }, 500)

        //localStorage.removeItem(getTableName() + 'Filter')
      }
      if (!getTableName().endsWith('Detail')) {
        Object.keys(localStorage).forEach(key => {
          if (key.endsWith('Filter')) {
            localStorage.removeItem(key)
          }
        })
      }
    }
  }
  const [isMounted, setIsMounted] = useState(true)
  const [loadFilter, setLoadFilter] = useState(true)
  const [data, setData] = useState([])
  const [dataDownload, setDataDownload] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [loading, setLoading] = useState(true)
  const [controlledPageCount, setControlledPageCount] = useState(0)

  const [globalSearch, setGlobalSearch] = useState('')
  const [prevGlobalSearch, setPrevGlobalSearch] = useState('')

  const [prevColumnSearch, setPrevColumnSearch] = useState(customFilter)
  const [columnSearch, setColumnSearch] = useState(customFilter)

  const [order, setOrder] = useState(orderColumn)
  const classesCommon = useCommonStyles()
  const classes = useStyles()
  const logoUrl = process.env.REACT_APP_PUBLIC_URL + '/logoLogin.png'

  const hoy = new Date();
  const fechaFormateada = `${hoy.getDate()}/${hoy.getMonth() + 1}/${hoy.getFullYear()}`;
  
  const userSession = JSON.parse(sessionStorage.getItem('userSession'))
  const nameUser = userSession.userName + " " + userSession.userSurname


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: pageSizeParam,
        hiddenColumns: columns
          .filter(column => column.hiddenColumn)
          .map(column => column.accessor)
      },
      manualPagination: true,
      manualSortBy: false,
      autoResetPage: false,
      pageCount: controlledPageCount
    },
    useSortBy,
    useExpanded,
    usePagination
  )

  const fetchData = ({
    pageSize,
    pageIndex,
    globalSearch,
    columnSearch,
    order,
    endpoint
  }) => {
    //setLoading(true);
    let columnSearchValue
    if (columnSearch.length === 0 && columnSearchParam.length !== 0) {
      columnSearchValue = columnSearchParam
      columnSearchParam = []
    } else {
      columnSearchValue = columnSearch
    }
    if (prevGlobalSearch !== globalSearch) {
      gotoPage(0)
      setPrevGlobalSearch(globalSearch)
    }
    if (prevColumnSearch !== columnSearch) {
      gotoPage(0)
      setPrevColumnSearch(columnSearch)
    }
    apiRequest('post', endpoint, {
      pageSize: pageSize,
      pageNumber: pageIndex,
      globalSearch: globalSearch,
      search: columnSearchValue,
      order: order
    }).then(response => {
      if (!response.error) {
        setData(response.data)
        setTotalRecords(response.pagination.records)
        setControlledPageCount(response.pagination.pages)
        setLoading(false)
      }
    })
  }

  const fetchDataDownlodad = ({
    globalSearch,
    columnSearch,
    order,
    endpoint
  }) => {
    setLoading(true)
    let columnSearchValue
    if (columnSearch.length === 0 && columnSearchParam.length !== 0) {
      columnSearchValue = columnSearchParam
      columnSearchParam = []
    } else {
      columnSearchValue = columnSearch
    }
    if (prevGlobalSearch !== globalSearch) {
      gotoPage(0)
      setPrevGlobalSearch(globalSearch)
    }
    if (prevColumnSearch !== columnSearch) {
      gotoPage(0)
      setPrevColumnSearch(columnSearch)
    }
    apiRequest('post', endpoint, {
      pageSize: 0,
      pageNumber: 0,
      globalSearch: globalSearch,
      search: columnSearchValue,
      order: order
    }).then(response => {
      if (!response.error) {
        setDataDownload(response.data)
        setLoading(false)
        document.querySelector('#' + idDownloadButton).click()
      }
    })
  }

  const handleGlobalSearch = search => {
    setGlobalSearch(search)
    handleGetSearch && handleGetSearch(search, columnSearch)
  }

  const handleColumnSearch = (event, filterMap, searchMode) => {
    var searchField = event.target.name
    var searchValue = filterMap
      ? filterMap(event.target.value)
      : event.target.value
    var searchArray = columnSearch
    searchArray = searchArray.filter(
      filter => filter.SearchField !== searchField
    )
    if (searchValue) {
      searchArray.push({
        SearchField: searchField,
        SearchValue: searchValue,
        SearchMode: searchMode ?? 'Contains'
      })
    }
    setColumnSearch(searchArray)
    handleGetSearch && handleGetSearch(globalSearch, searchArray)
  }

  const handleOrder = (orderField, orderMode) => {
    var orderArray = order
    orderArray = orderArray.filter(filter => filter.orderField !== orderField)
    if (orderMode) {
      orderArray.unshift({
        orderField: orderField,
        orderAsc: orderMode === 'asc' ? true : false
      })
    }
    setOrder(orderArray)
  }

  const handleDownload = (globalSearch, columnSearch, order, endpoint) => {
    fetchDataDownlodad({
      globalSearch,
      columnSearch,
      order,
      endpoint
    })
  }

  useEffect(() => {
    if (customFilter.length > 0) {
      setColumnSearch(customFilter)
    }
  }, [customFilter])

  useEffect(() => {
    return () => {
      setIsMounted(false)
    }
  }, [])
  useEffect(() => {
    checkFilters()
  }, [loading])
  useEffect(() => {
    if (isMounted) {
      fetchData &&
        fetchData({
          pageIndex,
          pageSize,
          globalSearch,
          columnSearch,
          order,
          endpoint
        })
    }
  }, [pageIndex, pageSize, globalSearch, columnSearch, order, endpoint, reload])

  const handleClickCell = (target, id) => {
    if (id == 'Info' && target.nodeName != 'td') {
      setLoadFilter(false)
      localStorage.setItem(
        getTableName() + 'Filter',
        JSON.stringify({
          pageIndex: pageIndex,
          globalSearch: globalSearch,
          columnSearch: columnSearch
        })
      )
    }
  }

  const getFilterExcel = () =>{
    const cruzarInformacion = 
      columnSearch.map(obj1 => {
        const match = columns.find(obj2 => obj2.accessor === obj1.SearchField);
        if (match) {
          return { ...obj1, Header: match.Header };
        }
        return obj1;
      });
      let criterio = "";
      let contador = 0
      cruzarInformacion.map(obj1 => {
        if(contador != 0) criterio = criterio + ", "
        criterio = criterio + obj1.Header +"=" +obj1.SearchValue
        contador ++
      })
   return criterio
  }

  if (loading) {
    return <Loading />
  }
  return (
    <Fragment>
      {isGlobalSearch && (
        <Grid container mb={2} className={classes.globalSearchContainer}>
          <Search
            global
            name='searchTerm'
            inputRef={el => (el.current = globalRef)}
            value={globalSearch || ''}
            handleOnchange={e => handleGlobalSearch(e.target.value)}
          />
        </Grid>
      )}
      <TableContainer>
        <Table {...getTableProps()} size={size}>
          <TableHead className={classesCommon.bgPrimary}>
            {headerGroups.map((headerGroup, i) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, index) => {
                  return (
                    /* TÍTULOS TABLA */
                    <TableCell
                      {...column.getHeaderProps()}
                      style={{
                        width: column.width,
                        padding: !size ? '10px 16px' : null
                      }}
                    >
                      <Box display='flex' alignItems='center'>
                        {index === 0 && viewExcel && (
                          <Tooltip title={t('MENU_ADMIN.COMPLETE_REPORT')}>
                            <IconButton
                              aria-label='info'
                              color='white'
                              style={{
                                padding: '0px'
                              }}
                              onClick={() => {
                                handleDownload(
                                  globalSearch,
                                  columnSearch,
                                  order,
                                  endpoint
                                )
                              }}
                            >
                              <Assessment fontSize='inherit' />
                            </IconButton>
                          </Tooltip>
                        )}
                        {index === 0 && viewSendMail && (
                          <Tooltip title={t('CYCLES.SEND_MAIL')}>
                            <IconButton
                              aria-label='info'
                              color='white'
                              style={{
                                padding: '0px'
                              }}
                              onClick={handleSendMail}
                            >
                              <MailRounded fontSize='inherit' />
                            </IconButton>
                          </Tooltip>
                        )}
                        {!column.hideHeader && (
                          <Typography
                            color='secondary.light'
                            variant='button'
                            fontWeight='bold'
                            mr={1}
                          >
                            {column.render('Header')}
                          </Typography>
                        )}

                        {isOrdered && !column.hideHeader && (
                          <>
                            {order.filter(
                              filter => filter.orderField === column.id
                            ).length > 0 ? (
                              order.filter(
                                filter => filter.orderField === column.id
                              )[0].orderAsc ? (
                                <IconButton
                                  aria-label='delete'
                                  size='small'
                                  color='grey'
                                  onClick={() => handleOrder(column.id, 'desc')}
                                >
                                  <KeyboardArrowDownIcon fontSize='inherit' />
                                </IconButton>
                              ) : (
                                <IconButton
                                  size='small'
                                  color='grey'
                                  onClick={() => handleOrder(column.id, null)}
                                >
                                  <KeyboardArrowUpIcon fontSize='inherit' />
                                </IconButton>
                              )
                            ) : (
                              <IconButton
                                size='small'
                                color='grey'
                                onClick={() => handleOrder(column.id, 'asc')}
                              >
                                <UnfoldMoreIcon fontSize='inherit' />
                              </IconButton>
                            )}
                          </>
                        )}
                      </Box>
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableHead>
          {isColumnsSearch && (
            <TableBody className={classes.tableBodySearch}>
              {headerGroups.map((headerGroup, i) => (
                <TableRow key={i}>
                  {headerGroup.headers.map(column => {
                    /* BUSCADORES */
                    let value = columnSearch.filter(
                      x => x.SearchField == column.id
                    )
                    return (
                      <TableCell
                        {...column.getHeaderProps()}
                        style={{
                          width: column.width,
                          padding: !size ? '10px 16px' : null
                        }}
                      >
                        {column.clickable}
                        {column.filterable == null && (
                          <Search
                            name={column.id}
                            size={column.size ?? 18}
                            value={value.length > 0 ? value[0].SearchValue : ''}
                            handleOnchange={e =>
                              handleColumnSearch(
                                e,
                                column.filterMap,
                                column.searchMode
                              )
                            }
                          />
                        )}
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableBody>
          )}

          {/* LOS DATOS */}
          <TableBody {...getTableBodyProps()} className={classes.tableBodyRows}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <TableRow
                  key={i}
                  style={{
                    filter: loading && 'blur(2px)'
                  }}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, i) => {
                    return (
                      <TableCell
                        key={i}
                        {...cell.getCellProps()}
                        style={{
                          width: cell.column.width,
                          padding: !size ? '10px 16px' : null
                        }}
                        onClick={e => handleClickCell(e.target, cell.column.id)}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {Boolean(isPaginated) && (
        <Grid container className={classes.footer} py={2}>
          <Grid item className={classes.footerResult}>
            <Typography mr={2} color='primary'>
              {totalRecords} {t('TEXT.RESULTS')}
            </Typography>
            <Typography variant='subtitle2'>
              ({t('TEXT.SHOWING_FROM')} {pageIndex * pageSize + 1}{' '}
              {t('TEXT.TO')} {pageIndex * pageSize + pageSize})
            </Typography>
          </Grid>
          <Grid item className={classes.footerPagination}>
            <IconButton
              variant='contained'
              color='primary'
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
              size='large'
            >
              <SkipPreviousIcon fontSize='inherit' />
            </IconButton>
            &nbsp;
            <IconButton
              variant='contained'
              color='primary'
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              size='large'
            >
              <NavigateBeforeIcon fontSize='inherit' />
            </IconButton>
            <Typography
              mx={2}
              sx={{
                fontSize: {
                  xs: '12px',
                  md: '14px'
                }
              }}
            >
              {t('TEXT.PAGE')} {pageIndex + 1} {t('TEXT.OF')}{' '}
              {pageOptions.length}
            </Typography>
            <IconButton
              variant='contained'
              color='primary'
              onClick={() => nextPage()}
              disabled={!canNextPage}
              size='large'
            >
              <NavigateNextIcon fontSize='inherit' />
            </IconButton>
            &nbsp;
            <IconButton
              variant='contained'
              color='primary'
              onClick={() => gotoPage(pageOptions.length - 1)}
              disabled={!canNextPage}
              size='large'
            >
              <SkipNextIcon fontSize='inherit' />
            </IconButton>
          </Grid>
        </Grid>
      )}
      {viewExcel && (
        <>
          <>
            <table id={idTableToXls} className={classesCommon.hidden}>
              <tbody>
                <tr style={{ height: '100px', width: '300px' }}>
                  <th  colSpan={2}>
                    <img
                      src={logoUrl}
                      alt=''
                      border='3'
                      height='100'
                      width='300'
                    ></img>
                  </th>
                  <th colSpan={3}></th>
                  <th colSpan={3} style={{ color: '#00007D', fontSize: 'x-large' }}>{titleBodyExcel}</th>
                </tr>
                <tr style={{ height: '100px', width: '300px' }}>
                <td colSpan={3}></td>
                <td style={{color: '#00007D', fontWeight: 'bold' , textAlign: 'right'}}>{t('LABEL.DATE')}:</td>
                <td style={{color: '#00007D' , textAlign: 'left'}}>{fechaFormateada}</td>
                <td style={{color: '#00007D', fontWeight: 'bold', textAlign: 'right' }}>{t('LABEL.DONE_BY')}</td>
                <td style={{color: '#00007D' , textAlign: 'left'}}>{nameUser}</td>
                <td style={{color: '#00007D', fontWeight: 'bold' , textAlign: 'right'}}>{t('LABEL.CRITERIA')}</td>
                <td style={{color: '#00007D' , textAlign: 'left'}}>{getFilterExcel()}</td>
                </tr>
                <tr style={{ color: '#00007D', fontWeight: 'bold' }}>
                  {columns
                    .filter(columnExcel => !columnExcel.hideHeader)
                    .map(columns => {
                      return (
                        <td
                          key={columns.Header}
                          style={{ borderBottom: '1px solid rgb(0, 0, 125)' }}
                        >
                          {columns.Header}
                        </td>
                      )
                    })}
                </tr>

                {dataDownload.map((dataExcel, index) => {
                  let dataExcelTable = dataExcel
                  let table = ''
                  columns
                    .filter(columnExcel => !columnExcel.hideHeader)
                    .map(columns => {
                      let tableData = ''
                      if (dataExcelTable[columns.accessor] != null) {
                        tableData = dataExcelTable[columns.accessor]
                      }
                      table = table + '<td>' + tableData + '</td>'
                    })
                  return (
                    <tr
                      key={index}
                      dangerouslySetInnerHTML={{ __html: table }}
                    ></tr>
                  )
                })}
              </tbody>
            </table>
          </>
          <ReactHTMLTableToExcel
            id={idDownloadButton}
            className={classesCommon.hidden}
            table={idTableToXls}
            filename={nameExcel}
            sheet={nameExcel}
            buttonText='Download as XLS'
          />
        </>
      )}
    </Fragment>
  )
}

export default DynamicTable
