import React from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import { useCheckboxStyles } from '@styles/checkbox.style';

const CheckBox = ({
    label,
    value,
    checked,
    handleChange,
    fontSize,
    left,
    name,
    disabled,
    ...props
}) => {
    const classes = useCheckboxStyles(props);
    return (
        <Box {...props} className={classes.container}>
            {!left && (
                <Typography variant='caption' fontSize={fontSize}>
                    {label}
                </Typography>
            )}

            <Checkbox
                sx={{ p: 0, ml: 0.5 }}
                disabled={disabled}
                size='small'
                value={value}
                color='primary'
                disableRipple
                name={name}
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
            />
            {left && (
                <Typography variant='caption' fontSize={fontSize}>
                    {label}
                </Typography>
            )}
        </Box>
    );
};

export default CheckBox;