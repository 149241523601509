const getCurrentJourney = cycle => {
  const today = new Date()
  today.setHours(0, 0, 0, 0) // Fecha de hoy sin horas

  // Filtramos sin considerar horas
  const filtered = cycle.cycleJourneys.filter(cycleJourney => {
    const journeyDate = new Date(cycleJourney.cycleJourneyDateFrom)
    journeyDate.setHours(0, 0, 0, 0) // Eliminamos las horas
    return journeyDate <= today // Comparación solo de fechas
  })
  
  if (filtered.length > 0) {
    return filtered[filtered.length - 1] // Última jornada válida
  } else {
    return cycle.cycleJourneys[0] // En caso de que no haya jornadas válidas
  }
}

export default getCurrentJourney
