import { useEffect } from 'react';

function useCheckVersion() {
    const currentVersion = process.env.REACT_APP_VERSION;

    useEffect(() => {
        const storedVersion = sessionStorage.getItem('appVersion');

        if (storedVersion !== currentVersion) {
            // Si la versi�n es diferente, forzar la recarga
            sessionStorage.setItem('appVersion', currentVersion);
            console.log("**** Version actual INCORRECTA: ", currentVersion);
            //window.location.reload(true); // Forzar la recarga de la p�gina
        } else {
            console.log("**** Version actual CORRECTA: ", currentVersion);
        }
    }, [currentVersion]);
}

export default useCheckVersion;
