import apiRequest from '@helpers/apiRequest';

const getTimezonesByLanguage = idLanguage => {
    return apiRequest('GET', `Timezones/List/${idLanguage}`);
};

const getTimezonesPagination = data => {
    return apiRequest('POST', 'Timezones/ListPagination', data);
};

const getTimezoneById = idTimezone => {
    return apiRequest('GET', `Timezones/Details/${idTimezone}`);
};


const serviceTimezones = {
    getTimezonesByLanguage,
    getTimezonesPagination,
    getTimezoneById
};

export default serviceTimezones;