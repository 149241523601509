import React from 'react';
import Loading from '@components/Loading';
import { useFlags } from '@hooks/useFlags';
import { useStyles } from '@styles/login.style';
import SignIn from './SignIn';

const LoginPage = () => {
    const classes = useStyles();
    const { flagOptions } = useFlags();

    if (!flagOptions) return <Loading />;

    return (
        <div className={classes.login}>
            <SignIn flagOptions={flagOptions} />
        </div>
    );
};

export default LoginPage;