import React, { useContext, useState } from 'react';
import LogoCampus from '@assets/images/logoCampus.png';
import LogoOW from '@assets/images/logoLogin.png';
import LogoKitOW from '@assets/images/logoKitOW.png';
import Flags from '@components/Flags';
import { AuthContext } from '@context/auth/AuthContext';
import { useForm } from '@hooks/useForm';
import { styled, Box, Button, Grid, TextField, Typography, Tab, Tabs, IconButton, InputAdornment } from '@mui/material';
import serviceLogin from '@services/serviceLogin';
import { useStyles } from '@styles/loginParticipants.style';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';


const props = {
    inputStyle: {
        textAlign: 'center',
        fontFamily: 'Arial Nova',
        margin: '4px',
        MozAppearance: 'textfield',
        width: '36px',
        borderRadius: '0px',
        fontSize: '24px',
        height: '46px',
        paddingLeft: '0px',
        backgroundColor: 'white',
        color: 'black',
        borderTop: '0px solid white',
        borderLeft: '0px solid white',
        borderRight: '0px solid white',
        borderBottom: '1px solid lightgray'
    }
}

export function a11yProps (index) {
    return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    key: index
    }
}

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none'
}));    

const SignInKitOW = ({ flagOptions }) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const { login } = useContext(AuthContext);
    const [value, setValue] = useState(0)

    const { formData, loginPassword, loginEmail, handleChange } = useForm({
        loginEmail: '',
        loginPassword: '',
        loginMode: 'LoginKitOW'
    });

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }
  
    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const handleSubmit = e => {
        e.preventDefault();
        serviceLogin.postSignIn(formData).then(response => {
            const data = response.data;
            if (!response.error) {
                login(data);
            }
        });
    };


        const handleChangeLanguage = e => {
        const newLanguage = e.currentTarget.dataset.lang;   
        const tabValue = e.currentTarget.dataset.value;
        sessionStorage.setItem('lang', newLanguage);

        setValue(parseInt(tabValue));
        i18n.changeLanguage(newLanguage);
    };

return (
    <div className={classes.card}>
        <div className={classes.header} xs={{ marginTop: '10px', minHeight: '500px !important' }}>
            <img src={LogoKitOW} alt='logo' className={classes.image} />
        </div>

        <Typography className={classes.version} style={{textAlign:'center'}}>
            {process.env.REACT_APP_VERSION}
        </Typography>

        <Typography className={classes.title}>KitOW</Typography>
            
            <Grid className="reactCodeInput" display='flex' flexDirection='column'>
               
            <Box sx={{display:'flex', justifyContent:'center'}}>
                    <Tabs
                        value={value}
                        onChange={e => handleChangeLanguage(e)}
                        aria-label='basic tabs example'
                        
                    >
                    {flagOptions.map(({ languageCode, Icon }, i) => {    
                        const languageTitle = i == 0 ? 'Idioma' : i == 1 ? 'Language' : 'Lingua';
                        return (
                        <AntTab 
                            key={i}
                            data-lang={languageCode} 
                            data-value={i} 
                            label={
                                <>
                                    <Typography variant='subtitle1' sx={{fontSize:'1em'}} >{languageTitle}</Typography><Icon style={{ display: 'flex' }}/>
                                </> 
                            } 
                            {...a11yProps(`flagTab${i}`)} 
                        />
                        )
                    })}
                    </Tabs>
                </Box>

                <Typography sx={{ color:'#e9a459 !important', textAlign:'center', fontSize:'1.2em'}} className={classes.title} >{t('LOGIN.TITLE_PARTICIPANTS').toUpperCase()}</Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12} mb={1}>
                        <TextField
                            id='loginEmail'
                            label={t('LABEL.EMAIL')}
                            variant='filled'
                            name='loginEmail'
                            type='text'
                            fullWidth
                            value={loginEmail}
                            onChange={handleChange}
                            size='small'
                            inputProps={{ maxLength: 100 }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} mb={1}>
                        <TextField
                            id='loginPassword'
                            label={t('LABEL.PASSWORD')}
                            variant='filled'
                            name='loginPassword'
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                            value={loginPassword}
                            onChange={handleChange}
                            autoComplete='off'
                            size='small'
                            inputProps={{ maxLength: 100 }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                        />
                    </Grid>
                </Grid>
                <Box mt={3} mb={3} sx={{ display:'flex', flexDirection:'column', alignItems:'flex-end'}}>
                    <Button variant='contained' onClick={handleSubmit} type='submit' size='large' color='secondary' sx={{color:'white !important', width:'40%'}}>
                        {t('BUTTON.LOGIN')}
                    </Button>
                    <Box
                        mt={1}
                        display='flex'
                        sx={{ justifyContent: 'end' }}>
                        <Typography
                            component={RouterLink}
                            className={classes.subtitle}
                            to='/lostPassword'
                            color='secondary'
                            fontSize={14}
                        >
                            {t('LOGIN.TAG_LOST_PASSWORD')}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
                
            
        </div>
    );
};

export default SignInKitOW;