import React from 'react'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { Button, Hidden } from '@mui/material'
import { red } from '@mui/material/colors'
import { Margin } from '@mui/icons-material'
export const Etiquetas = () => {
  return (
    <div>
      <h2 style={{ margin: '10px' }}>Instrucciones Etiquetas</h2>
      <p style={{ margin: '10px' }}>
        A la hora de poner etiquetas en las plantillas tenemos que tener en
        cuenta que existen dos tipos de etiquetas, las que contienen información
        referente al grupo y las que contienen información relativa a las
        jornadas. Las primeras solo tienen un valor para la plantilla y las
        segundas un valor diferente por cada jornada
      </p>
      <h3 style={{ margin: '10px' }}>Etiquetas de grupo</h3>
      <p style={{ margin: '10px' }}>
        Pueden ponerse en cualquier posición de la plantilla y reflejarán el
        valor correspondiente a su grupo. No requieren ningún tratamiento
        especial. Por ejemplo:
      </p>
      <blockquote
        style={{
          margin: '10px',
          borderLeft: 'solid 4px grey',
          paddingLeft: '20px'
        }}
      >
        Hola, estamos encantados de contar con su participación en el{' '}
        <span style={{ color: 'green' }}>#Ciclo#</span> de tipo{' '}
        <span style={{ color: 'blue' }}>#tipoCiclo#</span>
      </blockquote>
      <h3 style={{ margin: '10px' }}>Etiquetas de jornadas</h3>
      <p style={{ margin: '10px' }}>
        Las etiquetas de jornadas son las que hacen referencia a información de
        cada una de las jornadas (fechas, objetivos, etc.). Este tipo de
        etiquetas tendrá un valor diferente por cada una de las jornadas y, por
        lo tanto, no pueden ponerse sueltas, deben estar siempre dentro de una{' '}
        <b>tabla</b>. El programa detecta estas etiquetas y ajusta la tabla al
        número de jornadas del grupo, por lo que esas tablas pueden tener el
        tamaño que se quiera, pero recomendamos poner cinco columnas (máximo de
        jornadas) para poder hacerse una idea del resultado. Por ejemplo:
      </p>
      <table
        className='table'
        style={{ margin: '10px', border: 'solid 2px green', padding: '10px' }}
      >
        <tbody>
          <tr>
            <td style={{ border: 'solid 2px yellow', padding: '10px' }}>
              <p>1ª jornada</p>
              <p>#tipoJornada#</p>
            </td>
            <td style={{ border: 'solid 2px yellow', padding: '10px' }}>
              <p>2ª jornada</p>
              <p>#tipoJornada#</p>
            </td>
            <td style={{ border: 'solid 2px yellow', padding: '10px' }}>
              <p>3ª jornada</p>
              <p>#tipoJornada#</p>
            </td>
            <td style={{ border: 'solid 2px yellow', padding: '10px' }}>
              <p>4ª jornada</p>
              <p>#tipoJornada#</p>
            </td>
            <td style={{ border: 'solid 2px yellow', padding: '10px' }}>
              <p>5ª jornada</p>
              <p>#tipoJornada#</p>
            </td>
          </tr>
          <tr>
            <td style={{ border: 'solid 2px grey', padding: '10px' }}>
              <p>#fechaJornada#</p>
            </td>
            <td style={{ border: 'solid 2px grey', padding: '10px' }}>
              <p>#fechaJornada#</p>
            </td>
            <td style={{ border: 'solid 2px grey', padding: '10px' }}>
              <p>#fechaJornada#</p>
            </td>
            <td style={{ border: 'solid 2px grey', padding: '10px' }}>
              <p>#fechaJornada#</p>
            </td>
            <td style={{ border: 'solid 2px grey', padding: '10px' }}>
              <p>#fechaJornada#</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p style={{ margin: '10px' }}>
        En el caso anterior el programa reemplaza las etiquetas con los valores
        de las jornadas del grupo y, si el número de jornadas fuera inferior,
        ajustaría automáticamente el tamaño de la tabla.
      </p>
    </div>
  )
}

export default Etiquetas
