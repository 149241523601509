import React from 'react'
import {
  RadioGroup,
  Radio,
  Box,
  TextField,
  Typography,
  Button,
  FormControlLabel,
  ButtonGroup
} from '@mui/material'
import Card from '@components/Card'
import CheckBox from '@components/CheckBox'
import { useCommonStyles } from '@styles/common.style'
import { useTranslation } from 'react-i18next'

const SectionGlobalConfig = ({
  formData,
  handleChange,
  setFormData,
  isUpdate,
  setSelectShownFace,
  setSelectShownVirtual
}) => {

  const { t } = useTranslation()

  const classes = useCommonStyles()

  const daysToText = days => {
    if (days === 1) {
      return `${days} ${t('CYCLE_TYPES.JOURNEY')}`
    }
    if (days > 1) {
      return `${days} ${t('CYCLE_TYPES.JOURNEYS')}`
    }
    if (days === 0) {
      return t('CYCLE_TYPES.NO_JOURNEYS')
    }
  }

  const addJourney = () => {
    const journeysSelected = !formData.cycleTypeJourneysNum
      ? 1
      : formData.cycleTypeJourneysNum + 1

    const totalJourneys = formData.cycleTypeJourneys.length
    const newJourneys = {
      ...(isUpdate && { idCycleType: formData?.idCycleType }),
      cycleTypeJourneyTargets: [],
      cycleTypeJourneyWorkSheets: [],
      cycleTypeJourneyMobileSheets: [],
      cycleTypeJourneyMailings: []
    }

    const journeysToAdd = journeysSelected - totalJourneys
    const journeysObj = Array.from(Array(journeysToAdd)).map((e, i) => {
      return { ...newJourneys }
    })

    setFormData({
      ...formData,
      cycleTypeJourneysNum: journeysSelected,
      cycleTypeJourneys: [...formData.cycleTypeJourneys, ...journeysObj]
    })
  }

  const removeJourney = () => {
    const journeysSelected = Number(formData.cycleTypeJourneysNum - 1)
    const newJourneys = formData.cycleTypeJourneys.splice(0, journeysSelected)
    setFormData({
      ...formData,
      cycleTypeJourneysNum: journeysSelected,
      cycleTypeJourneys: newJourneys
    })
  }

  const handleChangeRadio = e => {
    if (e.target.value == 1) {
      setSelectShownFace(true)
      setSelectShownVirtual(false)
    } else if (e.target.value == 2) {
      setSelectShownFace(false)
      setSelectShownVirtual(true)
    } else if (e.target.value == 3) {
      setSelectShownFace(true)
      setSelectShownVirtual(true)
    }
    handleChange(e)
  }

  return (
    <Card fullHeight>
      <Typography
        color='primary'
        variant='subtitle1'
        mb={1}
        textAlign='center'
        className={classes.cardTitle}
      >
        {t('CYCLE_TYPES.TITLE_GLOBAL_CONFIG').toUpperCase()}
      </Typography>

      <Box my={1}>
        <Typography variant='caption'>
          {t('CYCLE_TYPES.CYCLE_TYPE')}*
        </Typography>
        <TextField
          error={formData?.cycleTypeCode === ''}
          fullWidth
          name='cycleTypeCode'
          size='small'
          onChange={handleChange}
          value={formData?.cycleTypeCode || ''}
          inputProps={{ maxLength: 100 }}
        />
      </Box>

      <Typography variant='caption'>
        {t('CYCLE_TYPES.NUMBER_JOURNEYS')}*
      </Typography>
      <Box display='flex' alignItems='center'>
        <ButtonGroup
          disableElevation
          variant='contained'
          fullWidth
          style={{ height: 39 }}
        >
          <Button
            sx={{
              width: 50
            }}
            disabled={
              formData.cycleTypeJourneysNum === 0 ||
              !formData.cycleTypeJourneysNum
            }
            onClick={removeJourney}
          >
            -
          </Button>
          <Button
            disabled
            sx={{
              textTransform: 'none',
              '&:disabled': {
                color: 'black'
              }
            }}
          >
            {daysToText(formData?.cycleTypeJourneysNum) || daysToText(0)}
          </Button>
          <Button
            sx={{
              width: 50
            }}
            disabled={formData.cycleTypeJourneysNum === 5}
            onClick={addJourney}
          >
            +
          </Button>
        </ButtonGroup>
      </Box>
      <Box my={1}>
        <Typography variant='caption'>{t('CYCLE_TYPES.CODE')}*</Typography>
        <RadioGroup
          name='cycleTypeMode'
          value={
            formData?.cycleTypeMode !== undefined ? formData?.cycleTypeMode : ''
          }
          onChange={handleChangeRadio}
          row
        >
          <FormControlLabel
            value='0'
            control={
              <Radio
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 14
                  }
                }}
              />
            }
            label={
              <Typography variant='caption'>
                {t('CYCLE_TYPES.GENERAL')}
              </Typography>
            }
          />
          <FormControlLabel
            value='1'
            control={
              <Radio
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 14
                  }
                }}
              />
            }
            label={
              <Typography variant='caption'>{t('CYCLE_TYPES.FACE')}</Typography>
            }
          />
          <FormControlLabel
            value='2'
            control={
              <Radio
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 14
                  }
                }}
              />
            }
            label={
              <Typography variant='caption'>
                {t('CYCLE_TYPES.VIRTUAL')}
              </Typography>
            }
          />
          <FormControlLabel
            value='3'
            control={
              <Radio
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 14
                  }
                }}
              />
            }
            label={
              <Typography variant='caption'>{t('CYCLE_TYPES.MIX')}</Typography>
            }
          />
        </RadioGroup>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
        <Box my={1}>
          <CheckBox
            label={t('CYCLES.EVAL')}
            name='hasEvaluator'
            checked={formData?.hasEvaluator}
            onChange={e => {
              console.log("Nuevo estado de hasEvaluator al pulsar:", e.target.checked);
              handleChange(e)
            }}
          />
        </Box>
        <Box my={1}>
          <CheckBox
            label={t('CYCLES.IS_VISIBLE')}
            name='isVisible'
            checked={formData?.isVisible}
            onChange={e => {
              console.log("Nuevo estado de isVisible al pulsar:", e.target.checked);
              handleChange(e)
            }}
          />
        </Box>
      </Box>
    </Card>
  )
}

export default SectionGlobalConfig
