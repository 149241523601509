import React, { Fragment, useContext, useState, useEffect } from 'react';
import Logo from '@assets/images/logoCampus.png';
import NoAvatar from '@assets/images/avatar.png';
import { AuthContext } from '@context/auth/AuthContext';
import { useFlags } from '@hooks/useFlags';
import { Logout } from '@mui/icons-material';
import {
    AppBar,
    Avatar,
    Box,
    Grid,
    Hidden,
    Icon,
    IconButton,
    Stack,
    Switch,
    Toolbar,
    Typography,
} from '@mui/material';
import { useStyles } from '@styles/participants.style';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Header = (props) => {
    const classes = useStyles();
    const { user, logout } = useContext(AuthContext);
    const { t } = useTranslation();
    const groupPhoto = props.groupPhoto;
    const withGroupPhoto = props.groupPhoto ? true : false;

    return (
        <Grid className={classes.header} container >
            { withGroupPhoto ?
            <Grid item xs={12} paddingLeft={0} sx={{overflow: 'hidden'}}>
                <img src={"data:image/png;base64," + groupPhoto}
                    alt='target img'
                    className={classes.imageHeader}
                />
            </Grid>
            :
            <Grid item xs={12} display="flex" justifyContent="center" alignItems='center' paddingTop={0} style={{color: 'white', textAlign:'center', fontSize:'30px'}}>
                {t('LABEL.CAMPUS_OW')}
            </Grid>
            }  
        </Grid>
    );
};

export default Header;