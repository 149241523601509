import React, { useState, useEffect, useCallback } from 'react'
import Dialog from '@components/Dialog'
import FormActions from '@components/FormActions'
import HeaderTitle from '@components/HeaderTitle'
import { useForm } from '@hooks/useForm'
import { Box, Button, Grid } from '@mui/material'
import serviceCycleTypes from '@services/serviceCycleTypes'
import { useCommonStyles } from '@styles/common.style'
import { useLocation, useNavigate } from 'react-router-dom'
import SectionTemplateCalls from './components/CycleTypeSections/SectionTemplateCalls'
import SectionGlobalConfig from './components/CycleTypeSections/SectionGlobalConfig'
import SectionJourneysTabs from './components/CycleTypeSections/SectionJourneysTabs'
import SectionLocale from './components/CycleTypeSections/SectionLocale'
import SectionTemplateRemind from './components/CycleTypeSections/SectionTemplateRemind'
import { useTranslation } from 'react-i18next'
import { snackActions } from '@helpers/snackbarUtils'

import Loading from '@components/Loading'

import { useDataCycleTypes } from '@hooks/useDataCycleTypes'

const CycleTypesDetail = () => {
  const { t } = useTranslation()
  const classes = useCommonStyles()
  const navigate = useNavigate()
  const [openDialog, setOpenDialog] = useState(false)
  
  const {
    state: { data: CYCLE_TYPE_DATA, type: TYPE }
  } = useLocation()

  const { formData, handleChange, setFormData } = useForm(
    CYCLE_TYPE_DATA || {
      cycleTypeJourneys: [],
      cycleTypeLocales: [],
      isVisible: true
    }
  )

  const initialSelectShowFace = formData.cycleTypeMode == 1 ? true : formData.cycleTypeMode == 2 ? false : true ;
  const initialSelectShowVirtual = formData.cycleTypeMode == 1 ? false : true ;
  
  const [selectShownFace, setSelectShownFace] = useState(initialSelectShowFace)
  const [selectShownVirtual, setSelectShownVirtual] = useState(initialSelectShowVirtual)


  const {
    emailList,
    allEmails,
    targetList,
    worksheetList,
    mobilesheetList,
    categories,
    loading,
    setLoading,
    showEmailItems,
    showEmailModal,
    setShowEmailModal,
    showPreparationItems
  } = useDataCycleTypes(TYPE, setFormData, formData)

  useEffect(() => {
    if (emailList && TYPE === 'NEW') {
      setFormData({
        ...formData,
        cycleTypeCallPIdMailing: emailList[1][0].idMailing, //CONP
        cycleTypeCallDIdMailing:
          emailList[2].length > 0 ? emailList[2][0].idMailing : '', //COND
        cycleTypeRemindPIdMailing:
          emailList[3].length > 0 ? emailList[3][0].idMailing : '', //RECP
        cycleTypeRemindDIdMailing:
          emailList[4].length > 0 ? emailList[4][0].idMailing : '', //RECD
        cycleTypeCallMIdMailing:
          emailList[10].length > 0 ? emailList[10][0].idMailing : '', //CONM
        cycleTypeRemindMIdMailing:
          emailList[11].length > 0 ? emailList[11][0].idMailing : '' //RECM
      })
    }
  }, [emailList])

  const HEADER_TITLE =
    TYPE === 'NEW' ? t('CYCLE_TYPES.NEW_TITLE') : t('CYCLE_TYPES.UPDATE_TITLE')
  const HEADER_SUBTITLE =
    TYPE === 'NEW'
      ? t('CYCLE_TYPES.NEW_SUBTITLE')
      : t('CYCLE_TYPES.UPDATE_SUBTITLE')

  const disabledSaveButton = CYCLE_TYPE_DATA === formData

  const handleSubmit = type => {
    setLoading(true)
    // Validar campos requeridos aquí si es necesario
    const newValuesForm = {
        ...formData,
        cycleTypeJourneys: formData.cycleTypeJourneys || [],
        cycleTypeLocales: formData.cycleTypeLocales || [],
        isVisible: formData.isVisible !== undefined ? formData.isVisible : true
    }

    switch (type) {
      case 'NEW':
        return serviceCycleTypes.newCycleType(newValuesForm).then(response => handleResponse(response, 'NEW'))
      case 'UPDATE':
        return serviceCycleTypes.updateCycleType(newValuesForm).then(response => handleResponse(response, 'UPDATE'))
      default:
        return serviceCycleTypes.deleteCycleType(newValuesForm).then(response => handleResponse(response, 'DELETE'))
    }
  }

  const handleResponse = useCallback(async (response, actionType) => {
    setLoading(false);
    if (!response.error) {
      if (actionType === 'DELETE') {
        navigate('/cycleTypes');
      } else {
          const updatedId = actionType === 'NEW' ? response.data.idCycleType : formData.idCycleType;
          const { data, error } = await serviceCycleTypes.getCycleTypeDetail(updatedId, true);
          if (!error) {
              setFormData(data);
              navigate(`/cycleTypesDetail`, {
                  state: { data, type: 'UPDATE' }
              })
          }
        }
    }
  },
    [navigate, formData.idCycleType, t]
  )
  

  const props = {
    formData,
    setFormData,
    handleChange,
    emailList,
    targetList,
    worksheetList,
    mobilesheetList,
    showEmailItems,
    categories,
    allEmails,
    isUpdate: TYPE === 'UPDATE',
    showEmailModal,
    setShowEmailModal,
    selectShownFace,
    selectShownVirtual,
    setSelectShownFace,
    setSelectShownVirtual,
    showPreparationItems
  }

  if (loading) {
    return <Loading />
  }
  return (
    <div className={classes.container}>
      <Dialog
        open={openDialog}
        hasCloseBtn={false}
        height={200}
        title={t('CONFIRM.TITLE_CYCLETYPE')}
        subtitle={t('CONFIRM.UNRECOVERY_DELETE')}
        close={() => setOpenDialog(false)}
        actions={
          <Box display='flex' alignItems='center'>
            <Box mr={1}>
              <Button
                variant='contained'
                onClick={() => setOpenDialog(false)}
                color='error'
              >
                {t('BUTTON.CANCEL')}
              </Button>
            </Box>
            <Button variant='contained' onClick={() => handleSubmit('DELETE')}>
              {t('BUTTON.DELETE')}
            </Button>
          </Box>
        }
      />

      <HeaderTitle title={HEADER_TITLE} subtitle={HEADER_SUBTITLE}>
        <FormActions
          show={TYPE === 'UPDATE'}
          handleDelete={() => setOpenDialog(true)}
          handleSave={() => handleSubmit(TYPE)}
          disabledButton={disabledSaveButton}
          urlBack='/cycleTypes'
        />
      </HeaderTitle>

      <Grid container spacing={2} mb={2}>
        <Grid item lg={3} md={6} xs={12}>
          <SectionLocale {...props} />
        </Grid>
        <Grid item lg={3} md={6} xs={12}>
          <SectionGlobalConfig {...props} />
        </Grid>
        <Grid item lg={3} md={6} xs={12}>
          <SectionTemplateCalls {...props} />
        </Grid>
        <Grid item lg={3} md={6} xs={12}>
          <SectionTemplateRemind {...props} />
        </Grid>
      </Grid>

      <SectionJourneysTabs {...props} />
    </div>
  )
}

export default CycleTypesDetail
