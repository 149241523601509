import React from 'react';
import AddButton from '@components/AddButton';
import DynamicTable from '@components/DynamicTable';
import HeaderTitle from '@components/HeaderTitle';
import { EditRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import serviceTemplates from '@services/serviceTemplates';
import { useCommonStyles } from '@styles/common.style';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { snackActions } from '@helpers/snackbarUtils';

const TemplateTargets = () => {
    const classes = useCommonStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const ORDER = [
        {
            "orderField": "targetLocaleName",
            "orderAsc": true
        }
    ];
    const COLUMNS = [
        {
            accessor: '',
            Header: 'Info',
            filterable: false,
            hideHeader: true,
            width: '5%',
            Cell: ({ row: { values } }) => (
                <IconButton
                    aria-label='info'
                    color='info'
                    style={{
                        padding: '0px',
                    }}
                    onClick={() => {
                        handleShowTargetDetail(values.idTarget);
                    }}>
                    <EditRounded fontSize='inherit' />
                </IconButton>
            ),
        },
        {
            accessor: 'idTarget',
            Header: t('LABEL.ID'),
            filterable: false,
            width: '5%',
        },
        {
            accessor: 'targetLocaleName',
            Header: t('LABEL.NAME'),
        },
    ];

    const handleShowTargetDetail = idTarget => {
        const userSession = JSON.parse(sessionStorage.getItem('userSession'))
        if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 4)) {
            serviceTemplates.getTargetDetail(idTarget).then(response => {
                if (!response.error) {
                    navigate('/templateTargetDetail', {
                        state: { data: response.data, type: 'UPDATE' },
                    });
                }
            });
        } else {
            snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'));
        } 
    };

    const handleNewTarget = () => {
        const userSession = JSON.parse(sessionStorage.getItem('userSession'))
        if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 4)) {
            navigate('/templateTargetDetail', { state: { data: {}, type: 'NEW' } });
        } else {
            snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'));
        } 
    };
    return (
        <div className={classes.container}>
            <HeaderTitle
                title={t('TEXT.TITLE_TEMPLATE_TARGET')}
                subtitle={t('TEXT.SUBTITLE_TEMPLATE')}>
                <AddButton
                    text={t('BUTTON.NEW_TEMPLATE_TARGET')}
                    handleOnclick={handleNewTarget}
                />
            </HeaderTitle>
            <DynamicTable
                columns={COLUMNS}
                endpoint='Target/List'
                isGlobalSearch={true}
                isColumnsSearch={true}
                isOrdered={true}
                orderColumn={ORDER}
            />
        </div>
    );
};

export default TemplateTargets;