import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import * as MuiIcons from '@mui/icons-material';

const MuiIconNames = Object.keys(MuiIcons);

const Icons = MuiIconNames.reduce((iconMap, iconName) => {
    iconMap[iconName] = MuiIcons[iconName];
    return iconMap;
}, {});

const DynamicIcon = ({ iconName, fontSize }) => {
    const IconComponent = Icons[iconName];

    if (IconComponent) {
        return <IconComponent fontSize={fontSize || 'default'} />;
    } else {
        // Manejar el caso en que el �cono no est� definido
        return <SvgIcon fontSize={fontSize || 'default'} />;
    }
};

export default DynamicIcon;
