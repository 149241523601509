import React, { Fragment, useEffect, useState } from 'react'
import {
  TextField,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import { useCommonStyles } from '@styles/common.style'
import { useTranslation } from 'react-i18next'
import LocaleTabs from '@components/LocaleTabs'
import Editor from '@components/CkEditor'
import Card from '@components/Card'
import servicePreparation from '@services/servicePreparation'

const PreparationConfiguration = ({
  setJourney,
  isUpdate,
  setFormData,
  formData,
  journey
}) => {
  const { t } = useTranslation()
  const [preparations, setPreparations] = useState()

  useEffect(() => {
    servicePreparation.getPreparations({ search: [] }).then(response => {
      if (!response.error) {
        setPreparations(response.data || [])
      }
    })
  }, [])
  
  useEffect(() => {
    if (formData?.idPreparation !== undefined) {
      setJourney(prevState => ({
        ...prevState,
        idPreparation: formData.idPreparation,
      }))
    }
  }, [formData?.idPreparation])
  
  const handleChange = e => {
    setJourney({ ...journey, [e.target.name]: e.target.value })
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  
  return (
    <Fragment>
      <Typography
        textAlign='center'
        color='white'
        variant='subtitle1'
        sx={{
          bgcolor: 'grey.main',
          color: 'primary.main',
          borderRadius: 1,
          marginBottom: 2,
          fontSize: 14,
          fontWeight: 'bold',
          py: 1
        }}
      >
        {t('CONFIG_JOURNEYS.PREPARATION.CONFIG').toUpperCase()}
      </Typography>
      <Card fullHeight>
        <Grid container>
          <Grid item xs={12} md={12}>
              <Typography variant='caption' component='div'>
                {t('CONFIG_JOURNEYS.PREPARATION.PREPARATION')}
              </Typography>
              <FormControl fullWidth size='small' margin='none'>
                <Select
                  labelId='idPreparation'
                  value={journey?.idPreparation || ''}
                  onChange={handleChange}
                  name='idPreparation'
                >
                  {preparations?.map(preparation => (
                    <MenuItem
                      value={preparation.idPreparation}
                      key={preparation.idPreparation}
                    >
                      {preparation.preparationName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
      </Card>
    </Fragment>
  )
}

export default PreparationConfiguration
