import apiRequest from '@helpers/apiRequest';

const getWorkerDetails = idWorker => {
    return apiRequest('GET', `Workers/Details/${idWorker}`);
};

const updateWorker = data => {
    const idWorker = data.idWorker;
    return apiRequest('PUT', `Workers/Update/${idWorker}`, data);
};

const newWorker = data => {
    return apiRequest('POST', 'Workers/New', data);
};

const deleteWorker = data => {
    const idWorker = data.idWorker;
    return apiRequest('DELETE', `Workers/Delete/${idWorker}`);
};

const serviceWorkers = {
    getWorkerDetails,
    updateWorker,
    newWorker,
    deleteWorker
};

export default serviceWorkers;