import React, { Fragment } from 'react';
import clsx from 'clsx';
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    IconButton,
} from '@mui/material';

import MUIDialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from '@styles/dialog.style';

const Dialog = ({
    content,
    title,
    subtitle,
    actions,
    open,
    close,
    hideBackdrop,
    hasCloseBtn = true,
    dividers = true,
    fullScreen = false,
    disabled = false,
    style,
    ...props
}) => {
    const classes = useStyles(props);

    const DialogHeader = props => {
        const { children, onClose, ...other } = props;
        return (
            <DialogTitle {...other}>
                {children}
                {hasCloseBtn && (
                    <IconButton
                        aria-label='close'
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 12,
                            color: theme => theme.palette.grey[500],
                        }}>
                        <CloseIcon />
                    </IconButton>
                )}
            </DialogTitle>
        );
    };

    return (
        <Fragment>
            <MUIDialog
                fullScreen={fullScreen}
                hideBackdrop={hideBackdrop}
                open={open}
                onClose={!disabled ? close : () => { }}
                classes={{ 
                    paper: classes.cardContainer, 
                    container: props.topContent && classes.contentTop 
                }}
            >
                <DialogHeader onClose={close} style={{ flex: content ? 0 : 1 , backgroundColor: title == "Post-It Digital" ? '#FFFF99' : 'none'}}>
                    <Typography
                        sx={{
                            typography: { xs: 'body1', sm: 'h6' },
                        }}>
                        {title}
                    </Typography>

                    {subtitle && (
                        <Typography
                            variant='body1'
                            color='primary'
                            mt={1}
                            sx={{
                                typography: { xs: 'body1' },
                            }}>
                            <span dangerouslySetInnerHTML={{ __html: subtitle }} />
                        </Typography>
                    )}
                </DialogHeader>

                {content && (
                    <DialogContent
                        className={(props.centerContent && classes.contentCenter)}
                        dividers={dividers}
                        style={style}>
                        {content}
                    </DialogContent>
                )}
                {actions && (
                    <DialogActions style={{ padding: '16px 24px' }}>
                        {actions}
                    </DialogActions>
                )}
            </MUIDialog>
        </Fragment>
    );
};

export default Dialog;