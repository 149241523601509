import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import React from 'react'
import { DebounceInput as Debounce } from 'react-debounce-input'
import { useStyles } from '@styles/table.style'

const Search = ({
  handleOnchange,
  name,
  size = 15,
  global = false,
  value = ''
}) => {
  const classes = useStyles({ global })

  return (
    <div className={classes.searchContainer}>
      <Debounce
        className={classes.search}
        minLength={0}
        debounceTimeout={500}
        id={name}
        name={name}
        size={size}
        type='text'
        onChange={handleOnchange}
        value={value}
      />
      <SearchRoundedIcon className={classes.searchIcon} color='primary' />
    </div>
  )
}

export default Search
