import {
    StarBorderPurple500Outlined,
    ArticleOutlined
  } from '@mui/icons-material'

const menuKitOW = t => [
    {    
        items: [
          {
            title: t('MENU_OWTOOLBOX.WORKSHEET'),
            url: '/workSheetsListKitOW',
            icon: <ArticleOutlined />
          },
          {
            title: t('MENU_OWTOOLBOX.FAVOURITE'),
            url: '/worksheetsFavoriteKitOW',
            icon: <StarBorderPurple500Outlined />
          }
        ]
      }   
];

export default menuKitOW;