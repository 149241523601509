import React, { useState } from 'react'
import AddButton from '@components/AddButton'
import DynamicTable from '@components/DynamicTable'
import HeaderTitle from '@components/HeaderTitle'
import { EditRounded } from '@mui/icons-material'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import serviceEvaluatorData from '@services/serviceEvaluatorData'
import { useCommonStyles } from '@styles/common.style'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Moment from 'react-moment'
import { snackActions } from '@helpers/snackbarUtils'
import Loading from '@components/Loading'
import serviceCompanies from '@services/serviceCompanies'
import serviceParticipants from '@services/serviceParticipants'


const Evaluator = () => {
  const classes = useCommonStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)

  const ORDER = [
      {
          "orderField": "IdEvaluator",
          "orderAsc": true
      }
  ];
  const COLUMNS = [
      {
          accessor: '',
          Header: 'Info',
          filterable: false,
          hideHeader: true,
          width: '5%',
          Cell: ({ row: { values } }) => (
              <Box display='flex' flexDirection='row'>
                  <Tooltip title={t('LABEL.EDIT')}>
                      <IconButton
                          aria-label='info'
                          color='icon_blue'
                          style={{
                              padding: '0px',
                              marginRight: 15,
                          }}
                          onClick={() => {
                            handleShowEvaluatorDetail({
                                  IdEvaluator: values.idEvaluator,
                                  type: 'UPDATE',
                              });
                          }}>
                          <EditRounded fontSize='inherit' />
                      </IconButton>
                  </Tooltip>
              </Box>
          ),
      },
      {
          accessor: 'idEvaluator',
          Header: t('LABEL.ID'),
          filterable: false,
          width: '5%',
          hiddenColumn: true
      },
      {
          accessor: 'evaluatorName',
          Header: t('LABEL.EV_NAME'),
          Cell: props => <Typography variant='body2'>{props.value}</Typography>,
      },
      {
          accessor: 'companyName',
          Header: t('LABEL.COMPANY'),
          Cell: props => <Typography variant='body2'>{props.value}</Typography>,
      },
      {
          accessor: 'evaluatorDateInit',
          Header: t('LABEL.EV_START'),
          Cell: props => (
            <Typography variant='body2'>
              <Moment format='DD[/]MM[/]yyyy'>{props.value}</Moment>
            </Typography>
          ),
          width: '5%'
      },
        {
          accessor: 'evaluatorDateEnd',
          Header: t('LABEL.EV_END'),
          Cell: props => (
            <Typography variant='body2'>
              <Moment format='DD[/]MM[/]yyyy'>{props.value}</Moment>
            </Typography>
          ),
          width: '5%'
      },
  ];

const handleShowEvaluatorDetail = async ({ IdEvaluator, type }) => {
  //Se verifica si el usuario tiene permisos para realizar las acciones sobre un ciclo
  const userSession = JSON.parse(sessionStorage.getItem('userSession'))
  if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 4)) {
    setLoading(true)
    const { data, error } = await serviceEvaluatorData.getEvaluatorDetails(IdEvaluator)
    if (!error) {
      const dataPart = await fetchParticipants(data,IdEvaluator)
      const dataFull = await fetchCompanies(dataPart)
      navigate(`/evaluatorDetail`, {
        state: { dataFull, type }
      })
    } else {
      setLoading(false)
    }
  } else {
    setLoading(false)
    snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'))
  }
}
const fetchCompanies = async (data) => {
  const updatedCompanies = await Promise.all(
    data.evaluatorCompanies.map(async (company) => {
      const fullCompany = await serviceCompanies.getCompaniesDetails(company.idCompany);
      return { ...company, companyName: fullCompany.data.companyName };
    })
  );
  let formData = data
  formData.evaluatorCompanies = updatedCompanies
  return formData;
};

  const fetchParticipants = async (data,IdEvaluator) => {
  const updatedParticipants = await Promise.all(
      data.evaluatorParticipants.map(async (participant) => {
          const fullParticipant = await serviceEvaluatorData.getEvaluatorParticipantsDetails(participant.idParticipant, IdEvaluator);
          console.log(fullParticipant?.data)
          if (fullParticipant.data !== null) {
              return {
                  ...participant, participantName: fullParticipant.data.participantName, participantSurname: fullParticipant.data.participantSurname,
                  participantEmail: fullParticipant.data.participantEmail,
                  nota_Total: fullParticipant.data.nota_Total, nota_QTR: fullParticipant.data.nota_QTR, nota_AutoEval: fullParticipant.data.nota_AutoEval,
                  numReceivers: fullParticipant.data.numReceivers, totalReceiverResponses: fullParticipant.data.totalReceiverResponses,
                  idEvaluatorType: fullParticipant.data.idEvaluatorType, isAutoevalDone: fullParticipant.data.isAutoevalDone
              }
          };
    })
  );
  let formData = data
  formData.evaluatorParticipants = updatedParticipants
  return formData;
};


/*const handleNewEvaluator = () => {
    const userSession = JSON.parse(sessionStorage.getItem('userSession'))
    if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 2)) {
        navigate(`/evaluatorDetail`, { state: { data: {}, type: 'NEW' } });
    } else {
        snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'));
    }  
  }*/
    if (loading) {
      return <Loading />
    }
  
  return (
      <div className={classes.container}>
      <HeaderTitle
        title={t('MENU_ADMIN.EVALUATOR')}
        subtitle={t('TEXT.SUBTITLE_EVALUATOR')}
      >
      </HeaderTitle>
      <DynamicTable
        columns={COLUMNS}
        endpoint='EvaluatorData/Evaluator/List'
        isGlobalSearch={true}
        isColumnsSearch={true}
        isOrdered={true}
        viewExcel={true}
        nameExcel={t('MENU_ADMIN.EVALUATOR')}
        titleBodyExcel={t('EVALUATOR.BODY_EXCEL')}
        orderColumn={ORDER}
      />
    </div>
  )
}

export default Evaluator
