import React, { useState, useRef, useEffect } from 'react';
import Dialog from '@components/Dialog';
import { Grid, TextField, Box, Typography, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import * as MuiIcons from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

const MaterialIconPicker = ({ value, onChange }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
		const {t} = useTranslation();

    const materialIcons = Object.keys(MuiIcons);

    const filteredIcons = materialIcons.filter((icon) =>
        icon.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSearchClick = () => {
        setOpenDialog(true);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleIconSelect = (selectedIcon) => {
        setOpenDialog(false);
        onChange(selectedIcon);
    };

    const handleTextFieldChange = (e) => {
        // Actualizar el valor y también el término de búsqueda
        onChange(e.target.value);
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        // Actualizar el término de búsqueda al abrir el diálogo con el valor seleccionado
        if (openDialog) {
            setSearchTerm(value);
        }
    }, [openDialog, value]);

    return (
        <Grid container spacing={2}>
            <Grid item>
                <TextField
                    type="text"
                    className="use-material-icon-picker"
                    value={value}
                    fullWidth
                    onChange={handleTextFieldChange}
                    InputProps={{
                        style: { padding: '1px' },
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton onClick={handleSearchClick}>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Dialog
                    open={openDialog}
                    hasCloseBtn={true}
                    width='50%'
                    //height='75%'
                    close={() => setOpenDialog(false)}
                    actions={
											<Grid container direction="column" spacing={2} alignItems="center">
												<Grid item>
													<Typography color='primary' variant='subtitle1' mb={2}>
														{t('SCHEDULER.SELECT_ICON')}
													</Typography>
												</Grid>
												<Grid item xs={9} 
													sx={{
														marginTop: '-25px', 
														marginBottom: '25px',
														width: {
															xs: '250px', // Ancho para pantallas pequeñas
															sm: '500px', // Ancho para pantallas más grandes
														}
													}}
												>
                        <TextField
                          type="text"
                          placeholder={t('SCHEDULER.SEARCH_ICON')}
                          fullWidth
                          onChange={handleSearchChange}
                          value={searchTerm}                          
                          InputProps={{
                            style: { padding: '1px', height: '40px' },
                            endAdornment: (
                              searchTerm && (
                                <InputAdornment position="end">
                                  <IconButton onClick={() => setSearchTerm('')} size="small">
                                    <CloseIcon />
                                  </IconButton>
                                </InputAdornment>
                              )
                            ),
                          }}
                        />
											</Grid>
                            
                      <Grid container spacing={2}>
                        {filteredIcons.map((icon) => (
                          <Grid item key={icon} xs={4} sm={3} md={2}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              onClick={() => handleIconSelect(icon)}
                              style={{ cursor: 'pointer' }}
                            >
                              {React.createElement(MuiIcons[icon], {
                                fontSize: 'large',
                              })}
                              <Typography variant="body2" style={{maxWidth: '70px', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                  {icon}
                              </Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    }
                />
            </Grid>
        </Grid>
    );
};

export default MaterialIconPicker;
