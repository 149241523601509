import { makeStyles } from '@mui/styles';

export const useCheckboxStyles = makeStyles(({ palette }) => {
    return {
        container: props => {
            return {
                background: !props.simple && '#f1f1f1',
                borderRadius: 4,
                width: props.fullWidth ? '100%' : 'fit-content',
                padding: '10px',
                justifyContent: 'center',
                display: props.fullWidth && 'flex',
                alignItems: props.fullWidth && 'center',
            };
        },
    };
});