import * as React from 'react';
import Popover from '@mui/material/Popover';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Icon from '@components/Icon';
import { useTranslation } from "react-i18next";

export default function DeletePopover({ remove }) {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Icon variant='warning' onClick={handleClick}>
                <DeleteIcon />
            </Icon>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}>
                <Box>
                    <Typography align='center' p={1}>
                        {t("LABEL.DELETE")}
                    </Typography>
                    <Box pb={1} px={1}>
                        <Button
                            size='small'
                            variant='contained'
                            color='error'
                            onClick={handleClose}
                            sx={{ mr: 0.5 }}>
                            {t("LABEL.FALSE")}
                        </Button>
                        <Button
                            size='small'
                            variant='contained'
                            color='success'
                            onClick={() => {
                                remove();
                                handleClose();
                            }}
                            sx={{ ml: 0.5 }}>
                            {t("LABEL.TRUE")}
                        </Button>
                    </Box>
                </Box>
            </Popover>
        </div>
    );
}