import React, { useState } from 'react';
import Logo from '@assets/images/logoLogin.png';
import { useForm } from '@hooks/useForm';
import { Box, Button, TextField, Typography, IconButton, InputAdornment } from '@mui/material';
import serviceLogin from '@services/serviceLogin';
import { useStyles } from '@styles/login.style';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const NewPassword = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const { t } = useTranslation();

    const [error, setError] = useState('');
    const { userRecoveryHash, userPassword, userPasswordConfirm, handleChange } =
        useForm({
            userRecoveryHash: '',
            userPassword: '',
            userPasswordConfirm: '',
        });    

    const [showPassword, setShowPassword] = useState(false);
    
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }
  
    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const handleSubmit = e => {
        e.preventDefault();
        if (!userRecoveryHash || !userPassword) {
            setError(t('LOGIN.TAG_EMPTY_FIELDS'));
            return;
        }
        serviceLogin
            .setNewPassword({ userRecoveryHash, userPassword })
            .then(response => {
                if (!response.error) {
                    navigate('/', { replace: true });
                }
            });
    };

    return (
        <div className={classes.login}>
            <form onSubmit={handleSubmit} className={classes.card}>
                <div className={classes.header}>
                    <img src={Logo} alt='logo' className={classes.image}></img>
                </div>
                <div>
                    <Typography color='secondary' align='left' mt={3} fontSize={14}>
                        {t('LOGIN.TAG_CODE')}
                    </Typography>
                    <TextField
                        name='userRecoveryHash'
                        label={t('LABEL.CODE')}
                        variant='filled'
                        fullWidth
                        value={userRecoveryHash}
                        onChange={handleChange}
                        margin='dense'
                        size='small'
                        inputProps={{ maxLength: 10 }}
                    />
                    <Typography color='secondary' align='left' mt={3} fontSize={14}>
                        {t('LOGIN.TAG_NEW_PASSWORD')}
                    </Typography>
                    <TextField
                        name='userPassword'
                        label={t('LABEL.NEW_PASSWORD')}
                        variant='filled'
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        value={userPassword}
                        onChange={handleChange}
                        margin='dense'
                        size='small'
                        inputProps={{ maxLength: 100 }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                    />

                    <TextField
                        name='userPasswordConfirm'
                        label={t('LABEL.CONFIRM_PASSWORD')}
                        variant='filled'
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        value={userPasswordConfirm}
                        onChange={handleChange}
                        margin='dense'
                        size='small'
                        inputProps={{ maxLength: 100 }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                    />
                </div>
                {error && (
                    <Typography align='center' color='error' fontSize={14} mt={3}>
                        {error}
                    </Typography>
                )}
                <Box mt={3}>
                    <Button variant='contained' fullWidth type='submit' size='large'>
                        {t('BUTTON.SEND')}
                    </Button>
                </Box>
            </form>
        </div>
    );
};

export default NewPassword;