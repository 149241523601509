import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '@context/auth/AuthContext';
import serviceLogin from '@services/serviceLogin';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loading from '@components/Loading';

const SimulatedLogin = () => {
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    
    const loginSimulado = e => {
        
        sessionStorage.setItem('loginToken', searchParams.get("loginToken"));

        const dataSignSimulated = {
           loginEmail: searchParams.get("email"),
           loginMode: 'LoginParticipant'
       };

        serviceLogin.postSignInSimulated(dataSignSimulated).then(response => {
            const data = response.data;
            sessionStorage.setItem('area', 'participant');
            let url;
            if (!response.error) {
                url = process.env.REACT_APP_PUBLIC_URL_PARTICIPANT;
                login(data);
            } else {
                //En caso de error se navega al componente de login
                url = process.env.REACT_APP_PUBLIC_URL;
            }
            navigate(url);
        });
    };

    useEffect(() => {
        loginSimulado();
    }, []);

    return <Loading />;

};

export default SimulatedLogin;