import React, {useEffect, useState} from 'react'
import {useStyles} from '@styles/participants.style'
import {useTranslation} from 'react-i18next'
import {Grid, Typography} from '@mui/material'
import {useOutletContext} from 'react-router-dom'
import getCurrentJourney from '@helpers/currentJourney'
import {Link} from 'react-router-dom'
import ArrowBack from '@mui/icons-material/ArrowBack'
import Loading from '@components/Loading'
import {print} from 'react-html2pdf'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import PrintIcon from '@mui/icons-material/Print'
import {FLAG_ITEMS} from '@constants/flagItems'
import {transformText} from '@helpers/common'
import serviceCycleJourneys from '@services/serviceCycleJourneys'
import Tooltip from '@mui/material/Tooltip';

const ActiveJourney = () => {
    const classes = useStyles()
    const {t} = useTranslation()
    const languageSelected = sessionStorage.getItem('lang')
    const {
        cycle: [cycle, setCycle]
    } = useOutletContext()
    const [details, setDetails] = useState([
        getCurrentJourney(cycle).cycleJourneyPreparation[0]
    ])
    const [loading, setLoading] = useState(true)
    const [lang, setLang] = useState(1)
    const [preparation, setPreparation] = useState(null)

    /*const printNotPdf = () => {
        let printContents = document.getElementById('templatePdf').innerHTML
        var mywindow = window.open()
        console.log(mywindow);
        mywindow.document.write(printContents)
        mywindow.document.close() // necessary for IE >= 10
        mywindow.focus() // necessary for IE >= 10
        mywindow.print()
        mywindow.close()
    }*/

    const printNotPdf = () => {
        // Asegúrate de que preparation.preparationDocument contenga el PDF en Base64
        if (!preparation || !preparation.preparationDocument) {
            alert('No hay preparación disponible para descargar.');
            return;
        }

        // Convertimos el Base64 en un Blob y forzamos la descarga
        const byteCharacters = atob(preparation.preparationDocument); // Decodificar Base64
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        // Crear un enlace de descarga
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'preparacion.pdf'; // Nombre del archivo
        document.body.appendChild(a);
        a.click();

        // Remover el enlace del DOM y liberar la URL del Blob
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }


    useEffect(() => {
        setLoading(true)
        const {id: idLangSelected} = FLAG_ITEMS.find(
            item => item.value === languageSelected
        )

        let journey = getCurrentJourney(cycle)

        serviceCycleJourneys.getCyclePreparation(cycle.idCycle, journey.idCycleJourney, idLangSelected).then(response => {
            if (!response.error) {
                setPreparation(response.data)
            }
            setLoading(false)
        })
        setLang(idLangSelected)

    }, [])

    return (
        <div style={{margin: '0vh 0%', maxWidth: '100vw', minHeight: '80vh', overflowX: 'hidden'}}>
            <Grid container justifyContent="center">
                <Grid item xs={11}>
                    <Grid container spacing={1} style={{paddingTop: '1vh', paddingLeft: '2%', paddingRight: '2%', alignItems: 'space-between'}}>
                        <Grid item xs={1.5} display='flex' justifyContent='center'>
                            <Tooltip title={t('PARTICIPANTS.MY_TARGETS')}>
                                <Link to={'/targets'}>
                                    <ArrowBack />
                                </Link>
                            </Tooltip>
                        </Grid>

                        <Grid item xs={9} display='flex' justifyContent='center'>
                            <h3>{t('MENU_PARTIPANTS.NEXT_JOURNEY')}</h3>
                        </Grid>

                        <Grid item xs={1} style={{marginTop: '0.1vh'}}>
                            <Tooltip title={t('PARTICIPANTS.SAVE_PDF')}>
                                <PrintIcon style={{cursor: 'pointer'}} onClick={printNotPdf} />
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <hr />
            <Grid container justifyContent="center" style={{padding: '1%', width: '100%', minHeight: '50vh', overflowX: 'hidden'}} id={'templatePdf'}>
                {loading && (
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh', width: '100%'}}>
                        <Loading />
                    </div>
                )}
                {details[0]?.preparationName !== '' && details[0]?.preparationName !== null ? (
                    <Typography className={classes.subtitle}>
                        {/* {details[0]?.preparationName} */}
                    </Typography>
                ) : (
                    <Typography marginTop={2}>{t('PARTICIPANTS.NODATA')}</Typography>
                )}

                {

                    (preparation &&
                        preparation.preparationBody !== '' &&
                        preparation.preparationBody !== 'undefined' &&
                        preparation.preparationBody !== null) ? (
                        <Grid item xs={12} style={{minHeight: '50vh', marginBottom: '10vh', overflow: 'hidden'}}>
                            <Typography marginLeft='2%' marginTop='0' marginRight='2%' style={{overflowX: 'hidden'}} component={"div"}>
                                <p dangerouslySetInnerHTML={{__html: transformText(preparation?.preparationBody)}} />
                            </Typography>
                        </Grid>
                    ) : (
                        <Typography marginTop='2%'>{t('PARTICIPANTS.NODATA')}</Typography>
                    )}
            </Grid>

        </div>
    )
}

export default ActiveJourney