import apiRequest from '@helpers/apiRequest';

const getCycleCompaniesList = idCycle => {
    return apiRequest('GET', `CycleCompanies/List/${idCycle}`);
};

const serviceCycleCompanies = {
    getCycleCompaniesList
};

export default serviceCycleCompanies;