import { makeStyles } from '@mui/styles';

export const useIconStyles = makeStyles(({ palette }) => {
    const color = (variant, isHover) => {
        switch (variant) {
            case 'warning':
                return !isHover ? palette.error.main : palette.error.light;
            case 'success':
                return !isHover ? palette.success.main : palette.success.light;
            default:
                return !isHover ? palette.primary.main : palette.primary.light;
        }
    };
    return {
        container: props => {
            return {
                color: 'white',
                backgroundColor: color(props.variant),
                borderRadius: props.rounded ? '50%' : 3,
                padding: 7,
                ...props.style,

                '&:hover': {
                    backgroundColor: color(props.variant, true),
                },
            };
        },
        icon: props => {
            return {
                fontSize: props.fontSize || '1.2rem',
            };
        },
    };
});