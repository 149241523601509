import apiRequest from '@helpers/apiRequest'

const getPreparations = data => {
  return apiRequest('POST', 'Preparation/List', data)
}
const getPreparationDetails = idPreparation => {
  return apiRequest('GET', `Preparation/Details/${idPreparation}`)
}

const updatePreparation = data => {
  const idPreparation = data.idPreparation
  return apiRequest('PUT', `Preparation/Update/${idPreparation}`, data)
}

const newPreparation = data => {
  return apiRequest('POST', 'Preparation/New', data)
}

const deletePreparation = data => {
  const idPreparation = data.idPreparation
  return apiRequest('DELETE', `Preparation/Delete/${idPreparation}`)
}

const servicePreparations = {
  getPreparations,
  getPreparationDetails,
  updatePreparation,
  newPreparation,
  deletePreparation
}

export default servicePreparations
