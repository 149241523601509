import React, { Fragment } from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { Box, Chip, MenuItem, Select, Typography, Grid } from '@mui/material'
import { useCommonStyles } from '@styles/common.style'
import { useTranslation } from 'react-i18next'
import DeletePopover from '@components/DeletePopover'

const MobileSheetConfiguration = ({
  getNewJourneys,
  mobilesheetList,
  isUpdate,
  setFormData,
  formData,
  journeyIndex,
  data,
  setData
}) => {
  const classes = useCommonStyles()
  const { t } = useTranslation()
  const journey = data[journeyIndex]
  // console.log('msh', {
  //   getNewJourneys,
  //   mobilesheetList,
  //   isUpdate,
  //   setFormData,
  //   formData,
  //   journey,
  //   journeyIndex,
  //   data,
  //   setData
  // })
  const addMobileSheet = item => {
    const newMobileSheetValues = [
      ...item.cycleJourneyMobileSheets,
      {
        idMobileSheets: mobilesheetList[0].idMobileSheet, //select
        ...(isUpdate && {
          idCycleJourney: item.idCycleJourney
        })
      }
    ]

    const newJourneys = getNewJourneys(
      newMobileSheetValues,
      'cycleJourneyMobileSheets',
      journeyIndex
    )

    setData(newJourneys)
  }
  const removeMobileSheet = (journey, targetIndex) => {
    const newMobileSheetsValues = journey.cycleJourneyMobileSheets.filter(
      (_, index) => index !== targetIndex
    )
    const newJourneys = getNewJourneys(
      newMobileSheetsValues,
      'cycleJourneyMobileSheets',
      journeyIndex
    )

    setData(newJourneys)
  }
  const handleChangeMobileSheets = (e, journey, index) => {
    const newTargetValues = journey.cycleJourneyMobileSheets.map(
      (mobilesheet, i) => {
        if (i === index) {
          return {
            ...mobilesheet,
            [e.target.name]: e.target.value
          }
        }
        return mobilesheet
      }
    )

    const newJourneys = getNewJourneys(
      newTargetValues,
      'cycleJourneyMobileSheets',
      journeyIndex
    )

    setData(newJourneys)
  }
  return (
    <Fragment>
      <Typography
        textAlign='center'
        color='white'
        variant='subtitle1'
        sx={{
          bgcolor: 'grey.main',
          color: 'primary.main',
          borderRadius: 1,
          marginBottom: 2,
          fontSize: 14,
          fontWeight: 'bold',
          py: 1
        }}
      >
        {t('CONFIG_JOURNEYS.MOBILESHEETS.CONFIG_MOBILESHEETS').toUpperCase()}
      </Typography>

      {journey.cycleJourneyMobileSheets?.length !== 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Typography variant='caption' component='div'>
              {t('CONFIG_JOURNEYS.MOBILESHEETS.MOBILESHEET')}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Typography variant='caption' component='div'>
          {t('CONFIG_JOURNEYS.MOBILESHEETS.NO_ASSIGNED_MOBILESHEETS')}
        </Typography>
      )}

      {journey.cycleJourneyMobileSheets?.map((mobilesheet, i) => {
        return (
          <Grid container spacing={1} mb={1} key={i}>
            <Grid item xs={10}>
              <Select
                className={classes.select}
                fullWidth
                size='small'
                name='idMobileSheets'
                value={mobilesheet.idMobileSheets}
                onChange={e => handleChangeMobileSheets(e, journey, i)}
              >
                {mobilesheetList &&
                  mobilesheetList.map(mobilesheet => {
                    return (
                      <MenuItem
                        className={classes.menuItem}
                        value={mobilesheet.idMobileSheet}
                        key={mobilesheet.idMobileSheet}
                      >
                        {mobilesheet.mobileSheetName}
                      </MenuItem>
                    )
                  })}
              </Select>
            </Grid>

            <Grid item xs={2}>
              <Box
                style={{
                  height: '100%',
                  alignItems: 'center',
                  display: 'flex'
                }}
              >
                <DeletePopover
                  remove={() => {
                    removeMobileSheet(journey, i)
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        )
      })}
      <Box display='flex' justifyContent='start' mt={2}>
        <Chip
          size='small'
          onClick={() => {
            addMobileSheet(journey)
          }}
          label={t('CONFIG_JOURNEYS.MOBILESHEETS.ADD')}
          color='primary'
          sx={{ padding: 0 }}
          icon={<AddCircleIcon />}
        />
      </Box>
    </Fragment>
  )
}

export default MobileSheetConfiguration
