import apiRequest from '@helpers/apiRequest';

const getCycleInvitedGuestsByIdCycle = IdCycle => {
    return apiRequest('GET', `CycleInvitedGuests/List/${IdCycle}`);
};

const getCycleInvitedGuestsDetailsByIdGuest = IdGuest => {
    return apiRequest('GET', `CycleInvitedGuests/Details/${IdGuest}`);
};
//pte ver como actualizar
const updateCycleInvitedGuestsDetails = data => {
    const idInvited = data.idInvited;
    return apiRequest('PUT', `CycleInvitedGuests/Update/${idInvited}`, data);
};

const newInvitedGuest = data => {
    return apiRequest('POST', 'CycleInvitedGuests/New', data);
};

const deleteInvitedGuest = data => {
    const idParticipant = data.idParticipant;
    return apiRequest('DELETE', `CycleInvitedGuests/Delete/${idParticipant}`);
};

const serviceCycleInvitedGuests = {
    getCycleInvitedGuestsDetailsByIdGuest,
    updateCycleInvitedGuestsDetails,
    newInvitedGuest,
    deleteInvitedGuest,
    getCycleInvitedGuestsByIdCycle
};

export default serviceCycleInvitedGuests;