import React from 'react'
import AddButton from '@components/AddButton'
import DynamicTable from '@components/DynamicTable'
import HeaderTitle from '@components/HeaderTitle'
import { EditRounded } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import serviceFiles from '@services/serviceFiles'
import { useCommonStyles } from '@styles/common.style'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { snackActions } from '@helpers/snackbarUtils';

const TemplateFilesAttachs = () => {
  const classes = useCommonStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const ORDER = [
    {
      orderField: 'Name',
      orderAsc: true
    }
  ]
  const COLUMNS = [
    {
      accessor: '',
      Header: 'Info',
      filterable: false,
      hideHeader: true,
      width: '5%',
      Cell: ({ row: { values } }) => (
        <IconButton
          aria-label='info'
          color='info'
          style={{
            padding: '0px'
          }}
          onClick={() => {
            handleShowFilesAttachDetail(values.idFileAttach)
          }}
        >
          <EditRounded fontSize='inherit' />
        </IconButton>
      )
    },
    {
      accessor: 'idFileAttach',
      Header: t('LABEL.ID'),
      filterable: false,
      width: '5%'
    },
    {
      accessor: 'name',
      Header: t('LABEL.NAME')
    },
    {
      accessor: 'description',
      Header: t('LABEL.DESCRIPTION')
    },
    {
      accessor: 'filename',
      Header: t('LABEL.FILE')
    }
  ]

  const handleShowFilesAttachDetail = idFilesAttach => {
    const userSession = JSON.parse(sessionStorage.getItem('userSession'))
    if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 4)) {
        serviceFiles.getFilesAttachDetail(idFilesAttach).then(response => {
          if (!response.error) {
            navigate('/templateFilesAttachDetail', {
              state: { data: response.data, type: 'UPDATE' }
            })
          }
        })
      } else {
        snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'));
    }    
  }

  const handleNewFilesAttach = () => {
    const userSession = JSON.parse(sessionStorage.getItem('userSession'))
    if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 4)) {
        navigate('/templateFilesAttachDetail', {
          state: { data: {}, type: 'NEW' }
        })
      } else {
        snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'));
    }    
  }
  return (
    <div className={classes.container}>
      <HeaderTitle
        title={t('MENU_ADMIN.FILESATTACH')}
        subtitle={t('TEXT.SUBTITLE_FILEATTACH')}
      >
        <AddButton
          text={t('BUTTON.NEW_FILESATTACH')}
          handleOnclick={handleNewFilesAttach}
        />
      </HeaderTitle>
      <DynamicTable
        columns={COLUMNS}
        endpoint='File/List'
        isGlobalSearch={true}
        isColumnsSearch={true}
        isOrdered={true}
        orderColumn={ORDER}
      />
    </div>
  )
}

export default TemplateFilesAttachs
