import React, { useState, Fragment, useEffect } from 'react'
import { useForm } from '@hooks/useForm'
import FormActions from '@components/FormActions'
import HeaderTitle from '@components/HeaderTitle'
import Loading from '@components/Loading'
import LocaleTabs from '@components/LocaleTabs'
import serviceScheduler from '@services/serviceScheduler'
import { useCommonStyles } from '@styles/common.style'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { snackActions } from '@helpers/snackbarUtils'
import Card from '@components/Card'
import Dialog from '@components/Dialog'
import Tooltip from '@mui/material/Tooltip'
import MaterialIconPicker from '@components/MaterialIconPicker'
import CloseIcon from '@mui/icons-material/Close';

import {
  Grid,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  MenuItem,
  Select,
  Checkbox,
  Box,
  Button
} from '@mui/material'

const SchedulerCategoriesDetail = () => {

  const classes = useCommonStyles()
  const {t} = useTranslation()
  const navigate = useNavigate()
  const [openDialog, setOpenDialog] = useState(false)
  const [loadingDelayRequest, setLoadingDelayRequest] = useState(false)

  const { state: { data: SCHEDULERCATEGORIES_DATA, type: TYPE } } = useLocation()

  const HEADER_TITLE =
    TYPE === 'NEW' ? t('SCHEDULER.SCHEDULER_ADD')
      : t('SCHEDULER.UPDATE_CATEGORY')
  const HEADER_SUBTITLE =
    TYPE === 'NEW' ? t('WORKSHEET.NEW_SUBTITLE')
      : t('WORKER.UPDATE_SUBTITLE')

  const initialValues = {
    categoryPrivate: 0,
    allDay: 0,
    isRecurrent: 0,
    frequency: '',
    frequencyUnit: '',
    blockPre: '',
    blockPost: '',
    duration: '',
    fontColor: '#000000',
    color: '#ffffff',
    icon: '',
    iconPosiiton: 0
  }
  
  const values = TYPE === 'NEW' ? initialValues : { ...initialValues, ...SCHEDULERCATEGORIES_DATA }
  
  const { formData, setFormData, handleChange } = useForm(values)  

  const disabledSaveButton = SCHEDULERCATEGORIES_DATA === formData

  const staticidCategory = formData.idCategory
  
  const handleChangeNew = (e) => {
    const { type, name, value, checked } = e.target
    
    if (type === "checkbox") {
      let updates = { [name]: checked }
  
      // Ajusta duration solo si se trata del checkbox de allDay
      if (name === "allDay") {
        updates.duration = checked ? null : formData.duration
      }
  
      // Si isRecurrent se desmarca, establece frequencyUnit y frequency a null
      if (name === "isRecurrent" && !checked) {
        updates = { ...updates, frequencyUnit: null, frequency: null }
      }
  
      setFormData({
        ...formData,
        ...updates
      })
    } else {
      setFormData({
        ...formData,
        [name]: name === "iconPosition" ? parseInt(value, 10) : value,
      })
    }
  }  
  
  function getDefaultOption(fieldName, options) {
    const fieldOptions = options[fieldName]
    return fieldOptions && fieldOptions.length > 0 ? fieldOptions[0] : ''
  }
  
  const handleSubmit = (type) => {
    setLoadingDelayRequest(true)
    const charactersMinimum = formData.schedulerCategoriesLocale && formData.schedulerCategoriesLocale.every(locale => 
      locale.categoryName && locale.categoryName.length >= 3);    
  
    if (!charactersMinimum) {
      snackActions.error(t('SCHEDULER.MIN_CHARACTERS_REQUIRED', { min: 3 }));
      setLoadingDelayRequest(false)
      return
    }
  
    let dataToSend = {
      ...formData,
      idCategory: formData.idCategory,
			icon: formData.icon,
			duration: formData.duration || null, 
			blockPre: formData.blockPre || null, 
			blockPost: formData.blockPost || null, 
    }
    
    // Lógica de isRecurrent en un solo if
    if (formData.isRecurrent) {
      // Validar campos si isRecurrent está marcado
      if (!formData.frequency || !formData.frequencyUnit) {
        snackActions.error(t('SCHEDULER.REQUIRED'))
        setLoadingDelayRequest(false)
        return
      }
    } else {
      // Ajustar frequencyUnit y frequency a null si isRecurrent está desmarcado
      dataToSend.frequencyUnit = null
      dataToSend.frequency = null
    }
  
    // Manejo de NEW, UPDATE y DELETE
    switch (type) {
      case 'NEW':
        return serviceScheduler.newSchedulerCategory(dataToSend).then(response => handleResponse(response, 'SAVE'))
      case 'UPDATE':
        return serviceScheduler.updateSchedulerCategory(dataToSend).then(response => handleResponse(response, 'SAVE'))
      case 'DELETE':
        return serviceScheduler.deleteSchedulerCategory(formData).then(response => handleResponse(response, 'DELETE'))
      default:
        break
    }
  }
  
  const currentLocation = useLocation()
    useEffect(() => {
      if (currentLocation.state && currentLocation.state.data) {
        const updatedData = {
          ...currentLocation.state.data,
          iconPosition: currentLocation.state.data.iconPosition === true ? 1 : 0,
          fontColor: currentLocation.state.data.fontColor || '#000000',
          color: currentLocation.state.data.color || '#ffffff',
          Frequency: currentLocation.state.data.Frequency,
          frequencyUnit: currentLocation.state.data.frequencyUnit || null,
        }
    
        setFormData(updatedData)
      }
    }, [currentLocation.state])    

    const handleDeleteClick = () => {
      if (formData.categoryBlocked) {
        snackActions.error(t('SCHEDULER.BLOCKED_CATEGORY'))
      } else {
        setOpenDialog(true)
      }
    }
    
    const handleResponse = (response, actionType) => {
      setLoadingDelayRequest(false)
      if (!response.error) {
        if (actionType === 'DELETE') { // Al Eliminar vuelve a la página anterior
          navigate('/schedulerCategories')
        } else if (actionType === 'SAVE') { // Al Crear nuevo o Actualizar se queda en la página
          if (TYPE === 'NEW') {
            sessionStorage.setItem('categoryNew', JSON.stringify(response.data))
            serviceScheduler.getSchedulerCategoryDetails(response.data.idCategory).then(response => {
              if (!response.error) {
                setFormData(response.data) // Actualiza formData con los datos de la categoría incluyendo idCategory
                navigate('/schedulerCategoryDetail', {
                  state: { data: response.data, type: 'UPDATE' },
                })
              }
            })
          }
        }
      }
    }
    
    
    

  const handleLocaleChange = (data, idLanguage, name) => {
    let localeLanguage = formData?.schedulerCategoriesLocale || []
    let index = localeLanguage.findIndex(
        (locale) => locale.idLanguage === idLanguage
    )

    if (index < 0) {
        localeLanguage.push({
            idLanguage: idLanguage,
            idCategory: formData.idCategory,
        })
        index = localeLanguage.length - 1
    }
    
    localeLanguage[index][name] = data
    setFormData({ ...formData, schedulerCategoriesLocale: localeLanguage })
  }

  const options = {
    iconPosition: [0, 1],
    categoryPrivate: [0, 1],
    allDay: [0, 1],
    isRecurrent: [0, 1]
  }

  const frequencyUnitOptions = [
    { value: 'days', label: t('SCHEDULER.DAYS') },
    { value: 'weeks', label: t('SCHEDULER.WEEKS') },
    { value: 'months', label: t('SCHEDULER.MONTHS') },
    { value: 'years', label: t('SCHEDULER.YEARS') },
  ]  

  const SectionOne = (
    <Fragment>
      <Typography color='primary' variant='subtitle1' mb={2}>
        {t('SCHEDULER.STYLE')}
      </Typography>

      <LocaleTabs
        locale={formData?.schedulerCategoriesLocale}
        content={(localeLanguage, lang) => {

          return (

            <Grid container spacing={2} style={{ marginTop: '10px' }}>

              {/* Nombre de la categoría */}
              <Grid item style={{ marginTop: '5px' }}>
                <Typography variant="subtitle2">
                  {t('SCHEDULER.CATEGORY_NAME')}
                </Typography>

                <TextField
                  size='small'
                  name='categoryName'
                  value={localeLanguage?.categoryName || ''}
                  fullWidth
                  inputProps={{maxLength: 100}}
                  onChange={(e) =>
                    handleLocaleChange(
                      e.target.value.toUpperCase(),
                      lang.idLanguage,
                      'categoryName'
                    )
                  }
                  InputProps={{
                    style: {
                      color: formData.fontColor,
                      backgroundColor: formData.color,
                    },
                  }}
                />
              </Grid>

              {/* Selector de color de texto */}
              <Grid item xs={4} sm={2} style={{ marginTop: '5px', marginLeft:'20px' }}>
                <Typography variant="subtitle2">
                  {t('LABEL.TEXT')}
                </Typography>

                <input
                  type='color'
                  name='fontColor'
                  value={formData.fontColor}
                  onChange={handleChangeNew}
                  style={{ width: '60px', height: '40px' }}
                />
              </Grid>

              {/* Selector de color de fondo */}
              <Grid item xs={6} sm={2} style={{ marginTop: '5px' }}>
                <Typography variant="subtitle2">
                  {t('LABEL.BACKGROUND')}
                </Typography>

                <input
                  type='color'
                  name='color'
                  value={formData.color}
                  onChange={handleChangeNew}
                  style={{ width: '60px', height: '40px' }}
                />
              </Grid>
            </Grid>

        )
      }}
      />
		</Fragment>
	)
	
  const SectionTwo = (    
    <Fragment>
      <Typography color='primary' variant='subtitle1' mb={2}>
        {t('SCHEDULER.PROPERTIES')}
      </Typography>
      
      {/* Contenedor para categoryPrivate */}
      <Grid container spacing={2} alignItems='center'>
        <Grid item style={{ maxWidth: '120px', marginTop: '-5px' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!formData.categoryPrivate}
                onChange={handleChange}
                name='categoryPrivate'
              />
            }
            label={t('SCHEDULER.PRIVADA')}
            style={{ whiteSpace: 'nowrap' }}      
          />
        </Grid>
      </Grid>

      <Typography color='primary' variant='subtitle1' mb={2} style={{ marginTop: '15px' }}>
        {t('SCHEDULER.ICON_CARD')}
      </Typography>

      <Grid container spacing={2} style={{ marginTop: '-15px' }}>
        
        {/* Buscador de icono */}
        <Grid item xs={7} sm={5}>
          <Typography variant="subtitle2">
            {t('SCHEDULER.ICON')}
          </Typography>          
          <FormControl 
            fullWidth 
            size="small" 
            sx={{ 
              '.MuiInputBase-root': { 
                height: '40px', // Alto del input interno
              }
            }}
          >
            <MaterialIconPicker
                value={formData.icon || ''}
                onChange={(selectedIcon) => handleChange({ target: { name: 'icon', value: selectedIcon } })}
            />
          </FormControl>

        </Grid>

        {/* Posición del icono */}
        <Grid item xs={12} sm={6} style={{ marginLeft: '15px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ marginBottom: '-5px' }}>
              <Typography variant="subtitle1" style={{ marginLeft: '35px'}}>
                {t('SCHEDULER.ICONPOSITION')}
              </Typography>
            </Grid>
            
            <RadioGroup
              row
              name="iconPosition"
              value={formData.iconPosition ? formData.iconPosition.toString() : '0'} // Asegurarse de que siempre haya un valor por defecto
              onChange={handleChangeNew}
            >
              <FormControlLabel value="0" control={<Radio />} label={t('SCHEDULER.POSITION_BE')} />
              <FormControlLabel value="1" control={<Radio />} label={t('SCHEDULER.POSITION_AF')} />
            </RadioGroup>
          </Grid>
        </Grid>

      </Grid>
    </Fragment>

  )  

  const SectionThree = (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography color='primary' variant='subtitle1' mb={2}>
            {t('SCHEDULER.TEMP')}
          </Typography>
        </Grid>
  
        <Grid item xs={6} sm={4} md={3} lg={4} style={{ maxWidth: '150px' }}>
          <Grid container>
            
            {/* AllDay */}
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                {t('SCHEDULER.PERIOD')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!formData.allDay}
                    onChange={handleChangeNew}
                    name='allDay'
                  />
                }
                label={t('SCHEDULER.ALLDAY')}
              />
            </Grid>
          </Grid>
        </Grid>
        
        {/* Duration */}
        <Grid item xs={3} sm={2} style={{ maxWidth: '200px' }}>
          <Typography variant="subtitle2">{t('SCHEDULER.DURATION')}</Typography>  
          <TextField
            size='small'
            name='duration'
            value={formData.duration || getDefaultOption('duration', options)}
            onChange={handleChange}
            inputProps={{ min: 0, step: 1 }}
            placeholder='min.'
            type='number'
            style={{ width: '100px' }}
            disabled={formData.allDay === true}
          />

        </Grid>
				
      {/* Bloqueo previo o posterior */}
      <Grid item xs={12} sm={6}>
        <Grid container spacing={1} style = {{ marginLeft: '20px'}}>
          <Grid item xs={6} sm={6} style={{ width: '250px' }}>
            <Typography variant="subtitle2">{t('SCHEDULER.BLOCK_BE')}</Typography>
            <TextField
              size='small'
              name='blockPre'
              value={formData.blockPre}
              onChange={handleChange}
              inputProps={{ min: 0, step: 1 }}
              placeholder='min.'
              type='number'
              style={{ width: '100px' }}
            />
          </Grid>
  
          <Grid item xs={5} sm={5} style={{ width: '250px' }}>
            <Typography variant="subtitle2" style={{ whiteSpace: 'nowrap' }}>
              {t('SCHEDULER.BLOCK_AF')}
            </Typography>
            <TextField
              size='small'
              name='blockPost'
              value={formData.blockPost}
              onChange={handleChange}
              inputProps={{ min: 0, step: 1 }}
              placeholder='min.'
              type='number'
              style={{ width: '120px' }}
            />
          </Grid>
        </Grid>
			</Grid>
    </Grid>
   </Fragment>
  )  
  
  const SectionFour = (
    <Fragment>
      <Grid container spacing={1}>
      <Grid item xs={12}>
          <Typography color='primary' variant='subtitle1' mb={2}>
            {t('SCHEDULER.CATEGORY_RECURRENT')}
          </Typography>
        </Grid>
        
        <Grid style={{ marginTop: '20px'}}>            
          {/* Selector de isRecurrent */}            
              <FormControlLabel
              control={
                <Checkbox
                checked={!!formData.isRecurrent}
                  onChange={handleChange}
                  name='isRecurrent'
                />
              }
              label={t('SCHEDULER.RECURRENT_CATEGORY')}
            />
          </Grid>
    
          {/* Frequency y frequencyUnit */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" style={{ marginLeft: '30px', marginTop: '-15px'}}>
                {t('SCHEDULER.REPEAT_EACH')}
            </Typography>
            <Grid container spacing={1} alignItems="center">
              {/* Frequency */}
              <Grid item>
                <TextField
                  disabled={!formData.isRecurrent}
                  size='small'
                  name='frequency'
                  value={formData.frequency}
                  onChange={handleChange}
                  inputProps={{ maxLength: 3, min: 1 }}
                  placeholder='nº'
                  type='number'
                  style={{ width: '70px', marginRight: '5px' }}
                />
              </Grid>

              {/* frequencyUnit */}
              <Grid item>
                <FormControl size='small'>
                  <Select
                    disabled={!formData.isRecurrent}
                    name='frequencyUnit'
                    value={formData.frequencyUnit || ''}
                    onChange={handleChange}
                    style={{ width: '120px' }}
                  >
                    {frequencyUnitOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>


        </Grid>
    </Fragment>
  )  
  
  if (loadingDelayRequest) {
    return <Loading showMessageDelayRequest='true' />
  }

  return (

    <div className={classes.container}>

      <Dialog
        open={openDialog}
        hasCloseBtn={false}
        height={150}
        title={t('CONFIRM.TITLE_DESACTIVATED_CATEGORY')}
        close={() => setOpenDialog(false)}
        actions={
          <Box display='flex' alignItems='center'>
            <Box mr={1}>
              <Button
                variant='contained'
                onClick={() => setOpenDialog(false)}
                color='error'
              >
                {t('BUTTON.CANCEL')}
              </Button>
            </Box>
            <Button
              variant='contained'
              onClick={() => handleSubmit('DELETE')}
              disabled={formData.categoryBlocked === true}
            >
              {t('BUTTON.DELETE')}
            </Button>
          </Box>
        }
      />

      <HeaderTitle title={HEADER_TITLE} subtitle={HEADER_SUBTITLE}>
        <FormActions
          show={TYPE === 'UPDATE'}
          handleDelete={handleDeleteClick}
          handleUndo={() => {}}
          handleSave={() => handleSubmit(TYPE)}
          disabledButton={disabledSaveButton}
          urlBack='/schedulerCategories'
        />
      </HeaderTitle>

      <Grid container spacing={2}>
        <Grid item xs={12} lg={5}>
          <Card fullHeight>{SectionOne}</Card>
        </Grid>
        <Grid item xs={12} lg={5}>
          <Card fullHeight>{SectionTwo}</Card>
        </Grid>
        <Grid item xs={12} lg={5}>
          <Card fullHeight>{SectionThree}</Card>
        </Grid>
        <Grid item xs={12} lg={5}>
          <Card fullHeight>{SectionFour}</Card>
        </Grid>
      </Grid>

    </div>
  )
}

export default SchedulerCategoriesDetail