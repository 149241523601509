import DynamicTable from '@components/DynamicTable'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { useCommonStyles } from '@styles/common.style'
import React, { useEffect, useState } from 'react'

const HistoryCycle = (props) => {
    const { t } = useTranslation()
    const commonClasses = useCommonStyles()
    const [customFilter, setCustomFilter] = useState([])

    useEffect(() => {
       
            setCustomFilter([
              { searchField: 'IdCycle', searchMode: 'in', searchValue: props.cycle }
            ])
        
      }, [])

    const ORDER = [
    {
      orderField: 'historyDateTime',
      orderAsc: false
    }
  ]

  var COLUMNS = [

    {
      accessor: 'historyDateTime',
      Header: t('LABEL.DATE'),
      Cell: props => <Moment format='DD/MM/YY HH:mm'>{props.value}</Moment>,
      width: '8%'
    },
    {
        accessor: 'participantFullName',
        Header: t('MENU_ADMIN.PARTICIPANTS')
    },
    {
        accessor: 'historyTypeName',
        Header: t('LABEL.TYPE')
    },
    { 
        accessor: 'participantEmail', 
        Header: 'E-Mail' 
    },
    { 
        accessor: 'historyDesc', 
        Header: t('LABEL.DETAILS') 
    }
  ]

  
  return (
    <div className={commonClasses.container}>
      {customFilter.length > 0 && (
        <DynamicTable
          columns={COLUMNS}
          endpoint='History/List'
          customFilter={customFilter}
          isGlobalSearch={true}
          isColumnsSearch={true}
          isOrdered={true}
          viewExcel={true}
          nameExcel={
             t('MENU_ADMIN.HISTORY')
            
          }
          titleBodyExcel={
             t('HISTORY.BODY_EXCEL')

          }
          orderColumn={ORDER}
        />
      )}
    </div>
  )

}
export default HistoryCycle