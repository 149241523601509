import React, { Fragment, useState } from 'react';
import Dialog from '@components/Dialog';
import FormActions from '@components/FormActions';
import { useForm } from '@hooks/useForm';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import serviceValuations from '@services/serviceValuations';
import {snackActions} from '@helpers/snackbarUtils';

const ValuationDetail = ({ data, open, handleClose, handleReload }) => {
    const { t } = useTranslation();

    const TYPE = data ? 'UPDATE' : 'NEW';
    const RANKING_DATA = data;
    const HEADER_TITLE =
        TYPE === 'NEW' ? t('VALUATIONS.NEW_TITLE') : t('VALUATIONS.UPDATE_TITLE');
    const HEADER_SUBTITLE =
        TYPE === 'NEW'
            ? t('VALUATIONS.NEW_SUBTITLE')
            : t('VALUATIONS.UPDATE_SUBTITLE');

    const [openDialogDelete, setOpenDialogDelete] = useState(false);
    const { formData, setFormData, handleChange } = useForm(RANKING_DATA);

    const handleSubmit = type => {      
      if(!formData?.valuationCode) {        
        snackActions.error(t('VALUATIONS.VALUATION_REQUIRED')); // Mensaje de error en tu idioma preferido
        return;
      }
        switch (type) {
            case 'NEW':
                serviceValuations.newValuation(formData).then(handleResponse);
                break;
            case 'UPDATE':
                serviceValuations.updateValuation(formData).then(handleResponse);
                break;
            case 'DELETE':
                serviceValuations.deleteValuation(formData).then(handleResponse);
                break;
            default:
                break;
        }
    };

    const handleResponse = response => {
        if (!response.error) {
            handleReload();
            handleClose();
        }
    };
    const DeleteDialog = ({ open, close, content }) => {
        return (
            <Dialog
                width='20%'
                hideBackdrop
                open={open}
                close={close}
                title={t('CONFIRM.TITLE_VALUATION')}
                subtitle={t('CONFIRM.UNRECOVERY_DELETE2')}
                content={content}
                actions={
                    <Box display='flex' alignItems='center'>
                        <Box mr={1}>
                            <Button variant='contained' onClick={close} color='error'>
                                {t('BUTTON.CANCEL')}
                            </Button>
                        </Box>
                        <Button variant='contained' onClick={() => handleSubmit('DELETE')}>
                            {t('BUTTON.DELETE')}
                        </Button>
                    </Box>
                }
            />
        );
    };

    return (
        <Fragment>
            <DeleteDialog
                open={openDialogDelete}
                close={() => {
                    setOpenDialogDelete(false);
                }}
                content={
                    <Box display='flex' justifyContent='center'>
                        <Typography>{formData?.valuationCode}</Typography>
                    </Box>
                }
            />

            <Dialog
                width={'20%'}
                open={open}
                close={handleClose}
                title={HEADER_TITLE}
                subtitle={HEADER_SUBTITLE}
                content={
                    <TextField
                        label={t('LABEL.VALUATION')}
                        name='valuationCode'
                        value={formData?.valuationCode || ''}
                        size='small'
                        onChange={handleChange}
                        fullWidth
                        margin='normal'
                        disabled={false}
                        inputProps={{ maxLength: 10 }}
                    />
                }
                actions={
                    <FormActions
                        show={data}
                        handleUndo={() => setFormData(RANKING_DATA)}
                        handleSave={() => handleSubmit(TYPE)}
                        handleDelete={() => setOpenDialogDelete(true)}
                    />
                }
            />
        </Fragment>
    );
};

export default ValuationDetail;