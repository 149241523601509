import {
  Search,
  Business,
  People,
  BusinessCenter,
  Event,
  Description,
  ManageAccounts,
  Grading,
  EventNote,
  MailRounded,
  CampaignRounded,
  RestoreRounded,
  PhoneAndroidRounded,
  AttachEmailRounded,
  NoteAlt,
  AssignmentIndRounded,
  AppSettingsAlt,
  Warning,
  UploadFileRounded,
  HistoryEdu,
  ThumbsUpDown,
  Category
} from '@mui/icons-material'
const menuCampusItems = t => [
  {
    menuTitle: t('MENU_ADMIN.DATA_MANAGEMENT'),

    items: [
      {
        title: t('MENU_ADMIN.CYCLES'),
        url: '/cycles',
        icon: <Event />,
        status: 2
      },
      {
        title: t('MENU_ADMIN.PARTICIPANTS'),
        url: '/participants',
        icon: <People />,
        status: 2
      },
      {
        title: t('MENU_ADMIN.COMPANIES'),
        url: '/companies',
        icon: <Business />,
        status: 2
      },
      {
        title: t('MENU_ADMIN.JOURNEYSSER'),
        url: '/journeysser',
        icon: <EventNote />,
        status: 2
      },
      {
        title: t('MENU_ADMIN.EVALUATOR'),
        url: '/evaluator',
        icon: <ThumbsUpDown />,
        status: 2
      }
    ]
  },
  {
    menuTitle: t('MENU_ADMIN.RECORDS'),
    items: [
      {
        title: t('MENU_ADMIN.INCIDENTS'),
        url: '/incidents',
        icon: <Warning />,
        status: 2
      },
      {
        title: t('MENU_ADMIN.MAILING'),
        url: '/history/mailing',
        icon: <MailRounded />,
        status: 2
      },

      {
        title: t('MENU_ADMIN.CALLS'),
        url: '/history/call',
        icon: <CampaignRounded />,
        status: 2
      },
      {
        title: t('MENU_ADMIN.HISTORY'),
        url: '/history/actions',
        icon: <RestoreRounded />,
        status: 2
      }
    ]
  },

  {
    menuTitle: t('MENU_ADMIN.CYCLE_CONFIG'),
    items: [
      {
        title: t('MENU_ADMIN.CYCLE_TYPES'),
        url: '/cycleTypes',
        icon: <Description />,
        status: 2
      },
      {
        title: t('MENU_ADMIN.RATINGS'),
        url: '/valuations',
        icon: <Grading />,
        status: 2
      },
      {
        title: t('MENU_ADMIN.TEMPLATE_EMAIL'),
        url: '/templateMailing',
        icon: <AttachEmailRounded />,
        status: 2
      },

      {
        title: t('MENU_ADMIN.TEMPLATE_TARGET'),
        url: '/templateTargets',
        icon: <AssignmentIndRounded />,
        status: 2
      },
      {
        title: t('CONFIG_JOURNEYS.PREPARATION.CONFIG'),
        url: '/preparations',
        icon: <HistoryEdu />,
        status: 2
      },
      {
        title: t('MENU_ADMIN.TEMPLATE_WORKSHEET'),
        url: '/templateWorksheets',
        icon: <NoteAlt />,
        status: 2
      },
      {
        title: t('MENU_ADMIN.FILESATTACH'),
        url: '/templateFilesAttach',
        icon: <UploadFileRounded />,
        status: 2
      },

      {
        title: 'KitOW / Toolbox',
        url: '/owToolbox',
        icon: <AppSettingsAlt />,
        status: 2
      }
    ]
  },

  {
    menuTitle: t('MENU_ADMIN.ADMINISTRATOR'),
    items: [
      {
        title: t('MENU_ADMIN.MANAGERS'),
        url: '/workers',
        icon: <ManageAccounts />,
        status: 2
      },
      {
        title: t('MENU_ADMIN.CONSULTANTS'),
        url: '/consultants',
        icon: <BusinessCenter />,
        status: 2
      },
      {
        title: t('MENU_ADMIN.SCHEDULER'),
        url: '/schedulerCategories',
        icon: <Category />,
        status: 2
      }
    ]
  }
]

export default menuCampusItems
