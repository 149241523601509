export const transformText = text => {
  if (!text) return
  text = `<p>${text}</p>`

  let domParser = new DOMParser().parseFromString(text, 'text/html')

  let childs = domParser.body.firstElementChild.childNodes

  let checkNextChild, originalChild

  for (let q = 0; q < childs.length; q++) {
    //recorre los elementos del texto convertido a elemento html
    originalChild = childs[q]

    checkNextChild = originalChild.firstChild
    text = parseColorSizeSave(originalChild, checkNextChild, text)
    if (
      originalChild.children !== undefined &&
      originalChild.children !== null
    ) {
      Array.from(originalChild.children).forEach(htmlChild => {
        do {
          originalChild = htmlChild
          checkNextChild = originalChild.firstChild
          text = parseColorSizeSave(originalChild, checkNextChild, text)
          Array.from(htmlChild.children).forEach(nextHtmlChild => {
            originalChild = nextHtmlChild
            checkNextChild = originalChild.firstChild
            text = parseColorSizeSave(originalChild, checkNextChild, text)
          })
          htmlChild.firstElementChild === null
            ? (htmlChild = null)
            : (htmlChild = htmlChild.firstElementChild)
        } while (htmlChild !== null)
      })
    }
  }

  return text
}

// Parsea las etiquetas <size> y <color> a un <span> para ser interpretado por la App
export function parseColorSizeSave (originalChild, checkNextChild, _string) {
  const NODE_COLOR = 'COLOR'
  const NODE_SIZE = 'SIZE'

  if (originalChild.nodeName === NODE_COLOR) {
    if (checkNextChild !== undefined && checkNextChild.nodeName === NODE_SIZE) {
      //COLOR y SIZE
      //comprueba que la proxima etiqueta sea un size para unificarlas en 1
      _string = _string
        .replace('<size ' + checkNextChild.attributes[0].name + '>', '')
        .replace('</size>', '')
      _string = _string
        .replace(
          '<color ' + originalChild.attributes[0].name + '>',
          "<span style='color:" +
            originalChild.attributes[0].name +
            ';font-size:' +
            checkNextChild.attributes[0].name +
            "px'>"
        )
        .replace('</color>', '</span>')
    } else {
      //COLOR
      _string = _string
        .replace(
          '<color ' + originalChild.attributes[0].name + '>',
          "<span style='color:" + originalChild.attributes[0].name + "'>"
        )
        .replace('</color>', '</span>')
    }
  } else if (originalChild.nodeName === NODE_SIZE) {
    if (
      checkNextChild !== undefined &&
      checkNextChild.nodeName === NODE_COLOR
    ) {
      //SIZE y COLOR
      //comprueba que la proxima etiqueta sea un color para unificarlas en 1
      _string = _string
        .replace('<color ' + checkNextChild.attributes[0].name + '>', '')
        .replace('</color>', '')
      _string = _string
        .replace(
          '<size ' + originalChild.attributes[0].name + '>',
          "<span style='font-size:" +
            originalChild.attributes[0].name +
            'px;color:' +
            checkNextChild.attributes[0].name +
            "'>"
        )
        .replace('</size>', '</span>')
    } else {
      //SIZE
      _string = _string
        .replace(
          '<size ' + originalChild.attributes[0].name + '>',
          "<span style='font-size:" + originalChild.attributes[0].name + "px'>"
        )
        .replace('</size>', '</span>')
    }
  }
  return _string
}

export function cycleTypeToCycle (data) {
  try {
    for (let prop in data) {
      if (prop.startsWith('cycleType')) {
        data[prop.replace('cycleTypeJourney', 'cycleJourneys')] = data[prop]
        delete data[prop]
      }
    }
    if (data.cycleJourneysWorkSheets) {
      data.cycleJourneysWorkSheets = data.cycleJourneysWorkSheets.map(x => ({
        ...x,
        idWorkSheet: x.idWorkSheets
      }))
    }
    return data
  } catch (ex) {
    console.log(ex)
  }
}
export function cycleToCycleType (data) {
  try {
    for (let prop in data) {
      if (prop.startsWith('cycleJourneys') && !prop.endsWith('Participants')) {
        data[prop.replace('cycleJourneys', 'cycleTypeJourney')] = data[prop]
        delete data[prop]
      }
    }
    if (data.cycleTypeJourneyWorkSheets) {
      data.cycleTypeJourneyWorkSheets = data.cycleTypeJourneyWorkSheets.map(
        x => ({
          ...x,
          idWorkSheets: x.idWorkSheet
        })
      )
    }
    return data
  } catch (ex) {
    console.log(ex)
  }
}
