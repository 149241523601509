import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => {
    return {
        buttonIcon: {
            minWidth: '45px',
            padding: '6px',
            [theme.breakpoints.down('sm')]: {
                minWidth: '25px',
            },
        },

        undo: {
            background: theme.palette.primary.light,
        },
    };
});