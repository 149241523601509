import { makeStyles } from '@mui/styles';
import Image from '@assets/images/evaluator.jpg';
import ArrowW from '@assets/images/surveys/arrow_w.gif'
import PlusW from '@assets/images/surveys/plus_w.gif'
import MinusW from '@assets/images/surveys/minus1_w.gif'
import ArrowB from '@assets/images/surveys/arrow_b.gif'
import PlusB from '@assets/images/surveys/plus_b.gif'
import MinusB from '@assets/images/surveys/minus1_b.gif'

export const useStyles = makeStyles(theme => {
    return {
        loginEvaluator: {
            backgroundImage: `linear-gradient(90deg, rgba(4,139,197,0.6) 10%, rgba(117,206,224,0.9) 100%),url(${Image})`,
            backgroundSize: 'cover',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            // [theme.breakpoints.down('sm')]: {
            //     backgroundImage: 'none',
            // },
        },
        siginEvaluator: {
            backgroundColor: 'white',
            opacity: '80%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            height: '100vh',
            width: '400px',
            [theme.breakpoints.down('sm')]: {
                width: '90%',
            },
        },
        version: {
          margin: '25px 0',
          color: theme.palette.primary.main,
          fontWeight: 'bold',
          fontSize: '1.5rem',
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            height: "100px",
            background: `linear-gradient(90deg, ${theme.palette.primary.main}, 60%, ${theme.palette.primary.light} 70%)`,
        },
        card: {
            padding: '20px 20px 40px 20px',
            [theme.breakpoints.down('sm')]: {
                padding: '15px 15px 30px 15px'
            }
        },
        card2: {
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            // height: '5em',
            width: '50%',
            padding: '20px',
            border: '1px solid lightgrey',
            borderRadius: '5px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            }
        },
        card3: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '70%',
            padding: '20px',
            border: '1px solid lightgrey',
            borderRadius: '5px',
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            }
        },
        card4: {
            padding: '20px',
            height: '110px',
            [theme.breakpoints.down('sm')]: {
                padding: '15px'
            }
        },
        cardTitle: {
            color: theme.palette.primary.main,
            borderRadius: '4px',
            fontSize: '1.2em',
            fontWeight: 'bold',
        },
        title: {
            margin: '20px 0 10px',
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            fontSize:'1.2em',
            textAlign:'center'
        },
        score: {
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            fontSize:'1.5em',
            textAlign:'center'
        },
        titleScore: {
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            fontSize:'1em',
            textAlign:'justify'
        },
        title2: {
            margin: '20px 0 10px',
            color: theme.palette.secondary.main,
            fontWeight: 'bold',
            fontSize:'1.2em',
            textAlign:'center'
        },
        subtitle: {
            margin: '5px 5px',
            color: theme.palette.primary.main,
        },
        btn: {
            textTransform: 'none',
        },
        image: {
            width: '90%',
        },
        imageScore: {
            width: '1em',
            height: '1em',
            maxWidth: '1em',
            color: '#0085B4 !important'
        },
        box1: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '2em',
            width: 'fit-content',
            padding: '0px 10px',
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            borderRadius: '3px'
        },
        box2: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '2em',
            width: 'fit-content',
            padding: '0px 10px',
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
            borderRadius: '3px'
        },
        icon: {
            width: '100%',
            height: '2em',
            color: theme.palette.primary.main,
            padding: '16px 0px 0px 16px',
            [theme.breakpoints.down('md')]: {
                padding: '0px 0px 0px 0px',
                margin: '10px 0px 0px 10px',
            },
        },
        bold: {
            fontWeight: '700'
        },
        justified: {
            textAlign: 'justify',
            fontSize: '1.1em'
        },
        centeredGrid: {
            display: 'flex',
            justifyContent: 'center', 
            alignItems: 'start',
            [theme.breakpoints.up('md')]: {
              justifyContent: 'flex-end', 
            },
        },
        blueOtto: {
            color: theme.palette.primary.main,
        },
        orangeOtto: {
            color: theme.palette.secondary.main,
        },
        bgOrangeOtto: {
            backgroundColor: theme.palette.secondary.main
        },
        bgBlueOtto: {
            backgroundColor: theme.palette.primary.main
        },
        paddingReduced: {
            paddingLeft: '8px !important'
        },
        legend: {
            position: 'sticky',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'start',
            padding: '8px 8px 8px 33% !important',
            margin: '1em -5% 1em 0px !important',
            width: '100vw !important',
            top: '0px',
            borderTop: '1px solid rgba(0, 0, 0, 0.4)',
            borderBottom: '1px solid rgba(0, 0, 0, 0.4)',
            borderRadius: '0px',
            backgroundColor: 'yellow',
            // backgroundColor: 'rgba(196, 196, 196, 0.9)',
            boxShadow: 'none',
            opacity: '100%',
            zIndex: '999',
            [theme.breakpoints.up('md')]: {
                position: 'fixed',
                display: 'initial',
                padding: '8px !important',
                width: '220px',
                maxWidth: '20%',
                top: '105px',
                left: 'auto',
                right: '0px', 
                bottom: 'auto',
                borderTop: '0px solid black',
                borderBottom: '0px',
                borderRadius: '20px 0px 0px 20px',
                boxShadow: '2px 2px 10px 2px rgba(0, 0, 0, 0.2)'
            }
        },
        responseBox: {
            height: '2.3em',
            width: '2.3em',
            minWidth: '2.3em',
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: '10%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '2px',
            cursor: 'pointer'
        },
        responseBlue: {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            fontWeight: 'bold'
        },
        plusW: {
            backgroundImage: `url(${PlusW})`,
            backgroundSize: '60%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundColor: '#0085B4'
        },
        arrowW: {
            backgroundImage: `url(${ArrowW})`,
            backgroundSize: '80%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundColor: '#0085B4',
            transform: 'rotate(90deg)'
        },
        minusW: {
            backgroundImage: `url(${MinusW})`,
            backgroundSize: '60%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundColor: '#0085B4'
        },
        plusB: {
            backgroundImage: `url(${PlusB})`,
            backgroundSize: '60%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            fill: '#0085B4'
        },
        arrowB: {
            backgroundImage: `url(${ArrowB})`,
            backgroundSize: '80%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            transform: 'rotate(90deg)'
        },
        minusB: {
            backgroundImage: `url(${MinusB})`,
            backgroundSize: '60%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
        }
        
    };
});