import React from 'react';
import { IconButton } from '@mui/material';

import { useIconStyles } from '@styles/icon.style';

const Icon = ({ children, ...props }) => {
    const classes = useIconStyles(props);
    const { onClick } = props;
    return (
        <IconButton className={classes.container} disableRipple onClick={onClick}>
            {React.cloneElement(children, { className: classes.icon })}
        </IconButton>
    );
};

export default Icon;