import React, { useContext, useState, useEffect } from "react";
import Avatar from "@components/Avatar";
import CheckBox from "@components/CheckBox";
import Dialog from "@components/Dialog";
import FormActions from "@components/FormActions";
import HeaderTitle from "@components/HeaderTitle";
import Editor from "@components/CkEditor";
import { ListContext } from "@context/ListContext";
import { useForm } from "@hooks/useForm";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import serviceConsultants from "@services/serviceConsultants";
import serviceLogin from "@services/serviceLogin";
import { useCommonStyles } from "@styles/common.style";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LocaleTabs from "@components/LocaleTabs";
import Card from "@components/Card";
import ConsultantCycles from "./ConsultantCycles";
import Loading from "@components/Loading";
import { snackActions } from "@helpers/snackbarUtils";
import { useHandleImages } from "@hooks/useHandleImages";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';



const ConsultantDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useCommonStyles();
  const { countriesList } = useContext(ListContext);
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogRequestPassword, setOpenDialogRequestPassword] = useState(false);

  const {
    state: { data: CONSULTANT_DATA, type: TYPE },
  } = useLocation();

  
  const handleChangeDate = (fieldName, e) => {
    let fecha = e.target ? e.target.value : e.format('YYYY-MM-DDT00:00')

    setFormData({...formData, [fieldName]: fecha})

  }; 
  

  const HEADER_TITLE =
    TYPE === "NEW" ? t("CONSULTANT.NEW_TITLE") : t("CONSULTANT.UPDATE_TITLE");
  const HEADER_SUBTITLE =
    TYPE === "NEW"
      ? t("CONSULTANT.NEW_SUBTITLE")
      : t("CONSULTANT.UPDATE_SUBTITLE");

  const { formData, setFormData, handleChange } = useForm(CONSULTANT_DATA);
  const { manageImages, infoData } = useHandleImages();
  const staticIdConsultant = formData.idConsultant;

  const disabledSaveButton = CONSULTANT_DATA === formData;

  useEffect(() => {
    if (infoData?.consultantImageProfile !== undefined && infoData?.consultantImageProfile !== null && infoData?.consultantImageProfile.length !== 0) {
      setFormData((prevState) => {
        return { ...prevState, 
          consultantImageProfile: infoData.consultantImageProfile, 
        };
      });
    }
    if (infoData?.consultantImageMailBox !== undefined && infoData?.consultantImageMailBox !== null && infoData?.consultantImageMailBox.length !== 0) {
      setFormData((prevState) => {
        return { ...prevState, 
          consultantImageMailBox: infoData.consultantImageMailBox, 
        };
      });
    }
    if (infoData?.consultantImageSign !== undefined && infoData?.consultantImageSign !== null && infoData?.consultantImageSign.length !== 0) {
      setFormData((prevState) => {
        return { ...prevState, 
          consultantImageSign: infoData.consultantImageSign, 
        };
      });
    }
  }, [infoData])
  

  const handleSubmit = type => {
    setLoading(true)
    switch (type) {
      case "NEW":
        serviceConsultants.newConsultant(formData).then(response => handleResponse(response, 'SAVE'))
        break
      case "UPDATE":
        serviceConsultants.updateConsultant(formData).then(response => handleResponse(response, 'SAVE'))
        break
      case "DELETE":
        formData.consultantActive = false
        serviceConsultants.deleteConsultant(formData).then(response => handleResponse(response, 'DELETE'))
        break
      default:
        break
    }
  }
  

  const handleResponse = (response, actionType) => {
    setLoading(false)
    if (!response.error) {
      if (actionType === 'DELETE') { // Al Eliminar vuelve a la página anterior
        navigate('/consultants')
      } else if (actionType === 'SAVE') { // Al Crear nuevo o Actualizar se queda en la página
        if (TYPE === 'NEW') {
          sessionStorage.setItem('consultantNew', JSON.stringify(response.data))
          serviceConsultants.getConsultantsDetails(response.data.idConsultant).then(response => {
            if (!response.error) {
              setFormData(response.data) // Actualiza formData con los datos del consultor incluyendo idConsultant
              navigate('/consultantDetail', {
                state: { data: response.data, type: 'UPDATE' },
              })
            }
          })
        }
      }
    } else {
      console.error(response.message)
    }
  }
  

  const handleLocaleChange = (data, idLanguage, name) => {
    let localeLanguage = formData?.locale || [];
    let index = localeLanguage.findIndex(
      (locale) => locale.idLanguage === idLanguage
    );
    if (index < 0) {
      localeLanguage.push({
        idLanguage: idLanguage,
        idConsultant: formData.idConsultant,
      });
      index = localeLanguage.length - 1;
    }
    localeLanguage[index][name] = data;
    setFormData({ ...formData, locale: localeLanguage });
  };

  useEffect(() => {
    const userSession = JSON.parse(sessionStorage.getItem("userSession"));
    userSession.userIdsRoles.map((idRol, i) => {
      if (idRol === 1) {
        setIsAdmin(true);
      }
    });
  }, []);

  const simulatedLogin = (e) => {
    const userSession = JSON.parse(sessionStorage.getItem('userSession'))
    if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 3)) {
        let url =
          process.env.REACT_APP_PUBLIC_URL_CONSULTOR_SIMULATED +
          "?loginToken=" +
          sessionStorage.getItem("loginToken") +
          "&email=" +
          formData.consultantEmail;
        window.open(url, "_blank");
      } else {
        snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'));
    } 
  };

  const handleChangeCountries = (e) => {
    const idCountrySelected = Number(e.target.value);
    const isChecked = e.target.checked;
    const consultantCountries = formData?.countries || [];

    if (isChecked) {
      const newCountry = {
        idConsultantLocale: idCountrySelected,
        idConsultant: formData.idConsultant,
        idCountry: idCountrySelected,
      };
      const newValues = [...consultantCountries, newCountry];
      setFormData({ ...formData, countries: newValues });
    } else {
      const newValues = consultantCountries.filter(
        (country) => country.idCountry !== idCountrySelected
      );

      setFormData({ ...formData, countries: newValues });
    }
  };

  const handleSubmitRequestPassword = (email) => {
    setLoading(true);
    const data = {
      userEmail: email,
      requestChangePassword: true,
      area: "consultant",
    };
    serviceLogin.lostPassword(data).then((response) => {
      setLoading(false);
      if (!response.error) {
        snackActions.success(t("TEXT.OK_SEND_CHANGE_PASSWORD"));
      } else {
        snackActions.error(response.message);
      }
      setOpenDialogRequestPassword(false);
    });
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div className={classes.container}>
      <Dialog
        open={openDialog}
        hasCloseBtn={false}
        height={200}
        title={t("CONFIRM.TITLE_DESACTIVATED_CONSULTANT")}
        subtitle={t("CONFIRM.SUBTITLE_CONSULTANT_DELETE")}
        close={() => setOpenDialog(false)}
        actions={
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <Button
                variant="contained"
                onClick={() => setOpenDialog(false)}
                color="error"
              >
                {t("BUTTON.CANCEL")}
              </Button>
            </Box>
            <Button variant="contained" onClick={() => handleSubmit("DELETE")}>
              {t("BUTTON.DELETE")}
            </Button>
          </Box>
        }
      />
      <Dialog
        open={openDialogRequestPassword}
        close={() => setOpenDialogRequestPassword(false)}
        title={t("CONFIRM.TITLE_SEND_REQUEST_CHANGE_PASSWORD")}
        subtitle={t("CONFIRM.SUBTITLE_SEND_REQUEST_CHANGE_PASSWORD")}
        width={300}
        actions={
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <Button
                variant="contained"
                onClick={() => setOpenDialogRequestPassword(false)}
                color="error"
              >
                {t("BUTTON.CANCEL")}
              </Button>
            </Box>
            <Button
              variant="contained"
              onClick={() =>
                handleSubmitRequestPassword(formData.consultantEmail)
              }
            >
              {t("BUTTON.OK")}
            </Button>
          </Box>
        }
      />

      <HeaderTitle title={HEADER_TITLE} subtitle={HEADER_SUBTITLE}>
        {isAdmin && TYPE !== "NEW" && (
          <Box mr={1}>
            <Button
              variant="contained"
              style={{ transform: "translateX(-5%)" }}
              onClick={simulatedLogin}
            >
              {t("BUTTON.SIMULATED_LOGIN")}
            </Button>
          </Box>
        )}
        {TYPE !== "NEW" && (
          <Button
            variant="contained"
            style={{ transform: "translateX(-5%)" }}
            onClick={() => setOpenDialogRequestPassword(true)}
            color="error"
          >
            {t("BUTTON.RESET_PASSWORD")}
          </Button>
        )}
        <FormActions
          show={TYPE === "UPDATE"}
          handleDelete={() => setOpenDialog(true)}
          handleUndo={() => {}}
          handleSave={() => handleSubmit(TYPE)}
          disabledButton={disabledSaveButton}
          urlBack="/consultants"
        />
      </HeaderTitle>

      <Grid container spacing={2}>
        <Grid item lg={2} xs={12}>
          <Card>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={12}>
                <Box>
                  <Typography color="primary" variant="caption">
                    {t("FORM.PROFILE_PICTURE")}
                  </Typography>
                  <Avatar
                    height={120}
                    handleChange={e => manageImages(e, 'consultantImageProfile')}
                    image={formData?.consultantImageProfile}
                    idbutton={"upload-button-profile"}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} lg={12}>
                <Box>
                  <Typography color="primary" variant="caption">
                    {t("FORM.MAILBOX_IMAGE")}
                  </Typography>
                  <Avatar
                    height={120}
                    handleChange={ e => manageImages(e, 'consultantImageMailBox')}
                    image={formData?.consultantImageMailBox}
                    idbutton={"upload-button-MailBox"}
                  />
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item lg={4} xs={12}>
          <Card>
            <Typography color="primary" variant="subtitle1">
              {t("FORM.PERSONAL_DATA")}
            </Typography>
            <Grid container>
              <Grid item xs={8}>
                <TextField
                  required
                  error={formData.consultantCode === ""}
                  label={t("LABEL.CODE")}
                  name="consultantCode"
                  value={formData.consultantCode || ""}
                  size="small"
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                  inputProps={{ maxLength: 3 }}
                />
              </Grid>
              <Grid
                item
                xs={4}
                display="flex"
                justifyContent="end"
                alignItems="center"
              >
                <Box mt={1}>
                  <CheckBox
                    label={t("LABEL.ACTIVE")}
                    value={formData.consultantActive}
                    checked={
                      TYPE === "NEW"
                        ? (formData.consultantActive = true)
                        : formData.consultantActive
                    }
                    handleChange={(e) => {
                      setFormData({
                        ...formData,
                        consultantActive: e.target.checked,
                      });
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <TextField
              required
              error={formData.consultantName === ""}
              label={t("LABEL.NAME")}
              name="consultantName"
              value={formData.consultantName || ""}
              size="small"
              onChange={handleChange}
              margin="normal"
              fullWidth
              inputProps={{ maxLength: 100 }}
            />
            <TextField
              required
              error={formData.consultantSurname === ""}
              label={t("LABEL.SURNAME")}
              name="consultantSurname"
              value={formData.consultantSurname || ""}
              size="small"
              onChange={handleChange}
              margin="normal"
              fullWidth
              inputProps={{ maxLength: 100 }}
            />
            <TextField
              required
              error={formData.consultantEmail === ""}
              label={t("LABEL.EMAIL")}
              name="consultantEmail"
              value={formData.consultantEmail || ""}
              size="small"
              onChange={handleChange}
              margin="normal"
              fullWidth
              inputProps={{ maxLength: 100 }}
            />
            
            
            <Grid item xs={12} sm={5}>
								<Typography variant='caption' component='div'>
                  {t('LABEL.BIRTHDATE')} *
                </Typography>
								<DatePicker
									name='consultantBirthdate'
									slotProps={{
										textField: {
											size: 'small'
										}
									}}
									format='DD/MM/YYYY' // formato de fecha y hora válido para el locale en español
                  value={
                    formData?.consultantBirthdate
                      ? moment(formData?.consultantBirthdate)
                      : null // Usa null en lugar de cadena vacía '' para el valor de fecha y hora
                  }
									onChange={(e) => handleChangeDate('consultantBirthdate', e)}
								/>
              </Grid>



            <Box>
              <Typography color="primary" variant="subtitle1" my={2}>
                {t("FORM.ACCESS_CYCLES")}
              </Typography>
              <Box display="flex" flexWrap="wrap">
                {countriesList.map((itemList, i) => {
                  const existCountry = formData?.countries?.find(
                    (country) => country.idCountry === itemList.idCountry
                  );
                  const checked = existCountry ? true : false;
                  return (
                    <Box mr={1} mb={1} key={i}>
                      <CheckBox
                        key={itemList.idCountry}
                        label={
                          t(
                            `COUNTRIES.${itemList.countryCode.toUpperCase()}`
                          ) || itemList.countryName
                        }
                        value={itemList.idCountry}
                        checked={checked}
                        handleChange={handleChangeCountries}
                      />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Card>
            <Typography color="primary" variant="subtitle1">
              {t("FORM.PROFILE_DATA")}
            </Typography>

            <LocaleTabs
              locale={formData.locale}
              content={(localeLanguage, lang) => (
                <Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    my={2}
                  >
                    <Typography color="primary" variant="caption">
                      {t("FORM.MESSAGE_SIGN")}
                    </Typography>
                    <Avatar
                      height={100}
                      handleChange={e => manageImages( e, 'consultantImageSign' )}
                      image={formData?.consultantImageSign}
                      idbutton={"upload-button-sign"}
                    />
                  </Box>
                  <TextField
                    name="consultantJobTitle"
                    label={t("LABEL.POSITION")}
                    fullWidth
                    value={localeLanguage?.consultantJobTitle || ""}
                    onChange={(e) =>
                      handleLocaleChange(
                        e.target.value,
                        lang.idLanguage,
                        "consultantJobTitle"
                      )
                    }
                  />
                  <Box mb={2} />
                  <Editor
                    data={localeLanguage?.consultantProfile || ""}
                    onChange={(newData) => {
                      handleLocaleChange(
                        newData,
                        lang.idLanguage,
                        "consultantProfile"
                      );
                    }}
                  />
                </Box>
              )}
            />
          </Card>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Card fullHeight>
            <Typography color="primary" variant="subtitle1" my={1}>
              {t("TEXT.CONSULTANT_CYCLES")}
            </Typography>
            <ConsultantCycles idConsultant={staticIdConsultant} />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default ConsultantDetail;
