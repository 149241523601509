import React, { useState, useEffect } from 'react'
import serviceEvaluatorInfo from '@services/serviceEvaluatorInfo'
import Dialog from "@components/Dialog";
import { useNavigate } from 'react-router-dom'
import { Grid, Card, Box, Button, Tooltip, Typography, CircularProgress } from '@mui/material'
import HeaderTitle from '@components/HeaderTitle';
import { useCommonStyles } from '@styles/common.style'
import { useStyles } from '@styles/evaluator.style'
import { useTranslation } from 'react-i18next'
import serviceFiles from '@services/serviceFiles'
import { snackActions } from '@helpers/snackbarUtils';

const Panel = () => {
  const classes = useCommonStyles()
  const classes2 = useStyles()
  const { t } = useTranslation()  
  const navigate = useNavigate()
  const [openLoading, setOpenLoading] = useState(false);
  const receiver = JSON.parse(sessionStorage.getItem('receiver'))
  const participant = receiver.participant
  const conditionDisabled = receiver?.numReceivers != 0 && (receiver?.totalReceiverResponses > 3 || receiver?.numReceivers < 4 && receiver?.totalReceiverResponses == receiver?.numReceivers)
  
  const goBack = () => {
    navigate('/panel')
  }

  const goAutoEvResponses = () => {
    navigate('/reports/autoevresp')
  }

  const goAutoEvReport = () => {
    navigate('/reports/autoevrep')
  }

  const getReportEvaluator = (participant) => {
    setOpenLoading(true);
    
    const file = {
      idEvaluatorParticipant: receiver.idEvaluatorParticipant,
      fileName: participant.participantName + " " + participant.participantSurname + " " + t("LABEL.REPORT"),
      fileType: "participant"
    };

    serviceFiles.getPdfEvaluator(file)
      .then(() => setOpenLoading(false))
      .catch(error => {
        setOpenLoading(false);
        snackActions.error(t('EVALUATOR.ERROR_REPORT'));
      });
  };
  
  return (
    <>
    <Dialog
        title={t("TEXT.LOADING")}
        open={openLoading}
        hasCloseBtn={false}
        content={
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
          <Box>  
                <CircularProgress />   
          </Box>
          <br />
          <Box>
          {t("EVALUATOR.LOADING_DATA")}
          </Box>
          </div>
        }
    />
    <div className={classes.container}>
      <HeaderTitle
          title={t('EVALUATOR.AUTOEVALUATION')}
          subtitle={t('EVALUATOR.REPORTS_SUBTITLE')}
      />
      <br />
      <Card className={classes2.card}>
            <Grid container spacing={2} mt={0} p={1}>
                <Grid item xs={12} md={4} display={'flex'} justifyContent={'center'}>
                    <Button fullWidth className={classes2.btn} variant='contained' size='large' onClick={() => goAutoEvReport()}>
                        {t('EVALUATOR.AUTOEV_BTN')}
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} display={'flex'} justifyContent={'center'}>
                    <Button fullWidth className={classes2.btn} variant='contained' size='large' onClick={() => goAutoEvResponses()}>
                        {t('EVALUATOR.AUTOEV2_BTN')}
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} display={'flex'} justifyContent={'center'}>
                    <Button 
                        fullWidth 
                        className={classes2.btn} 
                        variant='contained' 
                        size='large' 
                        onClick={() => getReportEvaluator(participant)}
                        disabled={!conditionDisabled}
                    >
                        {t('EVALUATOR.FINAL_REPORT')}
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={0} p={1} display={'flex'} justifyContent={'end'}>
                <Grid item xs={12} md={4} display={'flex'} justifyContent={'center'}>
                    <Button className={classes2.btn} variant='outlined' size='large' onClick={() => goBack()}>
                        {t('TEXT.BACK')}
                    </Button>
                </Grid>
            </Grid>
      </Card>
    </div>
  </>
  )
}

export default Panel
