import {
    HomeOutlined,
    Info,
    MailOutline,
    ManageAccounts
} from '@mui/icons-material';

const menuParticipantsItems = t => [
    {
        title: t('MENU_PARTICIPANTS.HOME'),
        url: '/targets',
        icon: <HomeOutlined />,
    }, 
    {
        title: t('MENU_PARTICIPANTS.INFO'),
        url: '/cycledetails',
        icon: <Info />,
    },
    {
        title: t('MENU_PARTICIPANTS.TUTOR'),
        url: '/tutorship',
        icon: <MailOutline />,
    },
    {
        title: t('MENU_CONSULTANTS.PROFILE'),
        url: '/myprofile',
        icon: <ManageAccounts />,
    }
];

export default menuParticipantsItems;