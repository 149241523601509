import React, { useState, useEffect } from "react";
import { Card, Grid, Box, IconButton, Typography } from '@mui/material';
import { Link } from "react-router-dom";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { useStyles } from '@styles/owToolboxKitOw.style'

const WorksheetFavoriteCard = ({handleFavorite, ...props}) => {
    const classes = useStyles()
    const handleFavoriteClick = () => {
        handleFavorite(props.idMobileSheetFavorite);
    };

    return(
        <Card  variant="outlined" className={classes.card}>
            <Grid container spacing={1} style={{ padding: '0px', marginBottom: '0px'}}>
                <Grid xs={1} style={{ backgroundColor: props.mobileSheetColor }} item display="flex" justifyContent="center" ></Grid>
                <Grid item xs={10}>
                    <Link to={'/worksheet/' + props.idMobileSheet}>
                        <Box className={classes.boxCard}>
                            <Grid item>
                                <Typography className={classes.textCard} style={{ lineHeight: '1.0' }}>
                                    {props.mobileSheetTitle}
                                </Typography>
                            </Grid>
                        </Box>
                    </Link>
                </Grid>
                <Grid item xs={1} display="flex" justifyContent="center" >
                    <IconButton
                        className={classes.trashIcon}
                        onClick={() => {handleFavoriteClick()}}
                    >
                        <DeleteRoundedIcon sx={{fontSize: '1.2em !important'}}/>
                    </IconButton>

                </Grid>
            </Grid>
        </Card>
    )
}
export default WorksheetFavoriteCard;