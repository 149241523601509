import React from 'react';
import Loading from '@components/Loading';
import { useFlags } from '@hooks/useFlags';
import { useStyles } from '@styles/loginOWToolbox.style';
import SignInOWToolbox from './SignInOWToolbox';

const LoginOWToolboxPage = () => {
    const classes = useStyles();
    const { flagOptions } = useFlags();

    if (!flagOptions) return <Loading />;

    return (
        <div className={classes.loginOWToolbox}>
            <SignInOWToolbox flagOptions={flagOptions} />
        </div>
    );
};

export default LoginOWToolboxPage;